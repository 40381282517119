import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
import { useStartLink } from "../../../../hooks";
import { imagesFileUrl } from "../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [startLink] = useStartLink();
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:h-[360px] mx-auto gradient-plan relative">
      <section className="xl:w-[1128px]  mx-auto pt-6 flex">
        <span className="text-[#5408A9]">خانه</span>
        <span className="text-[#A6A9BD]">/ تمرینات ورزش</span>
      </section>
      <img
        src={baseFileUrl + "/bg-section1-excer.png"}
        alt=""
        className="absolute top-0 left-2 xl:left-8 w-[43%] xl:w-[378.86px] "
      />
      <img
        src={baseFileUrl + "/Vector-bg.png"}
        alt=""
        className="absolute -bottom-[40px] xl:top-[110px] right-8 xl:right-[177px] w-[30%] xl:w-[311.86px]"
      />

      <h2 className="xl:text-[50px] text-[32px] text-center mt-[65px] xl:mt-[108px] leading-[46.5px] xl:leading-[77.5px] font-bold text-[#191919]">
        برنامه‌های ورزشی
      </h2>
      <p className="text-center mb-[80px] w-[90%] mx-auto xl:w-full xl:mb-0 xl:mt-10 mt-4 leading-[21.7px] text-sm xl:text-balance xl:leading-[24.8px] text-[#3E4151]">راهی مطمئن به سوی تناسب اندام ایده‌آل و دستیابی به سلامتی پایدار برای همه افراد.</p>
    </section>
  );
}
