import React, { useState, useContext, useEffect } from "react";
import { Checkbox } from "antd";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
 
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full h-[200px] flex justify-center items-center">
      <span className="text-lg font-bold text-purple">به زودی</span>
      {/* <Checkbox.Group onChange={onChange}>
      <section className="grid grid-cols-3 gap-x-8 gap-y-6 mt-10">
        <section>
          <Checkbox className="font-bold text-base font-sans" value={1}>
            1 عدد تخم بزرگ بزرگ
          </Checkbox>
          <span className="block font-sans font-normal text-sm mr-6">
            72 کالری
          </span>
        </section>
        <section>
          <Checkbox className="font-bold text-base font-sans" value={2}>
            1 لیوان شیر
          </Checkbox>
          <span className="block font-sans font-normal text-sm mr-6">
            72 کالری
          </span>
        </section>
        <section>
          <Checkbox className="font-bold text-base font-sans" value={3}>
            ۱ قالب پنیر کوچک
          </Checkbox>
          <span className="block font-sans font-normal text-sm mr-6">
            72 کالری
          </span>
        </section>
        <section>
          <Checkbox className="font-bold text-base font-sans" value={4}>
            ۱ عدد نان تست بزرگ
          </Checkbox>
          <span className="block font-sans font-normal text-sm mr-6">
            72 کالری
          </span>
        </section>
        <section>
          <Checkbox className="font-bold text-base font-sans" value={5}>
            ۱ لیوان چای تلخ
          </Checkbox>
          <span className="block font-sans font-normal text-sm mr-6">
            72 کالری
          </span>
        </section>
      </section>
    </Checkbox.Group> */}
    </section>
  );
}
