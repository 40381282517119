import React, { useState, useContext, useEffect } from 'react'
import Filter from './components/Filter'
import { imagesFileUrl, svgFileUrl } from '../../../../../../../config'
import Select from 'react-select';
import MySelect from "../../../../../../../components/common/inputs/Select";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────
    const data = [
        { value: 1, label: 'برنامه تمرینی 1' },
        { value: 2, label: 'برنامه تمرینی 2' },
    ];
    // ─── States ─────────────────────────────────────────────────────────────────────
    const [level, setLevel] = useState(1)
    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='mt-[20px] border border-[#E6E8E9] rounded-[10px] px-[16px] py-[24px]'>
            <Filter />
            <section className='w-full  py-[27px] px-[16px] items-center  border mt-[30px] border-[#E6E8E9] relative rounded-[10px]'>
                <section className='flex justify-between items-center'>
                    <span className='flex gap-1 items-center justify-center w-[101px] h-[46px] rounded-[5px] bg-[#F1E8FC] text-purple'>
                        <img src={svgFileUrl + "/boxexr.svg"} alt='' />
                        تمرین 1 </span>
                    <section className='w-[225px] h-[48px] flex items-center justify-between'>
                        <Select
                            /* value={chartMode}
                            onChange={setChartMode} */
                            options={data}
                            placeholder="سیستم تمرینی"
                        />
                        <img src={svgFileUrl + "/trash.svg"} alt='' />
                    </section>
                </section>
                <section className='grid grid-cols-2 gap-3 mt-[33px]'>
                    <MySelect title="دسته بندی" />
                    <MySelect title="نام حرکت" />
                </section>
                <section className='grid grid-cols-2 xl:grid-cols-6 gap-3 mt-[33px]'>
                    <MySelect title="تعداد ست" />
                    <MySelect title="تکرار" />
                    <input placeholder='Tempo' type='text' className='border border-[#EAEAEA] rounded-[10px]' />
                    <MySelect title="Rir" />
                    <input placeholder='Rest' type='text' className='border border-[#EAEAEA] rounded-[10px]' />
                    <input placeholder='تکرار دستی' type='text' className='border border-[#EAEAEA] rounded-[10px]' />
                </section>
            </section>
            <section className='grid grid-cols-1 xl:grid-cols-3 gap-4'>
                <section className=' py-[19px] px-[16px]  h-[157px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]'>
                    <section className='flex justify-between'>
                        <span className='text-[#3E4151]'>تنظیمات پیشرفته</span>
                        <img className='w-[18px] h-[18px]' src={svgFileUrl + "/trash.svg"} alt='' />
                    </section>
                    <section className='flex justify-between mt-[29px]'>
                        <section className="flex cursor-pointer" onClick={() => setLevel(1)}>
                            <span className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full transition-all ${level === 1 && "bg-purple"}`}>
                                <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
                            </span>
                            <label className="text-sm font-normal  mr-2">توان به درصد</label>
                        </section>
                        <section className="flex cursor-pointer" onClick={() => setLevel(2)}>
                            <span className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full transition-all  ${level === 2 && "bg-purple"}`}>
                                <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
                            </span>
                            <label className="text-sm font-normal mr-2">وزن به کیلوگرم</label>
                        </section>
                    </section>
                    <section className='grid grid-cols-2 gap-3 mt-[10px]'>
                        <MySelect title="توان" />
                        <MySelect title="وزن" />
                    </section>
                </section>
                <section className='col-span-2 flex items-center   h-[157px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]'>
                    <span className='w-[72px] text-[#3E4151] font-bold absolute top-[-12px] bg-white right-4'>توضیحات</span>
                    <textarea className='resize-none text-[14px] text-[#3E4151] border-none w-[98%] h-[99%] m-auto'>
                        توضیح خود را بنویسید
                    </textarea>
                </section>
            </section>
            <button className="bg-[#F3509D] m-auto mt-[35px] text-white w-full h-[40px] rounded-md text-sm flex justify-center gap-1 items-center">
                ذخیره  حرکت
            </button>
        </section>
    )
}
