import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="homeSection5 h-[631px] xl:hidden flex relative flex-col  items-center">
        <img src={baseFileUrl + "/images/pic-bg-section5.png"} className="w-[364px]  mt-[-43px] mr-[7.75px] mb-[110px]" alt="" />
        <img src={baseFileUrl + "/images/Group 1000007144.png"} className="w-[121.83px] h-[119.89px] absolute top-[55px] right-[32.17px]" alt="" />
        <img src={baseFileUrl + "/images/bell.png"} className="w-[66px] h-[69px] absolute top-[13px] left-[69px]" alt="" />
        <img src={baseFileUrl + "/images/Group 1000007145.png"} className="w-[121.83px] h-[119.89px] absolute top-[190px] right-[32.17px]" alt="" />
        <img src={baseFileUrl + "/images/Group 1000007146.png"} className="w-[121.83px] h-[119.89px] absolute top-[95.78px] left-[41.21px]" alt="" />
        <img src={baseFileUrl + "/images/Group 1000007147.png"} className="w-[121.83px] h-[119.89px] absolute top-[223px] left-[35.21px]" alt="" />
        <section className="text-[28px] px-1 text-[#3E4151] font-semibold flex flex-col items-center tracking-tighter ">
          سـلامت سنــج و محاسبه گرها
          <Link to="/tools">
            <button className="bg-purple text-base w-[118px] h-[44px] rounded-full font-semibold  text-white mt-6">بزن بریم</button>
          </Link>
        </section>
      </section>
      <section className="homeSection5 h-[540px] hidden xl:flex justify-between items-center">
        <section className="mr-[111px]">
          <section className="text-[60px] font-semibold">
            سـلامت سنــج و
            <br />
            محاسبه گرها
          </section>
          <Link to="/tools">
            <button className="bg-purple  w-[250px] h-[60px] text-xl rounded-full text-white mt-10 font-bold">
              بزن بریم
            </button>
          </Link>
        </section>
        <img src={baseFileUrl + "/images/heart.png"} className="w-[658px] h-[602px] ml-10" alt="" />
      </section>
    </>
  );
}
