import React, { useState, useEffect } from "react";
import { imagesFileUrl, svgFileUrl } from "../../../config";
import { Link } from "react-router-dom";
import FilterItem from "./components/FilterItem";
import TableRow from "./components/TableRow";
import axios from "axios";
import api from "../../../api";
import Loading from "../../../components/common/componentLoading";
import { useAxiosWithToken } from "../../../hooks";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeFilter, setActiveFilter] = useState("OPEN");
  const [ticketStatusCounts, setTicketStatusCounts] = useState({});
  const [ticketList, setTicketList] = useState([]);
  const [loading, setLoading] = useState(false);

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getTicketStatusContent = () => {
    useAxiosWithToken
      .get(api.ticket.calculateTickets)
      .then((res) => {
        setTicketStatusCounts(res.data.ticketStatusCounts);
      })
      .catch((err) => {});
  };
  const getTicketList = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.ticket.searchTickets + "?ticketStatusEnum=" + activeFilter)
      .then((res) => {
        setLoading(false);
        setTicketList(res.data.elements);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getTicketStatusContent();
    getTicketList();
  }, []);
  useEffect(() => {
    getTicketList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-12 bg-white rounded-[8px] px-1 xl:px-[34px] py-[44px] shadow-md">
      <section className="px-[20px] xl:px-0 flex justify-between items-center">
        <span className="font-bold text-lg">تیکت های من</span>
        <Link to="/dashboard/addticket" className="bg-white w-[183px] h-[40px] flex justify-center items-center border border-purple text-purple rounded-full">
          <img src={svgFileUrl + "plus.svg"} alt="" />
          افزودن تیکت جدید
        </Link>
      </section>
      <section className="xl:w-[489px] h-[188px] mt-[52px] m-auto flex justify-around items-center ">
        <FilterItem setActiveFilter={setActiveFilter} src={imagesFileUrl + "dashboard/ticket1.png"} filter="OPEN" currentFilter={activeFilter} title="تیکت های باز" badge={ticketStatusCounts?.OPEN} />
        <FilterItem setActiveFilter={setActiveFilter} src={imagesFileUrl + "dashboard/ticket2.png"} filter="IN_PROGRESS" currentFilter={activeFilter} title="در حال بررسی" badge={ticketStatusCounts?.IN_PROGRESS} />
        <FilterItem setActiveFilter={setActiveFilter} src={imagesFileUrl + "dashboard/ticket3.png"} filter="RESOLVED" currentFilter={activeFilter} title="پاسخ داده شده" badge={ticketStatusCounts?.RESOLVED} />
        <FilterItem setActiveFilter={setActiveFilter} src={imagesFileUrl + "dashboard/ticket4.png"} filter="CLOSED" currentFilter={activeFilter} title="بسته شده" badge={ticketStatusCounts?.CLOSED} />
      </section>
      <section className="xl:w-[700px] m-auto text-center text-[14px] relative">
        <section className="hidden xl:grid  grid-cols-9 gap-1">
          <section className="w-full">شماره</section>
          <section className="w-full col-span-2">موضوع</section>
          <section className="w-full">بخش</section>
          <section className="w-full col-span-2">وضعیت</section>
          <section className="w-full">تاریخ</section>
          <section className="w-full col-span-2">عملیات</section>
        </section>
        <section className="relative min-h-12">
          <Loading loading={loading} />
          {ticketList.map((item, index) => (
            <TableRow rowData={item} />
          ))}
        </section>

        {/* <section className="grid grid-cols-9 gap-1 mt-[12px] border border-[#E8E7EB] h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
          <section className="w-full">120</section>
          <section className="w-full col-span-2">عدم امکان مشاهده پکیج</section>
          <section className="w-full">پشتیبانی</section>
          <section className="w-full col-span-2">
            <span className="flex items-center m-auto justify-center font-normal w-[69px] h-[26px] rounded-full bg-[#FEEAF3] text-[#F02484] ">
              باز
            </span>
          </section>
          <section className="w-full">1402/9/16</section>
          <section className="w-full col-span-2">
            <button className="w-[83px] h-[30px] bg-purple text-white rounded-full">
              مشاهده
            </button>
          </section>
        </section>
        <section className="grid grid-cols-9 gap-1 mt-[12px] border border-[#E8E7EB] h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
          <section className="w-full">120</section>
          <section className="w-full col-span-2">عدم امکان مشاهده پکیج</section>
          <section className="w-full">پشتیبانی</section>
          <section className="w-full col-span-2">
            <span className="flex items-center m-auto justify-center font-normal w-[69px] h-[26px] rounded-full bg-[#FEEAF3] text-[#F02484] ">
              باز
            </span>
          </section>
          <section className="w-full">1402/9/16</section>
          <section className="w-full col-span-2">
            <button className="w-[83px] h-[30px] bg-purple text-white rounded-full">
              مشاهده
            </button>
          </section>
        </section>
        <section className="grid grid-cols-9 gap-1 mt-[12px] border border-[#E8E7EB] h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
          <section className="w-full">120</section>
          <section className="w-full col-span-2">عدم امکان مشاهده پکیج</section>
          <section className="w-full">پشتیبانی</section>
          <section className="w-full col-span-2">
            <span className="flex items-center justify-center m-auto font-normal w-[69px] h-[26px] rounded-full bg-[#FEEAF3] text-[#F02484] ">
              باز
            </span>
          </section>
          <section className="w-full">1402/9/16</section>
          <section className="w-full col-span-2">
            <button className="w-[83px] h-[30px] bg-purple text-white rounded-full">
              مشاهده
            </button>
          </section>
        </section>
        <section className="grid grid-cols-9 gap-1 mt-[12px] border border-[#E8E7EB] h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
          <section className="w-full">120</section>
          <section className="w-full col-span-2">عدم امکان مشاهده پکیج</section>
          <section className="w-full">پشتیبانی</section>
          <section className="w-full col-span-2">
            <span className="flex items-center justify-center m-auto font-normal w-[69px] h-[26px] rounded-full bg-[#EEFAFE] text-[#0097C1]">
              بسته
            </span>
          </section>
          <section className="w-full">1402/9/16</section>
          <section className="w-full col-span-2">
            <button className="w-[83px] h-[30px] bg-purple text-white rounded-full">
              مشاهده
            </button>
          </section>
        </section>
        <section className="grid grid-cols-9 gap-1 mt-[12px] border border-[#E8E7EB] h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
          <section className="w-full">120</section>
          <section className="w-full col-span-2">عدم امکان مشاهده پکیج</section>
          <section className="w-full">پشتیبانی</section>
          <section className="w-full col-span-2">
            <span className="flex items-center m-auto justify-center font-normal w-[98px] h-[26px] rounded-full bg-[#FFF0E6] text-[#FB8733]">
              در حال بررسی
            </span>
          </section>
          <section className="w-full">1402/9/16</section>
          <section className="w-full col-span-2">
            <button className="w-[83px] h-[30px] bg-purple text-white rounded-full">
              مشاهده
            </button>
          </section>
        </section>
        <section className="grid grid-cols-9 gap-1 mt-[12px] border border-[#E8E7EB] h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
          <section className="w-full">120</section>
          <section className="w-full col-span-2">عدم امکان مشاهده پکیج</section>
          <section className="w-full">پشتیبانی</section>
          <section className="w-full col-span-2">
            <span className="flex items-center justify-center m-auto font-normal w-[115px] h-[26px] rounded-full bg-[#E9F8ED] text-[#21B249]">
              پاسخ داده شده
            </span>
          </section>
          <section className="w-full">1402/9/16</section>
          <section className="w-full col-span-2">
            <button className="w-[83px] h-[30px] bg-purple text-white rounded-full">
              مشاهده
            </button>
          </section>
        </section>
        <section className="flex justify-between items-center mt-[32px]">
          <span className="font-normal text-xs">7 مورد تیکت</span>
          <section className="W-[164px] h-[32px] flex items-center">
            <span className="flex justify-center items-center w-[32px] h-[32px] bg-[#0000001F] rounded-full">
              <img src={imagesFileUrl + 'svg/arrowRight.svg'} alt="" />
            </span>
            <section className="w-[72px] flex justify-between items-center">
              <span className="block w-[24px]">1</span>
              <span className="block w-[24px]">2</span>
              <span className="text-purple block w-[24px] font-normal text-sm">
                3
              </span>
            </section>
            <span className="flex justify-center items-center w-[32px] h-[32px] bg-[#986BCB] rounded-full">
              <img src={imagesFileUrl + 'svg/arrowLeft.svg'} alt="" />
            </span>
          </section>
          <span></span>
        </section> */}
      </section>
    </section>
  );
}
