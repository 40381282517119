import React, { useState } from "react";
import LevelSelector from "./components/LevelSelector";
import { imagesFileUrl } from "../../../../../config";
import Item from "./components/SingleExercise";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [level, setLevel] = useState(1);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="max-w-full w-[1133px]  mt-[240px] m-auto">
      <LevelSelector level={level} setLevel={setLevel} />
      <section className="w-full py-[60px] px-[20px]">
        <section className="w-full flex flex-wrap gap-y-6 justify-between">
          <Item
            img={imagesFileUrl + "dashboard/zumba.png"}
            muscle={imagesFileUrl + "dashboard/zumbaIcon.png"}
            duration="20:00"
            level={1}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
          <Item
            img={imagesFileUrl + "dashboard/zumba2.png"}
            muscle={imagesFileUrl + "dashboard/zumba2Icon.png"}
            duration="20:00"
            level={2}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
          <Item
            img={imagesFileUrl + "dashboard/zumba3.png"}
            muscle={imagesFileUrl + "dashboard/zumba3Icon.png"}
            duration="20:00"
            level={1}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
          <Item
            img={imagesFileUrl + "dashboard/zumba.png"}
            muscle={imagesFileUrl + "dashboard/zumbaIcon.png"}
            duration="20:00"
            level={1}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
          <Item
            img={imagesFileUrl + "dashboard/zumba2.png"}
            muscle={imagesFileUrl + "dashboard/zumba2Icon.png"}
            duration="20:00"
            level={2}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
          <Item
            img={imagesFileUrl + "dashboard/zumba3.png"}
            muscle={imagesFileUrl + "dashboard/zumba3Icon.png"}
            duration="20:00"
            level={1}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
        </section>
      </section>
    </section>
  );
}
