import React, { useState, useContext, useEffect, useRef } from 'react';
import './style.css';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  setMuscle,
  style,
  onClick,
  setFront11,
  setFront12,
  setFront22,
  setFront16,
  setFront7,
  back22,
  back7,
  back16,
  back11,
  back12
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const n1_1 = useRef();
  const n1_2 = useRef();
  const n7_1 = useRef();
  const n7_2 = useRef();
  const n8_1 = useRef();
  const n8_2 = useRef();
  const n11_1 = useRef();
  const n11_2 = useRef();
  const n12_1 = useRef();
  const n12_2 = useRef();
  const n16_1 = useRef();
  const n16_2 = useRef();
  const n29_1 = useRef();
  const n29_2 = useRef();
  const n20_1 = useRef();
  const n20_2 = useRef();
  const n20_3 = useRef();
  const n20_4 = useRef();
  const n22_1 = useRef();
  const n22_2 = useRef();
  const n23 = useRef();
  const n24 = useRef();
  const n25_1 = useRef();
  const n25_2 = useRef();
  const n25_3 = useRef();
  const n25_4 = useRef();
  const n26_1 = useRef();
  const n26_2 = useRef();
  const n27_1 = useRef();
  const n27_2 = useRef();
  const n28_1 = useRef();
  const n28_2 = useRef();
  const n28_3 = useRef();
  const n28_4 = useRef();
  const n30_1 = useRef();
  const n30_2 = useRef();
  const n31_1 = useRef();
  const n31_2 = useRef();
  const n32 = useRef();

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const n1Enter = () => {
    n1_1.current.classList.add('activeMuscle');
    n1_2.current.classList.add('activeMuscle');
  };
  const n1Leave = () => {
    n1_1.current.classList.remove('activeMuscle');
    n1_2.current.classList.remove('activeMuscle');
  };
  const n7Enter = () => {
    setFront7(true)
    n7_1.current.classList.add('activeMuscle');
    n7_2.current.classList.add('activeMuscle');
  };
  const n7Leave = () => {
    setFront7(false)
    n7_1.current.classList.remove('activeMuscle');
    n7_2.current.classList.remove('activeMuscle');
  };
  const n8Enter = () => {
    n8_1.current.classList.add('activeMuscle');
    n8_2.current.classList.add('activeMuscle');
  };
  const n8Leave = () => {
    n8_1.current.classList.remove('activeMuscle');
    n8_2.current.classList.remove('activeMuscle');
  };
  const n11Enter = () => {
    setFront11(true)
    n11_1.current.classList.add('activeMuscle');
    n11_2.current.classList.add('activeMuscle');
  };
  const n11Leave = () => {
    setFront11(false)
    n11_1.current.classList.remove('activeMuscle');
    n11_2.current.classList.remove('activeMuscle');
  };
  const n12Enter = () => {
    setFront12(true)
    n12_1.current.classList.add('activeMuscle');
    n12_2.current.classList.add('activeMuscle');
  };
  const n12Leave = () => {
    setFront12(false)
    n12_1.current.classList.remove('activeMuscle');
    n12_2.current.classList.remove('activeMuscle');
  };
  const n16Enter = () => {
    setFront16(true)
    n16_1.current.classList.add('activeMuscle');
    n16_2.current.classList.add('activeMuscle');
  };
  const n16Leave = () => {
    setFront16(false)
    n16_1.current.classList.remove('activeMuscle');
    n16_2.current.classList.remove('activeMuscle');
  };
  const n22Enter = () => {
    setFront22(true);
    n22_1.current.classList.add('activeMuscle');
    n22_2.current.classList.add('activeMuscle');
  };
  const n22Leave = () => {
    setFront22(false);
    n22_1.current.classList.remove('activeMuscle');
    n22_2.current.classList.remove('activeMuscle');
  };

  const n20Enter = () => {
    n20_1.current.classList.add('activeMuscle');
    n20_2.current.classList.add('activeMuscle');
    n20_3.current.classList.add('activeMuscle');
    n20_4.current.classList.add('activeMuscle');
  };
  const n20Leave = () => {
    n20_1.current.classList.remove('activeMuscle');
    n20_2.current.classList.remove('activeMuscle');
    n20_3.current.classList.remove('activeMuscle');
    n20_4.current.classList.remove('activeMuscle');
  };
  const n23Enter = () => {
    n23.current.classList.add('activeMuscle');
  };
  const n23Leave = () => {
    n23.current.classList.remove('activeMuscle');
  };
  const n24Enter = () => {
    n24.current.classList.add('activeMuscle');
  };
  const n24Leave = () => {
    n24.current.classList.remove('activeMuscle');
  };
  const n25Enter = () => {
    n25_1.current.classList.add('activeMuscle');
    n25_2.current.classList.add('activeMuscle');
    n25_3.current.classList.add('activeMuscle');
    n25_4.current.classList.add('activeMuscle');
  };
  const n25Leave = () => {
    n25_1.current.classList.remove('activeMuscle');
    n25_2.current.classList.remove('activeMuscle');
    n25_3.current.classList.remove('activeMuscle');
    n25_4.current.classList.remove('activeMuscle');
  };
  const n26Enter = () => {
    n26_1.current.classList.add('activeMuscle');
    n26_2.current.classList.add('activeMuscle');
  };
  const n26Leave = () => {
    n26_1.current.classList.remove('activeMuscle');
    n26_2.current.classList.remove('activeMuscle');
  };
  const n27Enter = () => {
    n27_1.current.classList.add('activeMuscle');
    n27_2.current.classList.add('activeMuscle');
  };
  const n27Leave = () => {
    n27_1.current.classList.remove('activeMuscle');
    n27_2.current.classList.remove('activeMuscle');
  };
  const n28Enter = () => {
    n28_1.current.classList.add('activeMuscle');
    n28_2.current.classList.add('activeMuscle');
    n28_3.current.classList.add('activeMuscle');
    n28_4.current.classList.add('activeMuscle');
  };
  const n28Leave = () => {
    n28_1.current.classList.remove('activeMuscle');
    n28_2.current.classList.remove('activeMuscle');
    n28_3.current.classList.remove('activeMuscle');
    n28_4.current.classList.remove('activeMuscle');
  };
  const n29Enter = () => {
    n29_1.current.classList.add('activeMuscle');
    n29_2.current.classList.add('activeMuscle');
  };
  const n29Leave = () => {
    n29_1.current.classList.remove('activeMuscle');
    n29_2.current.classList.remove('activeMuscle');
  };
  const n30Enter = () => {
    n30_1.current.classList.add('activeMuscle');
    n30_2.current.classList.add('activeMuscle');
  };
  const n30Leave = () => {
    n30_1.current.classList.remove('activeMuscle');
    n30_2.current.classList.remove('activeMuscle');
  };
  const n31Enter = () => {
    n31_1.current.classList.add('activeMuscle');
    n31_2.current.classList.add('activeMuscle');
  };
  const n31Leave = () => {
    n31_1.current.classList.remove('activeMuscle');
    n31_2.current.classList.remove('activeMuscle');
  };

  const n32Enter = () => {
    n32.current.classList.add('activeMuscle');
  };
  const n32Leave = () => {
    n32.current.classList.remove('activeMuscle');
  };

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (back11) {
      n11Enter();
    } else {
      n11Leave();
    }
  }, [back11]);
  useEffect(() => {
    if (back12) {
      n12Enter();
    } else {
      n12Leave();
    }
  }, [back12]);
  useEffect(() => {
    if (back22) {
      n22Enter();
    } else {
      n22Leave();
    }
  }, [back22]);
  useEffect(() => {
    if (back16) {
      n16Enter();
    } else {
      n16Leave();
    }
  }, [back16]);
  useEffect(() => {
    if (back7) {
      n7Enter();
    } else {
      n7Leave();
    }
  }, [back7]);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <svg
      version="1.1"
      id="manBack"
      x="0px"
      y="0px"
      onClick={onClick}
      style={style}
      viewBox="0 0 1162.2 1692.28"
    >
      <path
        class="st0"
        d="M697.94,1557.13c0,0,12.97-4.69,28.38,6.49c15.41,11.18,27.91,28.95,17.39,44.08
        c-10.53,15.13-31.58,38.16-40.79,52.63c0,0-30.92-9.21-28.95-57.9C675.94,1553.75,697.94,1557.13,697.94,1557.13z"
      />
      <path
        class="st0"
        d="M644.53,1427.26c0,0,18.9,129.12,12.33,160.7c-6.58,31.58-24.34,64.47-9.21,80.26
   c15.13,15.79,46.71,5.92,55.26-7.89c8.55-13.82-19.08-15.13-17.11-43.42c1.97-28.29,13.16-71.05,31.58-111.19
   c18.42-40.13,31.15-84.9,31.15-84.9s-8.03-25.73-50.84-25.68C654.88,1395.19,644.53,1427.26,644.53,1427.26z"
      />
      <path
        class="st1 n20"
        ref={n20_1}
        onMouseEnter={n20Enter}
        onMouseLeave={n20Leave}
        onClick={()=>setMuscle(20)}
        d="M685.36,1273.47c0,0,4.99,4.33,9.42,17.11c0,0,2.48,7.46,4.1,13.59c0,0-0.08,17.94-0.27,39.1
        c0,0-0.26,20.4-0.71,34.74c0,0-0.4,8.31-0.33,14.96c0.06,5.52-0.76,10.92-1.3,12.75c-6.52,22.37-32.19,32.24-39.42,34.87
        c-7.23,2.63-22.37-26.32-20.39-60.53c1.97-34.21,10.41-65.68,15.13-103.95c0,0,0.87-3.79,4.31-5.72
        C655.9,1270.39,672.76,1260.48,685.36,1273.47z"
      />
      <path
        class="st2"
        d="M698.88,1304.17l-0.26,47.84l-1.11,42.84c-0.27,5.48-0.83,9.13-0.83,9.13c-4.26,21.02-29.09,33.14-39.82,36.61
          c-7.33,2.37-22.37-26.32-20.39-60.53c1.97-34.21,10.3-67.79,15.13-103.95c1.13-8.46,20.35-12.2,29.16-6.51
          C692.7,1277.32,696.03,1295.24,698.88,1304.17z"
      />
      <path
        class="st1 n20"
        ref={n20_2}
        onMouseEnter={n20Enter}
        onMouseLeave={n20Leave}
        onClick={()=>setMuscle(20)}
        d="M739.1,1270.85c11.18,26.97,26.32,118.42,13.82,142.77c-12.5,24.34-26.97,18.42-38.82,12.5
        c-11.84-5.92-17.59-19.75-16.45-42.11c0.38-7.4,0.85-31.73,1.07-51.45c0.18-15.76,0.16-28.4,0.16-28.4
        c1.92-9.23,4.01-21.34,4.01-21.34c3.77-18.19,21.73-15.75,21.73-15.75C731.38,1267.6,739.1,1270.85,739.1,1270.85z"
      />
      <path
        class="st2"
        d="M728.7,1267.81c11.27,1.55,12.12,7.18,14.04,13.63c10.43,35.01,21.34,110.41,10.17,132.18
        c-12.5,24.34-26.87,18.38-38.97,12.44c-11.87-5.83-17.44-19.69-16.29-42.04c1.15-22.35,1.23-79.84,1.23-79.84l3.4-17.19
        C704.54,1261.33,728.7,1267.81,728.7,1267.81z"
      />
      <path
        class="st0 n29"
        ref={n29_2}
        onMouseEnter={n29Enter}
        onMouseLeave={n29Leave}
        onClick={()=>setMuscle(29)}
        d="M605.54,1020.5c0,0,7.63,45.55,8.93,73.66c1.42,30.75,7.64,72.46,7.64,72.46c1.75,11.08,4.3,24.64,4.3,24.64
        c12.25,45.17,25.18,84.84,25.18,84.84c12.63-20.64,20.53-33.95,31.2-63.46c9.17-29.07,11.76-40.6,10.96-70.39
        c-1.21-44.66,0.12-73.57-9.3-131.86c0,0-6.96-43.75-23.59-68.29l-31.63-6.14l-12.74,28.98
        C616.48,964.96,605.15,996.27,605.54,1020.5z"
      />
      <path
        class="st3"
        d="M605.54,1020.19c0,0,6.25,36.64,9.12,76.21c4.61,63.56,11.74,94.87,11.74,94.87
        c2.45,12,25.18,84.84,25.18,84.84s46.85-67.8,42.15-134.17c-3.87-54.58,1.28-120.6-23.64-182.36c-4.23-10.48-9.25-17.47-9.25-17.47
        c-16.33,0.02-31.63-6.14-31.63-6.14C605.44,981.55,605.54,1020.19,605.54,1020.19z"
      />
      <path
        class="st1 n28"
        ref={n28_2}
        onMouseEnter={n28Enter}
        onMouseLeave={n28Leave}
        onClick={()=>setMuscle(28)}
        d="M660.86,942.11c0,0,26.92,34.65,30.21,133.34c3.29,98.69,1.97,126.32,48.03,195.4c0,0-10.97-64.72-3.29-96.05
        c8.68-35.4,12.82-69.74,12.82-69.74c3.99-41.54-0.68-91.57-0.68-91.57c-4.52-48.44-16.09-77.51-16.09-77.51
        C689.1,944.93,660.86,942.11,660.86,942.11z"
      />
      <path
        class="st3"
        d="M660.86,942.11c0,0,26.92,34.65,30.21,133.34c3.29,98.69,1.97,126.32,48.03,195.4c0,0-10.62-64.64-3.29-96.05
   c8.19-35.08,12.82-69.74,12.82-69.74c8.54-110.35-16.77-169.08-16.77-169.08C685.19,944.88,660.86,942.11,660.86,942.11z"
      />
      <path
        class="st0 n28"
        ref={n28_1}
        onMouseEnter={n28Enter}
        onMouseLeave={n28Leave}
        onClick={()=>setMuscle(28)}
        d="M739.77,845.46c0,0,12.24,19.38,17.41,102.93c5.18,83.55-7.76,147.7-7.76,147.7
   c2.28-56.01-1.16-95.09-8.75-129.57c-2.66-10.08-5.13-20.82-8.89-30.51c-3.7-10.55,0.24-34.64,0.24-34.64l4.42-28.71l2.5-18.74
   L739.77,845.46z"
      />
      <path
        class="st3"
        d="M739.94,844.29c0,0,1.31,4.35,3.89,11.42c0,0,8.74,23.61,13.12,91.45c5.51,85.46-7.37,148.2-7.37,148.2
   s6.96-93.3-17.71-159.38c-4.56-12.22,1.41-42.57,1.41-42.57l3.15-20.1l1.91-13.68L739.94,844.29z"
      />
      <line class="st4" x1="582.75" y1="179.95" x2="582.75" y2="864.26" />
      <line class="st4" x1="582.15" y1="179.95" x2="582.15" y2="864.26" />
      <path
        class="st4"
        d="M639.17,247.16c-0.88,5.49-23.59,25.36-57.02,25.3c-28.14-0.05-55.82-25.3-55.82-25.3
   c-5.55-31.09-5.85-54.56-5.85-54.56s-16.01,3.84-22.31-27.25c-6.41-31.6,14.06-29.74,14.06-29.74s-6.57-20.01,0.3-50.91
   c6.87-30.9,36.88-51.62,70.22-51.62c33.32,0,63.35,20.72,70.22,51.62c6.85,30.9,0.28,50.91,0.28,50.91s20.49-1.87,14.08,29.74
   c-6.3,31.09-22.31,27.25-22.31,27.25S643.38,220.83,639.17,247.16z"
      />
      <path
        class="st5"
        d="M656.47,71.19c-0.23-3.06-2.61-9.39-3.26-11.14c-3.76-10.16-11.98-19.71-43.5-30.41
   c-52.07-17.67-87.19,19.86-87.19,19.86c-12.95,3.95-12.6,4.98-18.6,13.55c-4.86,6.94-5.17,22.59-3.27,28.63l10.01,36.94l1.83,13.95
   c3.95,9.79,5.94,12.9,9.86,26.34c2.78,9.53,7.41,24.86,12.86,32.57c5.14,7.29,18.67,8.73,42.96,8.73c30.53,0,46.61-3.58,51.33-10.44
   c4.11-5.98,10.28-17.48,14.57-31.71c3.16-10.48,9.53-21.52,11.47-32.36l1.89-21.36l-1.39-25.71
   C657.28,83.41,656.87,76.48,656.47,71.19z"
      />
      <path
        class="st0 n16"
        ref={n16_1}
        onMouseEnter={n16Enter}
        onMouseLeave={n16Leave}
        onClick={()=>setMuscle(16)}
        d="M535.67,935.98c0,0,22.04,38.82,23.68,84.21c0,0,12.51-63.81,13.82-128.95L535.67,935.98z"
      />
      <path
        class="st3"
        d="M535.67,935.98c0,0,22.04,38.82,23.68,84.21c0,0,12.51-63.81,13.82-128.95L535.67,935.98z"
      />
      <path
        class="st4 n16"
        ref={n16_2}
        onMouseEnter={n16Enter}
        onMouseLeave={n16Leave}
        onClick={()=>setMuscle(16)}
        d="M629.22,935.98c0,0-22.04,38.82-23.68,84.21c0,0-12.51-63.81-13.82-128.95L629.22,935.98z"
      />
      <path
        class="st3"
        d="M629.22,935.98c0,0-22.04,38.82-23.68,84.21c0,0-12.51-63.81-13.82-128.95L629.22,935.98z"
      />
      <path
        class="st6 n27"
        ref={n27_1}
        onMouseEnter={n27Enter}
        onMouseLeave={n27Leave}
        onClick={()=>setMuscle(27)}
        d="M582.75,864.26c-0.43,26.97,10.95,57.24,46.48,71.71s102.63,0,102.63,0c-6.58-20.98,8.86-65.3,8.88-110.43
   c0.02-47.34-12.91-75.79-26.96-91.34c0,0-11.82-7.89-16.43-11.34c-6.55-4.9-14.07-10.29-14.07-10.29
   c-34.83-4.92-67.41,21.63-81.4,52.78C585,802.97,583.31,828.88,582.75,864.26z"
      />
      <path
        class="st7"
        d="M582.15,864.26c-0.7,26.97,11.55,57.24,47.08,71.71s102.63,0,102.63,0c-6.58-20.98,8.86-65.3,8.88-110.43
   c0.02-48.16-13.28-76.32-26.96-91.34c0,0-9.43-6.29-14.3-9.8c-4.09-2.94-16.2-11.83-16.2-11.83c-23.41-4.71-47.33,12.19-47.33,12.19
   c-15.97,9.44-28.67,29.75-28.67,29.75C583.05,800.38,582.87,836.3,582.15,864.26z"
      />
      <path
        class="st6 n27"
        ref={n27_2}
        onMouseEnter={n27Enter}
        onMouseLeave={n27Leave}
        onClick={()=>setMuscle(27)}
        d="M581.31,864.26c0.43,26.97-10.95,57.24-46.48,71.71s-102.63,0-102.63,0c6.58-20.98-8.86-65.3-8.88-110.43
   c-0.02-47.34,12.91-75.79,26.96-91.34c0,0,11.82-7.89,16.43-11.34c6.55-4.9,14.07-10.29,14.07-10.29
   c34.83-4.92,67.41,21.63,81.4,52.78C579.06,802.97,580.74,828.88,581.31,864.26z"
      />
      <path
        class="st7"
        d="M581.9,864.26c0.7,26.97-11.55,57.24-47.08,71.71s-102.63,0-102.63,0c6.58-20.98-8.86-65.3-8.88-110.43
   c-0.02-48.16,13.28-76.32,26.96-91.34c0,0,9.43-6.29,14.3-9.8c4.09-2.94,16.2-11.83,16.2-11.83c23.41-4.71,47.33,12.19,47.33,12.19
   c15.97,9.44,28.67,29.75,28.67,29.75C581,800.38,581.18,836.3,581.9,864.26z"
      />
      <path
        class="st8"
        d="M838.26,492.74c-35.64-11.19-50.56-22.82-61.48-30.28c-45.25-30.93-79-82.67-79-82.67s30.65-48.36,67.39-41.01
   C801.9,346.12,869.24,362.74,838.26,492.74z"
      />
      <path
        class="st8"
        d="M908.32,660.1l-19.47-12.52l-11.46-3.64l-11.57,2.21l-11.53,9.74l-6.12,9.63
   c-28.6-19.2-52.48-26.35-52.48-26.35c2.27-9.37,2.03-18.27,1.86-28.66c-0.36-21.77-3.56-39.76,10.41-50.66
   c0,0,22.92-0.49,30.29,1.96c3.5,1.17,9.93,8.01,16.94,14.85c4.9,4.77,11.19,11.11,19,18.89c0.83,0.83,3.78,3.78,3.78,3.78
   c7.15,5.68,12.59,11,17.66,16.92c8.32,9.72,13.71,18.7,18.13,27.61c6.14,12.38,9.59,26.27,11.01,32.96L908.32,660.1z"
      />
      <path
        class="st9"
        d="M795.47,638.01c10.67,3.38,32.56,12.56,52.48,26.35c9.13-18.01,20.86-20.66,20.86-20.66
   c7.63-2.16,12.3-1.22,20.04,2.55c0,0,19.72,8.87,35.99,29.1c-7.29-36.07-26.21-61.01-46.99-77.3c0,0-11.02-11.16-15.85-15.84
   c-12.6-12.21-19.52-20.08-23.97-21.56c-10.6-2.53-23.49-1.98-30.29-1.96c-7.29,5.35-10.27,14.12-10.81,24.47
   c-0.52,5.03,0.36,20.78,0.53,35.34C797.57,626.89,796.78,633.1,795.47,638.01z"
      />
      <g>
        <path
          class="st0"
          d="M919.07,851.1c3.49-21.23,42.09-17,42.09-17c17.41,8.97,38.99,12.89,52.15,26.28
     c13.16,13.39,21.08,16.02,34.73,20.38c13.66,4.35,8.5,19.5-9.23,17.5c-17.75-2.01-26.87-5.78-38.17-10.13l0,0c0,0,0,0,0,0.01
     c14.52,31.62,22.2,55.45,26.51,83.87c0.31,18.36-31.41,15.87-43.89,10.06c-23.82-10.06-41.26-53.7-51.92-66.78
     C920.77,901.18,915.58,872.33,919.07,851.1z"
        />
      </g>
      <path class="st0" d="M1014.72,922.35c0,0-26.97,15.43-75.22,5.58" />
      <path class="st0" d="M1000.64,888.13c0,0-4.31-9.53-5.55-11.84" />
      <path
        class="st0 n12"
        ref={n12_1}
        onMouseEnter={n12Enter}
        onMouseLeave={n12Leave}
        onClick={()=>setMuscle(12)}
        d="M921.39,845.04c0,0-28.81-60.46-55.7-79.01c-26.89-18.55-69.14-91.85-69.99-126.88
   c0,0,79.97,24.04,113.01,95.04c33.04,71,35.45,100.08,35.45,100.08L921.39,845.04z"
      />
      <path
        class="st10"
        d="M920.66,844.37c0,0-28.76-60.36-55.6-78.87c-26.84-18.52-68.3-91.8-69.36-126.34c0,0,79.32,23.67,112.3,94.56
   c32.99,70.88,35.49,100.64,35.49,100.64L920.66,844.37z"
      />
      <path
        class="st1 n11"
        ref={n11_1}
        onMouseEnter={n11Enter}
        onMouseLeave={n11Leave}
        onClick={()=>setMuscle(11)}
        d="M943.49,834.35c0,0-17.85-75.75-47.36-121.58c0,0-16.57-26.89-47.95-47.26c0,0,14.75-32.54,40.68-17.93
   c0,0,18.68,7.87,36.22,29.26c12.61,15.38,19.91,41.31,23.05,66.64c6.91,55.77,19.52,93.68,19.52,93.68L943.49,834.35z"
      />
      <path
        class="st10"
        d="M943.49,834.35c0,0-1.68-12.23-5.67-24.76c0,0-9.27-29.28-11.97-34.44c0,0-19.86-53.55-36.33-71.36
   c0,0-16.58-22-41.33-38.29c0,0,3.95-8.32,10.77-14.63c0,0,5.25-4.58,10.78-6.24c9.25-2.77,18.45,2.6,18.45,2.6
   c9.82,4.61,20.14,12.86,20.14,12.86c23.38,19.16,33.17,38.11,39.76,83.1c8.13,55.5,19.55,93.96,19.55,93.96L943.49,834.35z"
      />
      <path
        class="st0 n26"
        ref={n26_1}
        onMouseEnter={n26Enter}
        onMouseLeave={n26Leave}
        onClick={()=>setMuscle(26)}
        d="M654.86,722.24c7.82-9.87,15.17-12.15,28.42-9.66c0,0,9.21,6.86,15.13,11.04c5.51,4.12,15.37,10.59,15.37,10.59
   c11.63,12.55,16.91,28.56,18.74,33.61c6.36,19.1,8.22,40.41,8.22,57.26c-0.14,6.55-0.16,12.89-0.8,19.21
   c-21.59-52.08-60.17-70.26-74.29-81.68C650.03,750.07,650.21,728.3,654.86,722.24z"
      />
      <path
        class="st7"
        d="M654.86,722.24c7.82-9.87,15.17-12.15,28.42-9.66c0,0,9.21,6.86,15.13,11.04c5.51,4.12,15.37,10.59,15.37,10.59
   c11.63,12.55,16.91,28.56,18.74,33.61c6.36,19.1,8.22,40.41,8.22,57.26c-0.14,6.55-0.16,12.89-0.8,19.21
   c-21.59-52.08-60.17-70.26-74.29-81.68C650.03,750.07,650.21,728.3,654.86,722.24z"
      />
      <path
        class="st1 n7"
        ref={n7_1}
        onMouseEnter={n7Enter}
        onMouseLeave={n7Leave}
        onClick={()=>setMuscle(7)}
        d="M732.31,342.59c16.63-5.71,28.24-4.74,33.6-3.66c7.27,1.46,20.93,3.66,39.7,13.16c0,0,23.08,10.6,33.63,38.82
   c0,0,9.62,23.59,6.3,57.87c-2.63,27.14-7.29,43.97-7.29,43.97c-13.41-4.5-19-6.65-24.89-9.17c-2.95-1.26-5.73-2.46-10.44-4.89
   c3.16-17.29-3.19-47.38-17.52-74.28C755.45,348.17,732.31,342.59,732.31,342.59z"
      />
      <path
        class="st11"
        d="M732.31,342.59c16.63-5.71,28.24-4.74,33.6-3.66c7.27,1.46,20.93,3.66,39.7,13.16c0,0,23.08,10.6,33.63,38.82
   c0,0,9.62,23.59,6.3,57.87c-2.63,27.14-7.29,43.97-7.29,43.97c-13.41-4.5-19-6.65-24.89-9.17c-2.95-1.26-5.73-2.46-10.44-4.89
   c3.16-17.29-3.19-47.38-17.52-74.28C755.45,348.17,732.31,342.59,732.31,342.59z"
      />
      <path
        class="st12 n8"
        ref={n8_1}
        onMouseEnter={n8Enter}
        onMouseLeave={n8Leave}
        onClick={()=>setMuscle(8)}
        d="M732.31,342.59c-9.62,3.42-17.73,9.95-17.73,9.95c-8.65,6.84-14.94,12.04-21.89,19.57
   c0,0,18.55,29.54,40.19,51.65c17.11,18.54,26.01,25.11,39.41,35.56c10.32,8.05,19.66,13.84,30.65,19.36
   c3.77-26.91-12.8-69.14-29.4-94.99C763.25,368.32,747.77,348.08,732.31,342.59z"
      />
      <path
        class="st13"
        d="M732.31,342.59c-9.62,3.42-17.73,9.95-17.73,9.95c-8.65,6.84-14.94,12.04-21.89,19.57
   c0,0,18.55,29.54,40.19,51.65c17.11,18.54,26.01,25.11,39.41,35.56c10.32,8.05,19.66,13.84,30.65,19.36
   c3.77-26.91-12.8-69.14-29.4-94.99C763.25,368.32,747.77,348.08,732.31,342.59z"
      />
      <path
        class="st1 n30"
        ref={n30_1}
        onMouseEnter={n30Enter}
        onMouseLeave={n30Leave}
        onClick={()=>setMuscle(30)}
        d="M764.2,453.17c16.83,19.94,25.3,45.01,34.07,70.06c3.92,9.88,5.61,17.58,9.7,36.61
   c8.01-0.36,23.18-0.01,30.29,1.96c4.8,2.05,11.09,8.96,19.38,17.2c10.63,10.58,18.73,18.72,18.73,18.72
   c-2.02-15.94-6.26-48.44-9.32-64.43c-8.84-22.01-11.92-24.77-28.62-40.42c-8.53-3.12-20.75-7.08-35.33-14.06
   C789.44,472.28,771.54,458.74,764.2,453.17z"
      />
      <path
        class="st7"
        d="M764.2,453.17c16.83,19.94,25.3,45.01,34.07,70.06c3.92,9.88,5.61,17.58,9.7,36.61
   c8.01-0.36,23.18-0.01,30.29,1.96c4.8,2.05,11.09,8.96,19.38,17.2c10.63,10.58,18.73,18.72,18.73,18.72
   c-2.02-15.94-6.26-48.44-9.32-64.43c-8.84-22.01-11.92-24.77-28.62-40.42c-8.53-3.12-20.75-7.08-35.33-14.06
   C789.44,472.28,771.54,458.74,764.2,453.17z"
      />
      <path class="st14" d="M868.78,655.45" />
      <path
        class="st1 n1"
        ref={n1_1}
        onMouseEnter={n1Enter}
        onMouseLeave={n1Leave}
        onClick={()=>setMuscle(1)}
        d="M584.84,181.37c0.97,21.25,2.69,37.08,3.17,47.93c0.42,16.7,0.73,23.25,1.82,35.2
   c0.83,7.66,4.01,19.85,8.02,28.85c6.71,15.06,11.97,20.17,17.04,23.98c0,0,53.64-42.33,53.64-42.62
   c-17.32-11.5-24.39-19.21-27.47-22.6c-9.72-11.88-12.99-18.15-16.54-35.01c-0.64-3.16-1.97-11.75-5.75-21.62
   C612.21,181.88,592.85,181.65,584.84,181.37z"
      />
      <path
        class="st3"
        d="M584.84,181.37c0.97,21.25,2.69,37.08,3.17,47.93c0.42,16.7,0.73,23.25,1.82,35.2
   c0.83,7.66,4.01,19.85,8.02,28.85c6.71,15.06,11.97,20.17,17.04,23.98c0,0,53.64-42.33,53.64-42.62
   c-17.32-11.5-24.39-19.21-27.47-22.6c-9.72-11.88-12.99-18.15-16.54-35.01c-0.64-3.16-1.97-11.75-5.75-21.62
   C612.21,181.88,592.85,181.65,584.84,181.37z"
      />
      <path
        class="st1 n1"
        ref={n1_2}
        onMouseEnter={n1Enter}
        onMouseLeave={n1Leave}
        onClick={()=>setMuscle(1)}
        d="M580.5,181.37c-0.97,21.25-2.69,37.08-3.17,47.93c-0.42,16.7-0.73,23.25-1.82,35.2
   c-0.83,7.66-4.01,19.85-8.02,28.85c-6.71,15.06-11.97,20.17-17.04,23.98c0,0-53.64-42.33-53.64-42.62
   c17.32-11.5,24.39-19.21,27.47-22.6c9.72-11.88,12.99-18.15,16.54-35.01c0.64-3.16,1.97-11.75,5.75-21.62
   C553.13,181.88,572.49,181.65,580.5,181.37z"
      />
      <path
        class="st3"
        d="M580.5,181.37c-0.97,21.25-2.69,37.08-3.17,47.93c-0.42,16.7-0.73,23.25-1.82,35.2
   c-0.83,7.66-4.01,19.85-8.02,28.85c-6.71,15.06-11.97,20.17-17.04,23.98c0,0-53.64-42.33-53.64-42.62
   c17.32-11.5,24.39-19.21,27.47-22.6c9.72-11.88,12.99-18.15,16.54-35.01c0.64-3.16,1.97-11.75,5.75-21.62
   C553.13,181.88,572.49,181.65,580.5,181.37z"
      />
      <path
        class="st0"
        d="M466.96,1557.13c0,0-12.97-4.69-28.38,6.49c-15.41,11.18-27.91,28.95-17.39,44.08
   c10.53,15.13,31.58,38.16,40.79,52.63c0,0,30.92-9.21,28.95-57.9C488.96,1553.75,466.96,1557.13,466.96,1557.13z"
      />
      <path
        class="st0"
        d="M520.37,1427.26c0,0-18.9,129.12-12.33,160.7c6.58,31.58,24.34,64.47,9.21,80.26
   c-15.13,15.79-46.71,5.92-55.26-7.89c-8.55-13.82,19.08-15.13,17.11-43.42c-1.97-28.29-13.16-71.05-31.58-111.19
   c-18.42-40.13-31.15-84.9-31.15-84.9s8.03-25.73,50.84-25.68C510.01,1395.19,520.37,1427.26,520.37,1427.26z"
      />
      <path
        class="st1 n20"
        ref={n20_3}
        onMouseEnter={n20Enter}
        onMouseLeave={n20Leave}
        onClick={()=>setMuscle(20)}
        d="M479.54,1273.47c0,0-4.99,4.33-9.42,17.11c0,0-2.48,7.46-4.1,13.59c0,0,0.08,17.94,0.27,39.1
   c0,0,0.26,20.4,0.71,34.74c0,0,0.4,8.31,0.33,14.96c-0.06,5.52,0.76,10.92,1.3,12.75c6.52,22.37,32.19,32.24,39.42,34.87
   c7.23,2.63,22.37-26.32,20.39-60.53c-1.97-34.21-10.41-65.68-15.13-103.95c0,0-0.87-3.79-4.31-5.72
   C508.99,1270.39,492.14,1260.48,479.54,1273.47z"
      />
      <path
        class="st2"
        d="M466.02,1304.17l0.26,47.84l1.11,42.84c0.27,5.48,0.83,9.13,0.83,9.13c4.26,21.02,29.09,33.14,39.82,36.61
   c7.33,2.37,22.37-26.32,20.39-60.53c-1.97-34.21-10.3-67.79-15.13-103.95c-1.13-8.46-20.35-12.2-29.16-6.51
   C472.2,1277.32,468.87,1295.24,466.02,1304.17z"
      />
      <path
        class="st0 n22"
        ref={n22_1}
        onMouseEnter={n22Enter}
        onMouseLeave={n22Leave}
        onClick={()=>setMuscle(22)}
        d="M614.9,317.33c8.18,5.87,22.45,13.05,45.35,20.75c26.37,7.83,34.5,10,54.33,14.46
   c16.98-12.68,31.6-14.06,40.88-14.37c-22.8-23.26-65.3-49.72-86.92-63.46L614.9,317.33z"
      />
      <path
        class="st3"
        d="M614.9,317.33c8.18,5.87,22.45,13.05,45.35,20.75c26.37,7.83,34.5,10,54.33,14.46
   c16.98-12.68,31.6-14.06,40.88-14.37c-22.8-23.26-65.3-49.72-86.92-63.46L614.9,317.33z"
      />
      <path
        class="st0 n22"
        ref={n22_2}
        onMouseEnter={n22Enter}
        onMouseLeave={n22Leave}
        onClick={()=>setMuscle(22)}
        d="M550.44,317.33c-8.18,5.87-22.45,13.05-45.35,20.75c-26.37,7.83-34.5,10-54.33,14.46
   c-16.98-12.68-31.6-14.06-40.88-14.37c22.8-23.26,65.3-49.72,86.92-63.46L550.44,317.33z"
      />
      <path
        class="st3"
        d="M550.44,317.33c-8.18,5.87-22.45,13.05-45.35,20.75c-26.37,7.83-34.5,10-54.33,14.46
   c-16.98-12.68-31.6-14.06-40.88-14.37c22.8-23.26,65.3-49.72,86.92-63.46L550.44,317.33z"
      />
      <path
        class="st1 n20"
        ref={n20_4}
        onMouseEnter={n20Enter}
        onMouseLeave={n20Leave}
        onClick={()=>setMuscle(20)}
        d="M425.8,1270.85c-11.18,26.97-26.32,118.42-13.82,142.77c12.5,24.34,26.97,18.42,38.82,12.5
   c11.84-5.92,17.59-19.75,16.45-42.11c-0.38-7.4-0.85-31.73-1.07-51.45c-0.18-15.76-0.16-28.4-0.16-28.4
   c-1.92-9.23-4.01-21.34-4.01-21.34c-3.77-18.19-21.73-15.75-21.73-15.75C433.51,1267.6,425.8,1270.85,425.8,1270.85z"
      />
      <path
        class="st2"
        d="M436.19,1267.81c-11.27,1.55-12.12,7.18-14.04,13.63c-10.43,35.01-21.34,110.41-10.17,132.18
   c12.5,24.34,26.87,18.38,38.97,12.44c11.87-5.83,17.44-19.69,16.29-42.04c-1.15-22.35-1.23-79.84-1.23-79.84l-3.4-17.19
   C460.36,1261.33,436.19,1267.81,436.19,1267.81z"
      />
      <path
        class="st0 n29"
        ref={n29_1}
        onMouseEnter={n29Enter}
        onMouseLeave={n29Leave}
        onClick={()=>setMuscle(29)}
        d="M559.35,1020.5c0,0-7.63,45.55-8.93,73.66c-1.42,30.75-7.64,72.46-7.64,72.46c-1.75,11.08-4.3,24.64-4.3,24.64
   c-12.25,45.17-25.18,84.84-25.18,84.84c-12.63-20.64-20.53-33.95-31.2-63.46c-9.17-29.07-11.76-40.6-10.96-70.39
   c1.21-44.66-0.12-73.57,9.3-131.86c0,0,6.96-43.75,23.59-68.29l31.63-6.14l12.74,28.98C548.41,964.96,559.75,996.27,559.35,1020.5z"
      />
      <path
        class="st3"
        d="M559.35,1020.19c0,0-6.25,36.64-9.12,76.21c-4.61,63.56-11.74,94.87-11.74,94.87
   c-2.45,12-25.18,84.84-25.18,84.84s-46.85-67.8-42.15-134.17c3.87-54.58-1.28-120.6,23.64-182.36c4.23-10.48,9.25-17.47,9.25-17.47
   c16.33,0.02,31.63-6.14,31.63-6.14C559.46,981.55,559.35,1020.19,559.35,1020.19z"
      />
      <path
        class="st1 n28"
        ref={n28_3}
        onMouseEnter={n28Enter}
        onMouseLeave={n28Leave}
        onClick={()=>setMuscle(28)}
        d="M504.04,942.11c0,0-26.92,34.65-30.21,133.34c-3.29,98.69-1.97,126.32-48.03,195.4c0,0,10.97-64.72,3.29-96.05
   c-8.68-35.4-12.82-69.74-12.82-69.74c-3.99-41.54,0.68-91.57,0.68-91.57c4.52-48.44,16.09-77.51,16.09-77.51
   C475.79,944.93,504.04,942.11,504.04,942.11z"
      />
      <path
        class="st3"
        d="M504.04,942.11c0,0-26.92,34.65-30.21,133.34c-3.29,98.69-1.97,126.32-48.03,195.4c0,0,10.62-64.64,3.29-96.05
   c-8.19-35.08-12.82-69.74-12.82-69.74c-8.54-110.35,16.77-169.08,16.77-169.08C479.7,944.88,504.04,942.11,504.04,942.11z"
      />
      <path
        class="st0 n28"
        ref={n28_4}
        onMouseEnter={n28Enter}
        onMouseLeave={n28Leave}
        onClick={()=>setMuscle(28)}
        d="M425.12,845.46c0,0-12.24,19.38-17.41,102.93c-5.18,83.55,7.76,147.7,7.76,147.7
   c-2.28-56.01,1.16-95.09,8.75-129.57c2.66-10.08,5.13-20.82,8.89-30.51c3.7-10.55-0.24-34.64-0.24-34.64l-4.42-28.71l-2.5-18.74
   L425.12,845.46z"
      />
      <path
        class="st3"
        d="M424.96,844.29c0,0-1.31,4.35-3.89,11.42c0,0-8.74,23.61-13.12,91.45c-5.51,85.46,7.37,148.2,7.37,148.2
   s-6.96-93.3,17.71-159.38c4.56-12.22-1.41-42.57-1.41-42.57l-3.15-20.1l-1.91-13.68L424.96,844.29z"
      />
      <path
        class="st8"
        d="M326.64,492.74c35.64-11.19,50.56-22.82,61.48-30.28c45.25-30.93,79-82.67,79-82.67s-30.65-48.36-67.39-41.01
   S295.66,362.74,326.64,492.74z"
      />
      <path
        class="st15"
        d="M256.58,660.1l19.47-12.52l11.46-3.64l11.57,2.21l11.53,9.74l6.12,9.63c28.6-19.2,52.48-26.35,52.48-26.35
   c-2.27-9.37-2.03-18.27-1.86-28.66c0.36-21.77,3.56-39.76-10.41-50.66c0,0-22.92-0.49-30.29,1.96c-3.5,1.17-9.93,8.01-16.94,14.85
   c-4.9,4.77-11.19,11.11-19,18.89c-0.83,0.83-3.78,3.78-3.78,3.78c-7.15,5.68-12.59,11-17.66,16.92
   c-8.32,9.72-13.71,18.7-18.13,27.61c-6.14,12.38-9.59,26.27-11.01,32.96L256.58,660.1z"
      />
      <path
        class="st9"
        d="M369.42,638.01c-10.67,3.38-32.56,12.56-52.48,26.35c-9.13-18.01-20.86-20.66-20.86-20.66
   c-7.63-2.16-12.3-1.22-20.04,2.55c0,0-19.72,8.87-35.99,29.1c7.29-36.07,26.21-61.01,46.99-77.3c0,0,11.02-11.16,15.85-15.84
   c12.6-12.21,19.52-20.08,23.97-21.56c10.6-2.53,23.49-1.98,30.29-1.96c7.29,5.35,10.27,14.12,10.81,24.47
   c0.52,5.03-0.36,20.78-0.53,35.34C367.32,626.89,368.11,633.1,369.42,638.01z"
      />
      <g>
        <path
          class="st0"
          d="M245.82,851.1c-3.49-21.23-42.09-17-42.09-17c-17.41,8.97-38.99,12.89-52.15,26.28
     c-13.16,13.39-21.08,16.02-34.73,20.38c-13.66,4.35-8.5,19.5,9.23,17.5c17.75-2.01,26.87-5.78,38.17-10.13l0,0c0,0,0,0,0,0.01
     c-14.52,31.62-22.2,55.45-26.51,83.87c-0.31,18.36,31.41,15.87,43.89,10.06c23.82-10.06,41.26-53.7,51.92-66.78
     C244.12,901.18,249.31,872.33,245.82,851.1z"
        />
      </g>
      <path class="st0" d="M150.18,922.35c0,0,26.97,15.43,75.22,5.58" />
      <path class="st0" d="M164.25,888.13c0,0,4.31-9.53,5.55-11.84" />
      <path
        class="st0 n12"
        ref={n12_2}
        onMouseEnter={n12Enter}
        onMouseLeave={n12Leave}
        onClick={()=>setMuscle(12)}
        d="M243.51,845.04c0,0,28.81-60.46,55.7-79.01c26.89-18.55,69.14-91.85,69.99-126.88c0,0-79.97,24.04-113.01,95.04
   c-33.04,71-35.45,100.08-35.45,100.08L243.51,845.04z"
      />
      <path
        class="st10"
        d="M244.23,844.37c0,0,28.76-60.36,55.6-78.87c26.84-18.52,68.3-91.8,69.36-126.34c0,0-79.32,23.67-112.3,94.56
   c-32.99,70.88-35.49,100.64-35.49,100.64L244.23,844.37z"
      />
      <path
        class="st1 n11"
        ref={n11_2}
        onMouseEnter={n11Enter}
        onMouseLeave={n11Leave}
        onClick={()=>setMuscle(11)}
        d="M221.41,834.35c0,0,17.85-75.75,47.36-121.58c0,0,16.57-26.89,47.95-47.26c0,0-14.75-32.54-40.68-17.93
   c0,0-18.68,7.87-36.22,29.26c-12.61,15.38-19.91,41.31-23.05,66.64c-6.91,55.77-19.52,93.68-19.52,93.68L221.41,834.35z"
      />
      <path
        class="st10"
        d="M221.41,834.35c0,0,1.68-12.23,5.67-24.76c0,0,9.27-29.28,11.97-34.44c0,0,19.86-53.55,36.33-71.36
   c0,0,16.58-22,41.33-38.29c0,0-3.95-8.32-10.77-14.63c0,0-5.25-4.58-10.78-6.24c-9.25-2.77-18.45,2.6-18.45,2.6
   c-9.82,4.61-20.14,12.86-20.14,12.86c-23.38,19.16-33.17,38.11-39.76,83.1c-8.13,55.5-19.55,93.96-19.55,93.96L221.41,834.35z"
      />
      <path
        class="st0 n26"
        ref={n26_2}
        onMouseEnter={n26Enter}
        onMouseLeave={n26Leave}
        onClick={()=>setMuscle(26)}
        d="M510.03,722.24c-7.82-9.87-15.17-12.15-28.42-9.66c0,0-9.21,6.86-15.13,11.04
   c-5.51,4.12-15.37,10.59-15.37,10.59c-11.63,12.55-16.91,28.56-18.74,33.61c-6.36,19.1-8.22,40.41-8.22,57.26
   c0.14,6.55,0.16,12.89,0.8,19.21c21.59-52.08,60.17-70.26,74.29-81.68C514.86,750.07,514.68,728.3,510.03,722.24z"
      />
      <path
        class="st7"
        d="M510.05,722.14c-7.82-9.87-15.17-12.15-28.42-9.66c0,0-9.21,6.86-15.13,11.04
   c-5.51,4.12-15.37,10.59-15.37,10.59c-11.63,12.55-16.91,28.56-18.74,33.61c-6.36,19.1-8.22,40.41-8.22,57.26
   c0.14,6.55,0.16,12.89,0.8,19.21c21.59-52.08,60.17-70.26,74.29-81.68C514.88,749.97,514.7,728.2,510.05,722.14z"
      />
      <path
        class="st1 n7"
        ref={n7_2}
        onMouseEnter={n7Enter}
        onMouseLeave={n7Leave}
        onClick={()=>setMuscle(7)}
        d="M432.59,342.59c-16.63-5.71-28.24-4.74-33.6-3.66c-7.27,1.46-20.93,3.66-39.7,13.16c0,0-23.08,10.6-33.63,38.82
   c0,0-9.62,23.59-6.3,57.87c2.63,27.14,7.29,43.97,7.29,43.97c13.41-4.5,19-6.65,24.89-9.17c2.95-1.26,5.73-2.46,10.44-4.89
   c-3.16-17.29,3.19-47.38,17.52-74.28C409.44,348.17,432.59,342.59,432.59,342.59z"
      />
      <path
        class="st11"
        d="M432.59,342.59c-16.63-5.71-28.24-4.74-33.6-3.66c-7.27,1.46-20.93,3.66-39.7,13.16
   c0,0-23.08,10.6-33.63,38.82c0,0-9.62,23.59-6.3,57.87c2.63,27.14,7.29,43.97,7.29,43.97c13.41-4.5,19-6.65,24.89-9.17
   c2.95-1.26,5.73-2.46,10.44-4.89c-3.16-17.29,3.19-47.38,17.52-74.28C409.44,348.17,432.59,342.59,432.59,342.59z"
      />
      <path
        class="st12 n8"
        ref={n8_2}
        onMouseEnter={n8Enter}
        onMouseLeave={n8Leave}
        onClick={()=>setMuscle(8)}
        d="M432.59,342.59c9.63,3.42,17.73,9.95,17.73,9.95c8.65,6.84,14.94,12.04,21.89,19.57
   c0,0-18.55,29.54-40.19,51.65c-17.11,18.54-26.01,25.11-39.41,35.56c-10.32,8.05-19.66,13.84-30.65,19.36
   c-3.77-26.91,12.8-69.14,29.4-94.99C401.64,368.32,417.12,348.08,432.59,342.59z"
      />
      <path
        class="st13"
        d="M432.59,342.59c9.63,3.42,17.73,9.95,17.73,9.95c8.65,6.84,14.94,12.04,21.89,19.57
   c0,0-18.55,29.54-40.19,51.65c-17.11,18.54-26.01,25.11-39.41,35.56c-10.32,8.05-19.66,13.84-30.65,19.36
   c-3.77-26.91,12.8-69.14,29.4-94.99C401.64,368.32,417.12,348.08,432.59,342.59z"
      />
      <path
        class="st1 n30"
        ref={n30_2}
        onMouseEnter={n30Enter}
        onMouseLeave={n30Leave}
        onClick={()=>setMuscle(30)}
        d="M400.69,453.17c-16.83,19.94-25.3,45.01-34.07,70.06c-3.92,9.88-5.61,17.58-9.7,36.61
        c-8.01-0.36-23.18-0.01-30.29,1.96c-4.8,2.05-11.09,8.96-19.38,17.2c-10.63,10.58-18.73,18.72-18.73,18.72
        c2.02-15.94,6.26-48.44,9.32-64.43c8.84-22.01,11.92-24.77,28.62-40.42c8.53-3.12,20.75-7.08,35.33-14.06
        C375.45,472.28,393.36,458.74,400.69,453.17z"
      />
      <path
        class="st7"
        d="M400.69,453.17c-16.83,19.94-25.3,45.01-34.07,70.06c-3.92,9.88-5.61,17.58-9.7,36.61
        c-8.01-0.36-23.18-0.01-30.29,1.96c-4.8,2.05-11.09,8.96-19.38,17.2c-10.63,10.58-18.73,18.72-18.73,18.72
        c2.02-15.94,6.26-48.44,9.32-64.43c8.84-22.01,11.92-24.77,28.62-40.42c8.53-3.12,20.75-7.08,35.33-14.06
        C375.45,472.28,393.36,458.74,400.69,453.17z"
      />
      <path class="st14" d="M296.12,655.45" />
      <path
        class="st6 n23"
        ref={n23}
        onMouseEnter={n23Enter}
        onMouseLeave={n23Leave}
        onClick={()=>setMuscle(23)}
        d="M590.44,272.46h-16.71c-7.23,28.29-15.05,39.92-30.73,49.4c-14.22,8.6-30.04,13.42-41.75,17.22
          c-22.42,7.28-50.93,13.45-50.93,13.45c11.51,8.93,12.83,10.23,21.89,19.57c9.21,9.49,17.26,22.87,17.26,22.87
          c15.3,23.19,21.75,49.82,26.6,86.4c0,0,44.81-0.25,64.43-0.5c17.46-0.22,68.31,0.5,68.31,0.5c5.93-40,10.59-63.32,28.14-88.74
          c0,0,5.82-10.41,15.82-20.66c6.43-6.59,11.42-11.81,21.77-19.45c0,0-14.93-1.49-37.22-8.72c0,0-44.58-11.16-65.57-28.21
          c0,0-3.46-2.29-7.75-8.69C599.41,300.06,594.35,288.62,590.44,272.46z"
      />
      <path
        class="st16"
        d="M590.44,272.46h-16.71c-7.23,28.29-15.05,39.92-30.73,49.4c-14.22,8.6-30.04,13.42-41.75,17.22
        c-22.42,7.28-50.93,13.45-50.93,13.45c11.51,8.93,12.83,10.23,21.89,19.57c9.21,9.49,17.26,22.87,17.26,22.87
        c15.3,23.19,21.75,49.82,26.6,86.4c0,0,44.81-0.25,64.43-0.5c17.46-0.22,68.31,0.5,68.31,0.5c5.93-40,10.59-63.32,28.14-88.74
        c0,0,5.82-10.41,15.82-20.66c6.43-6.59,11.42-11.81,21.77-19.45c0,0-14.93-1.49-37.22-8.72c0,0-44.58-11.16-65.57-28.21
        c0,0-3.46-2.29-7.75-8.69C599.41,300.06,594.35,288.62,590.44,272.46z"
      />
      <path
        class="st17 n24"
        ref={n24}
        onMouseEnter={n24Enter}
        onMouseLeave={n24Leave}
        onClick={()=>setMuscle(24)}
        d="M648.52,481.39c-11.92,28.31-19.13,44.17-27.08,61.55c-12.25,20.81-25.66,41.8-38.99,56.44
   c-11.66-14.05-18.45-23.74-29.45-41.78c-12.46-18.1-22.43-45.47-28.34-57.07c-5.55-12.26-6.29-13.31-8.88-19.14
   C580.6,481.21,583.7,481.57,648.52,481.39z"
      />
      <path
        class="st18"
        d="M648.52,481.39c-11.92,28.31-19.13,44.17-27.08,61.55c-12.25,20.81-25.66,41.8-38.99,56.44
   c-11.66-14.05-18.45-23.74-29.45-41.78c-12.46-18.1-22.43-45.47-28.34-57.07c-5.55-12.26-6.29-13.31-8.88-19.14
   C580.6,481.21,583.7,481.57,648.52,481.39z"
      />
      <path
        class="st0 n25"
        ref={n25_3}
        onMouseEnter={n25Enter}
        onMouseLeave={n25Leave}
        onClick={()=>setMuscle(25)}
        d="M760.01,463.97c0,0-8.73-14.55-54.45-4.72c0,0-28.15,4.47-56.74,22.13c0,0,3.23-23.04,7.92-43.25
   c0,0,3.15-13.62,10.9-29.96c0,0,13.84-25.44,25.05-36.06c0,0,1.94,3.05,5.26,7.94c6.59,9.73,19.26,27.38,37.88,46.78
   c8.27,8.62,16.84,16.9,28.38,26.33C764.2,453.17,763.51,458.44,760.01,463.97z"
      />
      <path
        class="st10"
        d="M760.01,463.97c0,0-8.73-14.55-54.45-4.72c0,0-28.15,4.47-56.74,22.13c0,0,3.23-23.04,7.92-43.25
   c0,0,3.15-13.62,10.9-29.96c0,0,13.84-25.44,25.05-36.06c0,0,1.94,3.05,5.26,7.94c6.59,9.73,19.26,27.38,37.88,46.78
   c8.27,8.62,16.84,16.9,28.38,26.33C764.2,453.17,763.51,458.44,760.01,463.97z"
      />
      <path
        class="st19 n25"
        ref={n25_1}
        onMouseEnter={n25Enter}
        onMouseLeave={n25Leave}
        onClick={()=>setMuscle(25)}
        d="M710.99,621.28c-5.73,22.98,4.48,24.98,7.87,61.41c2.96,31.82-5.22,51.33-5.22,51.33
   s-108.59-69.88-130.89-134.64c0,0,12.86-14.15,32.85-47.82c11.03-18.57,20.76-42.96,33.12-70.29c0,0,19.85-13.18,49.22-20.49
   c53.57-13.32,61.93,3.15,61.93,3.15c0,91.97-29.65,111.49-39.03,130.73C716.27,604.04,712.86,613.07,710.99,621.28z"
      />
      <path
        class="st20"
        d="M710.83,621.47c-5.73,22.98,4.48,24.98,7.87,61.41c2.96,31.82-5.22,51.33-5.22,51.33l-23.89-16.69
   c-12.05-9.19-24.18-18.35-35.52-28.04c0,0-32.13-27.27-50.66-52.63c0,0-15.78-20.23-20.96-37.47c0,0,12.55-11.39,38.99-56.44
   c0,0,17.16-36.94,27.08-61.55c0,0,19.93-13.22,49.26-20.42c53.61-13.15,61.93,3.01,61.93,3.01c-0.47,19.1,0.28,41.5-9.76,76.48
   c0,0-4.01,14.45-13.54,30.06c-4.72,7.73-11.07,15.65-15.73,24.34C717.1,601.52,712.7,613.25,710.83,621.47z"
      />
      <path
        class="st0 n25"
        ref={n25_4}
        onMouseEnter={n25Enter}
        onMouseLeave={n25Leave}
        onClick={()=>setMuscle(25)}
        d="M404.89,463.97c0,0,8.73-14.55,54.45-4.72c0,0,28.15,4.47,56.74,22.13c0,0-3.23-23.04-7.92-43.25
   c0,0-3.15-13.62-10.9-29.96c0,0-13.84-25.44-25.05-36.06c0,0-1.94,3.05-5.26,7.94c-6.59,9.73-19.26,27.38-37.88,46.78
   c-8.27,8.62-16.84,16.9-28.38,26.33C400.69,453.17,401.39,458.44,404.89,463.97z"
      />
      <path
        class="st10"
        d="M404.89,463.97c0,0,8.73-14.55,54.45-4.72c0,0,28.15,4.47,56.74,22.13c0,0-3.23-23.04-7.92-43.25
   c0,0-3.15-13.62-10.9-29.96c0,0-13.84-25.44-25.05-36.06c0,0-1.94,3.05-5.26,7.94c-6.59,9.73-19.26,27.38-37.88,46.78
   c-8.27,8.62-16.84,16.9-28.38,26.33C400.69,453.17,401.39,458.44,404.89,463.97z"
      />
      <path
        class="st0 n25"
        ref={n25_2}
        onMouseEnter={n25Enter}
        onMouseLeave={n25Leave}
        onClick={()=>setMuscle(25)}
        d="M453.91,621.28c5.73,22.98-4.48,24.98-7.87,61.41c-2.96,31.82,5.22,51.33,5.22,51.33
   s108.59-69.88,130.89-134.64c0,0-12.86-14.15-32.85-47.82c-11.03-18.57-20.76-42.96-33.12-70.29c0,0-19.85-13.18-49.22-20.49
   c-53.57-13.32-61.93,3.15-61.93,3.15c0,91.97,29.65,111.49,39.03,130.73C448.62,604.04,452.04,613.07,453.91,621.28z"
      />
      <path
        class="st20"
        d="M453.77,621.47c5.73,22.98-4.48,24.98-7.87,61.41c-2.96,31.82,5.22,51.33,5.22,51.33l23.89-16.69
   c12.05-9.19,24.18-18.35,35.52-28.04c0,0,32.13-27.27,50.66-52.63c0,0,16.08-20.23,21.26-37.47c0,0-12.31-12.2-38.75-57.25
   c0,0-17.88-38.7-27.62-60.74c0,0-19.93-13.22-49.26-20.42c-53.61-13.15-61.93,3.01-61.93,3.01c0.47,19.1-0.28,41.5,9.76,76.48
   c0,0,4.01,14.45,13.54,30.06c4.72,7.73,11.07,15.65,15.73,24.34C447.49,601.52,451.9,613.25,453.77,621.47z"
      />
      <path
        class="st1 n32"
        ref={n32}
        onMouseEnter={n32Enter}
        onMouseLeave={n32Leave}
        onClick={()=>setMuscle(32)}
        d="M481.62,712.46c0,0,14.25-10.7,25.93-20.31c0,0,26.9-22.84,49.06-49.33c19.97-23.86,25.84-43.44,25.84-43.44
   s4.84,18.09,26.77,44.97c20.92,25.64,47.7,48.38,47.7,48.38c11.52,9.62,26.36,19.85,26.36,19.85s-71.89-13.1-95.38,94.55
   c-5.55,25.42-5.78,55.45-5.78,55.45s-0.27-30.13-5.69-55.22c-16.11-74.64-57.21-92.09-81.41-95.37
   C486.89,710.89,481.62,712.46,481.62,712.46z"
      />
      <path
        class="st10"
        d="M481.62,712.46c0,0,14.25-10.7,25.93-20.31c0,0,26.9-22.84,49.06-49.33c19.97-23.86,25.84-43.44,25.84-43.44
   s4.84,18.09,26.77,44.97c20.92,25.64,47.7,48.38,47.7,48.38c11.52,9.62,26.36,19.85,26.36,19.85s-71.89-13.1-95.38,94.55
   c-5.55,25.42-5.78,55.45-5.78,55.45s-0.27-30.13-5.69-55.22c-16.11-74.64-57.21-92.09-81.41-95.37
   C486.89,710.89,481.62,712.46,481.62,712.46z"
      />
      <path
        class="st19"
        d="M764.2,453.17c-1.21,6.96-4.2,10.8-4.2,10.8c-0.3,9.48,0,17.58-1.57,35.58c-2.43,27.69-9.88,36.68-6.1,54.94
   c3.36,31.83,14.77,56.56,43.37,84.66c2.48-8.37,1.94-23.56,1.94-23.56c0.36-21.24-5.19-44.13,10.34-55.76
   c-1.34-6.21-2.87-13.15-4.09-19.03c-3.59-10.29-8.85-28.21-13.1-38.47C782.9,482.25,777.14,469.18,764.2,453.17z"
      />
      <path
        class="st14 n31"
        ref={n31_1}
        onMouseEnter={n31Enter}
        onMouseLeave={n31Leave}
        onClick={()=>setMuscle(31)}
        d="M764.2,453.17c-1.21,6.96-4.2,10.8-4.2,10.8c-0.3,9.48,0,17.58-1.57,35.58c-2.43,27.69-9.88,36.68-6.1,54.94
   c3.36,31.83,14.77,56.56,43.37,84.66c2.48-8.37,1.94-23.56,1.94-23.56c0.36-21.24-5.19-44.13,10.34-55.76
   c-1.34-6.21-2.87-13.15-4.09-19.03c-3.59-10.29-8.85-28.21-13.1-38.47C782.9,482.25,777.14,469.18,764.2,453.17z"
      />
      <path
        class="st0"
        d="M400.69,453.17c1.21,6.96,4.2,10.8,4.2,10.8c0.3,9.48,0,17.58,1.57,35.58c2.43,27.69,9.88,36.68,6.1,54.94
   c-3.36,31.83-14.77,56.56-43.37,84.66c-2.48-8.37-1.94-23.56-1.94-23.56c-0.36-21.24,5.19-44.13-10.34-55.76
   c1.34-6.21,2.87-13.15,4.09-19.03c3.59-10.29,8.85-28.21,13.1-38.47C381.99,482.25,387.76,469.18,400.69,453.17z"
      />
      <path
        class="st14 n31"
        ref={n31_2}
        onMouseEnter={n31Enter}
        onMouseLeave={n31Leave}
        onClick={()=>setMuscle(31)}
        d="M400.69,453.17c1.21,6.96,4.2,10.8,4.2,10.8c0.3,9.48,0,17.58,1.57,35.58c2.43,27.69,9.88,36.68,6.1,54.94
   c-3.36,31.83-14.77,56.56-43.37,84.66c-2.48-8.37-1.94-23.56-1.94-23.56c-0.36-21.24,5.19-44.13-10.34-55.76
   c1.34-6.21,2.87-13.15,4.09-19.03c3.59-10.29,8.85-28.21,13.1-38.47C381.99,482.25,387.76,469.18,400.69,453.17z"
      />
      <g class="st21">
        <path
          class="st22"
          d="M1789.47,1551.34c19.73,20.85,44.95,61.1,47.37,77.1c2.42,16-11.67,33.94-27.41,41.22
     c-15.74,7.27-30.56,0.97-39.39-3.88c-8.83-4.85-20.94-13.09-24.34-44.61c-3.4-31.52-3.89-35.88-14.56-44.61
     c-10.67-8.73-13.12-21.82-2.44-39.76C1739.37,1518.85,1778.46,1519.82,1789.47,1551.34z"
        />
        <path
          class="st22"
          d="M1811.87,1200.28c0,0,29.33,84.37,12.84,149.83s-45.58,127.53-47.03,149.83s11.79,51.4,11.79,51.4
     s-13.75-38.79-60.77-14.55l-2.9-17.48l51.97-288.49L1811.87,1200.28z"
        />
        <path
          class="st22"
          d="M1732.93,1218.2c0,0-18.77,62.08-13.92,96.99c4.85,34.91,17.17,126.23,13.92,144.82
     c-1.4,7.99-18.05,29.28-12.59,44.29c2.9,7.96,8.36,32.49,8.36,32.49s9.81-6.98,22.61-7.12c12.8-0.14,21.74-214.47,9.29-271.69
     C1741.69,1171.06,1732.93,1218.2,1732.93,1218.2z"
        />
        <path
          class="st22"
          d="M1826.83,1111.28c-6.77,20.21-8.42,92.88-26.85,119.55c0,0-17.94-49.94,0-86.31
     C1817.92,1108.15,1826.83,1111.28,1826.83,1111.28z"
        />
        <path
          class="st22"
          d="M1710.74,1139.95c0,0-1.11,34.95,9.56,58.87c10.67,23.92,34.59,38.47,34.59,38.47s13.92-55.28-9.2-82.43
     C1722.56,1127.71,1710.74,1139.95,1710.74,1139.95z"
        />
        <path
          class="st23"
          d="M1748.14,1216.09c0,0-12.4,13.12-9.77,35.75c2.62,22.63,17.81,85.92,17.58,128.33
     c-0.57,103.44-10.26,150.07-10.26,150.07s10.26-1.4,18.42-0.71c6.14-12.61,13.58-138.2,19.4-184.75
     c5.82-46.55,0.38-76.13,8.51-87.44c8.14-11.31,14.01-42.31,1.7-52.84C1781.4,1193.98,1748.14,1216.09,1748.14,1216.09z"
        />
        <path
          class="st24"
          d="M1799.95,1167.18c3.34,20.07-6.14,50.23-12.6,67.53c-4.99,13.36-28.3,12.92-32.46,2.59
     c-5.89-14.65-10.7-30.08-8.74-58.36c1.96-28.28,12.18-62.53,33.31-62.54C1800.58,1116.38,1796.61,1147.12,1799.95,1167.18z"
        />
        <path
          class="st22"
          d="M1551.23,1551.34c-19.73,20.85-44.95,61.1-47.37,77.1c-2.42,16,11.67,33.94,27.41,41.22
     c15.74,7.27,30.56,0.97,39.39-3.88c8.83-4.85,20.94-13.09,24.34-44.61c3.4-31.52,3.89-35.88,14.56-44.61
     c10.67-8.73,13.12-21.82,2.44-39.76C1601.32,1518.85,1562.23,1519.82,1551.23,1551.34z"
        />
        <path
          class="st25"
          d="M1528.83,1200.28c0,0-29.33,84.37-12.84,149.83s45.58,127.53,47.03,149.83c1.45,22.3-11.79,51.4-11.79,51.4
     s13.75-38.79,60.77-14.55l2.9-17.48l-51.97-288.49L1528.83,1200.28z"
        />
        <path
          class="st26"
          d="M1607.77,1218.2c0,0,18.77,62.08,13.92,96.99c-4.85,34.91-17.17,126.23-13.92,144.82
     c1.4,7.99,18.05,29.28,12.59,44.29c-2.9,7.96-8.36,32.49-8.36,32.49s-9.81-6.98-22.61-7.12c-12.8-0.14-21.74-214.47-9.29-271.69
     C1599.01,1171.06,1607.77,1218.2,1607.77,1218.2z"
        />
        <path
          class="st22"
          d="M1513.86,1111.28c6.77,20.21,8.42,92.88,26.85,119.55c0,0,17.94-49.94,0-86.31
     C1522.77,1108.15,1513.86,1111.28,1513.86,1111.28z"
        />
        <path
          class="st22"
          d="M1629.95,1139.95c0,0,1.11,34.95-9.56,58.87c-10.67,23.92-34.59,38.47-34.59,38.47s-13.92-55.28,9.2-82.43
     C1618.13,1127.71,1629.95,1139.95,1629.95,1139.95z"
        />
        <path
          class="st23"
          d="M1592.55,1216.09c0,0,12.4,13.12,9.78,35.75c-2.62,22.63-17.81,85.92-17.58,128.33
     c0.57,103.44,10.26,150.07,10.26,150.07s-10.26-1.4-18.42-0.71c-6.14-12.61-13.58-138.2-19.4-184.75
     c-5.82-46.55-0.38-76.13-8.51-87.44c-8.14-11.31-14.01-42.31-1.7-52.84C1559.3,1193.98,1592.55,1216.09,1592.55,1216.09z"
        />
        <path
          class="st24"
          d="M1540.75,1167.18c-3.34,20.07,6.14,50.23,12.6,67.53c4.99,13.36,28.3,12.92,32.46,2.59
     c5.89-14.65,10.7-30.08,8.74-58.36c-1.96-28.28-12.18-62.53-33.31-62.54C1540.11,1116.38,1544.08,1147.12,1540.75,1167.18z"
        />
        <path
          class="st24"
          d="M1800.62,722.53c0,0,17.32,11.12,15.38,35.05s-2.91,53.82-2.42,65.46c0.48,11.64-34.04-59.23-34.04-59.23
     S1782.33,729.67,1800.62,722.53z"
        />
        <path
          class="st24"
          d="M1539,722.53c0,0-17.32,11.12-15.38,35.05s2.91,53.82,2.42,65.46c-0.48,11.64,34.04-59.23,34.04-59.23
     S1557.3,729.67,1539,722.53z"
        />
        <path
          class="st22"
          d="M1675.47,883.91c0,0,1.86,69.56,12.04,108.83c10.18,39.28,18.2,82,18.25,99.4
     c0.05,17.4,121.03-245.56,54.06-302.19C1692.84,733.33,1675.47,883.91,1675.47,883.91z"
        />
        <path
          class="st22"
          d="M1784.73,756.76c0,0,64.46,75.49,55.79,195.75l-70.97-179.07L1784.73,756.76z"
        />
        <path
          class="st22"
          d="M1832.82,923.73c0,0,29.45,82.11,8.5,142.23c-20.95,60.13-29.97,92.13-35.89,99.89
     c-5.91,7.76-23.37-8.24-18.03-39.76C1792.73,1094.57,1832.82,923.73,1832.82,923.73z"
        />
        <path
          class="st23"
          d="M1779.54,763.8c12.51,82.36,58.07,95.6,53.67,181.42c-4.4,85.83-42.89,165.83-45.81,180.86
     c-2.92,15.03-15.06,14.23-17.85,3.39c-3-11.64-40.34-153.22-19.97-227.41c20.37-74.19,10.24-112.11,10.24-112.11
     S1766.72,760.6,1779.54,763.8z"
        />
        <path
          class="st23"
          d="M1766.83,1118.75c6.02,25.28-0.39,55.83-15.12,59.71c-14.73,3.88-48.68-11.15-45.77-81.46
     c2.91-70.31,32.13-158.49,42.25-189.31C1758.3,876.86,1766.83,1118.75,1766.83,1118.75z"
        />
        <path
          class="st22"
          d="M1664.16,883.91c0,0-1.86,69.56-12.04,108.83c-10.18,39.28-18.2,82-18.25,99.4
     c-0.05,17.4-121.03-245.56-54.06-302.19C1646.78,733.33,1664.16,883.91,1664.16,883.91z"
        />
        <path
          class="st22"
          d="M1554.9,756.76c0,0-64.46,75.49-55.79,195.75l70.97-179.07L1554.9,756.76z"
        />
        <path
          class="st22"
          d="M1506.81,923.73c0,0-29.45,82.11-8.5,142.23s29.97,92.13,35.89,99.89c5.91,7.76,23.37-8.24,18.03-39.76
     C1546.89,1094.57,1506.81,923.73,1506.81,923.73z"
        />
        <path
          class="st23"
          d="M1560.09,763.8c-12.51,82.36-58.07,95.6-53.67,181.42c4.4,85.83,42.89,165.83,45.81,180.86
     c2.92,15.03,15.06,14.23,17.85,3.39c3-11.64,40.34-153.22,19.97-227.41s-10.24-112.11-10.24-112.11S1572.91,760.6,1560.09,763.8z"
        />
        <path
          class="st23"
          d="M1572.8,1118.75c-6.02,25.28,0.39,55.83,15.12,59.71c14.73,3.88,48.68-11.15,45.77-81.46
     c-2.91-70.31-32.13-158.49-42.25-189.31C1581.32,876.86,1572.8,1118.75,1572.8,1118.75z"
        />
        <path
          class="st22"
          d="M1941.42,654.94c4.45-40.61-17.57-48.12-64.81-59.95h-0.01c0,0-7.14,72.65,24.27,84.8
     c31.41,12.15,42.98,14.78,50.21,26.33c0.01,0.02,0.02,0.03,0.04,0.05C1937.02,681.93,1941.42,654.94,1941.42,654.94z"
        />
        <path
          class="st22"
          d="M1958.31,717.67c-2.7-4.02-5.08-7.86-7.2-11.5c-0.01-0.02-0.02-0.03-0.04-0.05
     c-7.23-11.55-18.8-14.18-50.21-26.33c-5.74-2.22-10.55-6.29-14.59-11.43l0,0c0,0,4.04,40.5,28.97,60.81
     c24.93,20.31,67.37,97.87,71.18,112.07c0,0,4.89-2.98,13.98-2.48C2000.41,838.76,1978.96,748.43,1958.31,717.67z"
        />
        <path
          class="st22"
          d="M1876.61,594.98c-20.01-27.94-49.24-63.15-49.84-118.12c-0.67-60.94,46.61-64.99,52.39-2.84
     c3.32,35.66,49.31,91.28,47.57,114.27C1925.44,605.37,1896.62,622.92,1876.61,594.98z"
        />
        <path
          class="st23"
          d="M1941.08,576.87c0,0,44.97,35.88,56,86.06c11.03,50.19,26.26,150.61,26.26,150.61s-11.83,21.2-31.63,18.07
     c0,0-12.76-83.18-33.41-113.95c-20.66-30.76-16.89-62.73-16.89-62.73s-28.86-51.21-9.26-76.91L1941.08,576.87z"
        />
        <g class="st27">
          <path
            class="st4"
            d="M1983.25,833.09c1.96-21.05,40.09-19.54,40.09-19.54c17.68,7.6,39.11,9.96,52.92,22.19
       c13.81,12.23,21.76,14.26,35.45,17.6c13.69,3.33,9.67,18.54-7.85,17.78c-17.54-0.75-26.74-3.82-38.11-7.32l0,0c0,0,0,0,0,0
       c16.4,30,25.56,52.83,31.73,80.41c1.56,17.98-29.7,17.71-42.34,12.87c-24.04-8.23-44.12-49.82-55.47-61.91
       C1988.35,882.06,1981.29,854.14,1983.25,833.09z"
          />
          <path
            class="st4"
            d="M2065.75,863.8c-14.3-1.76-34.43,0.45-53.07-18.39"
          />
        </g>
        <path
          class="st26"
          d="M1724.33,250.13c-0.08,3.52,0.04,10.49,0,13.06v40.97c3.76,27.39,15.8,37.61,28.99,44.29
     c0,0,3.95,0.81,15.78,3.62c6.81,1.62,12.43,3.79,16.03,5.5c0,0-0.1-0.93,1.18-1.89c3.97-2.98,11.96-7.61,18.78-10.33
     c12.03-4.49,20.55-5.54,28.61-5.74c2.77-0.07,8.26,0.55,10.47,0.88c-1.18,0.03-3.88-1.28-4.64-1.96l-4.72-4.54
     c-2.91-2.7-7.46-6.53-7.46-6.53c-6.68-5.75-19.62-15.27-19.62-15.27c-16.02-12.28-68.74-44.64-75.48-51.96c0,0-2.6-2.28-3.86-3.78
     C1727.31,255.19,1724.84,251.41,1724.33,250.13z"
        />
        <path
          class="st23"
          d="M1735.28,496.07c0,0-22.25-11.3-29.81-15.85c-2.43-1.47-5.56-3.13-8.94-5.2c-0.05-0.03-0.12-0.07-0.17-0.1
     c-13.73-8.13-26.54,12.08-26.54,29.86s14.63,22.63,20.12,21.49c5.5-1.14,30.71,1.46,45.58,8.25c14.87,6.79,17.18,2.35,16.19-11.15
     C1750.71,509.87,1742.07,497.41,1735.28,496.07z"
        />
        <path
          class="st28"
          d="M1735.28,496.07c0,0,20.53,6.58,20.5,6.57c-24.29-5.55-42.75-17.87-50.31-22.43
     c-2.43-1.47-5.56-3.13-8.94-5.2c-0.05-0.03-0.12-0.07-0.17-0.1c-13.73-8.13-26.54,12.08-26.54,29.86s14.63,22.63,20.12,21.49
     c5.5-1.14,30.71,1.46,45.58,8.25c14.87,6.79,17.18,2.35,16.19-11.15C1750.71,509.87,1742.07,497.41,1735.28,496.07z"
        />
        <path
          class="st23"
          d="M1741.38,536.98c-0.03-0.01-0.06-0.03-0.09-0.05c-1.66-0.61-3.55-1.39-5.78-2.41
     c-12.01-5.48-30.75-8.23-40.42-8.48c-8.4,0.28-25.28,6.23-25.28,24.32c0,18.1,5.25,39.76,9.13,45.9
     c3.88,6.14,12.61,6.14,25.54,2.26c12.93-3.88,32-7.11,42.35-4.85c10.34,2.26,13.9-11.64,9.05-31.68
     C1751.03,541.96,1747.25,539.44,1741.38,536.98z"
        />
        <path
          class="st28"
          d="M1741.38,536.98c-0.03-0.01-0.06-0.03-0.09-0.05c-1.66-0.61-3.55-1.39-5.78-2.41
     c-12.01-5.48-30.75-8.23-40.42-8.48c-8.4,0.28-25.28,6.23-25.28,24.32c0,18.1,5.25,39.76,9.13,45.9
     c3.88,6.14,12.61,6.14,25.54,2.26c12.93-3.88,32-7.11,42.35-4.85c10.34,2.26,13.9-11.64,9.05-31.68
     C1751.03,541.96,1747.25,539.44,1741.38,536.98z"
        />
        <path
          class="st23"
          d="M1748.58,593.91c-0.56-0.02-1.14-0.09-1.75-0.22c-1.74-0.15-4.13-0.44-6.95-0.73
     c-10.61-0.24-24.99,2.45-35.4,5.58c-2.41,0.72-4.62,1.26-6.74,1.7c-0.03,0.02-0.06,0.04-0.09,0.06
     c-10.96,3.41-27.87,9.23-27.87,23.46c0,14.22,0.02,11.41,0.02,21.75c0,10.34,11.72,39.99,24.97,32.56
     c13.25-7.43,36.21-16.16,44.93-18.1c8.73-1.94,13.65-10.04,16.16-35.56C1758.22,600.62,1756.38,595.22,1748.58,593.91z"
        />
        <path
          class="st23"
          d="M1739.72,659.95c-3,0.91-6.85,2.04-11.08,3.37c-10.57,3.7-24.58,9.53-33.86,14.73
     c-10.54,7.66-24.97,21.98-24.97,44.61c0,22.63,0,63.29,0,90.93c0,27.64,30.89,3.26,48.28-34.88c17.4-38.14,31.96-80.55,33.26-97.07
     C1752.65,665.12,1750.69,656.61,1739.72,659.95z"
        />
        <path
          class="st23"
          d="M1604.35,496.07c0,0,22.25-11.3,29.81-15.85c2.43-1.47,5.56-3.13,8.94-5.2c0.05-0.03,0.12-0.07,0.17-0.1
     c13.73-8.13,26.54,12.08,26.54,29.86s-14.63,22.63-20.12,21.49c-5.5-1.14-30.71,1.46-45.58,8.25
     c-14.87,6.79-17.18,2.35-16.19-11.15C1588.91,509.87,1597.56,497.41,1604.35,496.07z"
        />
        <path
          class="st28"
          d="M1604.35,496.07c0,0-20.53,6.58-20.5,6.57c24.29-5.55,42.75-17.87,50.31-22.43c2.43-1.47,5.56-3.13,8.94-5.2
     c0.05-0.03,0.12-0.07,0.17-0.1c13.73-8.13,26.54,12.08,26.54,29.86s-14.63,22.63-20.12,21.49c-5.5-1.14-30.71,1.46-45.58,8.25
     c-14.87,6.79-17.18,2.35-16.19-11.15C1588.91,509.87,1597.56,497.41,1604.35,496.07z"
        />
        <path
          class="st23"
          d="M1587.57,536.98c0.03-0.01,0.06-0.03,0.09-0.05c1.66-0.61,3.55-1.39,5.78-2.41
     c12.01-5.48,30.75-8.23,40.42-8.48c8.4,0.28,25.28,6.23,25.28,24.32c0,18.1-5.25,39.76-9.13,45.9c-3.88,6.14-12.61,6.14-25.54,2.26
     c-12.93-3.88-32-7.11-42.35-4.85c-10.34,2.26-13.9-11.64-9.05-31.68C1577.92,541.96,1581.7,539.44,1587.57,536.98z"
        />
        <path
          class="st28"
          d="M1598.24,536.98c0.03-0.01,0.06-0.03,0.09-0.05c1.66-0.61,3.55-1.39,5.78-2.41
     c12.01-5.48,30.75-8.23,40.42-8.48c8.4,0.28,25.28,6.23,25.28,24.32c0,18.1-5.25,39.76-9.13,45.9c-3.88,6.14-12.61,6.14-25.54,2.26
     c-12.93-3.88-32-7.11-42.35-4.85c-10.34,2.26-13.9-11.64-9.05-31.68C1588.59,541.96,1592.38,539.44,1598.24,536.98z"
        />
        <path
          class="st23"
          d="M1591.04,593.91c0.56-0.02,1.14-0.09,1.75-0.22c1.74-0.15,4.13-0.44,6.95-0.73
     c10.61-0.24,24.99,2.45,35.4,5.58c2.41,0.72,4.62,1.26,6.74,1.7c0.03,0.02,0.06,0.04,0.08,0.06c10.96,3.41,27.87,9.23,27.87,23.46
     c0,14.22-0.02,11.41-0.02,21.75c0,10.34-11.72,39.99-24.97,32.56c-13.25-7.43-36.21-16.16-44.93-18.1
     c-8.73-1.94-13.65-10.04-16.16-35.56C1581.41,600.62,1583.25,595.22,1591.04,593.91z"
        />
        <path
          class="st23"
          d="M1599.91,659.95c3,0.91,6.85,2.04,11.08,3.37c10.57,3.7,24.58,9.53,33.85,14.73
     c10.54,7.66,24.97,21.98,24.97,44.61c0,22.63,0,63.29,0,90.93c0,27.64-30.89,3.26-48.28-34.88c-17.4-38.14-31.96-80.55-33.26-97.07
     S1588.93,656.61,1599.91,659.95z"
        />
        <path
          class="st29"
          d="M1873.56,467.58c0,0-12.82,30.14,2.19,95.62c15.02,65.48,65.67,91.74,65.67,91.74
     S1990.54,502.81,1873.56,467.58z"
        />
        <path
          class="st22"
          d="M1398.13,654.94c-4.45-40.61,17.57-48.12,64.81-59.95h0.01c0,0,7.14,72.65-24.27,84.8
     c-31.41,12.15-42.98,14.78-50.21,26.33c-0.01,0.02-0.02,0.03-0.04,0.05C1402.53,681.93,1398.13,654.94,1398.13,654.94z"
        />
        <path
          class="st22"
          d="M1381.24,717.67c2.7-4.02,5.08-7.86,7.2-11.5c0.01-0.02,0.02-0.03,0.04-0.05
     c7.23-11.55,18.8-14.18,50.21-26.33c5.74-2.22,10.55-6.29,14.59-11.43l0,0c0,0-4.04,40.5-28.97,60.81
     c-24.93,20.31-67.37,97.87-71.18,112.07c0,0-4.89-2.98-13.98-2.48C1339.14,838.76,1360.58,748.43,1381.24,717.67z"
        />
        <path
          class="st22"
          d="M1462.94,594.98c20.01-27.94,49.24-63.15,49.84-118.12c0.67-60.94-46.61-64.99-52.39-2.84
     c-3.32,35.66-49.31,91.28-47.57,114.27C1414.11,605.37,1442.93,622.92,1462.94,594.98z"
        />
        <path
          class="st23"
          d="M1398.47,576.87c0,0-44.97,35.88-56,86.06c-11.03,50.19-26.26,150.61-26.26,150.61s11.83,21.2,31.63,18.07
     c0,0,12.76-83.18,33.41-113.95c20.66-30.76,16.89-62.73,16.89-62.73s28.86-51.21,9.26-76.91L1398.47,576.87z"
        />
        <g class="st27">
          <path
            class="st4"
            d="M1356.29,833.09c-1.96-21.05-40.09-19.54-40.09-19.54c-17.68,7.6-39.11,9.96-52.92,22.19
       c-13.81,12.23-21.76,14.26-35.45,17.6c-13.69,3.33-9.67,18.54,7.85,17.78c17.54-0.75,26.74-3.82,38.11-7.32l0,0c0,0,0,0,0,0
       c-16.4,30-25.56,52.83-31.73,80.41c-1.56,17.98,29.7,17.71,42.34,12.87c24.04-8.23,44.12-49.82,55.47-61.91
       C1351.19,882.06,1358.26,854.14,1356.29,833.09z"
          />
          <path
            class="st4"
            d="M1273.8,863.8c14.3-1.76,34.43,0.45,53.07-18.39"
          />
        </g>
        <path
          class="st29"
          d="M1465.99,467.58c0,0,12.83,30.14-2.19,95.62c-15.02,65.48-65.67,91.74-65.67,91.74
     S1349.01,502.81,1465.99,467.58z"
        />
        <path
          class="st29"
          d="M1687.9,351.34c4.05-1.46,10.84,0.11,19.3,5.32c7.46,4.6,15.12,10.77,23.93,18.26
     c12.44,10.56,25,22.79,37.47,33.44c10.09,8.62,24.22,19.18,31.77,24.49c10.23,7.19,22.4,12.3,26.63,13.9
     c10.41,3.93,22.73,8.42,22.73,8.42s-7.65,10.73-10.93,16.03c-2.1,3.4-4.46,8.22-6.77,6.33c-4.84-3.96-6.47-6.48-16.12-9.83
     c-10.51-3.65-21.32-5.61-30.17-7.25c-13.28-2.46-36.31-4.5-44.36-5.25c-10.77-1-32.29-1.81-39.15-1.98
     c-7.84-0.2-17.56,0.02-24.71-0.39c0,0-6.42-10.05-6.92-25.44c-0.2-6.17-0.17-13.49-0.22-22.83c-0.08-13.98,2.21-24.7,6.1-37.23
     C1680.75,353.58,1687.9,351.34,1687.9,351.34z"
        />
        <path
          class="st29"
          d="M1785.12,357.49c-5.35-2.34-8.6-3.35-13.74-4.79c-12.96-3.64-26.67-5.87-29.31-6.18
     c-5.03-0.59-17.94-1.36-25.72-1.36c-15.93,0-26.07,0.21-32.5,8.79c0,0,2.96-3.13,6.86-3.13c3,0,6.32,0.93,8.42,1.75
     c17.51,6.8,53.47,41.72,60.93,48.29c17.61,15.52,34.85,28.64,48.56,37.18c5.12,3.19,15.16,7.77,25.61,11.36
     c15.27,5.25,15.57,5.82,15.96,5.15c0,0-10.23-4.45-26.42-25.97c-4.52-6.02-10.06-13.01-15.43-21.81
     C1796.3,387.09,1785.12,357.49,1785.12,357.49z"
        />
        <path
          class="st29"
          d="M1679.83,452.94c8.87-0.16,38.34-0.11,75.07,3.56c43.95,4.39,77.82,14.31,77.82,23.25
     c0,4.61-8.1,9.99-13.54,14.35c-16.49,13.2-43.64,10.74-54.95,9.47c-19.45-2.19-46.98-16.03-62.52-25.96
     c-8.27-5.29-25.59-20.58-24.19-24.77C1677.53,452.83,1678.76,452.96,1679.83,452.94z"
        />
        <path
          class="st29"
          d="M1872.5,467.06c0,0-7.64-4.56-15.04-8.43c-0.15-0.08-12.83-5.96-22.59-16.7
     c-8.68-9.55-17.16-21.18-24.02-31.28c-13.1-19.29-24.94-50.54-25.58-52.26c-0.71-1.89,1.28-2.97,3.31-4.35
     c3.21-2.18,7.53-4.53,9.78-5.67c9.86-5.04,23.88-8.91,35.18-8.75c2.05,0.03,4.35-0.38,4.98,0.83c0.32,0.61,0.56,2.77,3.04,6.66
     c5.81,9.1,11.41,19.06,14.47,25.29c8.61,17.49,17.17,38.37,16.54,69.17c-0.15,7.5-0.78,15.12-0.82,19.32
     C1871.69,467.17,1872.76,467.15,1872.5,467.06z"
        />
        <path
          class="st29"
          d="M1872.5,467.06c-3.01-0.98,4.04-30.71-3.65-60.86c-10.91-42.8-37.13-67.21-28.25-66.19
     c6.77,0.78,37.98,5.47,55.64,18.96c16.62,12.69,23.3,26.55,26.74,44.77c4.18,22.19,0.09,47.86-3.67,67.1
     c-0.6,3.05-4.67,18.3-4.67,18.3C1897.26,474.27,1880.31,469.61,1872.5,467.06z"
        />
        <path
          class="st26"
          d="M1615.01,250.13c0.08,3.52-0.04,10.49,0,13.06v40.97c-3.76,27.39-15.8,37.61-28.99,44.29
     c0,0-3.95,0.81-15.78,3.62c-6.81,1.62-12.43,3.79-16.03,5.5c0,0,0.1-0.93-1.18-1.89c-3.97-2.98-11.96-7.61-18.78-10.33
     c-12.03-4.49-20.55-5.54-28.61-5.74c-2.77-0.07-8.26,0.55-10.47,0.88c1.18,0.03,3.88-1.28,4.64-1.96l4.72-4.54
     c2.91-2.7,7.46-6.53,7.46-6.53c6.68-5.75,19.62-15.27,19.62-15.27c16.02-12.28,68.74-44.64,75.48-51.96c0,0,2.6-2.28,3.86-3.78
     C1612.03,255.19,1614.5,251.41,1615.01,250.13z"
        />
        <path
          class="st29"
          d="M1651.44,351.34c-4.05-1.46-10.84,0.11-19.3,5.32c-7.46,4.6-15.12,10.77-23.93,18.26
     c-12.44,10.56-25,22.79-37.47,33.44c-10.09,8.62-24.22,19.18-31.77,24.49c-10.23,7.19-22.4,12.3-26.63,13.9
     c-10.41,3.93-22.73,8.42-22.73,8.42s7.65,10.73,10.93,16.03c2.1,3.4,4.46,8.22,6.77,6.33c4.84-3.96,6.47-6.48,16.12-9.83
     c10.51-3.65,21.32-5.61,30.17-7.25c13.28-2.46,36.31-4.5,44.36-5.25c10.77-1,32.29-1.81,39.15-1.98c7.84-0.2,17.56,0.02,24.71-0.39
     c0,0,6.42-10.05,6.92-25.44c0.2-6.17,0.17-13.49,0.22-22.83c0.08-13.98-2.21-24.7-6.1-37.23
     C1658.59,353.58,1651.44,351.34,1651.44,351.34z"
        />
        <path
          class="st29"
          d="M1554.22,357.49c5.35-2.34,8.6-3.35,13.74-4.79c12.96-3.64,26.67-5.87,29.31-6.18
     c5.03-0.59,17.94-1.36,25.72-1.36c15.93,0,26.07,0.21,32.5,8.79c0,0-2.96-3.13-6.86-3.13c-3,0-6.32,0.93-8.42,1.75
     c-17.51,6.8-53.47,41.72-60.93,48.29c-17.61,15.52-34.85,28.64-48.56,37.18c-5.12,3.19-15.16,7.77-25.61,11.36
     c-15.27,5.25-15.57,5.82-15.96,5.15c0,0,10.23-4.45,26.42-25.97c4.52-6.02,10.06-13.01,15.43-21.81
     C1543.04,387.09,1554.22,357.49,1554.22,357.49z"
        />
        <path
          class="st30"
          d="M1659.51,452.94c-8.87-0.16-38.34-0.11-75.07,3.56c-43.95,4.39-77.82,14.31-77.82,23.25
     c0,4.61,8.1,9.99,13.54,14.35c16.49,13.2,43.64,10.74,54.95,9.47c19.45-2.19,46.98-16.03,62.52-25.96
     c8.27-5.29,25.59-20.58,24.19-24.77C1661.81,452.83,1660.58,452.96,1659.51,452.94z"
        />
        <path
          class="st29"
          d="M1466.84,467.06c3.01-0.98-4.04-30.71,3.65-60.86c10.91-42.8,37.13-67.21,28.25-66.19
     c-6.77,0.78-37.98,5.47-55.64,18.96c-16.62,12.69-23.3,26.55-26.74,44.77c-4.18,22.19-0.09,47.86,3.67,67.1
     c0.6,3.05,4.67,18.3,4.67,18.3C1442.08,474.27,1459.03,469.61,1466.84,467.06z"
        />
        <path
          class="st29"
          d="M1466.84,467.06c0,0,7.64-4.56,15.04-8.43c0.16-0.08,12.83-5.96,22.59-16.7
     c8.68-9.55,17.16-21.18,24.02-31.28c13.1-19.29,24.94-50.54,25.58-52.26c0.71-1.89-1.28-2.97-3.31-4.35
     c-3.21-2.18-7.53-4.53-9.78-5.67c-9.86-5.04-23.88-8.91-35.18-8.75c-2.05,0.03-4.35-0.38-4.98,0.83c-0.32,0.61-0.56,2.77-3.04,6.66
     c-5.81,9.1-11.41,19.06-14.47,25.29c-8.61,17.49-17.17,38.37-16.54,69.17c0.15,7.5,0.78,15.12,0.82,19.32
     C1467.65,467.17,1466.58,467.15,1466.84,467.06z"
        />
        <path
          class="st26"
          d="M1512.95,488.1c1.33,1.1,2.51,2.08,3.93,3.15c2.07,1.57,4.72,2.73,6.32,4.78
     c16.04,20.55,56.23,14.12,65.68,38.99c0.99,2.62,5.51,3.33,5.51,3.33s-3.6,4.14-4.66,5.99c-2.24,3.9-3.61,9.25-5.26,14.73
     c-0.99,3.29-1.48,6.25-2.2,10.46c-1.93,4.17-2.88,8.63-4.28,13.04c-4.37,13.79-8,28.44-6.74,41.93
     c5.03,23.24,10.65,60.05,11.55,77.17c1.25,9.86-5.78,38.82-17.36,52.81c-5.36,4.91-7.5,4.9-10.28,4.83
     c-19.17-27.16-22.05-52.92-20.11-76.89c0.79-8.87,1.62-14.93,2.76-20.69c0.85-4.28,2.04-8.71,3.48-13.44
     c1-3.27,2.11-6.64,2.83-10.57c0.54-2.95,0.42-4.67,0.09-10.06c-0.79-5.61-2.08-11.51-3.92-16.82c-3.15-9.1-6.68-17.54-12.01-25.06
     c-2.16-3.05-4.24-6-6.4-9.26c-4.93-7.45-9.81-15.87-13.62-25.56c-1.33-3.39-2.58-6.53-3.43-9.27c-0.51-1.66-0.99-3.34-1.47-4.86
     c-0.66-2.08-1.17-3.99-1.24-5.65c0,0,3.86-9.69,5.46-16.22c0.74-2.43,1.7-7.04,2.75-11.72
     C1511.7,497.16,1511.78,490.97,1512.95,488.1z"
        />
        <path
          class="st26"
          d="M1827.74,487.64c-1.33,1.1-2.51,2.08-3.93,3.15c-2.07,1.57-4.72,2.73-6.32,4.78
     c-16.04,20.55-56.23,14.12-65.68,38.99c-0.99,2.62-5.51,3.33-5.51,3.33s3.6,4.14,4.66,5.99c2.24,3.9,3.61,9.25,5.26,14.73
     c0.99,3.29,1.48,6.25,2.2,10.46c1.93,4.17,2.88,8.63,4.28,13.04c4.37,13.79,8,28.44,6.74,41.93
     c-5.03,23.24-10.65,60.05-11.55,77.17c-1.25,9.86,5.78,38.82,17.36,52.81c5.36,4.91,7.5,4.9,10.28,4.83
     c19.17-27.16,22.05-52.92,20.11-76.89c-0.79-8.87-1.62-14.93-2.76-20.69c-0.85-4.28-2.04-8.71-3.48-13.44
     c-1-3.27-2.11-6.64-2.83-10.57c-0.54-2.95-0.42-4.67-0.09-10.06c0.79-5.61,2.08-11.51,3.92-16.82c3.15-9.1,6.68-17.54,12.01-25.06
     c2.16-3.05,4.24-6,6.4-9.26c4.93-7.45,9.81-15.87,13.62-25.56c1.33-3.39,2.58-6.53,3.43-9.27c0.51-1.66,0.99-3.34,1.47-4.86
     c0.66-2.08,1.17-3.99,1.24-5.65c0,0-3.86-9.69-5.46-16.22c-0.74-2.43-1.7-7.04-2.75-11.72
     C1828.98,496.7,1828.9,490.51,1827.74,487.64z"
        />
        <path
          class="st31"
          d="M1724.3,213.77c-0.11,2.64-0.22,89.11,0,90.58c1.77,11.67,5.36,24.17,14.29,33.61
     c6.07,6.42,14.64,10.47,14.64,10.47c-10.43-2.4-30.04-3.58-41.92-3.27c-14.56,0.38-22.65,0.28-31.36,14.06
     c-3.15,4.99-6.71,19.87-6.71,19.87c-2.56,11.29-3.97,27.49-3.97,27.49s-1.08-19.93-3.18-27.58c0,0-3.94-16.45-7.13-20.51
     c0,0-4.9-6.96-8.03-8.92c-8-5.03-20.5-4.27-26.7-4.41c-24.75-0.54-38.2,3.29-38.2,3.29c12.79-6.18,22.65-16.32,27.27-34.91
     c0.9-3.62,1.72-9.37,1.72-9.37c0.1-6.82-0.13-11.2,0.03-19.14c0,0-0.01-20.33-0.01-27.89c0-12.07,0.01-43.36,0.01-43.36
     s29.64,46.42,51.22,46.1C1688.04,259.55,1724.3,213.77,1724.3,213.77z"
        />
        <path
          class="st32"
          d="M1713.89,240.67c-5.92,9.23-13.78,17.4-24.2,22.07c-5.79,2.61-12.4,4.13-19.9,4.13
     c-7.5,0-14.11-1.52-19.9-4.13c-10.44-4.67-18.32-12.86-24.22-22.11c0-0.02,0-0.02,0-0.02c-14.19-22.24-17.07-42.21-17.07-42.21
     s-15.74,3.77-21.93-26.78c-6.29-31.05,13.82-29.22,13.82-29.22s-6.46-19.67,0.29-50.03c6.75-30.37,36.24-50.73,69.01-50.73
     c32.74,0,62.25,20.36,69.01,50.73c6.73,30.37,0.27,50.03,0.27,50.03s20.13-1.83,13.84,29.22c-6.19,30.55-21.93,26.78-21.93,26.78
     S1728.11,218.43,1713.89,240.67z"
        />
        <path
          class="st33"
          d="M1732.68,44.99c-15.3-19.57-39.62-15.06-39.62-15.06s-15.81-8.57-31.01-8.38
     c-20.39,0.26-43.02,12.52-55.23,23.44c-8.79,7.86-16.84,27.14-16.84,41.24c0,20.63,3.34,44.14,9.78,56.41
     c-1.25-27.31,5.97-31.25,6.43-40.04c0.38-7.27-1.27-15.28,2.11-22.02c5.77-11.49,21.88-7.77,31.29-5.2
     c15.22,4.16,28.94,5.65,44.68,3.88c12.59-1.42,32.11-13.13,43.84-2.7c6.19,5.51,5.14,15.13,5.1,22.77
     c-0.01,1.09-0.02,2.18,0.04,3.27c0.46,8.78,7.53,10.48,6.43,40.04c6.44-12.27,9.78-35.78,9.78-56.41
     C1749.48,72.13,1740.02,54.38,1732.68,44.99z"
        />
      </g>
      <g class="st21">
        <path
          class="st22"
          d="M-683.86,1548.98c0,0-12.94-4.68-28.32,6.48c-15.38,11.16-27.85,28.89-17.35,43.99
     c10.5,15.1,31.51,38.08,40.71,52.53c0,0,30.86-9.19,28.89-57.78C-661.9,1545.61-683.86,1548.98-683.86,1548.98z"
        />
        <path
          class="st22"
          d="M-630.56,1419.38c0,0-18.86,128.85-12.3,160.37c6.56,31.52,24.29,64.34,9.19,80.1
     c-15.1,15.76-46.62,5.91-55.15-7.88c-8.54-13.79,19.04-15.1,17.07-43.33c-1.97-28.23-13.13-70.91-31.51-110.96
     c-18.38-40.05-31.09-84.72-31.09-84.72s8.01-25.68,50.74-25.63C-640.89,1387.38-630.56,1419.38-630.56,1419.38z"
        />
        <path
          class="st22"
          d="M-453.95,1548.98c0,0,12.94-4.68,28.32,6.48c15.38,11.16,27.85,28.89,17.35,43.99
     c-10.5,15.1-31.51,38.08-40.71,52.53c0,0-30.86-9.19-28.89-57.78C-475.91,1545.61-453.95,1548.98-453.95,1548.98z"
        />
        <path
          class="st22"
          d="M-507.25,1419.38c0,0,18.86,128.85,12.3,160.37c-6.56,31.52-24.29,64.34-9.19,80.1
     c15.1,15.76,46.62,5.91,55.15-7.88c8.54-13.79-19.04-15.1-17.07-43.33c1.97-28.23,13.13-70.91,31.51-110.96
     c18.38-40.05,31.09-84.72,31.09-84.72s-8.01-25.68-50.74-25.63C-496.92,1387.38-507.25,1419.38-507.25,1419.38z"
        />
        <path
          class="st23"
          d="M-682.21,1134.64c0,0-7.76,222.57-1.26,244.9c6.51,22.32,32.12,32.17,39.34,34.8
     c7.22,2.63,22.32-26.26,20.35-60.4c-1.97-34.14-10.39-65.55-15.1-103.74C-643.59,1212-656.6,1128.07-682.21,1134.64z"
        />
        <path
          class="st23"
          d="M-458.13,1134.64c0,0,7.76,222.57,1.26,244.9c-6.51,22.32-32.12,32.17-39.34,34.8
     c-7.22,2.63-22.32-26.26-20.35-60.4c1.97-34.14,10.39-65.55,15.1-103.74C-496.76,1212-483.74,1128.07-458.13,1134.64z"
        />
        <g class="st27">
          <path
            class="st8"
            d="M-592.96,971.12c0,0-23.64,53.06-26.92,115.49c-3.28,62.44-21.01,159.61-21.01,159.61
       s-41.46-45.22-38.78-111.57c2.67-66.35-19.75-151.93,32.82-199.42C-594.28,887.73-592.96,971.12-592.96,971.12z"
          />
          <path
            class="st4"
            d="M-591.65,1013.14c-9.85,53.18-11.16,139.19-23.64,181.87c-12.47,42.68-22.32,73.53-22.32,73.53
       s-2.63-7.22-3.28-22.32c-0.66-15.1-14.13-98.48,10.33-208.79c8.21-37.03,22.04-72.28,27.23-81.23
       C-594.58,941.14-589.14,996.66-591.65,1013.14z"
          />
          <path
            class="st8"
            d="M-646.85,935.22c0,0-26.87,34.58-30.15,133.07c-3.28,98.48-1.97,126.06-47.93,195c0,0,9.85-64.34,3.28-95.86
       c-6.57-31.51-8.18-36.3-12.8-69.6c-4.62-33.3-24.22-183.59,19.77-187.53C-670.69,906.36-646.85,935.22-646.85,935.22z"
          />
          <path
            class="st4"
            d="M-713.76,793.19c0,0-23.81,63.69-28.98,147.07c-5.17,83.38,8.3,157.57,8.3,157.57s-9.18-97.78,16.74-168.74
       c7.33-20.07,87.15,4.27,87.15,4.27L-713.76,793.19z"
          />
          <path
            class="st8"
            d="M-544.85,971.12c0,0,23.64,53.06,26.92,115.49c3.28,62.44,21.01,159.61,21.01,159.61
       s41.46-45.22,38.78-111.57c-2.67-66.35,19.75-151.93-32.82-199.42C-543.53,887.73-544.85,971.12-544.85,971.12z"
          />
          <path
            class="st4"
            d="M-546.16,1013.14c9.85,53.18,11.16,139.19,23.64,181.87c12.47,42.68,22.32,73.53,22.32,73.53
       s2.63-7.22,3.28-22.32c0.66-15.1,14.13-98.48-10.33-208.79c-8.21-37.03-22.04-72.28-27.23-81.23
       C-543.23,941.14-548.67,996.66-546.16,1013.14z"
          />
          <path
            class="st8"
            d="M-490.96,935.22c0,0,26.87,34.58,30.15,133.07c3.28,98.48,1.97,126.06,47.93,195c0,0-9.85-64.34-3.28-95.86
       c6.57-31.51,8.18-36.3,12.8-69.6c4.62-33.3,24.22-183.59-19.77-187.53C-467.12,906.36-490.96,935.22-490.96,935.22z"
          />
          <path
            class="st4"
            d="M-424.04,793.19c0,0,23.81,63.69,28.98,147.07c5.17,83.38-8.3,157.57-8.3,157.57s9.18-97.78-16.74-168.74
       c-7.33-20.07-87.15,4.27-87.15,4.27L-424.04,793.19z"
          />
        </g>
        <path
          class="st23"
          d="M-625.21,241.69c0,13.64-76.42,49.38-117.07,90.35c0,0-3.22,34.38-3.22,126.17
     c0,91.78,29.59,111.26,38.95,130.46c9.36,19.2,13.87,36.93,10.13,49.24s-22.93,70.23,10.8,116.36
     c33.73,46.14,84.02,90.45,95.56,101.29c11.54,10.83,18.33,7.49,18.33,7.49s12.45,3.34,23.99-7.49
     c11.54-10.83,61.83-55.15,95.56-101.29s14.53-104.05,10.8-116.36c-3.73-12.31,0.78-30.04,10.13-49.24
     c9.36-19.2,38.95-38.68,38.95-130.46c0-91.78-3.22-126.17-3.22-126.17c-40.65-40.97-117.07-76.71-117.07-90.35H-625.21z"
        />
        <path
          class="st22"
          d="M-675.82,711.76c0,0,21.01-17.06,32.17-26.83c0,0,31.31-92.58,74.75-92.58c43.43,0,74.75,92.58,74.75,92.58
     c11.16,9.77,32.17,26.83,32.17,26.83s-45.43,148.4-110.43,148.4C-637.41,860.16-675.82,711.76-675.82,711.76z"
        />
        <path
          class="st22"
          d="M-512.6,241.69c-1.76,11-112.61,0-112.61,0c-8.35-30.82-5.84-54.45-5.84-54.45s-15.98,3.83-22.27-27.2
     c-6.39-31.54,14.03-29.67,14.03-29.67s-6.56-19.97,0.3-50.81c6.86-30.84,36.81-51.52,70.08-51.52c33.25,0,63.22,20.68,70.08,51.52
     c6.84,30.84,0.28,50.81,0.28,50.81s20.45-1.86,14.05,29.67c-6.29,31.03-22.27,27.2-22.27,27.2S-508.4,215.41-512.6,241.69z"
        />
        <path
          class="st22"
          d="M-615.29,929.1c0,0,21.99,38.74,23.64,84.04c0,0,12.48-63.68,13.79-128.69L-615.29,929.1z"
        />
        <path
          class="st22"
          d="M-522.52,929.1c0,0-21.99,38.74-23.64,84.04c0,0-12.48-63.68-13.79-128.69L-522.52,929.1z"
        />
        <g class="st27">
          <path
            class="st8"
            d="M-568.9,857.53c0,26.92-10.93,57.12-46.38,71.57c-35.45,14.44-102.42,0-102.42,0
       c6.56-20.94-8.84-65.16-8.86-110.2c-0.03-65.23,22.87-91.43,39.33-104.16c33.78-26.13,58.82,10.83,75.89,48.26
       C-594.28,800.41-568.9,830.61-568.9,857.53z"
          />
          <path
            class="st8"
            d="M-568.9,857.53c0,26.92,10.93,57.12,46.38,71.57c35.45,14.44,102.42,0,102.42,0
       c-6.56-20.94,8.84-65.16,8.86-110.2c0.03-65.23-22.87-91.43-39.33-104.16c-33.78-26.13-58.82,10.83-75.89,48.26
       C-543.53,800.41-568.9,830.61-568.9,857.53z"
          />
        </g>
        <g class="st27">
          <path
            class="st8"
            d="M-745.5,458.21c0,0,23.32,52.66,118.06,43.47c94.75-9.19-2.42-116.72-56.26-127.63
       C-737.54,363.14-761.94,438.37-745.5,458.21z"
          />
          <path
            class="st4"
            d="M-696.72,615.23c7.78,32.2,53.07,69.7,53.07,69.7s58.33-53.19,74.75-92.58c0,0-0.1-109.14-114.8-137.29
       c-53.5-13.13-61.8,3.15-61.8,3.15c0,91.78,29.59,111.26,38.95,130.46C-702,598.03-698.59,607.03-696.72,615.23z"
          />
          <path
            class="st8"
            d="M-392.31,458.21c0,0-23.32,52.66-118.06,43.47c-94.75-9.19,2.42-116.72,56.26-127.63
       S-375.87,438.37-392.31,458.21z"
          />
          <path
            class="st4"
            d="M-441.09,615.23c-7.78,32.2-53.07,69.7-53.07,69.7s-58.33-53.19-74.75-92.58c0,0,0.1-109.14,114.8-137.29
       c53.5-13.13,61.8,3.15,61.8,3.15c0,91.78-29.59,111.26-38.95,130.46C-435.81,598.03-439.22,607.03-441.09,615.23z"
          />
          <g>
            <path
              class="st8"
              d="M-568.91,592.35c0,0,13.69-36.18,41.92-71.63c28.23-35.45,32.83-94.55,72.88-146.67l58.58-42.01
         c-40.65-40.97-91.07-56.84-117.07-90.35c-26-33.5,2.03-78.8-56.3-78.8c-58.33,0-23.68,50.85-56.3,78.8
         c-32.63,27.95-76.42,49.38-117.07,90.35l58.58,42.01c40.05,52.12,44.65,111.21,72.88,146.67
         C-582.59,556.17-568.91,592.35-568.91,592.35z"
            />
          </g>
        </g>
        <path
          class="st22"
          d="M-880.84,577.23c0,0-39.3,19.56-56.34,71.14S-847.52,606.17-880.84,577.23z"
        />
        <path
          class="st23"
          d="M-887.24,608.46l-10.51,15.33c0,0-0.54,38.14,36.83,19.4c37.36-18.74,82.01-51.66,49.84-85.1
     c-32.17-33.44-53.18-22.94-57.78-4.55C-873.45,571.93-887.24,608.46-887.24,608.46z"
        />
        <path
          class="st22"
          d="M-953.98,815.43c0,0,35.89-56.38,64.78-71.48c28.89-15.1,78.79-81.41,84.04-115.56
     c0,0-81.41,12.47-122.78,78.79c-41.36,66.31-47.29,94.82-47.29,94.82L-953.98,815.43z"
        />
        <path
          class="st22"
          d="M-856.38,658.83c19.7-13.37,82.07-36.79,86.01-87.45c3.94-50.66-84.58,59.05-90.55,71.82
     C-866.88,655.97-856.38,658.83-856.38,658.83z"
        />
        <path
          class="st23"
          d="M-975.23,801.99c0,0,38.1-97.62,118.85-143.16c0,0-7.22-48.17-41.36-35.04
     c-34.14,13.13-57.12,33.46-70.25,87.97c-13.13,54.51-30.84,90.23-30.84,90.23H-975.23z"
        />
        <g class="st27">
          <g>
            <path
              class="st4"
              d="M-952.43,821.71c-0.87-21.45-39.62-21.95-39.62-21.95c-18.34,6.77-40.19,8.02-54.85,19.69
         c-14.66,11.66-22.83,13.3-36.88,15.97c-14.06,2.64-10.79,18.28,7.02,18.45c17.83,0.17,27.32-2.45,39.04-5.4l0,0c0,0,0,0,0,0
         c-18.23,29.55-28.74,52.22-36.46,79.85c-2.54,18.15,29.18,19.54,42.25,15.3c24.81-7.07,47.4-48.17,59.55-59.84
         C-960.2,871.1-951.56,843.17-952.43,821.71z"
            />
          </g>
          <path class="st34" d="M-1055.83,880.65c0,0,24.84,18.56,73.82,14.67" />
          <path class="st34" d="M-1037.73,848.47c0,0,5.43-8.91,6.94-11.05" />
        </g>
        <path
          class="st22"
          d="M-256.97,577.23c0,0,39.3,19.56,56.34,71.14S-290.29,606.17-256.97,577.23z"
        />
        <path
          class="st23"
          d="M-250.57,608.46l10.5,15.33c0,0,0.54,38.14-36.83,19.4c-37.36-18.74-82.01-51.66-49.84-85.1
     c32.17-33.44,53.18-22.94,57.78-4.55C-264.36,571.93-250.57,608.46-250.57,608.46z"
        />
        <path
          class="st22"
          d="M-183.83,815.43c0,0-35.89-56.38-64.78-71.48c-28.89-15.1-78.79-81.41-84.04-115.56
     c0,0,81.41,12.47,122.78,78.79c41.36,66.31,47.29,94.82,47.29,94.82L-183.83,815.43z"
        />
        <path
          class="st22"
          d="M-281.43,658.83c-19.7-13.37-82.07-36.79-86.01-87.45c-3.94-50.66,84.58,59.05,90.55,71.82
     C-270.93,655.97-281.43,658.83-281.43,658.83z"
        />
        <path
          class="st23"
          d="M-162.58,801.99c0,0-38.1-97.62-118.85-143.16c0,0,7.22-48.17,41.36-35.04
     c34.14,13.13,57.12,33.46,70.25,87.97c13.13,54.51,30.84,90.23,30.84,90.23H-162.58z"
        />
        <g class="st27">
          <path
            class="st8"
            d="M-835.29,554.86c0,0-21.74-12.63-31.59,13.38s-20.35,40.22-20.35,40.22s13.87-74.62,25.97-98.91
       c12.11-24.29,25.32-34.56,64.01-41.9C-758.55,460.31-835.29,554.86-835.29,554.86z"
          />
          <path
            class="st4"
            d="M-825.56,598.08c0,0-32.13-52.97,20.4-127.87c52.53-74.9,78.15-34.32,64.2,42.13
       C-755.92,594.25-825.56,598.08-825.56,598.08z"
          />
          <path
            class="st8"
            d="M-302.52,554.86c0,0,21.74-12.63,31.59,13.38c9.85,26.01,20.35,40.22,20.35,40.22s-13.87-74.62-25.97-98.91
       c-12.11-24.29-25.32-34.56-64.01-41.9C-379.26,460.31-302.52,554.86-302.52,554.86z"
          />
          <path
            class="st4"
            d="M-312.25,598.08c0,0,32.13-52.97-20.4-127.87c-52.52-74.9-78.15-34.32-64.2,42.13
       C-381.89,594.25-312.25,598.08-312.25,598.08z"
          />
        </g>
        <g class="st27">
          <g>
            <path
              class="st4"
              d="M-185.38,821.71c0.87-21.45,39.62-21.95,39.62-21.95c18.34,6.77,40.19,8.02,54.85,19.69
         c14.66,11.66,22.83,13.3,36.88,15.97c14.06,2.64,10.79,18.28-7.02,18.45c-17.83,0.17-27.32-2.45-39.04-5.4l0,0c0,0,0,0,0,0
         c18.23,29.55,28.74,52.22,36.46,79.85c2.54,18.15-29.18,19.54-42.25,15.3c-24.81-7.07-47.4-48.17-59.55-59.84
         C-177.61,871.1-186.26,843.17-185.38,821.71z"
            />
          </g>
          <path class="st34" d="M-81.98,880.65c0,0-24.84,18.56-73.82,14.67" />
          <path class="st34" d="M-100.08,848.47c0,0-5.43-8.91-6.94-11.05" />
        </g>
        <g class="st27">
          <path
            class="st8"
            d="M-828.04,476.17c35.57-11.17,52.54-15.3,64.22-21.45c51.72-27.24,80.12-80.68,80.12-80.68
       s-30.59-48.26-67.25-40.93C-787.61,340.45-856.79,350.18-828.04,476.17z"
          />
          <path
            class="st8"
            d="M-309.77,476.17c-35.57-11.17-52.54-15.3-64.22-21.45c-51.72-27.24-80.12-80.68-80.12-80.68
       s30.59-48.26,67.25-40.93C-350.2,340.45-281.02,350.18-309.77,476.17z"
          />
        </g>
        <line class="st22" x1="-568.9" y1="162.89" x2="-568.9" y2="857.53" />
        <path
          class="st23"
          d="M-726.2,1244.94c-11.16,26.92-26.26,118.18-13.79,142.47c12.47,24.29,26.92,18.38,38.74,12.47
     c11.82-5.91,17.56-19.71,16.41-42.02c-1.14-22.31,2.63-223.23,2.63-223.23L-726.2,1244.94z"
        />
        <path
          class="st23"
          d="M-414.14,1244.94c11.16,26.92,26.26,118.18,13.79,142.47c-12.47,24.29-26.92,18.38-38.74,12.47
     c-11.82-5.91-17.56-19.71-16.41-42.02c1.14-22.31-2.63-223.23-2.63-223.23L-414.14,1244.94z"
        />
        <g class="st27">
          <path
            class="st35"
            d="M-568.9,857.53c0,26.92-10.93,57.12-46.38,71.57c-35.45,14.44-102.42,0-102.42,0
       c6.56-20.94-8.84-65.16-8.86-110.2c-0.03-65.23,22.87-91.43,39.33-104.16c33.78-26.13,58.82,10.83,75.89,48.26
       C-594.28,800.41-568.9,830.61-568.9,857.53z"
          />
          <path
            class="st35"
            d="M-568.9,857.53c0,26.92,10.93,57.12,46.38,71.57c35.45,14.44,102.42,0,102.42,0
       c-6.56-20.94,8.84-65.16,8.86-110.2c0.03-65.23-22.87-91.43-39.33-104.16c-33.78-26.13-58.82,10.83-75.89,48.26
       C-543.53,800.41-568.9,830.61-568.9,857.53z"
          />
        </g>
      </g>
    </svg>
  );
}
