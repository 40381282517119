import React, { useState, useContext, useEffect } from "react";
import Step from "../../../assets/images/basicInfo/step456L.png";
import WeightPicker from "../components/WeightPicker"
import Progress from "../components/Progress";
import MobileWeightPicker from '../components/MobileWeightPicker'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setStep, activeStep, setWeight, setGoalWeight, defaultValue, defaultGoalValue, weight, height, goalWeight }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────


  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Progress stepPic={Step} activeStep={activeStep} setStep={setStep} />
      <span className="hidden xl:block text-center font-bold text-xl xl:text-3xl mt-7">
        وزنتو وارد کن
      </span>
      <section className="hidden xl:block">
        <WeightPicker weight={weight} goalWeight={goalWeight} height={height} setWeight={setWeight} setGoalWeight={setGoalWeight} defaultValue={defaultValue} defaultGoalValue={defaultGoalValue} />
      </section>

      <button className="hidden xl:block text-[20px] bg-purple xl:m-auto xl:mt-10  p-[10px] w-[430px] max-w-[100%] text-white transition-all duration-500 rounded-2xl mt-10"
        onClick={() => setStep(5)}>
        مرحله بعد
      </button>
      <section className="xl:hidden h-[78%] flex flex-col justify-between items-center">
        <MobileWeightPicker weight={weight} goalWeight={goalWeight} height={height} setWeight={setWeight} setGoalWeight={setGoalWeight} defaultValue={defaultValue} defaultGoalValue={defaultGoalValue} />
        <button className="text-[20px] bg-purple xl:m-auto xl:mt-10  p-[10px] w-[430px] max-w-[100%] text-white transition-all duration-500 rounded-2xl mt-10"
          onClick={() => setStep(5)}>
          مرحله بعد
        </button>
      </section>
    </>
  );
}
