import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────
    const date = [
        { value: 1, label: 'برنامه تمرینی 1' },
        { value: 2, label: 'برنامه تمرینی 2' },
    ];
    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='flex justify-between'>
            <section className='flex justify-center items-center'>
                <span className='text-[#3E4151]'>انتخاب روز:</span>
                <section className='w-[245px] h-[38px] mr-[24px] flex justify-between'>
                    <button className='w-[62px] h-[38px] bg-purple text-white rounded-full'>روز</button>
                    <button className='w-[62px] h-[38px] text-purple border border-purple rounded-full'>هفته</button>
                    <button className='w-[62px] h-[38px] text-purple border border-purple rounded-full'>ماه</button>
                </section>
            </section>
            <Select
                /* value={chartMode}
                onChange={setChartMode} */
                options={date}
                placeholder="برنامه تمرینی"
            />
        </section>
    )
}
