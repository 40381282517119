import React, { useState, useContext, useEffect } from 'react'
import { imagesFileUrl, svgFileUrl } from '../../../../../../config'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section>
            <span className='text-[18px] text-[#3E4151] font-bold'>
                اطلاعات تکمیلی
            </span>
            <section className='h-[140px] flex gap-5'>
                <section className='flex h-[139px] justify-center items-center w-[180px]'>
                    <img className='' src={imagesFileUrl + "/dashboard/Avatar5.png"} alt='' />
                    <section className='flex flex-col mt-[-20px]'>
                        <span className='font-semibold'>محسن زیوری</span>
                        <span className='text-[14px] text-[#A6A9BD] font-normal'>1403/02/14</span>
                    </section>
                </section>
                <section className='w-[490px] h-[123px] border border-[#E6E8E9] relative rounded-[10px]'>
                    <span className='w-[71px] text-[#3E4151] font-bold absolute top-[-12px] bg-white  right-4'>درباره من</span>
                    <p className='text-[14px] text-justify text[#3E4151] px-[13px] py-[28px] font-normal'>
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچن تکنولوژی
                    </p>
                </section>
                <section className='w-[382px] h-[123px] border px-[14px] py-[28px] border-[#E6E8E9] relative rounded-[10px]'>
                    <span className='w-[111px] text-[#3E4151] font-bold absolute top-[-12px] bg-white  right-4'>مدارک پزشکی</span>
                    <section className='flex gap-4'>
                        <img src={imagesFileUrl + "/dashboard/doctordoc.png"} alt='' />
                        <img src={imagesFileUrl + "/dashboard/doctordoc.png"} alt='' />
                        <img src={imagesFileUrl + "/dashboard/doctordoc.png"} alt='' />
                        <button className='w-[72px] h-[72px] rounded-[10px] flex justify-center items-center border border-[#E6E8E9]'>
                            <img src={svgFileUrl + "/moredoc.svg"} alt='' />
                        </button>
                    </section>
                </section>
            </section>
        </section>
    )
}
