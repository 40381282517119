import React from 'react'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='w-full  py-[27px] px-[16px] items-center min-h-[94px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]'>
            <span className='w-[130px] text-[#3E4151] font-bold absolute top-[-12px] bg-white right-4'>بیماری های خاص</span>
            <section className='flex justify-between items-center h-[78] w-full'>
                <section className='w-[270px] h-[48px] text-center'>
                    <span className='text-[#5408A9] font-medium block text-[14px]'>حساسیت غذایی و عدم علاقه به غذای خاص :</span>
                    <span className='block text-[14px]'>خربزه،عسل،طالبی،گردو، آب هندوانه</span>
                </section>
                <span className='block h-[78px] bg-[#E6E8E9] w-[1px]'></span>
                <section className='w-[190px] h-[48px] text-center'>
                    <span className='text-[#5408A9] font-medium block text-[14px]'>حساسیت دارویی:</span>
                    <span className='text-[14px]'>خربزه،عسل،طالبی،گردو، آب هندوانه</span>
                </section>
                <span className='block h-[78px] bg-[#E6E8E9] w-[1px]'>

                </span>
                <section className='w-[190px] h-[48px]  text-center'>
                    <span className='text-[#5408A9] font-medium block text-[14px]'>سابقه بیماری:</span>
                    <span className='text-[14px]'>ایدز ، سرطان،سرماخوردگی</span>
                </section>
                <span className='block h-[78px] bg-[#E6E8E9] w-[1px]'></span>
                <section className='w-[190px] h-[48px] text-center'>
                    <span className='text-[#5408A9] font-medium block text-[14px]'>داروی مصرفی:</span>
                    <span className='text-[14px]'>استامینوفن</span>
                </section>
            </section>
            <span className='h-[1px] bg-[#E6E8E9] block my-[32px]'></span>
            <section className='flex justify-between items-center h-[78] w-full'>
                <section className='w-[270px] h-[48px] text-center'>
                    <span className='text-[#5408A9] font-medium block text-[14px]'>چه مکملی مصرف می کنید؟</span>
                    <span className='text-[14px]'>گینر ماسل میکرجاینت</span>
                </section>
                <span className='block h-[78px] bg-[#E6E8E9] w-[1px]'></span>
                <section className='w-[190px] h-[48px] text-center'>
                    <span className='text-[#5408A9] font-medium block text-[14px]'>تمایل به مصرف مکمل دارید؟</span>
                    <span className='text-[14px]'>خیر</span>
                </section>
                <span className='block h-[78px] bg-[#E6E8E9] w-[1px]'>

                </span>
                <section className='w-[190px] h-[48px]  text-center'>
                    <span className='text-[#5408A9] font-medium block text-[14px]'>مصرف الکل:</span>
                    <span className='text-[14px]'>ماهیانه</span>
                </section>
                <span className='block h-[78px] bg-[#E6E8E9] w-[1px]'></span>
                <section className='w-[190px] h-[48px] text-center'>
                    <span className='text-[#5408A9] font-medium block text-[14px]'>سابقه بیماری:</span>
                    <span className='text-[14px]'>استامینوفن</span>
                </section>
            </section>
        </section>
    )
}
