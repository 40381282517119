import React, { useState, useContext, useEffect } from 'react';
import { baseFileUrl, imagesFileUrl, svgFileUrl } from '../../../../config';
import MenuItem from './components/MenuItem';
import Dashboard from './svg/Dashboard';
import Profile from './svg/Profile';
import Target from './svg/Target';
import Restaurant from './svg/Restaurant';
import Sport from './svg/Sport';
import Clip from './svg/Clip';
import Setting from './svg/Setting';
import Fav from './svg/Fav';
import Recipe from './svg/Recipe';
import Ticket from './svg/Ticket';
import Exit from './svg/Exit';
import { Link, useLocation } from 'react-router-dom';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const location = useLocation();

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="fixed left-0 bottom-0 w-full h-[64px]  bg-white  shadow-[0px_-1px_9px_0px_#5408A926] rounded-t-[20px] z-[1000] px-[10%] flex justify-between xl:hidden ">
        <span className="flex flex-col justify-center cursor-pointer items-center">
          <img src={svgFileUrl + "mobileMenuPeople.svg"} alt="" className="w-[18px] h-[18px]" />
          پروفایل
        </span>
        <span className="flex flex-col justify-center cursor-pointer items-center">
          <img src={svgFileUrl + "mobileMenuTarget.svg"} alt="" className="w-[18px] h-[18px]" />
          هدف
        </span>
        <span className="flex w-[56px] h-[56px] cursor-pointer  bg-[#5408A9] mt-[-18px] rounded-full shadow-[0px_14px_23px_-6px_#5408A980] justify-center items-center">
          <img src={svgFileUrl + "mobileMenu.svg"} alt="" className="w-[20px] h-[20px]" />

        </span>
        <span className="flex flex-col justify-center cursor-pointer  items-center">
          <img src={svgFileUrl + "mobileMenuSport.svg"} alt="" className="w-[18px] h-[18px]" />
          ورزش
        </span>
        <span className="flex flex-col justify-center cursor-pointer items-center">
          <img src={svgFileUrl + "mobileMenuKnife.svg"} alt="" className="w-[18px] h-[18px]" />
          آشپزی
        </span>
      </section>
      <aside className="w-[199px] hidden xl:block">
        <Link to="/">
          <header className="flex w-[160px] h-[36px] justify-between items-center pr-7">
            <section className="w-[60px] h-[60px] p-1 bg-white rounded-[6px] ml-1">
              <img src={imagesFileUrl + 'logoWhite.png'} alt="" />
            </section>
            <span className="font-bold text-[15px] mr-1">Bodyness</span>
          </header>
        </Link>
        <nav className="mt-10">
          <MenuItem
            title="داشبورد"
            className="menuItem"
            link={'/dashboard'}
            active={location.pathname === "/dashboard"}
            Icon={Dashboard}
          />
          <MenuItem
            title="پروفایل"
            className="menuItem"
            link={'/dashboard/basicInfo'}
            active={location.pathname.substring(11) === "basicInfo"}
            Icon={Profile}
          />
          {/*   <MenuItem title="هدف" className="menuItem" Icon={Target} /> */}
          {/* <MenuItem
          title="برنامه ورزشی"
          className="menuItem"
          link={'/dashboard/exercise-category'}
          active={location.pathname.substring(11) === "exercise-category"}
          Icon={Sport}
        /> */}
          {/*   <MenuItem title="کلیپ های آموزشی" className="menuItem" Icon={Clip} /> */}
          <MenuItem
            link={'/dashboard/recipe'}
            title="رسپی"
            className="menuItem"
            active={location.pathname.substring(11, 17) === "recipe"}
            Icon={Recipe}
          />
          {/*  <MenuItem title="علاقه مندی" className="menuItem" Icon={Fav} /> */}
          {/*  <MenuItem title="تنظیمات" className="menuItem" Icon={Setting} /> */}
          <MenuItem
            link={'/dashboard/ticket'}
            title="ارسال تیکت"
            className="menuItem"
            active={location.pathname.substring(11) === "ticket"}
            Icon={Ticket}
          />
          <MenuItem
            link={'/'}
            title="بازگشت به سایت"
            className="menuItem"

            Icon={Target}
          />
          <MenuItem title="خروج" mode="logOut" className="menuItem" Icon={Exit} />
          {/*   <MenuItem link={"/dashboard/tools"} title="ابزارها" className="menuItem" Icon={Setting} /> */}
          {/*    <MenuItem link="/dashboard/diet" title="برنامه غذایی" className="menuItem" Icon={Restaurant} /> */}
          {/*  <MenuItem title="/dashboard" className="menuItem" link={"/dashboard"} active Icon={Dashboard} />
        <MenuItem link="/dashboard/diet" title="/dashboard/diet" className="menuItem" Icon={Profile} />
        <MenuItem link={"/dashboard/tools"} title="/dashboard/tools" className="menuItem" Icon={Profile} />
        <MenuItem link={"/dashboard/recipe"} title="/dashboard/recipe" className="menuItem" Icon={Profile} />
        <MenuItem link={"/dashboard/ticket"} title="/dashboard/ticket" className="menuItem" Icon={Profile} />
        <MenuItem link={"/dashboard/ticketchat"} title="/dashboard/ticketchat" className="menuItem" Icon={Profile} />
        <MenuItem link={"/dashboard/addticket"} title="/dashboard/addticket" className="menuItem" Icon={Profile} />
        <MenuItem link={"/dashboard/exercise-ca tegory"} title="/dashboard/exercise-category" className="menuItem" Icon={Profile} />
        <MenuItem link={"/dashboard/single-exercise"} title="/dashboard/single-exercise" className="menuItem" Icon={Profile} />
        <MenuItem link={"/dashboard/basicinfo"} title=" /dashboard/basicinfo" className="menuItem" Icon={Profile} /> */}
        </nav>
        <section className="w-full mt-20 relative">
          <img src={imagesFileUrl + 'dashboard/app.png'} alt="" />
          <section className="w-full h-full absolute top-0 left-0 flex flex-col justify-center items-center">
            <section className="text-center">
              <span className="block text-white text-[22px] font-normal">
                راهنمای آموزش
              </span>
              <span className="block text-white text-[22px] font-bold">
                اپلیکیشن
              </span>
            </section>
            <section className="w-[105px] h-[105px] bg-white rounded-[6px] p-1 mt-4">
              <img src={imagesFileUrl + 'logoWhite.png'} alt="" />
            </section>
            <button className="w-[99px] h-[32px] text-sm font-bold text-[#191919] mt-6 cursor-pointer rounded-full bg-gradient-to-r from-[#FAFF00] to-[#FF6523]">
              بزن بریم
            </button>
          </section>
        </section>
      </aside>
    </>
  );
}
