import React, { useState, useContext, useEffect } from 'react'
import ListItem from './components/ListItem'
import { svgFileUrl } from '../../../../../../../config'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='mt-[20px] border border-[#E6E8E9] rounded-[10px] px-[16px] py-[24px]'>
            <section className='flex justify-between'>
                <span className='font-bold rounded-[5px] bg-[#F9F5FE] block w-[75px] h-[26px] text-center'>پکیج 1</span>
                <button className="w-[117px] h-[40px] border border-purple text-purple  rounded-full mr-[16px] flex gap-2 justify-center items-center">
                    <img src={svgFileUrl + "plus.svg"} alt='' />
                    انتخاب غذا
                </button>

            </section>
            <ListItem title="املت گوجه فرنگی" index={1} />
            <ListItem title="شیر" index={2} />
            <ListItem title="عدسی" index={3} />
            <ListItem title="پاستا" index={4} />
            <ListItem title="فیله مرغ گریل" index={5} />
        </section>
    )
}
