import React from "react";
import { baseFileUrl, imagesFileUrl } from "../../config";
import { Link } from "react-router-dom";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <footer className="w-full h-auto   xl:h-[402px] bg-gradient-to-l from-[#E4E9FE] to-[#DEBFFA]">
      <section className=" h-full relative flex flex-col xl:flex-row justify-center items-center xl:items-start">
        {/* <img src={baseFileUrl + "/images/footerImg(1).png"} className="xl:block hidden absolute left-0 bottom-0 z-0" alt="" />*/}
        {/*         <img src={baseFileUrl + "/images/3-footer-mobile.png"} className="block xl:hidden absolute w-[200px] h-[200px] right-0 top-[400.32px] z-0" alt="" />*/}        <img src={baseFileUrl + "/images/footer-watermark.png"} className="hidden xl:block absolute left-[255px] top-0  w-[727px] h-[403px]  z-0" alt="" />
        {/* <img src={baseFileUrl + "/images/footerImg(3).png"} className="xl:block hidden absolute left-0 z-0" alt="" /> */}
        {/*         <img src={baseFileUrl + "/images/2-footer-mobile.png"} className="block xl:hidden absolute right-0 top-[130px] w-[301.66px] h-[371.33px] z-0 " alt="" />*/}        <section className="h-auto pr-[42px] pl-[50px] pt-[78px] xl:pt-[60px] pb-[40px] xl:h-[219px] w-full xl:w-[1136px]  z-10">
          <section className="flex xl:flex-row flex-col justify-between">
            <section className="w-full xl:w-[353px] h-auto xl:h-[219px]">
              <section className="flex mb-[30px] justify-between w-full">
                <img className="w-[77px] xl:w-[90px] h-[77px] xl:h-[90px]" src={baseFileUrl + "/images/logo-dark.png"} alt="" />
                <span className="font-normal text-[22px] xl:text-[32px] pr-4">بدن خودت رو با بادینس بساز!</span>
              </section>
              <section className="flex justify-around items-center h-[136px] px-2  mb-16 w-full xl:w-[420px] rounded-md">
                <section className="w-[80px] h-[80px] xl:w-[130px] xl:h-[130px] bg-white flex justify-center items-center rounded-md">
                  <a referrerpolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=499096&Code=mK5rJySTnF5J6WqoH4hv6S83KHCixUtk'><img className="w-[125px] max-w-full" referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=499096&Code=mK5rJySTnF5J6WqoH4hv6S83KHCixUtk' alt='' style={{ cursor: 'pointer' }} code='mK5rJySTnF5J6WqoH4hv6S83KHCixUtk' /></a>

                </section>
                <section className="w-[80px] h-[80px] xl:w-[130px] xl:h-[130px] bg-white flex justify-center items-center rounded-md">
                  <img className="w-[125px] max-w-full" referrerpolicy='origin' id='rgvjfukzsizpsizpwlaonbqe' style={{ cursor: 'pointer' }} onClick={() => window.open("https://logo.samandehi.ir/Verify.aspx?id=369942&p=xlaogvkapfvlpfvlaodsuiwk", "Popup", "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")} alt='logo-samandehi' src='https://logo.samandehi.ir/logo.aspx?id=369942&p=qftiwlbqbsiybsiyshwlodrf' />

                </section>
                <section className="w-[80px] h-[80px] xl:w-[130px] xl:h-[130px] bg-white flex justify-center items-center rounded-md">
                  <img className="w-[125px] max-w-full" src={imagesFileUrl + "majazi.png"} alt="" />
                </section>
                {/*  <span className="block font-semibold text-[20px]">دانلود اپلیکیشن:</span>
                <section className="flex gap-[8.5px] mt-3">
                  <img src={baseFileUrl + "/images/googlePlay.png"} alt="" />
                  <img src={baseFileUrl + "/images/appStore.png"} alt="" />
                </section> */}
              </section>
            </section>
            <section className="xl:w-[125px] w-full mb-[60px] xl:h-[219px]">
              <span className="font-semibold text-[20px]">لینک ها</span>
              <Link to="" className="block text-[16px] font-medium mt-6">
                خانه
              </Link>
              <Link to="" className="block text-[16px] font-medium mt-4">
                تمرینات ورزشی
              </Link>
              <Link to="" className="block text-[16px] font-medium mt-4">
                رژیم غذایی
              </Link>
              <Link to="" className="block text-[16px] font-medium mt-4">
                درباره ما
              </Link>
            </section>
            <section className="w-full pl-[40px] mb-[44px] xl:w-[295px] xl:h-[234px]">
              <span className="font-semibold text-[20px]">ارتباط با ما</span>
              <section className="flex mt-6 text-[16px] font-medium">
                <img src={baseFileUrl + "/images/svg/map.svg"} alt="" />
                <span className="mr-3 text-[14px]">تهران، خیابان نیاوران، خیابان سی و دوم، بوستان جمشیدیه، مرکز نوآوری و کارآفرینی</span>
              </section>
              <section className="flex mt-6 text-[16px] font-medium">
                <img src={baseFileUrl + "/images/svg/phone.svg"} alt="" />
                <span className="mr-3" style={{ direction: "ltr" }}>
                  09352920712
                </span>
              </section>
              <section className="flex mt-6 text-[16px] font-medium">
                <img src={baseFileUrl + "/images/svg/mail.svg"} alt="" />
                <span className="mr-3">info@bodyness.com</span>
              </section>
              <section className="mt-4 flex justify-between w-[208px]">
                <span className="rounded-full cursor-pointer w-[40px] h-[40px] border-[2px] border-[#] hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
                  <img src={baseFileUrl + "/images/svg/twitter.png"} alt="" />
                </span>
                <span className="rounded-full cursor-pointer w-[40px] h-[40px] border-[2px] border-textGray hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
                  <img src={baseFileUrl + "/images/svg/instagram.svg"} alt="" />
                </span>
                <span className="rounded-full cursor-pointer w-[40px] h-[40px] border-[2px] border-textGray hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
                  <img src={baseFileUrl + "/images/svg/linkdin.svg"} alt="" />
                </span>
                <span className="rounded-full cursor-pointer w-[40px] h-[40px] border-[2px] border-textGray hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
                  <img src={baseFileUrl + "/images/svg/facebook.svg"} alt="" />
                </span>
              </section>
            </section>
          </section>
          <section className="h-[52px] pl-[10px] w-full flex xl:flex-row flex-col xl:items-center xl:justify-between pt-[20px]  mb-[40px] border-t border-2px border-[#A6A9BD] ">
            <span className="text-textGray  order-last font-normal ">© Copyright 2024, All Rights Reserved</span>
            <section className="xl:w-[235px] xl:h-[26px] flex flex-col xl:flex-row mb-4 justify-between">
              <span className="font-normal text-[14px] text-textGray">حریم خصوصی</span>
              <span className="font-normal text-[14px] text-textGray">شرایط و قوانین</span>
            </section>
          </section>
        </section>
      </section>
    </footer>
  );
}
