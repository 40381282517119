import React, { useState, useContext, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import NotActive from '../../../../assets/images/basicInfo/notActive1.png';
import LightlyActive from '../../../../assets/images/basicInfo/LightlyActive1.png';
import ModeActive from '../../../../assets/images/basicInfo/modeActive1.png';
import VeryActive from '../../../../assets/images/basicInfo/veryActive1.png';
import VeryActive2 from '../../../../assets/images/basicInfo/veryActive11.png';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setActiveSlide, activeSlide }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [swipers, setSwipers] = useState(null);

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const slideTo = (index) => swipers.slideTo(index);
  //
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (swipers) {
      slideTo(activeSlide - 1);
    }
  }, [activeSlide, swipers]);
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (

    <section className='relative'>
      <section className='absolute z-10 w-full h-full'></section>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSwiper={setSwipers}
        onSlideChange={(e) => setActiveSlide(e.activeIndex + 1)}

      /*  enabled={false} */

      >
        <SwiperSlide>
          <img src={NotActive} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={LightlyActive} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ModeActive} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={VeryActive} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={VeryActive2} alt="" />
        </SwiperSlide>
      </Swiper>
    </section>
  );
}
