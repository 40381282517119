import React, { useState, useContext, useEffect } from 'react';
import { imagesFileUrl, svgFileUrl } from '../../../../config';
import Item from './components/Item';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:h-auto bg-white shadow-md rounded-[18px] mt-6 p-8 flex flex-col items-center justify-between">
      <section className="z-10 relative w-full flex justify-between items-center">
        <span className="text-lg font-bold">تمرین ورزشی</span>
        <button className="w-[105px] h-[32px] flex justify-around items-center rounded-full border px-2 border-[#DDCEEE] font-medium text-sm text-[#986BCB]">
          <span>مشاهده همه</span>
        </button>
      </section>
      <section className="w-full xl:w-[715px] mt-[10px] grid grid-cols-1 xl:grid-cols-2 gap-3  xl:mt-[20px]  justify-between">
        <Item
          img={imagesFileUrl + 'dashboard/zumba.png'}
          muscle={imagesFileUrl + 'dashboard/zumbaIcon.png'}
          duration="20:00"
          level={1}
          description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
        />
        <Item
          img={imagesFileUrl + 'dashboard/zumba2.png'}
          muscle={imagesFileUrl + 'dashboard/zumba2Icon.png'}
          duration="20:00"
          level={2}
          description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
        />
        <Item
          img={imagesFileUrl + 'dashboard/zumba3.png'}
          muscle={imagesFileUrl + 'dashboard/zumba3Icon.png'}
          duration="20:00"
          level={1}
          description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
        />
        <Item
          img={imagesFileUrl + 'dashboard/zumba4.png'}
          muscle={imagesFileUrl + 'dashboard/zumba4Icon.png'}
          isFavorite={true}
          duration="20:00"
          level={1}
          description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
        />
      </section>
    </section>
  );
}
