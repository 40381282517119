import React from "react";
import { imagesFileUrl } from "../../../config";
import Select from "../../../components/common/inputs/Select";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import api from "../../../api";

import { useGlobalContext } from "../../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useBasicInfoStore } from "../../../store/dashboard/basicInfo";
import { useAxiosWithToken } from "../../../hooks";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();
  const { showLoading, hideLoading } = useGlobalContext();
  const basicInfo = useBasicInfoStore((state) => state.basicInfo);
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const saveTicket = (data) => {
    showLoading();
    let params = {
      departmentId: data.department,
      ticketSubject: data.subject,
      initialMessage: data.message,
    };

    useAxiosWithToken
      .post(api.ticket.addTicket, params)
      .then((res) => {
        hideLoading();
        navigation("/dashboard/ticketchat/" + res?.data?.id);
      })
      .catch((e) => {});
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[784px] h-auto rounded-[18px] bg-[#FFFFFF] border-[0.81px] border-solid border-[#FFFFFF] box-shadow-[0px_4px_9px_0px_#0000000D]">
      <form onSubmit={handleSubmit((data) => saveTicket(data))}>
        <section className="flex items-center justify-between pt-8 px-[20px]  xl:px-[34px] mb-[58px]">
          <span className="text-lg text-[#3E4151] font-bold">ارسال تیکت جدید</span>
          <Link to="/dashboard/ticket" href="#" className="text-[#5408A9] text-[12px] flex items-center gap-2">
            بازگشت
            <section className="px-[10.5px] py-2">
              <img alt="" src={imagesFileUrl + "dashboard/Vector.png"} className="w-[5px] h-[10px]" />
            </section>
          </Link>
        </section>
        <section className="xl:w-[703px]  h-auto mr-[34px] pl-[47px]">
          <section className="grid grid-cols-1 xl:grid-cols-2 justify-between items-center">
            <section className="flex flex-col mt-5 xl:mt-0 order-2 xl:order-1 xl:w-[316px] text-[#3E4151] mb-10 text-sm">
              <span className="mb-1 mr-3">آدرس ایمیل</span>
              <input type="text" className="w-full h-[40px] border border-[#E6E8E9] rounded-[5px] pr-3 mb-[18px]" value="useremail@gmail.com" disabled />
              <span className="mb-1 mr-3">نام و نام خانوادگی</span>
              <input
                type="text"
                className="w-full h-[40px] border border-[#E6E8E9] rounded-[5px] pr-3 mb-[18px]"
                /*  placeholder="محسن زیوری" */
                value={basicInfo.firstName + " " + basicInfo.lastName}
                disabled
              />
              <span className="mb-1">
                بخش<span className="text-[#ED1C24]">*</span>
                <Controller
                  control={control}
                  name="department"
                  rules={{
                    required: "عنوان پلاک اجباری است",
                    /*  pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              }, */
                  }}
                  render={({ field: { onChange, value } }) => <Select setState={onChange} optionValue="id" optionTitle="departmentName" api={api.ticket.searchDepartments} />}
                />
              </span>
              {errors.department && <span className="text-[red] font-normal">{errors.department.message}</span>}

              <span className="mb-1 mt-2 mr-3 mt-[18px]">
                موضوع<span className="text-[#ED1C24]">*</span>
              </span>
              <input type="text" {...register("subject", { required: "موضوع اجباری است" })} className="w-full h-[40px] border border-[#E6E8E9] rounded-[5px] pr-3 " placeholder="اینجا بنویسید" />
              {errors.subject && <span className="text-[red] font-normal">{errors.subject.message}</span>}
              <span className="mb-1 mr-3 mt-[18px]">
                پیام<span className="text-[#ED1C24]">*</span>
              </span>
              <textarea {...register("message", { required: "موضوع اجباری است" })} placeholder="اینجا بنویسید" className="w-full h-[69px] text-sm border border-[#E6E8E9] rounded-[5px] pt-4 pr-3 "></textarea>
              {errors.message && <span className="text-[red] font-normal">{errors.message.message}</span>}
            </section>
            <img alt="" src={imagesFileUrl + "dashboard/Group-em.png"} className="order-1 xl:order-2 w-[171px] m-auto xl:w-[314.05px] " />
          </section>
        </section>
        {/*   <section className="mr-[34px] mb-5 flex items-center">
        <img
          alt=""
          src={imagesFileUrl + 'svg/Frame pdf.svg'}
          className="w-[24px] h-[32px] mr-[6px]"
        />
        <span className="text-[#3E4151] mr-[6px] text-xs">File_2.pdf</span>
        <button className="mr-[10px]">
          <img
            src={imagesFileUrl + 'dashboard/x (Stroke).png'}
            className="w-[12px] h-[12px] "
            alt=""
          />
        </button>
        <img
          src={imagesFileUrl + 'svg/Frame jpg.svg'}
          className="w-[24px] h-[32px] mr-[19px]"
          alt=""
        />
        <span className="text-[#3E4151] mr-[6px] text-xs">File_1.pdf</span>
        <button className="mr-[10px]">
          <img
            src={imagesFileUrl + 'dashboard/x (Stroke).png'}
            className="w-[12px] h-[12px] "
            alt=""
          />
        </button>
        <img
          src={imagesFileUrl + 'dashboard/25.png'}
          className="w-[32px] h-[32px] mr-[16px]"
          alt=""
        />
        <span className="text-[#3E4151] mr-[6px] text-xs">File_3.pdf</span>
        <button className="mr-[10px]">
          <img
            src={imagesFileUrl + 'dashboard/x (Stroke).png'}
            className="w-[12px] h-[12px]"
            alt=''
          />
        </button>
      </section>
      <button className="items-center w-[133px] text-sm gap-[6px] leading-[26px] flex h-[32px] border border-solid text-[#5408A9] border-[#5408A9] mr-[34px] rounded-[5px]">
        <img
          src={imagesFileUrl + 'dashboard/Vector (Stroke).png'}
          className="w-[11.43px] h-[12.93px] mr-[22.57px]"
          alt=''
        />
        انتخاب فایل
      </button>
      <p className="text-[#A6A9BD] text-xs leading-[18.6px] mr-[34px] mb-10 mt-[6px]">
        پسوندهای مجاز: PNG. JPG, PDF, gif{' '}
      </p> */}
        <section className="flex justify-center xl:justify-end  ml-[47px] mr-[34px] mb-[44px]">
          <button className="w-[128px] h-[32px] bg-[#5408A9] leading-[26px] rounded-[5px] text-white">ارسال تیکت</button>
        </section>

        {/* <input type="submit" /> */}
      </form>
    </section>
  );
}
