import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
import { useStartLink } from "../../../../hooks";
import { imagesFileUrl } from "../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [startLink] = useStartLink();
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full h-auto xl:h-[360px] gradient-plan relative">
      <section className="xl:w-[1124px] w-[90%] mx-auto pt-6 flex">
        <span className="text-[#5408A9]">خانه</span>
        <span className="text-[#A6A9BD]">/ تمرینات ورزش</span>
      </section>
      <img
        src={baseFileUrl + "/Maskgroup.png"}
        alt=""
        className="absolute top-0 w-[40%] left-2 xl:left-8 xl:w-[378.86px] "
      />
      <img
        src={baseFileUrl + "/Vector-bg.png"}
        alt=""
        className="absolute xl:top-[110px] top-[190px] right-8 xl:right-[177px] w-[35%] xl:w-[311.86px]"
      />

      <h2 className="xl:text-[50px] xl:w-full text-[30px] w-[90%] mx-auto text-center mt-[30px] xl:mt-[108px] leading-[46.5px] xl:leading-[77.5px] font-bold text-[#191919]">
      برنامه‌های غذایی
      </h2>
      <p className="text-center xl:w-full text-[14px] mx-auto  mb-20 xl:mb-0 mt-6 leading-[21.7px] xl:mt-10 xl:leading-[24.8px] text-[#3E4151]">برنامه‌های رژیم غذایی شخصی‌سازی‌شده توسط پزشکان مجرب برای زندگی بهتر</p>
    </section>
  );
}
