import React, { useState, useContext, useEffect } from "react";
import { imagesFileUrl, svgFileUrl } from "../../../config";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="max-w-full w-[1133px] m-auto xl:mt-[60px] px-[34px] 2xl:mt-[120px] mb-[160px]">
      <section className=" xl:w-full h-[199px] xl:h-[615px] rounded-[40px] border border-[#E6E8E9] flex justify-center mt-[80px] xl:mt-0 m-auto px-3 items-center relative">
        <img className="w-[1059px]  max-w-full rounded-[40px]" src={imagesFileUrl + "dashboard/singleExercise.jpg"} alt="" />
        <img className="w-[80px] xl:w-[158px] left-[30px] bottom-[-44px] absolute xl:bottom-[-75px] xl:left-10 z-10" src={imagesFileUrl + "dashboard/bigMuscleIcon.png"} alt="" />
      </section>
      <h3 className="font-bold text-[40px] mt-[64px]">اسم تمرین</h3>
      <section className="grid grid-cols-1 xl:grid-cols-2">
        <section className="w-[171px] h-[24px] flex justify-between items-center mt-[48px]">
          <section className="w-[100px] h-[24px] flex justify-between items-center">
            <section className="flex justify-center items-center">
              <img src={svgFileUrl + "timer1.svg"} alt="" />
              <span className="block h-[12px] text-xs font-normal mr-1">20:00</span>
            </section>
            <span className="block h-[12px] w-[1px] bg-[#E6E8E9]"></span>
            <section className="flex justify-center items-center">
              <img src={svgFileUrl + "damble.svg"} alt="" />
              <span className="block h-[12px] text-xs font-normal mr-1">دمبل</span>
            </section>
          </section>
          <button className="bg-[#FEEAF3] rounded-full w-[55px] h-[24px] text-xs font-normal text-[#F02484]">پیشرفته</button>
        </section>
        <section>
          <section className="flex justify-center xl:justify-end gap-[6px]  items-center">
            <span className="font-bold text-[32px]">2,500,000</span>
            <span className="font-normal text-[24px]">تومان</span>
          </section>
          <section className="flex justify-center xl:justify-end">
            <button className="bg-purple mt-[20px] font-normal text-white w-[238px] h-[40px] rounded-full">خرید پکیچ</button>
          </section>
        </section>
      </section>
      <section className="w-full xl:h-[401px]">
        <section>
          <section className="w-[347px] flex justify-start items-center mt-[56px] xl:mt-0">
            <span>به این پکیج امتیاز دهید</span>
            <div class="flex items-center">
              <svg class="w-4 h-4 text-yellow-300 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg class="w-4 h-4 text-yellow-300 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg class="w-4 h-4 text-yellow-300 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg class="w-4 h-4 text-yellow-300 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg class="w-4 h-4 ms-1 text-gray-300 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
            </div>
          </section>
          <section className="grid grid-cols-1 xl:grid-cols-10 mt-[25px] gap-[32px]">
            <section className="xl:col-span-7 xl:h-[349px] p-[20px] px-[40px] rounded-[20px]  shadow-[0px_6px_26px_-4px_#5408A91F]">
              <span className="text-[22px] font-bold">توضیحات تمرین</span>
              <ul className="mt-[10px] list-decimal text-[14px] font-normal text-[#3E4151] leading-6">
                <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li>
                <li className="mt-2">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li>
                <li className="mt-2">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. ساختگی با تولید سادگی نامفهوم اختگی با تولید سادگی از صنعت چاپ و با استفاده اختگی با تولید سادگی از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. </li>
              </ul>
            </section>
            <section className="xl:col-span-3 py-[15px] px-[24px] h-[349px] bg-[#F6FCFE] rounded-[20px] shadow-[0px_6px_26px_-4px_#5408A91F]">
              <img className="w-[140px] h-[140px] m-auto" src={imagesFileUrl + "coach/ch3big.png"} alt="" />
              <section className="mt-[22px]">
                <section className="flex justify-center items-center gap-2">
                  <img src={imagesFileUrl + "doctor-profile-check.png"} className=" w-[17px] h-[17px]" alt="" />
                  <span className="font-bold text-[22px] block">ستاره بخشنده</span>
                </section>
                <span className="block text-center font-semibold">مربی بدنسازی</span>
                <section className="flex gap-[5px] justify-center items-center mt-[20px]">
                  <section>
                    <span className="text-[18px] font-bold">4</span>
                    <span className="text-[18px] font-bold">/</span>
                    <span className="text-[14px] font-normal">5</span>
                  </section>
                  <img className="w-[18px] h-[18px]" src={imagesFileUrl + "doctor-profile-star-fill.png"} alt="" />
                </section>
              </section>
              <button className="text-purple border border-purple block mt-[10px] w-[190px] h-[30px] rounded-full m-auto">نمایش صفحه مربی</button>
            </section>
          </section>
        </section>
      </section>
      <section className="w-full xl:py-[60px] xl:px-[20px] mt-[64px]  ">
        <span className="text-[22px] font-bold ">رژیم های غذایی مشابه</span>
        <section className="grid grid-cols-1 xl:grid-cols-3 gap-5">
          <Item
            img={imagesFileUrl + "dashboard/zumba.png"}
            muscle={imagesFileUrl + "dashboard/zumbaIcon.png"}
            duration="20:00"
            level={1}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
          <Item
            img={imagesFileUrl + "dashboard/zumba2.png"}
            muscle={imagesFileUrl + "dashboard/zumba2Icon.png"}
            duration="20:00"
            level={2}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
          <Item
            img={imagesFileUrl + "dashboard/zumba3.png"}
            muscle={imagesFileUrl + "dashboard/zumba3Icon.png"}
            duration="20:00"
            level={1}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
        </section>
      </section>
    </section>
  );
}
