import React, { useEffect, useRef } from 'react';
import './style.css';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

const App = ({
  setMuscle,
  style,
  onClick,
  front11,
  front7,
  setBack7,
  front12,
  front22,
  front16,
  setBack22,
  setBack16,
  setBack11,
  setBack12,
}) => {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const n1 = useRef();
  const n22_r = useRef();
  const n22_l = useRef();
  const n3_r = useRef();
  const n3_l = useRef();
  const n4_r = useRef();
  const n4_l = useRef();
  const n5_r = useRef();
  const n5_l = useRef();
  const n6_r = useRef();
  const n6_l = useRef();
  const n7_r = useRef();
  const n7_l = useRef();
  const n9_r = useRef();
  const n9_l = useRef();
  const n10_r = useRef();
  const n10_l = useRef();
  const n11_r = useRef();
  const n11_l = useRef();
  const n12_r = useRef();
  const n12_l = useRef();
  const n13_1 = useRef();
  const n13_2 = useRef();
  const n13_3 = useRef();
  const n13_4 = useRef();
  const n14_1 = useRef();
  const n14_2 = useRef();
  const n14_3 = useRef();
  const n14_4 = useRef();
  const n15_r = useRef();
  const n15_l = useRef();
  const n16_r = useRef();
  const n16_l = useRef();
  const n17_r = useRef();
  const n17_l = useRef();
  const n18_r = useRef();
  const n18_l = useRef();
  const n19_1 = useRef();
  const n19_2 = useRef();
  const n19_4 = useRef();
  const n19_3 = useRef();
  const n20_1 = useRef();
  const n20_2 = useRef();
  const n20_3 = useRef();
  const n20_4 = useRef();
  const n21_r = useRef();
  const n21_l = useRef();
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const n1Enter = () => {
    n1.current.classList.add('activeMuscle');
  };
  const n1Leave = () => {
    n1.current.classList.remove('activeMuscle');
  };
  const n3Enter = () => {
    n3_r.current.classList.add('activeMuscle');
    n3_l.current.classList.add('activeMuscle');
  };
  const n3Leave = () => {
    n3_r.current.classList.remove('activeMuscle');
    n3_l.current.classList.remove('activeMuscle');
  };
  const n4Enter = () => {
    n4_r.current.classList.add('activeMuscle');
    n4_l.current.classList.add('activeMuscle');
  };
  const n4Leave = () => {
    n4_r.current.classList.remove('activeMuscle');
    n4_l.current.classList.remove('activeMuscle');
  };
  const n5Enter = () => {
    n5_r.current.classList.add('activeMuscle');
    n5_l.current.classList.add('activeMuscle');
  };
  const n5Leave = () => {
    n5_r.current.classList.remove('activeMuscle');
    n5_l.current.classList.remove('activeMuscle');
  };
  const n6Enter = () => {
    n6_r.current.classList.add('activeMuscle');
    n6_l.current.classList.add('activeMuscle');
  };
  const n6Leave = () => {
    n6_r.current.classList.remove('activeMuscle');
    n6_l.current.classList.remove('activeMuscle');
  };
  const n7Enter = () => {
    setBack7(true);
    n7_r.current.classList.add('activeMuscle');
    n7_l.current.classList.add('activeMuscle');
  };
  const n7Leave = () => {
    setBack7(false);
    n7_r.current.classList.remove('activeMuscle');
    n7_l.current.classList.remove('activeMuscle');
  };
  const n9Enter = () => {
    n9_r.current.classList.add('activeMuscle');
    n9_l.current.classList.add('activeMuscle');
  };
  const n9Leave = () => {
    n9_r.current.classList.remove('activeMuscle');
    n9_l.current.classList.remove('activeMuscle');
  };
  const n10Enter = () => {
    n10_r.current.classList.add('activeMuscle');
    n10_l.current.classList.add('activeMuscle');
  };
  const n10Leave = () => {
    n10_r.current.classList.remove('activeMuscle');
    n10_l.current.classList.remove('activeMuscle');
  };
  const n11Enter = () => {
    setBack11(true);
    n11_r.current.classList.add('activeMuscle');
    n11_l.current.classList.add('activeMuscle');
  };
  const n11Leave = () => {
    setBack11(false);
    n11_r.current.classList.remove('activeMuscle');
    n11_l.current.classList.remove('activeMuscle');
  };
  const n12Enter = () => {
    setBack12(true);
    n12_r.current.classList.add('activeMuscle');
    n12_l.current.classList.add('activeMuscle');
  };
  const n12Leave = () => {
    setBack12(false);
    n12_r.current.classList.remove('activeMuscle');
    n12_l.current.classList.remove('activeMuscle');
  };
  const n13Enter = () => {
    n13_1.current.classList.add('activeMuscle');
    n13_2.current.classList.add('activeMuscle');
    n13_3.current.classList.add('activeMuscle');
    n13_4.current.classList.add('activeMuscle');
  };
  const n13Leave = () => {
    n13_1.current.classList.remove('activeMuscle');
    n13_2.current.classList.remove('activeMuscle');
    n13_3.current.classList.remove('activeMuscle');
    n13_4.current.classList.remove('activeMuscle');
  };
  const n14Enter = () => {
    n14_1.current.classList.add('activeMuscle');
    n14_2.current.classList.add('activeMuscle');
    n14_3.current.classList.add('activeMuscle');
    n14_4.current.classList.add('activeMuscle');
  };
  const n14Leave = () => {
    n14_1.current.classList.remove('activeMuscle');
    n14_2.current.classList.remove('activeMuscle');
    n14_3.current.classList.remove('activeMuscle');
    n14_4.current.classList.remove('activeMuscle');
  };
  const n15Enter = () => {
    n15_r.current.classList.add('activeMuscle');
    n15_l.current.classList.add('activeMuscle');
  };
  const n15Leave = () => {
    n15_r.current.classList.remove('activeMuscle');
    n15_l.current.classList.remove('activeMuscle');
  };
  const n16Enter = () => {
    setBack16(true);
    n16_r.current.classList.add('activeMuscle');
    n16_l.current.classList.add('activeMuscle');
  };
  const n16Leave = () => {
    setBack16(false);
    n16_r.current.classList.remove('activeMuscle');
    n16_l.current.classList.remove('activeMuscle');
  };
  const n17Enter = () => {
    n17_r.current.classList.add('activeMuscle');
    n17_l.current.classList.add('activeMuscle');
  };
  const n17Leave = () => {
    n17_r.current.classList.remove('activeMuscle');
    n17_l.current.classList.remove('activeMuscle');
  };
  const n18Enter = () => {
    n18_r.current.classList.add('activeMuscle');
    n18_l.current.classList.add('activeMuscle');
  };
  const n18Leave = () => {
    n18_r.current.classList.remove('activeMuscle');
    n18_l.current.classList.remove('activeMuscle');
  };
  const n19Enter = () => {
    n19_1.current.classList.add('activeMuscle');
    n19_2.current.classList.add('activeMuscle');
    n19_3.current.classList.add('activeMuscle');
    n19_4.current.classList.add('activeMuscle');
  };
  const n19Leave = () => {
    n19_1.current.classList.remove('activeMuscle');
    n19_2.current.classList.remove('activeMuscle');
    n19_3.current.classList.remove('activeMuscle');
    n19_4.current.classList.remove('activeMuscle');
  };
  const n20Enter = () => {
    n20_1.current.classList.add('activeMuscle');
    n20_2.current.classList.add('activeMuscle');
    n20_3.current.classList.add('activeMuscle');
    n20_4.current.classList.add('activeMuscle');
  };
  const n20Leave = () => {
    n20_1.current.classList.remove('activeMuscle');
    n20_2.current.classList.remove('activeMuscle');
    n20_3.current.classList.remove('activeMuscle');
    n20_4.current.classList.remove('activeMuscle');
  };
  const n21Enter = () => {
    n21_r.current.classList.add('activeMuscle');
    n21_l.current.classList.add('activeMuscle');
  };
  const n21Leave = () => {
    n21_r.current.classList.remove('activeMuscle');
    n21_l.current.classList.remove('activeMuscle');
  };
  const n22Enter = () => {
    setBack22(true);
    n22_r.current.classList.add('activeMuscle');
    n22_l.current.classList.add('activeMuscle');
  };
  const n22Leave = () => {
    setBack22(false);
    n22_r.current.classList.remove('activeMuscle');
    n22_l.current.classList.remove('activeMuscle');
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (front11) {
      n11Enter();
    } else {
      n11Leave();
    }
  }, [front11]);
  useEffect(() => {
    if (front7) {
      n7Enter();
    } else {
      n7Leave();
    }
  }, [front7]);
  useEffect(() => {
    if (front12) {
      n12Enter();
    } else {
      n12Leave();
    }
  }, [front12]);
  useEffect(() => {
    if (front22) {
      n22Enter();
    } else {
      n22Leave();
    }
  }, [front22]);
  useEffect(() => {
    if (front16) {
      n16Enter();
    } else {
      n16Leave();
    }
  }, [front16]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //

  return (
    <svg
      version="1.1"
      id="manFront"
      x="0px"
      y="0px"
      onClick={onClick}
      style={style}
      viewBox="0 0 1162.2 1692.28"
    >
      <path
        class="st0"
        onClick={() => alert('ok')}
        d="M704.16,1554.1c19.73,20.85,44.95,61.1,47.37,77.1s-11.67,33.94-27.41,41.22c-15.74,7.27-30.56,0.97-39.39-3.88
      c-8.83-4.85-20.94-13.09-24.34-44.61c-3.4-31.52-3.89-35.88-14.56-44.61c-10.67-8.73-13.12-21.82-2.44-39.76
      C654.06,1521.61,693.15,1522.58,704.16,1554.1z"
      />
      <path
        class="st1 n1"
        ref={n1}
        onMouseEnter={n1Enter}
        onMouseLeave={n1Leave}
        onClick={()=>setMuscle(1)}
        d="M638.99,216.53c-0.11,2.64-0.22,89.11,0,90.58c1.77,11.67,5.36,24.17,14.29,33.61
        c6.07,6.42,14.64,10.47,14.64,10.47c-10.43-2.4-30.04-3.58-41.92-3.27c-14.56,0.38-22.65,0.28-31.36,14.06
        c-3.15,4.99-6.71,19.87-6.71,19.87c-2.56,11.29-3.97,27.49-3.97,27.49s-1.08-19.93-3.18-27.58c0,0-3.94-16.45-7.13-20.51
        c0,0-4.9-6.96-8.03-8.92c-8-5.03-20.5-4.27-26.7-4.41c-24.75-0.54-38.2,3.29-38.2,3.29c12.79-6.18,22.64-16.32,27.27-34.91
        c0.9-3.62,1.72-9.37,1.72-9.37c0.1-6.82-0.13-11.2,0.03-19.14c0,0-0.01-20.33-0.01-27.89c0-12.07,0.01-43.36,0.01-43.36
        s29.64,46.42,51.22,46.1C602.74,262.31,638.99,216.53,638.99,216.53z"
      />
      <path
        class="st2"
        d="M638.57,216.96c-0.11,2.64-0.22,89.11,0,90.58c1.77,11.67,5.36,24.17,14.29,33.61
        c6.07,6.42,14.64,10.47,14.64,10.47c-10.43-2.4-30.04-3.58-41.92-3.27c-14.56,0.38-22.65,0.28-31.36,14.06
        c-3.15,4.99-6.71,19.87-6.71,19.87c-2.56,11.29-3.97,27.49-3.97,27.49s-1.08-19.93-3.18-27.58c0,0-3.94-16.45-7.13-20.51
        c0,0-4.9-6.96-8.03-8.92c-8-5.03-20.5-4.27-26.7-4.41c-24.75-0.54-38.2,3.29-38.2,3.29c12.79-6.18,22.65-16.32,27.27-34.91
        c0.9-3.62,1.72-9.37,1.72-9.37c0.1-6.82-0.13-11.2,0.03-19.14c0,0-0.01-20.33-0.01-27.89c0-12.07,0.01-43.36,0.01-43.36
        s29.64,46.42,51.22,46.1C602.31,262.74,638.57,216.96,638.57,216.96z"
      />
      <path
        class="st0 n20"
        ref={n20_1}
        onMouseEnter={n20Enter}
        onMouseLeave={n20Leave}
        onClick={()=>setMuscle(20)}
        d="M726.56,1203.04c0,0,29.33,84.37,12.84,149.83c-16.49,65.46-45.58,127.53-47.03,149.83
        c-1.45,22.3,11.79,51.4,11.79,51.4s-13.75-38.79-60.77-14.55l-2.9-17.48l51.97-288.49L726.56,1203.04z"
      />
      <path
        class="st3"
        d="M726.56,1203.04c0,0,29.33,84.37,12.84,149.83c-16.49,65.46-45.58,127.53-47.03,149.83
        c-1.45,22.3,11.79,51.4,11.79,51.4s-13.75-38.79-60.77-14.55l-2.9-17.48l51.97-288.49L726.56,1203.04z"
      />
      <path
        class="st0 n20"
        ref={n20_2}
        onMouseEnter={n20Enter}
        onMouseLeave={n20Leave}
        onClick={()=>setMuscle(20)}
        d="M647.62,1220.97c0,0-18.77,62.08-13.92,96.99c4.85,34.91,17.17,126.23,13.92,144.82
        c-1.4,7.99-18.05,29.28-12.59,44.29c2.9,7.96,8.36,32.49,8.36,32.49s9.81-6.98,22.61-7.12c12.8-0.14,21.74-214.47,9.29-271.69
        C656.38,1173.83,647.62,1220.97,647.62,1220.97z"
      />
      <path
        class="st3"
        d="M647.62,1220.97c0,0-18.77,62.08-13.92,96.99c4.85,34.91,17.17,126.23,13.92,144.82
        c-1.4,7.99-18.05,29.28-12.59,44.29c2.9,7.96,8.36,32.49,8.36,32.49s9.81-6.98,22.61-7.12c12.8-0.14,21.74-214.47,9.29-271.69
        C656.38,1173.83,647.62,1220.97,647.62,1220.97z"
      />
      <path
        class="st0"
        d="M741.52,1114.04c-6.77,20.21-8.42,92.88-26.85,119.55c0,0-17.94-49.94,0-86.31
        C732.62,1110.91,741.52,1114.04,741.52,1114.04z"
      />
      <path
        class="st3"
        d="M741.52,1114.04c-6.77,20.21-8.42,92.88-26.85,119.55c0,0-17.94-49.94,0-86.31
        C732.62,1110.91,741.52,1114.04,741.52,1114.04z"
      />
      <path
        class="st0"
        d="M625.43,1142.71c0,0-1.11,34.95,9.56,58.87c10.67,23.92,34.59,38.47,34.59,38.47s13.92-55.28-9.2-82.43
C637.25,1130.47,625.43,1142.71,625.43,1142.71z"
      />
      <path
        class="st3"
        d="M625.43,1142.71c0,0-1.11,34.95,9.56,58.87c10.67,23.92,34.59,38.47,34.59,38.47s13.92-55.28-9.2-82.43
C637.25,1130.47,625.43,1142.71,625.43,1142.71z"
      />
      <path
        class="st4 n21"
        /*   ref={n21_l}
        onMouseEnter={n21Enter}
        onMouseLeave={n21Leave} */
        d="M662.83,1218.85c0,0-12.4,13.12-9.77,35.75c2.62,22.63,17.81,85.92,17.58,128.33
c-0.57,103.44-10.26,150.07-10.26,150.07s10.26-1.4,18.42-0.71c6.14-12.61,13.58-138.2,19.4-184.75
c5.82-46.55,0.38-76.13,8.51-87.44c8.14-11.31,14.01-42.31,1.7-52.84C696.09,1196.74,662.83,1218.85,662.83,1218.85z"
      />
      <path
        class="st3"
        d="M662.83,1218.85c0,0-12.4,13.12-9.77,35.75c2.62,22.63,17.81,85.92,17.58,128.33
c-0.57,103.44-10.26,150.07-10.26,150.07s10.26-1.4,18.42-0.71c6.14-12.61,13.58-138.2,19.4-184.75
c5.82-46.55,0.38-76.13,8.51-87.44c8.14-11.31,14.01-42.31,1.7-52.84C696.09,1196.74,662.83,1218.85,662.83,1218.85z"
      />
      <path
        class="st5"
        d="M714.64,1169.94c3.34,20.07-6.14,50.23-12.6,67.53c-4.99,13.36-28.3,12.92-32.46,2.59
c-5.89-14.65-10.7-30.08-8.74-58.36c1.96-28.28,12.18-62.53,33.31-62.54C715.27,1119.14,711.3,1149.88,714.64,1169.94z"
      />
      <path
        class="st0"
        d="M465.92,1554.1c-19.73,20.85-44.95,61.1-47.37,77.1c-2.42,16,11.67,33.94,27.41,41.22
c15.74,7.27,30.56,0.97,39.39-3.88c8.83-4.85,20.94-13.09,24.34-44.61c3.4-31.52,3.89-35.88,14.56-44.61
c10.67-8.73,13.12-21.82,2.44-39.76C516.02,1521.61,476.93,1522.58,465.92,1554.1z"
      />
      <path
        class="st6 n20"
        ref={n20_3}
        onMouseEnter={n20Enter}
        onMouseLeave={n20Leave}
        onClick={()=>setMuscle(20)}
        d="M443.52,1203.04c0,0-29.33,84.37-12.84,149.83c16.49,65.46,45.58,127.53,47.03,149.83
c1.45,22.3-11.79,51.4-11.79,51.4s13.75-38.79,60.77-14.55l2.9-17.48l-51.97-288.49L443.52,1203.04z"
      />
      <path
        class="st7"
        d="M443.52,1203.04c0,0-29.33,84.37-12.84,149.83c16.49,65.46,45.58,127.53,47.03,149.83
c1.45,22.3-11.79,51.4-11.79,51.4s13.75-38.79,60.77-14.55l2.9-17.48l-51.97-288.49L443.52,1203.04z"
      />
      <path
        class="st8 n20"
        ref={n20_4}
        onMouseEnter={n20Enter}
        onMouseLeave={n20Leave}
        onClick={()=>setMuscle(20)}
        d="M522.46,1220.97c0,0,18.77,62.08,13.92,96.99c-4.85,34.91-17.17,126.23-13.92,144.82
c1.4,7.99,18.05,29.28,12.59,44.29c-2.89,7.96-8.36,32.49-8.36,32.49s-9.81-6.98-22.61-7.12c-12.8-0.14-21.74-214.47-9.29-271.69
C513.7,1173.83,522.46,1220.97,522.46,1220.97z"
      />
      <path
        class="st7"
        d="M522.46,1220.97c0,0,18.77,62.08,13.92,96.99c-4.85,34.91-17.17,126.23-13.92,144.82
c1.4,7.99,18.05,29.28,12.59,44.29c-2.89,7.96-8.36,32.49-8.36,32.49s-9.81-6.98-22.61-7.12c-12.8-0.14-21.74-214.47-9.29-271.69
C513.7,1173.83,522.46,1220.97,522.46,1220.97z"
      />
      <path
        class="st0"
        d="M428.55,1114.04c6.77,20.21,8.42,92.88,26.85,119.55c0,0,17.94-49.94,0-86.31
C437.46,1110.91,428.55,1114.04,428.55,1114.04z"
      />
      <path
        class="st7"
        d="M428.55,1114.04c6.77,20.21,8.42,92.88,26.85,119.55c0,0,17.94-49.94,0-86.31
C437.46,1110.91,428.55,1114.04,428.55,1114.04z"
      />
      <path
        class="st0"
        d="M544.65,1142.71c0,0,1.11,34.95-9.56,58.87c-10.67,23.92-34.59,38.47-34.59,38.47s-13.92-55.28,9.2-82.43
C532.82,1130.47,544.65,1142.71,544.65,1142.71z"
      />
      <path
        class="st7"
        d="M544.65,1142.71c0,0,1.11,34.95-9.56,58.87c-10.67,23.92-34.59,38.47-34.59,38.47s-13.92-55.28,9.2-82.43
C532.82,1130.47,544.65,1142.71,544.65,1142.71z"
      />
      <path
        class="st4 n21"
        /*  ref={n21_r}
        onMouseEnter={n21Enter}
        onMouseLeave={n21Leave} */
        d="M507.24,1218.85c0,0,12.4,13.12,9.78,35.75c-2.62,22.63-17.81,85.92-17.58,128.33
          c0.57,103.44,10.26,150.07,10.26,150.07s-10.26-1.4-18.42-0.71c-6.14-12.61-13.58-138.2-19.4-184.75
          c-5.82-46.55-0.38-76.13-8.51-87.44c-8.14-11.31-14.01-42.31-1.7-52.84C473.99,1196.74,507.24,1218.85,507.24,1218.85z"
      />
      <path
        class="st7"
        d="M507.24,1218.85c0,0,12.4,13.12,9.78,35.75c-2.62,22.63-17.81,85.92-17.58,128.33
          c0.57,103.44,10.26,150.07,10.26,150.07s-10.26-1.4-18.42-0.71c-6.14-12.61-13.58-138.2-19.4-184.75
          c-5.82-46.55-0.38-76.13-8.51-87.44c-8.14-11.31-14.01-42.31-1.7-52.84C473.99,1196.74,507.24,1218.85,507.24,1218.85z"
      />
      <path
        class="st5"
        d="M455.44,1169.94c-3.34,20.07,6.14,50.23,12.6,67.53c4.99,13.36,28.3,12.92,32.46,2.59
c5.89-14.65,10.7-30.08,8.74-58.36c-1.96-28.28-12.18-62.53-33.31-62.54C454.81,1119.14,458.78,1149.88,455.44,1169.94z"
      />
      <path
        class="st5"
        d="M715.31,725.29c0,0,17.32,11.12,15.38,35.05c-1.94,23.92-2.91,53.82-2.42,65.46
c0.48,11.64-34.04-59.23-34.04-59.23S697.02,732.43,715.31,725.29z"
      />
      <path
        class="st5"
        d="M453.7,725.29c0,0-17.32,11.12-15.38,35.05c1.94,23.92,2.91,53.82,2.42,65.46
c-0.48,11.64,34.04-59.23,34.04-59.23S471.99,732.43,453.7,725.29z"
      />
      <path
        class="st0 n16"
        onMouseEnter={n16Enter}
        onMouseLeave={n16Leave}
        onClick={()=>setMuscle(16)}
        ref={n16_l}
        d="M590.16,886.68c0,0,1.86,69.56,12.04,108.83c10.18,39.28,18.2,82,18.25,99.4s121.03-245.56,54.06-302.19
S590.16,886.68,590.16,886.68z"
      />
      <path
        class="st3"
        d="M590.16,886.68c0,0,1.86,69.56,12.04,108.83c10.18,39.28,18.2,82,18.25,99.4s121.03-245.56,54.06-302.19
S590.16,886.68,590.16,886.68z"
      />
      <path
        class="st0 n19"
        onMouseEnter={n19Enter}
        onMouseLeave={n19Leave}
        onClick={()=>setMuscle(19)}
        ref={n19_1}
        d="M699.42,759.52c0,0,64.46,75.49,55.79,195.75l-70.97-179.07L699.42,759.52z"
      />
      <path
        class="st3"
        d="M699.42,759.52c0,0,64.46,75.49,55.79,195.75l-70.97-179.07L699.42,759.52z"
      />
      <path
        class="st0 n18"
        onMouseEnter={n18Enter}
        onMouseLeave={n18Leave}
        onClick={()=>setMuscle(18)}
        ref={n18_l}
        d="M747.51,926.49c0,0,29.45,82.11,8.5,142.23s-29.97,92.13-35.89,99.89s-23.37-8.24-18.03-39.76
C707.42,1097.34,747.51,926.49,747.51,926.49z"
      />
      <path
        class="st3"
        d="M747.51,926.49c0,0,29.45,82.11,8.5,142.23s-29.97,92.13-35.89,99.89s-23.37-8.24-18.03-39.76
C707.42,1097.34,747.51,926.49,747.51,926.49z"
      />
      <path
        class="st4 n19"
        onMouseEnter={n19Enter}
        onMouseLeave={n19Leave}
        onClick={()=>setMuscle(19)}
        ref={n19_2}
        d="M694.23,766.57c12.51,82.36,58.07,95.6,53.67,181.42c-4.4,85.83-42.89,165.83-45.81,180.86
c-2.92,15.03-15.06,14.23-17.85,3.39c-3-11.64-40.34-153.22-19.97-227.41c20.37-74.19,10.24-112.11,10.24-112.11
S681.41,763.36,694.23,766.57z"
      />
      <path
        class="st3"
        d="M694.23,766.57c12.51,82.36,58.07,95.6,53.67,181.42c-4.4,85.83-42.89,165.83-45.81,180.86
c-2.92,15.03-15.06,14.23-17.85,3.39c-3-11.64-40.34-153.22-19.97-227.41c20.37-74.19,10.24-112.11,10.24-112.11
S681.41,763.36,694.23,766.57z"
      />
      <path
        class="st4 n17"
        onMouseEnter={n17Enter}
        onMouseLeave={n17Leave}
        onClick={()=>setMuscle(17)}
        ref={n17_r}
        d="M681.52,1121.51c6.02,25.28-0.39,55.83-15.12,59.71c-14.73,3.88-48.68-11.15-45.77-81.46
c2.91-70.31,32.13-158.49,42.25-189.31C673,879.62,681.52,1121.51,681.52,1121.51z"
      />
      <path
        class="st3"
        d="M681.52,1121.51c6.02,25.28-0.39,55.83-15.12,59.71c-14.73,3.88-48.68-11.15-45.77-81.46
c2.91-70.31,32.13-158.49,42.25-189.31C673,879.62,681.52,1121.51,681.52,1121.51z"
      />
      <path
        class="st0 n16"
        onMouseEnter={n16Enter}
        onMouseLeave={n16Leave}
        onClick={()=>setMuscle(16)}
        ref={n16_r}
        d="M578.85,886.68c0,0-1.86,69.56-12.04,108.83c-10.18,39.28-18.2,82-18.25,99.4
c-0.05,17.4-121.03-245.56-54.06-302.19S578.85,886.68,578.85,886.68z"
      />
      <path
        class="st7"
        d="M578.85,886.68c0,0-1.86,69.56-12.04,108.83c-10.18,39.28-18.2,82-18.25,99.4
c-0.05,17.4-121.03-245.56-54.06-302.19S578.85,886.68,578.85,886.68z"
      />
      <path
        class="st5"
        d="M408.51,466.57c0,91.49,29.5,110.91,38.82,130.05c9.33,19.14,13.82,36.81,10.1,49.09
c-3.72,12.27-22.86,70,10.76,115.99c33.62,45.99,83.75,108.49,95.25,119.29c11.5,10.8,21.09,10.47,21.09,10.47
s9.59,0.33,21.09-10.47c11.5-10.8,61.63-73.3,95.25-119.29c33.62-45.99,14.48-103.72,10.76-115.99
c-3.72-12.27,0.78-29.94,10.1-49.09c9.33-19.14,38.82-38.56,38.82-130.05C760.59,375.08,408.51,375.08,408.51,466.57z"
      />
      <path
        class="st0 n19"
        onMouseEnter={n19Enter}
        onMouseLeave={n19Leave}
        onClick={()=>setMuscle(19)}
        ref={n19_3}
        d="M469.59,759.52c0,0-64.46,75.49-55.79,195.75l70.97-179.07L469.59,759.52z"
      />
      <path
        class="st7"
        d="M469.59,759.52c0,0-64.46,75.49-55.79,195.75l70.97-179.07L469.59,759.52z"
      />
      <path
        class="st0 n18"
        onMouseEnter={n18Enter}
        onMouseLeave={n18Leave}
        onClick={()=>setMuscle(18)}
        ref={n18_r}
        d="M421.5,926.49c0,0-29.45,82.11-8.5,142.23c20.95,60.13,29.97,92.13,35.89,99.89
        c5.91,7.76,23.37-8.24,18.03-39.76C461.59,1097.34,421.5,926.49,421.5,926.49z"
      />
      <path
        class="st7"
        d="M421.5,926.49c0,0-29.45,82.11-8.5,142.23c20.95,60.13,29.97,92.13,35.89,99.89
  c5.91,7.76,23.37-8.24,18.03-39.76C461.59,1097.34,421.5,926.49,421.5,926.49z"
      />
      <path
        class="st4 n19"
        onMouseEnter={n19Enter}
        onMouseLeave={n19Leave}
        onClick={()=>setMuscle(19)}
        ref={n19_4}
        d="M474.78,766.57c-12.51,82.36-58.07,95.6-53.67,181.42c4.4,85.83,42.89,165.83,45.81,180.86
c2.92,15.03,15.06,14.23,17.85,3.39c3-11.64,40.34-153.22,19.97-227.41S494.5,792.72,494.5,792.72S487.6,763.36,474.78,766.57z"
      />
      <path
        class="st7"
        d="M474.78,766.57c-12.51,82.36-58.07,95.6-53.67,181.42c4.4,85.83,42.89,165.83,45.81,180.86
c2.92,15.03,15.06,14.23,17.85,3.39c3-11.64,40.34-153.22,19.97-227.41S494.5,792.72,494.5,792.72S487.6,763.36,474.78,766.57z"
      />
      <path
        class="st4 n17"
        onMouseEnter={n17Enter}
        onMouseLeave={n17Leave}
        onClick={()=>setMuscle(17)}
        ref={n17_l}
        d="M487.49,1121.51c-6.02,25.28,0.39,55.83,15.12,59.71c14.74,3.88,48.68-11.15,45.77-81.46
c-2.91-70.31-32.13-158.49-42.25-189.31C496.01,879.62,487.49,1121.51,487.49,1121.51z"
      />
      <path
        class="st7"
        d="M487.49,1121.51c-6.02,25.28,0.39,55.83,15.12,59.71c14.74,3.88,48.68-11.15,45.77-81.46
c-2.91-70.31-32.13-158.49-42.25-189.31C496.01,879.62,487.49,1121.51,487.49,1121.51z"
      />
      <path
        class="st0"
        d="M856.11,657.7c4.45-40.61-17.57-48.12-64.81-59.95h-0.01c0,0-7.14,72.65,24.27,84.8
c31.41,12.15,42.98,14.78,50.21,26.33c0.01,0.02,0.02,0.03,0.04,0.05C851.71,684.69,856.11,657.7,856.11,657.7z"
      />
      <path
        class="st9"
        d="M856.11,657.7c4.45-40.61-17.57-48.12-64.81-59.95h-0.01c0,0-7.14,72.65,24.27,84.8
c31.41,12.15,42.98,14.78,50.21,26.33c0.01,0.02,0.02,0.03,0.04,0.05C851.71,684.69,856.11,657.7,856.11,657.7z"
      />
      <path
        class="st0 n12"
        onMouseEnter={n12Enter}
        onMouseLeave={n12Leave}
        onClick={()=>setMuscle(12)}
        ref={n12_r}
        d="M873,720.43c-2.7-4.02-5.08-7.86-7.2-11.5c-0.01-0.02-0.02-0.03-0.04-0.05c-7.23-11.55-18.8-14.18-50.21-26.33
c-5.74-2.22-10.55-6.29-14.59-11.43l0,0c0,0,4.04,40.5,28.97,60.81c24.93,20.31,67.37,97.87,71.18,112.07c0,0,4.89-2.98,13.98-2.48
C915.1,841.53,893.65,751.19,873,720.43z"
      />
      <path
        class="st9"
        d="M873,720.43c-2.7-4.02-5.08-7.86-7.2-11.5c-0.01-0.02-0.02-0.03-0.04-0.05c-7.23-11.55-18.8-14.18-50.21-26.33
c-5.74-2.22-10.55-6.29-14.59-11.43l0,0c0,0,4.04,40.5,28.97,60.81c24.93,20.31,67.37,97.87,71.18,112.07c0,0,4.89-2.98,13.98-2.48
C915.1,841.53,893.65,751.19,873,720.43z"
      />
      <path
        class="st0 n10"
        onMouseEnter={n10Enter}
        onMouseLeave={n10Leave}
        onClick={()=>setMuscle(10)}
        ref={n10_r}
        d="M791.3,597.75c-20.01-27.94-49.24-63.15-49.84-118.12c-0.67-60.94,46.61-64.99,52.39-2.84
c3.32,35.66,49.31,91.28,47.57,114.27C840.13,608.13,811.31,625.68,791.3,597.75z"
      />
      <path
        class="st9"
        d="M791.3,597.75c-20.01-27.94-49.24-63.15-49.84-118.12c-0.67-60.94,46.61-64.99,52.39-2.84
c3.32,35.66,49.31,91.28,47.57,114.27C840.13,608.13,811.31,625.68,791.3,597.75z"
      />
      <path
        class="st4 n11"
        onMouseEnter={n11Enter}
        onMouseLeave={n11Leave}
        onClick={()=>setMuscle(11)}
        ref={n11_l}
        d="M855.77,579.63c0,0,44.97,35.88,56,86.06s26.26,150.61,26.26,150.61s-11.83,21.2-31.63,18.07
c0,0-12.76-83.18-33.41-113.95c-20.66-30.76-16.89-62.73-16.89-62.73s-28.86-51.21-9.26-76.91L855.77,579.63z"
      />
      <path
        class="st9"
        d="M855.77,579.63c0,0,44.97,35.88,56,86.06s26.26,150.61,26.26,150.61s-11.83,21.2-31.63,18.07
c0,0-12.76-83.18-33.41-113.95c-20.66-30.76-16.89-62.73-16.89-62.73s-28.86-51.21-9.26-76.91L855.77,579.63z"
      />
      <g>
        <path
          class="st0"
          d="M897.94,835.85c1.96-21.05,40.09-19.54,40.09-19.54c17.68,7.6,39.11,9.96,52.92,22.19
c13.81,12.23,21.76,14.26,35.45,17.6c13.69,3.33,9.67,18.54-7.85,17.78c-17.54-0.75-26.74-3.82-38.11-7.32l0,0c0,0,0,0,0,0
c16.4,30,25.56,52.83,31.73,80.41c1.56,17.98-29.7,17.71-42.34,12.87c-24.04-8.23-44.12-49.82-55.47-61.91
C903.04,884.82,895.98,856.9,897.94,835.85z"
        />
        <path
          class="st0"
          d="M980.44,866.56c-14.3-1.76-34.43,0.45-53.07-18.39"
        />
      </g>
      <path
        class="st8 n22"
        onMouseEnter={n22Enter}
        onMouseLeave={n22Leave}
        onClick={()=>setMuscle(22)}
        ref={n22_l}
        d="M639.02,252.89c-0.08,3.52,0.04,10.49,0,13.06v40.97c3.76,27.39,15.8,37.61,28.99,44.29
c0,0,3.95,0.81,15.78,3.62c6.81,1.62,12.43,3.79,16.03,5.5c0,0-0.1-0.93,1.18-1.89c3.97-2.98,11.96-7.61,18.78-10.33
c12.03-4.49,20.55-5.54,28.61-5.74c2.77-0.07,8.26,0.55,10.47,0.88c-1.18,0.03-3.88-1.28-4.64-1.96l-4.72-4.54
c-2.91-2.7-7.46-6.53-7.46-6.53c-6.68-5.75-19.62-15.27-19.62-15.27c-16.02-12.28-68.74-44.64-75.48-51.96c0,0-2.6-2.28-3.86-3.78
C642,257.95,639.53,254.17,639.02,252.89z"
      />
      <path
        class="st10"
        d="M638.59,253.32c-0.08,3.52,0.04,10.49,0,13.06v40.97c3.76,27.39,15.8,37.61,28.99,44.29
c0,0,3.95,0.81,15.78,3.62c6.81,1.62,12.43,3.79,16.03,5.5c0,0-0.1-0.93,1.18-1.89c3.97-2.98,11.96-7.61,18.78-10.33
c12.03-4.49,20.55-5.54,28.61-5.74c2.77-0.07,8.26,0.55,10.47,0.88c-1.18,0.03-3.88-1.28-4.64-1.96l-4.72-4.54
c-2.91-2.7-7.46-6.53-7.46-6.53c-6.68-5.75-19.62-15.27-19.62-15.27c-16.02-12.28-68.74-44.64-75.48-51.96c0,0-2.6-2.28-3.86-3.78
C641.57,258.38,639.1,254.6,638.59,253.32z"
      />
      <path
        class="st4 n13"
        onMouseEnter={n13Enter}
        onMouseLeave={n13Leave}
        onClick={()=>setMuscle(13)}
        ref={n13_1}
        d="M649.97,498.83c0,0-22.25-11.3-29.81-15.85c-2.43-1.47-5.56-3.13-8.94-5.2c-0.05-0.03-0.12-0.07-0.17-0.1
c-13.73-8.13-26.54,12.08-26.54,29.86c0,17.78,14.63,22.63,20.12,21.49c5.5-1.14,30.71,1.46,45.58,8.25
c14.87,6.79,17.18,2.35,16.19-11.15C665.4,512.63,656.76,500.17,649.97,498.83z"
      />
      <path
        class="st9"
        d="M649.97,498.83c0,0,20.53,6.58,20.5,6.57c-24.29-5.55-42.75-17.87-50.31-22.43c-2.43-1.47-5.56-3.13-8.94-5.2
c-0.05-0.03-0.12-0.07-0.17-0.1c-13.73-8.13-26.54,12.08-26.54,29.86c0,17.78,14.63,22.63,20.12,21.49
c5.5-1.14,30.71,1.46,45.58,8.25c14.87,6.79,17.18,2.35,16.19-11.15C665.4,512.63,656.76,500.17,649.97,498.83z"
      />
      <path
        class="st4 n13"
        onMouseEnter={n13Enter}
        onMouseLeave={n13Leave}
        onClick={()=>setMuscle(13)}
        ref={n13_2}
        d="M656.07,539.74c-0.03-0.01-0.06-0.03-0.09-0.05c-1.66-0.61-3.55-1.39-5.78-2.41
c-12.01-5.48-30.75-8.23-40.42-8.48c-8.4,0.28-25.28,6.23-25.28,24.32c0,18.1,5.25,39.76,9.13,45.9c3.88,6.14,12.61,6.14,25.54,2.26
c12.93-3.88,32-7.11,42.35-4.85c10.34,2.26,13.9-11.64,9.05-31.68C665.72,544.72,661.94,542.2,656.07,539.74z"
      />
      <path
        class="st9"
        d="M656.07,539.74c-0.03-0.01-0.06-0.03-0.09-0.05c-1.66-0.61-3.55-1.39-5.78-2.41
c-12.01-5.48-30.75-8.23-40.42-8.48c-8.4,0.28-25.28,6.23-25.28,24.32c0,18.1,5.25,39.76,9.13,45.9c3.88,6.14,12.61,6.14,25.54,2.26
c12.93-3.88,32-7.11,42.35-4.85c10.34,2.26,13.9-11.64,9.05-31.68C665.72,544.72,661.94,542.2,656.07,539.74z"
      />
      <path
        class="st4 n14"
        onMouseEnter={n14Enter}
        onMouseLeave={n14Leave}
        onClick={()=>setMuscle(14)}
        ref={n14_1}
        d="M663.27,596.67c-0.56-0.02-1.14-0.09-1.75-0.22c-1.74-0.15-4.13-0.44-6.95-0.73
c-10.61-0.24-24.99,2.45-35.4,5.58c-2.41,0.72-4.62,1.26-6.74,1.7c-0.03,0.02-0.06,0.04-0.09,0.06
c-10.96,3.41-27.87,9.23-27.87,23.46c0,14.22,0.02,11.41,0.02,21.75s11.72,39.99,24.97,32.56c13.25-7.43,36.21-16.16,44.93-18.1
c8.73-1.94,13.65-10.04,16.16-35.56C672.91,603.38,671.07,597.98,663.27,596.67z"
      />
      <path
        class="st9"
        d="M663.27,596.67c-0.56-0.02-1.14-0.09-1.75-0.22c-1.74-0.15-4.13-0.44-6.95-0.73
c-10.61-0.24-24.99,2.45-35.4,5.58c-2.41,0.72-4.62,1.26-6.74,1.7c-0.03,0.02-0.06,0.04-0.09,0.06
c-10.96,3.41-27.87,9.23-27.87,23.46c0,14.22,0.02,11.41,0.02,21.75s11.72,39.99,24.97,32.56c13.25-7.43,36.21-16.16,44.93-18.1
c8.73-1.94,13.65-10.04,16.16-35.56C672.91,603.38,671.07,597.98,663.27,596.67z"
      />
      <path
        class="st4 n14"
        onMouseEnter={n14Enter}
        onMouseLeave={n14Leave}
        onClick={()=>setMuscle(14)}
        ref={n14_2}
        d="M654.41,662.71c-3,0.91-6.85,2.04-11.08,3.37c-10.57,3.7-24.58,9.53-33.85,14.73
c-10.54,7.66-24.97,21.98-24.97,44.61c0,22.63,0,63.29,0,90.93c0,27.64,30.89,3.26,48.28-34.88c17.4-38.14,31.96-80.55,33.26-97.07
C667.34,667.88,665.39,659.37,654.41,662.71z"
      />
      <path
        class="st9"
        d="M654.41,662.71c-3,0.91-6.85,2.04-11.08,3.37c-10.57,3.7-24.58,9.53-33.85,14.73
c-10.54,7.66-24.97,21.98-24.97,44.61c0,22.63,0,63.29,0,90.93c0,27.64,30.89,3.26,48.28-34.88c17.4-38.14,31.96-80.55,33.26-97.07
C667.34,667.88,665.39,659.37,654.41,662.71z"
      />
      <path
        class="st4 n13"
        onMouseEnter={n13Enter}
        onMouseLeave={n13Leave}
        onClick={()=>setMuscle(13)}
        ref={n13_3}
        d="M519.04,498.83c0,0,22.25-11.3,29.81-15.85c2.43-1.47,5.56-3.13,8.94-5.2c0.05-0.03,0.12-0.07,0.17-0.1
c13.73-8.13,26.54,12.08,26.54,29.86c0,17.78-14.63,22.63-20.12,21.49c-5.5-1.14-30.71,1.46-45.58,8.25
c-14.87,6.79-17.18,2.35-16.19-11.15C503.6,512.63,512.25,500.17,519.04,498.83z"
      />
      <path
        class="st9"
        d="M519.04,498.83c0,0-20.53,6.58-20.5,6.57c24.29-5.55,42.75-17.87,50.31-22.43c2.43-1.47,5.56-3.13,8.94-5.2
c0.05-0.03,0.12-0.07,0.17-0.1c13.73-8.13,26.54,12.08,26.54,29.86c0,17.78-14.63,22.63-20.12,21.49
c-5.5-1.14-30.71,1.46-45.58,8.25c-14.87,6.79-17.18,2.35-16.19-11.15C503.6,512.63,512.25,500.17,519.04,498.83z"
      />
      <path
        class="st4 n13"
        onMouseEnter={n13Enter}
        onMouseLeave={n13Leave}
        onClick={()=>setMuscle(13)}
        ref={n13_4}
        d="M513.26,539.74c0.03-0.01,0.06-0.03,0.09-0.05c1.66-0.61,3.55-1.39,5.78-2.41c12.01-5.48,30.75-8.23,40.42-8.48
c8.4,0.28,25.28,6.23,25.28,24.32c0,18.1-5.25,39.76-9.13,45.9c-3.88,6.14-12.61,6.14-25.54,2.26c-12.93-3.88-32-7.11-42.35-4.85
c-10.34,2.26-13.9-11.64-9.05-31.68C503.61,544.72,507.4,542.2,513.26,539.74z"
      />
      <path
        class="st9"
        d="M512.94,539.74c0.03-0.01,0.06-0.03,0.09-0.05c1.66-0.61,3.55-1.39,5.78-2.41c12.01-5.48,30.75-8.23,40.42-8.48
c8.4,0.28,25.28,6.23,25.28,24.32c0,18.1-5.25,39.76-9.13,45.9c-3.88,6.14-12.61,6.14-25.54,2.26c-12.93-3.88-32-7.11-42.35-4.85
c-10.34,2.26-13.9-11.64-9.05-31.68C503.29,544.72,507.07,542.2,512.94,539.74z"
      />
      <path
        class="st4 n14"
        onMouseEnter={n14Enter}
        onMouseLeave={n14Leave}
        onClick={()=>setMuscle(14)}
        ref={n14_3}
        d="M505.74,596.67c0.56-0.02,1.14-0.09,1.75-0.22c1.74-0.15,4.13-0.44,6.95-0.73c10.61-0.24,24.99,2.45,35.4,5.58
c2.41,0.72,4.62,1.26,6.74,1.7c0.03,0.02,0.06,0.04,0.09,0.06c10.96,3.41,27.87,9.23,27.87,23.46c0,14.22-0.02,11.41-0.02,21.75
s-11.72,39.99-24.97,32.56c-13.25-7.43-36.21-16.16-44.93-18.1c-8.73-1.94-13.65-10.04-16.16-35.56
C496.1,603.38,497.94,597.98,505.74,596.67z"
      />
      <path
        class="st9"
        d="M505.74,596.67c0.56-0.02,1.14-0.09,1.75-0.22c1.74-0.15,4.13-0.44,6.95-0.73c10.61-0.24,24.99,2.45,35.4,5.58
c2.41,0.72,4.62,1.26,6.74,1.7c0.03,0.02,0.06,0.04,0.09,0.06c10.96,3.41,27.87,9.23,27.87,23.46c0,14.22-0.02,11.41-0.02,21.75
s-11.72,39.99-24.97,32.56c-13.25-7.43-36.21-16.16-44.93-18.1c-8.73-1.94-13.65-10.04-16.16-35.56
C496.1,603.38,497.94,597.98,505.74,596.67z"
      />
      <path
        class="st4 n14"
        onMouseEnter={n14Enter}
        onMouseLeave={n14Leave}
        onClick={()=>setMuscle(14)}
        ref={n14_4}
        d="M514.6,662.71c3,0.91,6.85,2.04,11.08,3.37c10.57,3.7,24.58,9.53,33.85,14.73
c10.54,7.66,24.97,21.98,24.97,44.61c0,22.63,0,63.29,0,90.93c0,27.64-30.89,3.26-48.28-34.88c-17.4-38.14-31.96-80.55-33.26-97.07
C501.67,667.88,503.62,659.37,514.6,662.71z"
      />
      <path
        class="st9"
        d="M514.6,662.71c3,0.91,6.85,2.04,11.08,3.37c10.57,3.7,24.58,9.53,33.85,14.73
c10.54,7.66,24.97,21.98,24.97,44.61c0,22.63,0,63.29,0,90.93c0,27.64-30.89,3.26-48.28-34.88c-17.4-38.14-31.96-80.55-33.26-97.07
C501.67,667.88,503.62,659.37,514.6,662.71z"
      />
      <path
        class="st11"
        d="M828.19,491.27c-35.02-11-41.54-20.66-53.04-26.72c-50.92-26.82-75.74-103.9-75.74-103.9
s26.97-23.07,63.07-15.85C798.58,352.02,863.03,365.19,828.19,491.27z"
      />
      <path
        class="st0"
        d="M312.82,657.7c-4.45-40.61,17.57-48.12,64.81-59.95h0.01c0,0,7.14,72.65-24.27,84.8
c-31.41,12.15-42.98,14.78-50.21,26.33c-0.01,0.02-0.02,0.03-0.04,0.05C317.22,684.69,312.82,657.7,312.82,657.7z"
      />
      <path
        class="st7"
        d="M312.82,657.7c-4.45-40.61,17.57-48.12,64.81-59.95h0.01c0,0,7.14,72.65-24.27,84.8
c-31.41,12.15-42.98,14.78-50.21,26.33c-0.01,0.02-0.02,0.03-0.04,0.05C317.22,684.69,312.82,657.7,312.82,657.7z"
      />
      <path
        class="st0 n12"
        onMouseEnter={n12Enter}
        onMouseLeave={n12Leave}
        onClick={()=>setMuscle(12)}
        ref={n12_l}
        d="M295.93,720.43c2.7-4.02,5.08-7.86,7.2-11.5c0.01-0.02,0.02-0.03,0.04-0.05c7.23-11.55,18.8-14.18,50.21-26.33
c5.74-2.22,10.55-6.29,14.59-11.43l0,0c0,0-4.04,40.5-28.97,60.81c-24.93,20.31-67.37,97.87-71.18,112.07c0,0-4.89-2.98-13.98-2.48
C253.83,841.53,275.28,751.19,295.93,720.43z"
      />
      <path
        class="st7"
        d="M295.93,720.43c2.7-4.02,5.08-7.86,7.2-11.5c0.01-0.02,0.02-0.03,0.04-0.05c7.23-11.55,18.8-14.18,50.21-26.33
c5.74-2.22,10.55-6.29,14.59-11.43l0,0c0,0-4.04,40.5-28.97,60.81c-24.93,20.31-67.37,97.87-71.18,112.07c0,0-4.89-2.98-13.98-2.48
C253.83,841.53,275.28,751.19,295.93,720.43z"
      />
      <path
        class="st0 n10"
        onMouseEnter={n10Enter}
        onMouseLeave={n10Leave}
        onClick={()=>setMuscle(10)}
        ref={n10_l}
        d="M377.63,597.75c20.01-27.94,49.24-63.15,49.84-118.12c0.67-60.94-46.61-64.99-52.39-2.84
c-3.32,35.66-49.31,91.28-47.57,114.27C328.8,608.13,357.62,625.68,377.63,597.75z"
      />
      <path
        class="st2"
        d="M377.63,597.75c20.01-27.94,49.24-63.15,49.84-118.12c0.67-60.94-46.61-64.99-52.39-2.84
c-3.32,35.66-49.31,91.28-47.57,114.27C328.8,608.13,357.62,625.68,377.63,597.75z"
      />
      <path
        class="st4 n11"
        onMouseEnter={n11Enter}
        onMouseLeave={n11Leave}
        onClick={()=>setMuscle(11)}
        ref={n11_r}
        d="M313.16,579.63c0,0-44.97,35.88-56,86.06c-11.03,50.19-26.26,150.61-26.26,150.61s11.83,21.2,31.63,18.07
c0,0,12.76-83.18,33.41-113.95c20.66-30.76,16.89-62.73,16.89-62.73s28.86-51.21,9.26-76.91L313.16,579.63z"
      />
      <path
        class="st2"
        d="M313.16,579.63c0,0-44.97,35.88-56,86.06c-11.03,50.19-26.26,150.61-26.26,150.61s11.83,21.2,31.63,18.07
c0,0,12.76-83.18,33.41-113.95c20.66-30.76,16.89-62.73,16.89-62.73s28.86-51.21,9.26-76.91L313.16,579.63z"
      />
      <g>
        <path
          class="st0"
          d="M270.98,835.85c-1.96-21.05-40.09-19.54-40.09-19.54c-17.68,7.6-39.11,9.96-52.92,22.19
c-13.81,12.23-21.76,14.26-35.45,17.6c-13.69,3.33-9.67,18.54,7.85,17.78c17.54-0.75,26.74-3.82,38.11-7.32l0,0c0,0,0,0,0,0
c-16.4,30-25.56,52.83-31.73,80.41c-1.56,17.98,29.7,17.71,42.34,12.87c24.04-8.23,44.12-49.82,55.47-61.91
C265.89,884.82,272.95,856.9,270.98,835.85z"
        />
        <path class="st0" d="M188.49,866.56c14.3-1.76,34.43,0.45,53.07-18.39" />
      </g>
      <path
        class="st11"
        d="M340.74,491.27c35.02-11,41.54-20.66,53.04-26.72c50.92-26.82,75.74-103.9,75.74-103.9
s-26.97-23.07-63.07-15.85C370.35,352.02,305.9,365.19,340.74,491.27z"
      />
      <g class="st12">
        <g>
          <path
            class="st13"
            d="M700.72,364.39c-7.36-4.61-15.3-7.97-23.84-9.42c-57.11-9.7-71.55-2.95-81.61,9.05
  c-10.06,12-10.76,28.22-10.76,65.28s24.73,47.1,35.65,53.68c10.9,6.57,44.43,29.31,85.49,24.57
  c41.06-4.74,48.36-37.02,59.38-49.22c0,0,0-0.01,0-0.01C727.17,431.81,705.95,378.83,700.72,364.39z"
          />
        </g>
        <g>
          <path
            class="st4"
            d="M469.51,360.65c0,0,13.96-4.5,22.54-5.68c57.38-7.94,71.55-2.95,81.61,9.05c10.06,12,10.76,28.22,10.76,65.28
  s-24.73,47.1-35.65,53.68c-10.9,6.57-44.43,29.31-85.49,24.57c-41.06-4.74-48.36-37.02-59.38-49.22c0,0,0,0,0,0
  C441.76,431.82,464.28,375.1,469.51,360.65z"
          />
        </g>
      </g>
      <g class="st12">
        <path
          class="st14"
          d="M700.71,363.66c-7.36-4.61-15.3-7.97-23.85-9.42c-57.13-9.7-71.57-2.95-81.63,9.05
c-10.06,12-10.76,28.22-10.76,65.29c0,37.07,24.74,47.11,35.66,53.69c10.91,6.57,44.44,29.32,85.51,24.57
c41.07-4.74,48.37-37.03,59.39-49.23c0,0,0-0.01,0-0.01C727.16,431.1,705.94,378.1,700.71,363.66z"
        />
        <path
          class="st14"
          d="M468.22,363.66c7.36-4.61,15.3-7.97,23.85-9.42c57.13-9.7,71.57-2.95,81.63,9.05
c10.06,12,10.76,28.22,10.76,65.29c0,37.07-24.74,47.11-35.66,53.69c-10.91,6.57-44.44,29.32-85.51,24.57
c-41.07-4.74-48.37-37.03-59.39-49.23c0,0,0-0.01,0-0.01C441.77,431.1,462.99,378.1,468.22,363.66z"
        />
      </g>
      <path
        class="st15"
        d="M468.61,363.03c5.35-2.34,9.71-5.43,14.91-6.66c49.97-11.84,81.64-4.94,71.59-1.03
c-17.51,6.8-53.37,41.84-60.93,48.29c-15.84,13.53-34.42,28.93-48.21,37.33c-2,1.22-26.63,14.62-29.27,7.16
c0,0,16.59-17.92,29.44-38.39C458.42,390.19,468.61,363.03,468.61,363.03z"
      />
      <path
        class="st15"
        d="M574.51,456.34c-14.12-2.28-44.15,0.52-75.17,2.92c-44.04,3.41-77.82,14.31-77.82,23.25
c0,4.61,8.1,9.99,13.54,14.35c16.49,13.2,43.64,10.74,54.95,9.47c19.45-2.19,46.98-16.03,62.52-25.96
C562,474.31,582.86,457.69,574.51,456.34z"
      />
      <path
        class="st15"
        d="M382.14,469.82c3.01-0.98-4.04-30.71,3.65-60.86c10.91-42.8,37.13-67.21,28.25-66.19
        c-6.77,0.78-37.98,5.47-55.64,18.96c-16.62,12.69-23.3,26.55-26.74,44.77c-4.18,22.19-0.09,47.86,3.67,67.1
        c0.6,3.05,4.67,18.3,4.67,18.3C357.37,477.03,374.32,472.37,382.14,469.82z"
      />
      <path
        class="st8 n22"
        onMouseEnter={n22Enter}
        onMouseLeave={n22Leave}
        onClick={()=>setMuscle(22)}
        ref={n22_r}
        d="M529.7,252.89c0.08,3.52-0.04,10.49,0,13.06v40.97c-3.76,27.39-15.8,37.61-28.99,44.29
        c0,0-3.95,0.81-15.78,3.62c-6.81,1.62-12.43,3.79-16.03,5.5c0,0,0.1-0.93-1.18-1.89c-3.97-2.98-11.96-7.61-18.78-10.33
        c-12.03-4.49-20.55-5.54-28.61-5.74c-2.77-0.07-8.26,0.55-10.47,0.88c1.18,0.03,3.88-1.28,4.64-1.96l4.72-4.54
        c2.91-2.7,7.46-6.53,7.46-6.53c6.68-5.75,19.62-15.27,19.62-15.27c16.02-12.28,68.74-44.64,75.48-51.96c0,0,2.6-2.28,3.86-3.78
        C526.72,257.95,529.19,254.17,529.7,252.89z"
      />
      <path
        class="st10"
        d="M529.27,253.32c0.08,3.52-0.04,10.49,0,13.06v40.97c-3.76,27.39-15.8,37.61-28.99,44.29
c0,0-3.95,0.81-15.78,3.62c-6.81,1.62-12.43,3.79-16.03,5.5c0,0,0.1-0.93-1.18-1.89c-3.97-2.98-11.96-7.61-18.78-10.33
c-12.03-4.49-20.55-5.54-28.61-5.74c-2.77-0.07-8.26,0.55-10.47,0.88c1.18,0.03,3.88-1.28,4.64-1.96l4.72-4.54
c2.91-2.7,7.46-6.53,7.46-6.53c6.68-5.75,19.62-15.27,19.62-15.27c16.02-12.28,68.74-44.64,75.48-51.96c0,0,2.6-2.28,3.86-3.78
C526.29,258.38,528.76,254.6,529.27,253.32z"
      />
      <path
        class="st16 n3"
        onMouseEnter={n3Enter}
        onMouseLeave={n3Leave}
        onClick={()=>setMuscle(3)}
        ref={n3_l}
        d="M699.82,360.25c-5.35-2.34-8.6-3.35-13.74-4.79c-12.96-3.64-26.67-5.87-29.31-6.18
c-5.03-0.59-17.94-1.36-25.72-1.36c-15.93,0-26.07,0.21-32.5,8.79c0,0,2.96-3.13,6.86-3.13c3,0,6.32,0.93,8.42,1.75
c17.51,6.8,53.47,41.72,60.93,48.29c17.61,15.52,34.85,28.64,48.56,37.18c5.12,3.19,15.16,7.77,25.61,11.36
c15.27,5.25,15.57,5.82,15.96,5.15c0,0-10.23-4.45-26.42-25.97c-4.52-6.02-10.06-13.01-15.43-21.81
C710.99,389.85,699.82,360.25,699.82,360.25z"
      />
      <path
        class="st17"
        d="M699.39,360.68c-5.35-2.34-8.6-3.35-13.74-4.79c-12.96-3.64-26.67-5.87-29.31-6.18
c-5.03-0.59-17.94-1.36-25.72-1.36c-15.93,0-26.07,0.21-32.5,8.79c0,0,2.96-3.13,6.86-3.13c3,0,6.32,0.93,8.42,1.75
c17.51,6.8,53.47,41.72,60.93,48.29c17.61,15.52,34.85,28.64,48.56,37.18c5.12,3.19,15.16,7.77,25.61,11.36
c15.27,5.25,15.57,5.82,15.96,5.15c0,0-10.23-4.45-26.42-25.97c-4.52-6.02-10.06-13.01-15.43-21.81
C710.56,390.28,699.39,360.68,699.39,360.68z"
      />
      <path
        class="st16 n3"
        onMouseEnter={n3Enter}
        onMouseLeave={n3Leave}
        onClick={()=>setMuscle(3)}
        ref={n3_r}
        d="M468.91,360.25c5.35-2.34,8.6-3.35,13.74-4.79c12.96-3.64,26.67-5.87,29.31-6.18
c5.03-0.59,17.94-1.36,25.72-1.36c15.93,0,26.07,0.21,32.5,8.79c0,0-2.96-3.13-6.86-3.13c-3,0-6.32,0.93-8.42,1.75
c-17.51,6.8-53.47,41.72-60.93,48.29c-17.61,15.52-34.85,28.64-48.56,37.18c-5.12,3.19-15.16,7.77-25.61,11.36
c-15.27,5.25-15.57,5.82-15.96,5.15c0,0,10.23-4.45,26.42-25.97c4.52-6.02,10.06-13.01,15.43-21.81
C457.73,389.85,468.91,360.25,468.91,360.25z"
      />
      <path
        class="st17"
        d="M468.48,360.68c5.35-2.34,8.6-3.35,13.74-4.79c12.96-3.64,26.67-5.87,29.31-6.18
c5.03-0.59,17.94-1.36,25.72-1.36c15.93,0,26.07,0.21,32.5,8.79c0,0-2.96-3.13-6.86-3.13c-3,0-6.32,0.93-8.42,1.75
c-17.51,6.8-53.47,41.72-60.93,48.29c-17.61,15.52-34.85,28.64-48.56,37.18c-5.12,3.19-15.16,7.77-25.61,11.36
c-15.27,5.25-15.57,5.82-15.96,5.15c0,0,10.23-4.45,26.42-25.97c4.52-6.02,10.06-13.01,15.43-21.81
C457.3,390.28,468.48,360.68,468.48,360.68z"
      />
      <path
        class="st8 n15"
        onMouseEnter={n15Enter}
        onMouseLeave={n15Leave}
        onClick={()=>setMuscle(15)}
        ref={n15_l}
        d="M427.64,490.86c1.33,1.1,2.51,2.08,3.93,3.15c2.07,1.57,4.72,2.73,6.32,4.78
c16.04,20.55,56.23,14.12,65.68,38.99c0.99,2.62,5.51,3.33,5.51,3.33s-3.6,4.14-4.66,5.99c-2.24,3.9-3.61,9.25-5.26,14.73
c-0.99,3.29-1.48,6.25-2.2,10.46c-1.93,4.17-2.88,8.63-4.28,13.04c-4.37,13.79-8,28.44-6.74,41.93
c5.03,23.24,10.65,60.05,11.55,77.17c1.25,9.86-5.78,38.82-17.36,52.81c-5.36,4.91-7.5,4.9-10.28,4.83
c-19.17-27.16-22.05-52.92-20.11-76.89c0.79-8.87,1.62-14.93,2.76-20.69c0.85-4.28,2.04-8.71,3.48-13.44
c1-3.27,2.11-6.64,2.83-10.57c0.54-2.95,0.42-4.67,0.09-10.06c-0.79-5.61-2.08-11.51-3.92-16.82c-3.15-9.1-6.68-17.54-12.01-25.06
c-2.16-3.05-4.24-6-6.4-9.26c-4.93-7.45-9.81-15.87-13.62-25.56c-1.33-3.39-2.58-6.53-3.43-9.27c-0.51-1.66-0.99-3.34-1.47-4.86
c-0.66-2.08-1.17-3.99-1.24-5.65c0,0,3.86-9.69,5.46-16.22c0.74-2.43,1.7-7.04,2.75-11.72
C426.39,499.92,426.47,493.73,427.64,490.86z"
      />
      <path
        class="st10"
        d="M427.64,490.86c1.33,1.1,2.51,2.08,3.93,3.15c2.07,1.57,4.72,2.73,6.32,4.78
c16.04,20.55,56.23,14.12,65.68,38.99c0.99,2.62,5.51,3.33,5.51,3.33s-3.6,4.14-4.66,5.99c-2.24,3.9-3.61,9.25-5.26,14.73
c-0.99,3.29-1.48,6.25-2.2,10.46c-1.93,4.17-2.88,8.63-4.28,13.04c-4.37,13.79-8,28.44-6.74,41.93
c5.03,23.24,10.65,60.05,11.55,77.17c1.25,9.86-5.78,38.82-17.36,52.81c-5.36,4.91-7.5,4.9-10.28,4.83
c-19.17-27.16-22.05-52.92-20.11-76.89c0.79-8.87,1.62-14.93,2.76-20.69c0.85-4.28,2.04-8.71,3.48-13.44
c1-3.27,2.11-6.64,2.83-10.57c0.54-2.95,0.42-4.67,0.09-10.06c-0.79-5.61-2.08-11.51-3.92-16.82c-3.15-9.1-6.68-17.54-12.01-25.06
c-2.16-3.05-4.24-6-6.4-9.26c-4.93-7.45-9.81-15.87-13.62-25.56c-1.33-3.39-2.58-6.53-3.43-9.27c-0.51-1.66-0.99-3.34-1.47-4.86
c-0.66-2.08-1.17-3.99-1.24-5.65c0,0,3.86-9.69,5.46-16.22c0.74-2.43,1.7-7.04,2.75-11.72
C426.39,499.92,426.47,493.73,427.64,490.86z"
      />
      <path
        class="st8 n15"
        onMouseEnter={n15Enter}
        onMouseLeave={n15Leave}
        onClick={()=>setMuscle(15)}
        ref={n15_r}
        d="M742.43,490.4c-1.33,1.1-2.51,2.08-3.93,3.15c-2.07,1.57-4.72,2.73-6.32,4.78
c-16.04,20.55-56.23,14.12-65.68,38.99c-0.99,2.62-5.51,3.33-5.51,3.33s3.6,4.14,4.66,5.99c2.24,3.9,3.61,9.25,5.26,14.73
c0.99,3.29,1.48,6.25,2.2,10.46c1.93,4.17,2.88,8.63,4.28,13.04c4.37,13.79,8,28.44,6.74,41.93c-5.03,23.24-10.65,60.05-11.55,77.17
c-1.25,9.86,5.78,38.82,17.36,52.81c5.36,4.91,7.5,4.9,10.28,4.83c19.17-27.16,22.05-52.92,20.11-76.89
c-0.79-8.87-1.62-14.93-2.76-20.69c-0.85-4.28-2.04-8.71-3.48-13.44c-1-3.27-2.11-6.64-2.83-10.57c-0.54-2.95-0.42-4.67-0.09-10.06
c0.79-5.61,2.08-11.51,3.92-16.82c3.15-9.1,6.68-17.54,12.01-25.06c2.16-3.05,4.24-6,6.4-9.26c4.93-7.45,9.81-15.87,13.62-25.56
c1.33-3.39,2.58-6.53,3.43-9.27c0.51-1.66,0.99-3.34,1.47-4.86c0.66-2.08,1.17-3.99,1.24-5.65c0,0-3.86-9.69-5.46-16.22
c-0.74-2.43-1.7-7.04-2.75-11.72C743.68,499.46,743.6,493.27,742.43,490.4z"
      />
      <path
        class="st10"
        d="M742.43,490.4c-1.33,1.1-2.51,2.08-3.93,3.15c-2.07,1.57-4.72,2.73-6.32,4.78
c-16.04,20.55-56.23,14.12-65.68,38.99c-0.99,2.62-5.51,3.33-5.51,3.33s3.6,4.14,4.66,5.99c2.24,3.9,3.61,9.25,5.26,14.73
c0.99,3.29,1.48,6.25,2.2,10.46c1.93,4.17,2.88,8.63,4.28,13.04c4.37,13.79,8,28.44,6.74,41.93c-5.03,23.24-10.65,60.05-11.55,77.17
c-1.25,9.86,5.78,38.82,17.36,52.81c5.36,4.91,7.5,4.9,10.28,4.83c19.17-27.16,22.05-52.92,20.11-76.89
c-0.79-8.87-1.62-14.93-2.76-20.69c-0.85-4.28-2.04-8.71-3.48-13.44c-1-3.27-2.11-6.64-2.83-10.57c-0.54-2.95-0.42-4.67-0.09-10.06
c0.79-5.61,2.08-11.51,3.92-16.82c3.15-9.1,6.68-17.54,12.01-25.06c2.16-3.05,4.24-6,6.4-9.26c4.93-7.45,9.81-15.87,13.62-25.56
c1.33-3.39,2.58-6.53,3.43-9.27c0.51-1.66,0.99-3.34,1.47-4.86c0.66-2.08,1.17-3.99,1.24-5.65c0,0-3.86-9.69-5.46-16.22
c-0.74-2.43-1.7-7.04-2.75-11.72C743.68,499.46,743.6,493.27,742.43,490.4z"
      />
      <path
        class="st18"
        d="M628.59,243.43c-5.92,9.23-13.78,17.4-24.2,22.07c-5.79,2.61-12.4,4.13-19.9,4.13c-7.5,0-14.11-1.52-19.9-4.13
c-10.44-4.67-18.32-12.86-24.22-22.11c0-0.02,0-0.02,0-0.02c-14.19-22.24-17.07-42.21-17.07-42.21s-15.74,3.77-21.93-26.78
c-6.29-31.05,13.82-29.22,13.82-29.22s-6.46-19.67,0.29-50.03c6.75-30.37,36.24-50.73,69.01-50.73c32.74,0,62.25,20.36,69.01,50.73
c6.73,30.37,0.27,50.03,0.27,50.03s20.13-1.83,13.84,29.22c-6.19,30.55-21.93,26.78-21.93,26.78S642.8,221.19,628.59,243.43z"
      />
      <path
        class="st16 n6"
        onMouseEnter={n6Enter}
        onMouseLeave={n6Leave}
        onClick={()=>setMuscle(6)}
        ref={n6_r}
        d="M381.53,469.82c0,0,7.64-4.56,15.04-8.43c0.15-0.08,12.83-5.96,22.59-16.7c8.68-9.55,17.16-21.18,24.02-31.28
          c13.1-19.29,24.94-50.54,25.58-52.26c0.71-1.89-1.28-2.97-3.31-4.35c-3.21-2.18-7.53-4.53-9.78-5.67
          c-9.86-5.04-23.88-8.91-35.18-8.75c-2.05,0.03-4.35-0.38-4.98,0.83c-0.32,0.61-0.56,2.77-3.04,6.66
          c-5.81,9.1-11.41,19.06-14.47,25.29c-8.61,17.49-17.17,38.37-16.54,69.17c0.15,7.5,0.78,15.12,0.82,19.32
          C382.34,469.93,381.27,469.91,381.53,469.82z"
      />
      <path
        class="st17"
        d="M381.11,470.25c0,0,7.64-4.56,15.04-8.43c0.15-0.08,12.83-5.96,22.59-16.7c8.68-9.55,17.16-21.18,24.02-31.28
          c13.1-19.29,24.94-50.54,25.58-52.26c0.71-1.89-1.28-2.97-3.31-4.35c-3.21-2.18-7.53-4.53-9.78-5.67
          c-9.86-5.04-23.88-8.91-35.18-8.75c-2.05,0.03-4.35-0.38-4.98,0.83c-0.32,0.61-0.56,2.77-3.04,6.66
          c-5.81,9.1-11.41,19.06-14.47,25.29c-8.61,17.49-17.17,38.37-16.54,69.17c0.15,7.5,0.78,15.12,0.82,19.32
          C381.91,470.36,380.84,470.34,381.11,470.25z"
      />
      <path
        class="st16 n6"
        onMouseEnter={n6Enter}
        onMouseLeave={n6Leave}
        onClick={()=>setMuscle(6)}
        ref={n6_l}
        d="M787.19,469.82c0,0-7.64-4.56-15.04-8.43c-0.15-0.08-12.83-5.96-22.59-16.7
          c-8.68-9.55-17.16-21.18-24.02-31.28c-13.1-19.29-24.94-50.54-25.58-52.26c-0.71-1.89,1.28-2.97,3.31-4.35
          c3.21-2.18,7.53-4.53,9.78-5.67c9.86-5.04,23.88-8.91,35.18-8.75c2.05,0.03,4.35-0.38,4.98,0.83c0.32,0.61,0.56,2.77,3.04,6.66
          c5.81,9.1,11.41,19.06,14.47,25.29c8.61,17.49,17.17,38.37,16.54,69.17c-0.15,7.5-0.78,15.12-0.82,19.32
          C786.38,469.93,787.45,469.91,787.19,469.82z"
      />
      <path
        class="st17"
        d="M787.19,469.82c0,0-7.64-4.56-15.04-8.43c-0.15-0.08-12.83-5.96-22.59-16.7
c-8.68-9.55-17.16-21.18-24.02-31.28c-13.1-19.29-24.94-50.54-25.58-52.26c-0.71-1.89,1.28-2.97,3.31-4.35
c3.21-2.18,7.53-4.53,9.78-5.67c9.86-5.04,23.88-8.91,35.18-8.75c2.05,0.03,4.35-0.38,4.98,0.83c0.32,0.61,0.56,2.77,3.04,6.66
c5.81,9.1,11.41,19.06,14.47,25.29c8.61,17.49,17.17,38.37,16.54,69.17c-0.15,7.5-0.78,15.12-0.82,19.32
C786.38,469.93,787.45,469.91,787.19,469.82z"
      />
      <path
        class="st16 n7"
        ref={n7_r}
        onMouseEnter={n7Enter}
        onMouseLeave={n7Leave}
        onClick={()=>setMuscle(7)}
        d="M787.19,469.82c-3.01-0.98,4.04-30.71-3.65-60.86c-10.91-42.8-37.13-67.21-28.25-66.19
c6.77,0.78,37.98,5.47,55.64,18.96c16.62,12.69,23.3,26.55,26.74,44.77c4.18,22.19,0.09,47.86-3.67,67.1
c-0.6,3.05-4.67,18.3-4.67,18.3C811.95,477.03,795.01,472.37,787.19,469.82z"
      />
      <path
        class="st17"
        d="M787.19,469.82c-3.01-0.98,4.04-30.71-3.65-60.86c-10.91-42.8-37.13-67.21-28.25-66.19
c6.77,0.78,37.98,5.47,55.64,18.96c16.62,12.69,23.3,26.55,26.74,44.77c4.18,22.19,0.09,47.86-3.67,67.1
c-0.6,3.05-4.67,18.3-4.67,18.3C811.95,477.03,795.01,472.37,787.19,469.82z"
      />
      <path
        class="st16 n7"
        ref={n7_l}
        onMouseEnter={n7Enter}
        onMouseLeave={n7Leave}
        onClick={()=>setMuscle(7)}
        d="M381.53,469.82c3.01-0.98-4.04-30.71,3.65-60.86c10.91-42.8,37.13-67.21,28.25-66.19
c-6.77,0.78-37.98,5.47-55.64,18.96c-16.62,12.69-23.3,26.55-26.74,44.77c-4.18,22.19-0.09,47.86,3.67,67.1
c0.6,3.05,4.67,18.3,4.67,18.3C356.77,477.03,373.72,472.37,381.53,469.82z"
      />
      <path
        class="st17"
        d="M381.11,470.25c3.01-0.98-4.04-30.71,3.65-60.86c10.91-42.8,37.13-67.21,28.25-66.19
c-6.77,0.78-37.98,5.47-55.64,18.96c-16.62,12.69-23.3,26.55-26.74,44.77c-4.18,22.19-0.09,47.86,3.67,67.1
c0.6,3.05,4.67,18.3,4.67,18.3C356.34,477.46,373.29,472.8,381.11,470.25z"
      />
      <path
        class="st16 n9"
        ref={n9_l}
        onMouseEnter={n9Enter}
        onMouseLeave={n9Leave}
        onClick={()=>setMuscle(9)}
        d="M788.25,470.35c0,0-12.82,30.14,2.19,95.62s65.67,91.74,65.67,91.74S905.23,505.57,788.25,470.35z"
      />
      <path
        class="st19"
        d="M788.25,470.35c0,0-12.82,30.14,2.19,95.62s65.67,91.74,65.67,91.74S905.23,505.57,788.25,470.35z"
      />
      <path
        class="st16 n9"
        ref={n9_r}
        onMouseEnter={n9Enter}
        onMouseLeave={n9Leave}
        onClick={()=>setMuscle(9)}
        d="M380.68,470.35c0,0,12.83,30.14-2.19,95.62s-65.67,91.74-65.67,91.74S263.7,505.57,380.68,470.35z"
      />
      <path
        class="st17"
        d="M380.68,470.35c0,0,12.83,30.14-2.19,95.62s-65.67,91.74-65.67,91.74S263.7,505.57,380.68,470.35z"
      />
      <path
        class="st20"
        d="M647.37,47.75c-15.3-19.57-39.62-15.06-39.62-15.06s-15.81-8.57-31.01-8.38
c-20.39,0.26-43.02,12.52-55.23,23.44c-8.79,7.86-16.84,27.14-16.84,41.24c0,20.63,3.34,44.14,9.78,56.41
c-1.25-27.31,5.97-31.25,6.43-40.04c0.38-7.27-1.27-15.28,2.11-22.02c5.77-11.49,21.88-7.77,31.29-5.2
c15.22,4.16,28.94,5.65,44.68,3.88c12.59-1.42,32.11-13.13,43.84-2.7c6.19,5.51,5.14,15.13,5.1,22.77
c-0.01,1.09-0.02,2.18,0.04,3.27c0.46,8.78,7.53,10.48,6.43,40.04c6.44-12.27,9.78-35.78,9.78-56.41
C664.17,74.9,654.71,57.14,647.37,47.75z"
      />
      <path
        class="st16 n4"
        ref={n4_r}
        onMouseEnter={n4Enter}
        onMouseLeave={n4Leave}
        onClick={()=>setMuscle(4)}
        d="M602.59,354.1c4.05-1.46,10.84,0.11,19.3,5.32c7.46,4.6,15.12,10.77,23.93,18.26
c12.44,10.56,25,22.79,37.47,33.44c10.09,8.62,24.22,19.18,31.77,24.49c10.23,7.19,22.4,12.3,26.63,13.9
c10.41,3.93,22.73,8.42,22.73,8.42s-7.65,10.73-10.93,16.03c-2.1,3.4-4.46,8.22-6.77,6.33c-4.84-3.96-6.47-6.48-16.12-9.83
c-10.51-3.65-21.32-5.61-30.17-7.25c-13.28-2.46-36.31-4.5-44.36-5.25c-10.77-1-32.29-1.81-39.15-1.98
c-7.84-0.2-17.56,0.02-24.71-0.39c0,0-6.42-10.05-6.92-25.44c-0.2-6.17-0.17-13.49-0.22-22.83c-0.08-13.98,2.21-24.7,6.1-37.23
C595.44,356.34,602.59,354.1,602.59,354.1z"
      />
      <path
        class="st17"
        d="M602.16,354.53c4.05-1.46,10.84,0.11,19.3,5.32c7.46,4.6,15.12,10.77,23.93,18.26
c12.44,10.56,25,22.79,37.47,33.44c10.09,8.62,24.22,19.18,31.77,24.49c10.23,7.19,22.4,12.3,26.63,13.9
c10.41,3.93,22.73,8.42,22.73,8.42s-7.65,10.73-10.93,16.03c-2.1,3.4-4.46,8.22-6.77,6.33c-4.84-3.96-6.47-6.48-16.12-9.83
c-10.51-3.65-21.32-5.61-30.17-7.25c-13.28-2.46-36.31-4.5-44.36-5.25c-10.77-1-32.29-1.81-39.15-1.98
c-7.84-0.2-17.56,0.02-24.71-0.39c0,0-6.42-10.05-6.92-25.44c-0.2-6.17-0.17-13.49-0.22-22.83c-0.08-13.98,2.21-24.7,6.1-37.23
C595.01,356.77,602.16,354.53,602.16,354.53z"
      />
      <path
        class="st16 n4"
        ref={n4_l}
        onMouseEnter={n4Enter}
        onMouseLeave={n4Leave}
        onClick={()=>setMuscle(4)}
        d="M566.13,354.1c-4.05-1.46-10.84,0.11-19.3,5.32c-7.46,4.6-15.12,10.77-23.93,18.26
c-12.44,10.56-25,22.79-37.47,33.44c-10.09,8.62-24.22,19.18-31.77,24.49c-10.23,7.19-22.4,12.3-26.63,13.9
c-10.41,3.93-22.73,8.42-22.73,8.42s7.65,10.73,10.93,16.03c2.1,3.4,4.46,8.22,6.77,6.33c4.84-3.96,6.47-6.48,16.12-9.83
c10.51-3.65,21.32-5.61,30.17-7.25c13.28-2.46,36.31-4.5,44.36-5.25c10.77-1,32.29-1.81,39.15-1.98c7.84-0.2,17.56,0.02,24.71-0.39
c0,0,6.42-10.05,6.92-25.44c0.2-6.17,0.17-13.49,0.22-22.83c0.08-13.98-2.21-24.7-6.1-37.23
C573.28,356.34,566.13,354.1,566.13,354.1z"
      />
      <path
        class="st17"
        d="M565.7,354.53c-4.05-1.46-10.84,0.11-19.3,5.32c-7.46,4.6-15.12,10.77-23.93,18.26
c-12.44,10.56-25,22.79-37.47,33.44c-10.09,8.62-24.22,19.18-31.77,24.49c-10.23,7.19-22.4,12.3-26.63,13.9
c-10.41,3.93-22.73,8.42-22.73,8.42s7.65,10.73,10.93,16.03c2.1,3.4,4.46,8.22,6.77,6.33c4.84-3.96,6.47-6.48,16.12-9.83
c10.51-3.65,21.32-5.61,30.17-7.25c13.28-2.46,36.31-4.5,44.36-5.25c10.77-1,32.29-1.81,39.15-1.98c7.84-0.2,17.56,0.02,24.71-0.39
c0,0,6.42-10.05,6.92-25.44c0.2-6.17,0.17-13.49,0.22-22.83c0.08-13.98-2.21-24.7-6.1-37.23
C572.86,356.77,565.7,354.53,565.7,354.53z"
      />
      <path
        class="st16 n5"
        ref={n5_l}
        onMouseEnter={n5Enter}
        onMouseLeave={n5Leave}
        onClick={()=>setMuscle(5)}
        d="M594.52,455.7c8.87-0.16,38.34-0.11,75.07,3.56c43.95,4.39,77.82,14.31,77.82,23.25
c0,4.61-8.1,9.99-13.54,14.35c-16.49,13.2-43.64,10.74-54.95,9.47c-19.45-2.19-46.98-16.03-62.52-25.96
c-8.27-5.29-25.59-20.58-24.19-24.77C592.22,455.59,593.45,455.72,594.52,455.7z"
      />
      <path
        class="st17"
        d="M594.52,455.7c8.87-0.16,38.34-0.11,75.07,3.56c43.95,4.39,77.82,14.31,77.82,23.25
c0,4.61-8.1,9.99-13.54,14.35c-16.49,13.2-43.64,10.74-54.95,9.47c-19.45-2.19-46.98-16.03-62.52-25.96
c-8.27-5.29-25.59-20.58-24.19-24.77C592.22,455.59,593.45,455.72,594.52,455.7z"
      />
      <path
        class="st21 n5"
        ref={n5_r}
        onMouseEnter={n5Enter}
        onMouseLeave={n5Leave}
        onClick={()=>setMuscle(5)}
        d="M574.2,455.7c-8.87-0.16-38.34-0.11-75.07,3.56c-43.95,4.39-77.82,14.31-77.82,23.25
c0,4.61,8.1,9.99,13.54,14.35c16.49,13.2,43.64,10.74,54.95,9.47c19.45-2.19,46.98-16.03,62.52-25.96
c8.27-5.29,25.59-20.58,24.19-24.77C576.5,455.59,575.27,455.72,574.2,455.7z"
      />
      <path
        class="st17"
        d="M574.2,455.7c-8.87-0.16-38.34-0.11-75.07,3.56c-43.95,4.39-77.82,14.31-77.82,23.25
c0,4.61,8.1,9.99,13.54,14.35c16.49,13.2,43.64,10.74,54.95,9.47c19.45-2.19,46.98-16.03,62.52-25.96
c8.27-5.29,25.59-20.58,24.19-24.77C576.5,455.59,575.27,455.72,574.2,455.7z"
      />
      <g class="st12">
        <g class="st22">
          <path
            class="st0"
            d="M-417.98,1552.6c19.97,21.11,45.5,61.85,47.95,78.05c2.45,16.2-11.82,34.36-27.75,41.72
  c-15.94,7.36-30.94,0.98-39.88-3.93c-8.94-4.91-21.19-13.25-24.64-45.16c-3.45-31.91-3.94-36.32-14.74-45.16
  c-10.8-8.84-13.28-22.09-2.47-40.25C-468.68,1519.72-429.11,1520.7-417.98,1552.6z"
          />
          <path
            class="st0"
            d="M-395.29,1197.22c0,0,29.69,85.41,13,151.67c-16.69,66.27-46.14,129.1-47.61,151.68s11.93,52.03,11.93,52.03
  s-13.92-39.27-61.52-14.73l-2.93-17.69l52.61-292.04L-395.29,1197.22z"
          />
          <path
            class="st0"
            d="M-475.21,1215.37c0,0-19,62.85-14.09,98.19c4.91,35.34,17.38,127.79,14.09,146.6
  c-1.41,8.08-18.28,29.64-12.75,44.83c2.93,8.06,8.46,32.89,8.46,32.89s9.93-7.07,22.89-7.21c12.96-0.14,22.01-217.11,9.41-275.03
  C-466.34,1167.65-475.21,1215.37-475.21,1215.37z"
          />
          <path
            class="st0"
            d="M-380.15,1107.13c-6.86,20.46-8.53,94.02-27.18,121.02c0,0-18.16-50.56,0-87.37
  S-380.15,1107.13-380.15,1107.13z"
          />
          <path
            class="st0"
            d="M-497.67,1136.15c0,0-1.12,35.38,9.68,59.6c10.8,24.22,35.01,38.94,35.01,38.94s14.09-55.96-9.32-83.45
  C-485.7,1123.76-497.67,1136.15-497.67,1136.15z"
          />
          <path
            class="st4"
            d="M-459.81,1213.23c0,0-12.55,13.28-9.9,36.19c2.65,22.91,18.03,86.98,17.8,129.91
  c-0.58,104.72-10.39,151.92-10.39,151.92s10.39-1.41,18.64-0.72c6.22-12.76,13.75-139.91,19.64-187.03
  c5.89-47.12,0.38-77.06,8.62-88.52c8.24-11.45,14.18-42.83,1.72-53.49C-426.14,1190.84-459.81,1213.23-459.81,1213.23z"
          />
          <path
            class="st4"
            d="M-407.36,1163.72c3.38,20.31-6.22,50.85-12.76,68.36c-5.05,13.52-28.65,13.08-32.86,2.62
  c-5.96-14.83-10.83-30.45-8.85-59.08c1.98-28.62,12.33-63.3,33.72-63.31C-406.72,1112.29-410.74,1143.4-407.36,1163.72z"
          />
        </g>
        <g class="st22">
          <path
            class="st0"
            d="M-659.14,1552.6c-19.97,21.11-45.5,61.85-47.95,78.05c-2.45,16.2,11.82,34.36,27.75,41.72
  c15.94,7.36,30.94,0.98,39.88-3.93c8.94-4.91,21.19-13.25,24.64-45.16c3.45-31.91,3.94-36.32,14.74-45.16
  c10.8-8.84,13.28-22.09,2.47-40.25C-608.43,1519.72-648,1520.7-659.14,1552.6z"
          />
          <path
            class="st0"
            d="M-681.82,1197.22c0,0-29.69,85.41-13,151.67c16.69,66.27,46.14,129.1,47.61,151.68
  c1.47,22.58-11.93,52.03-11.93,52.03s13.92-39.27,61.52-14.73l2.93-17.69l-52.61-292.04L-681.82,1197.22z"
          />
          <path
            class="st0"
            d="M-601.91,1215.37c0,0,19,62.85,14.09,98.19c-4.91,35.34-17.38,127.79-14.09,146.6
  c1.41,8.08,18.28,29.64,12.75,44.83c-2.93,8.06-8.46,32.89-8.46,32.89s-9.93-7.07-22.89-7.21
  c-12.96-0.14-22.01-217.11-9.41-275.03C-610.78,1167.65-601.91,1215.37-601.91,1215.37z"
          />
          <path
            class="st0"
            d="M-696.97,1107.13c6.86,20.46,8.53,94.02,27.18,121.02c0,0,18.16-50.56,0-87.37
  C-687.95,1103.96-696.97,1107.13-696.97,1107.13z"
          />
          <path
            class="st0"
            d="M-579.45,1136.15c0,0,1.12,35.38-9.68,59.6c-10.8,24.22-35.01,38.94-35.01,38.94s-14.09-55.96,9.32-83.45
  C-591.42,1123.76-579.45,1136.15-579.45,1136.15z"
          />
          <path
            class="st4"
            d="M-617.31,1213.23c0,0,12.55,13.28,9.9,36.19c-2.65,22.91-18.03,86.98-17.8,129.91
  c0.58,104.72,10.39,151.92,10.39,151.92s-10.39-1.41-18.64-0.72c-6.22-12.76-13.75-139.91-19.64-187.03
  c-5.89-47.12-0.38-77.06-8.62-88.52s-14.18-42.83-1.72-53.49S-617.31,1213.23-617.31,1213.23z"
          />
          <path
            class="st4"
            d="M-669.76,1163.72c-3.38,20.31,6.22,50.85,12.76,68.36c5.05,13.52,28.65,13.08,32.86,2.62
  c5.96-14.83,10.83-30.45,8.85-59.08c-1.98-28.62-12.33-63.3-33.72-63.31C-670.4,1112.29-666.38,1143.4-669.76,1163.72z"
          />
        </g>
        <path
          class="st23"
          d="M-406.68,713.59c0,0,17.54,11.26,15.57,35.48c-1.96,24.22-2.95,54.49-2.45,66.27
c0.49,11.78-34.46-59.96-34.46-59.96S-425.2,720.82-406.68,713.59z"
        />
        <path
          class="st23"
          d="M-671.52,713.59c0,0-17.54,11.26-15.57,35.48c1.96,24.22,2.95,54.49,2.45,66.27
c-0.49,11.78,34.46-59.96,34.46-59.96S-653,720.82-671.52,713.59z"
        />
        <g class="st22">
          <g>
            <path
              class="st0"
              d="M-533.37,876.96c0,0,1.88,70.41,12.19,110.17c10.31,39.76,18.43,83.01,18.47,100.63
    c0.05,17.62,122.52-248.58,54.72-305.91S-533.37,876.96-533.37,876.96z"
            />
            <path
              class="st0"
              d="M-422.77,748.24c0,0,65.25,76.42,56.48,198.16l-71.84-181.28L-422.77,748.24z"
            />
            <path
              class="st0"
              d="M-374.09,917.27c0,0,29.81,83.12,8.61,143.99c-21.21,60.87-30.34,93.26-36.33,101.12
    c-5.99,7.85-23.66-8.34-18.26-40.25C-414.67,1090.22-374.09,917.27-374.09,917.27z"
            />
            <path
              class="st4"
              d="M-428.02,755.37c12.66,83.37,58.78,96.78,54.33,183.66c-4.45,86.88-43.42,167.88-46.37,183.09
    c-2.95,15.21-15.24,14.4-18.07,3.44c-3.04-11.78-40.83-155.11-20.22-230.21c20.62-75.1,10.37-113.49,10.37-113.49
    S-441,752.13-428.02,755.37z"
            />
            <path
              class="st4"
              d="M-440.89,1114.69c6.1,25.59-0.39,56.52-15.31,60.45c-14.92,3.93-49.28-11.29-46.33-82.46
    c2.95-71.17,32.52-160.44,42.77-191.64C-449.52,869.82-440.89,1114.69-440.89,1114.69z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-544.83,876.96c0,0-1.88,70.41-12.19,110.17c-10.31,39.76-18.42,83.01-18.47,100.63
    c-0.05,17.62-122.52-248.58-54.72-305.91C-562.42,724.53-544.83,876.96-544.83,876.96z"
            />
            <path
              class="st0"
              d="M-655.43,748.24c0,0-65.25,76.42-56.48,198.16l71.84-181.28L-655.43,748.24z"
            />
            <path
              class="st0"
              d="M-704.11,917.27c0,0-29.81,83.12-8.61,143.99c21.21,60.87,30.34,93.26,36.33,101.12s23.66-8.34,18.26-40.25
    C-663.53,1090.22-704.11,917.27-704.11,917.27z"
            />
            <path
              class="st4"
              d="M-650.18,755.37c-12.66,83.37-58.78,96.78-54.33,183.66s43.42,167.88,46.37,183.09
    c2.95,15.21,15.24,14.4,18.07,3.44c3.04-11.78,40.83-155.11,20.22-230.21c-20.62-75.1-10.37-113.49-10.37-113.49
    S-637.2,752.13-650.18,755.37z"
            />
            <path
              class="st4"
              d="M-637.31,1114.69c-6.1,25.59,0.39,56.52,15.31,60.45c14.92,3.93,49.28-11.29,46.33-82.46
    c-2.95-71.17-32.52-160.44-42.77-191.64C-628.68,869.82-637.31,1114.69-637.31,1114.69z"
            />
          </g>
        </g>
        <path
          class="st23"
          d="M-595.24,237.28c0,13.59-76.18,49.22-116.7,90.06c0,0-3.21,34.28-3.21,125.77
c0,91.49,29.5,110.91,38.82,130.05c9.33,19.14,13.82,36.81,10.1,49.09c-3.72,12.27-22.86,70,10.76,115.99
c33.62,45.99,83.75,108.49,95.25,119.29S-539.12,878-539.12,878s9.59,0.33,21.09-10.47s61.63-73.3,95.25-119.29
c33.62-45.99,14.48-103.72,10.76-115.99c-3.72-12.27,0.78-29.94,10.1-49.09c9.33-19.14,38.82-38.56,38.82-130.05
c0-91.49-3.21-125.77-3.21-125.77C-406.82,286.5-483,250.88-483,237.28H-595.24z"
        />
        <g class="st22">
          <path
            class="st24"
            d="M-539.18,413.96l-100.96,79.35c-30.2,16.94-49.01,62.2-34.26,94.04l4.06,9.82
  c8.04,17.4,37.16,46.2,60.39,53.07c0,0,25.06-29.29,70.8-29.29s70.8,29.29,70.8,29.29c23.23-6.87,52.36-35.67,60.39-53.07
  l4.06-9.82c14.75-31.85-4.06-77.11-34.26-94.04l-100.96-79.35H-539.18z"
          />
        </g>
        <path
          class="st23"
          d="M-479.14,191.63c0,1.61-4.48,55.28-5.73,63.56c-6.38,42.29-33.26,58.25-54.26,80.53
c18.39-33.22,19.17-98.44,19.17-98.44C-509.91,232.79-484.84,200.53-479.14,191.63z"
        />
        <path
          class="st23"
          d="M-599.11,191.63c0,1.61,4.48,55.28,5.73,63.56c6.38,42.29,33.26,58.25,54.26,80.53
c-18.39-33.22-19.17-98.44-19.17-98.44C-568.33,232.79-593.4,200.53-599.11,191.63z"
        />
        <path
          class="st23"
          d="M-519.95,237.28c0,0-0.78,65.22-19.17,98.44c-18.37-33.22-19.17-98.44-19.17-98.44
c5.58,2.51,11.94,3.97,19.17,3.97C-531.89,241.26-525.53,239.79-519.95,237.28z"
        />
        <g class="st22">
          <path
            class="st25"
            d="M-472.83,484.35c0,0,20.78,6.66,20.75,6.65c-24.59-5.62-43.28-18.09-50.93-22.7
  c-2.46-1.48-5.63-3.17-9.05-5.26c-0.05-0.03-0.12-0.07-0.17-0.1c-13.9-8.23-26.87,12.23-26.87,30.23c0,18,14.81,22.91,20.37,21.76
  c5.56-1.15,31.09,1.48,46.14,8.35c15.05,6.87,17.39,2.38,16.39-11.29C-457.2,498.32-465.96,485.7-472.83,484.35z"
          />
          <path
            class="st25"
            d="M-466.65,525.76c-0.03-0.01-0.06-0.03-0.09-0.05c-1.68-0.61-3.59-1.41-5.85-2.44
  c-12.16-5.55-31.13-8.33-40.92-8.59c-8.5,0.29-25.59,6.31-25.59,24.62c0,18.33,5.32,40.25,9.24,46.47
  c3.93,6.22,12.76,6.22,25.85,2.29c13.09-3.93,32.4-7.2,42.87-4.91c10.47,2.29,14.07-11.78,9.16-32.07
  C-456.88,530.8-460.71,528.25-466.65,525.76z"
          />
          <path
            class="st25"
            d="M-459.36,583.39c-0.57-0.02-1.16-0.09-1.77-0.23c-1.76-0.15-4.18-0.45-7.04-0.74
  c-10.74-0.25-25.3,2.49-35.83,5.64c-2.44,0.73-4.68,1.28-6.82,1.72c-0.03,0.02-0.06,0.04-0.09,0.06
  c-11.09,3.46-28.21,9.35-28.21,23.75c0,14.4,0.02,11.55,0.02,22.02c0,10.47,11.86,40.48,25.28,32.96
  c13.42-7.53,36.65-16.36,45.49-18.32c8.84-1.96,13.82-10.17,16.36-36C-449.6,590.18-451.47,584.71-459.36,583.39z"
          />
          <path
            class="st25"
            d="M-468.34,650.24c-3.04,0.92-6.93,2.07-11.21,3.41c-10.71,3.75-24.88,9.64-34.27,14.91
  c-10.67,7.75-25.28,22.25-25.28,45.16c0,22.91,0,64.07,0,92.05c0,27.98,31.27,3.3,48.88-35.31
  c17.61-38.61,32.36-81.54,33.67-98.26C-455.25,655.48-457.22,646.86-468.34,650.24z"
          />
          <path
            class="st25"
            d="M-605.37,484.35c0,0-20.78,6.66-20.75,6.65c24.59-5.62,43.28-18.09,50.93-22.7
  c2.46-1.48,5.63-3.17,9.05-5.26c0.05-0.03,0.12-0.07,0.17-0.1c13.9-8.23,26.87,12.23,26.87,30.23c0,18-14.81,22.91-20.37,21.76
  c-5.56-1.15-31.09,1.48-46.14,8.35c-15.05,6.87-17.39,2.38-16.39-11.29C-621,498.32-612.24,485.7-605.37,484.35z"
          />
          <path
            class="st25"
            d="M-611.55,525.76c0.03-0.01,0.06-0.03,0.09-0.05c1.68-0.61,3.59-1.41,5.85-2.44
  c12.16-5.55,31.13-8.33,40.92-8.59c8.5,0.29,25.59,6.31,25.59,24.62c0,18.33-5.32,40.25-9.24,46.47
  c-3.93,6.22-12.76,6.22-25.85,2.29c-13.09-3.93-32.4-7.2-42.87-4.91c-10.47,2.29-14.07-11.78-9.16-32.07
  C-621.32,530.8-617.49,528.25-611.55,525.76z"
          />
          <path
            class="st25"
            d="M-618.84,583.39c0.57-0.02,1.16-0.09,1.77-0.23c1.76-0.15,4.18-0.45,7.04-0.74
  c10.74-0.25,25.3,2.49,35.83,5.64c2.44,0.73,4.68,1.28,6.82,1.72c0.03,0.02,0.06,0.04,0.09,0.06
  c11.09,3.46,28.21,9.35,28.21,23.75c0,14.4-0.02,11.55-0.02,22.02c0,10.47-11.86,40.48-25.28,32.96
  c-13.42-7.53-36.65-16.36-45.49-18.32c-8.84-1.96-13.82-10.17-16.36-36C-628.6,590.18-626.73,584.71-618.84,583.39z"
          />
          <path
            class="st25"
            d="M-609.87,650.24c3.04,0.92,6.93,2.07,11.21,3.41c10.71,3.75,24.88,9.64,34.27,14.91
  c10.67,7.75,25.28,22.25,25.28,45.16c0,22.91,0,64.07,0,92.05c0,27.98-31.27,3.3-48.88-35.31
  c-17.61-38.61-32.36-81.54-33.67-98.26C-622.95,655.48-620.98,646.86-609.87,650.24z"
          />
        </g>
        <path
          class="st23"
          d="M-494.48,225.8c-5.99,9.35-13.95,17.62-24.5,22.34c-5.87,2.64-12.55,4.18-20.15,4.18s-14.28-1.54-20.15-4.18
c-10.57-4.73-18.55-13.02-24.52-22.39c0-0.02,0-0.02,0-0.02c-14.37-22.51-17.28-42.73-17.28-42.73s-15.93,3.82-22.2-27.11
c-6.37-31.44,13.99-29.58,13.99-29.58s-6.54-19.91,0.3-50.65c6.84-30.74,36.69-51.35,69.86-51.35c33.15,0,63.02,20.61,69.86,51.35
c6.81,30.74,0.27,50.65,0.27,50.65s20.38-1.86,14.01,29.58c-6.27,30.93-22.2,27.11-22.2,27.11S-480.09,203.29-494.48,225.8z"
        />
        <g class="st22">
          <path
            class="st4"
            d="M-421.45,348.25c-7.45-4.67-15.49-8.07-24.14-9.53c-57.82-9.82-72.43-2.99-82.62,9.16
  c-10.18,12.15-10.89,28.56-10.89,66.08c0,37.52,25.04,47.68,36.09,54.34c11.04,6.65,44.98,29.67,86.55,24.87
  c41.57-4.8,48.96-37.48,60.11-49.83c0,0,0-0.01,0-0.01C-394.68,416.5-416.15,362.87-421.45,348.25z"
          />
          <path
            class="st4"
            d="M-659.12,348.25c7.45-4.67,17.86-8.07,26.51-9.53c57.82-9.82,72.43-2.99,82.62,9.16
  c10.18,12.15,10.89,28.56,10.89,66.08c0,37.52-25.04,47.68-36.09,54.34c-11.04,6.65-44.98,29.67-86.55,24.87
  c-41.57-4.8-49.79-39.62-60.94-51.97c0,0,0,0,0,0C-684.35,414.37-664.42,362.87-659.12,348.25z"
          />
        </g>
        <g class="st22">
          <g>
            <path
              class="st0"
              d="M-750.28,583.04c20.26-28.28,49.84-63.93,50.45-119.57c0.68-61.69-47.18-65.79-53.03-2.87
    c-3.36,36.1-49.91,92.41-48.16,115.67C-799.71,593.55-770.54,611.32-750.28,583.04z"
            />
            <path
              class="st0"
              d="M-815.89,643.73c-4.51-41.11,17.79-48.71,65.61-60.69l0.01,0c0,0,7.23,73.54-24.57,85.85
    c-14.39,5.57-24.67,9.16-32.28,12.51c-4.61-3.77-5.47-4.48-10.08-8.25C-813.75,656.89-815.89,643.73-815.89,643.73z"
            />
            <path
              class="st0"
              d="M-832.99,707.23c2.73-4.07,5.14-7.95,7.29-11.64c0.01-0.02,0.02-0.03,0.04-0.05
    c7.32-11.69,19.03-14.35,50.83-26.66c5.81-2.25,10.68-6.36,14.77-11.57l0,0c0,0-4.09,41-29.33,61.56
    c-22.65,18.45-59.58,83.59-69.7,107.14c-1.39,3.24-4.85,4.98-8.31,4.31c-0.06-0.01-0.12-0.02-0.18-0.04
    c-4.21-0.79-6.95-4.87-5.9-9.02C-867.54,797.83-850,732.57-832.99,707.23z"
            />
            <path
              class="st4"
              d="M-812.34,565.11c-2.08-0.27-4.16,0.35-5.75,1.73c-9.73,8.49-44.58,41.41-54.16,84.98
    c-10.09,45.89-23.64,133.28-26.17,149.75c-0.29,1.88,0.18,3.77,1.28,5.33c3.32,4.72,11.41,14.3,23.17,15.79
    c3.88,0.49,7.47-2.17,8.13-6.03c3.37-19.68,15.29-83.28,32.85-109.44c20.91-31.14,18.5-68.3,18.5-68.3s25.67-43.24,10-70.03
    c-1.13-1.92-3.14-3.17-5.35-3.46L-812.34,565.11z"
            />
            <g>
              <path
                class="st0"
                d="M-858.24,824.07c-1.76-18.85-32.17-19.83-39.16-19.81c-0.93,0-1.82,0.2-2.68,0.55
      c-17.65,7.28-38.66,9.84-52.32,21.93c-13.98,12.38-22.03,14.43-35.88,17.82c-13.86,3.37-9.79,18.76,7.95,18
      c17.76-0.76,27.07-3.87,38.58-7.41l0,0c0,0,0,0,0,0c-16.6,30.37-25.88,53.48-32.12,81.39c-1.58,18.2,30.07,17.93,42.86,13.02
      c24.33-8.33,44.66-50.43,56.15-62.67C-863.41,873.65-856.26,845.38-858.24,824.07z"
              />
              <path
                class="st0"
                d="M-941.75,855.16c14.48-1.78,34.85,0.46,53.72-18.62"
              />
            </g>
          </g>
          <g>
            <path
              class="st0"
              d="M-264.68,643.73c4.51-41.11-17.79-48.71-65.61-60.69l-0.01,0c0,0-7.23,73.54,24.57,85.85
    c14.39,5.57,24.67,9.16,32.28,12.51c4.61-3.77,5.47-4.48,10.08-8.25C-266.82,656.89-264.68,643.73-264.68,643.73z"
            />
            <path
              class="st0"
              d="M-247.58,707.23c-2.73-4.07-5.14-7.95-7.29-11.64c-0.01-0.02-0.02-0.03-0.04-0.05
    c-7.32-11.69-19.03-14.35-50.83-26.66c-5.81-2.25-10.68-6.36-14.77-11.57l0,0c0,0,4.09,41,29.33,61.56
    c22.65,18.45,59.58,83.59,69.7,107.14c1.39,3.24,4.85,4.98,8.31,4.31c0.06-0.01,0.12-0.02,0.18-0.04
    c4.21-0.79,6.95-4.87,5.9-9.02C-213.03,797.83-230.57,732.57-247.58,707.23z"
            />
            <path
              class="st0"
              d="M-330.29,583.04c-20.26-28.28-49.84-63.93-50.45-119.57c-0.68-61.69,47.18-65.79,53.03-2.87
    c3.36,36.1,49.91,92.41,48.16,115.67C-280.86,593.55-310.03,611.32-330.29,583.04z"
            />
            <path
              class="st4"
              d="M-268.23,565.11c2.08-0.27,4.17,0.35,5.75,1.73c9.73,8.49,44.58,41.41,54.16,84.98
    c10.09,45.89,23.64,133.28,26.17,149.75c0.29,1.88-0.18,3.77-1.28,5.33c-3.32,4.72-11.41,14.3-23.17,15.79
    c-3.88,0.49-7.47-2.17-8.13-6.03c-3.37-19.68-15.29-83.28-32.85-109.44c-20.91-31.14-19.94-69.02-19.94-69.02
    s-24.23-42.52-8.56-69.31c1.13-1.92,3.13-3.17,5.35-3.46L-268.23,565.11z"
            />
            <g>
              <path
                class="st0"
                d="M-222.33,824.07c1.76-18.85,32.17-19.83,39.16-19.81c0.93,0,1.82,0.2,2.68,0.55
      c17.65,7.28,38.66,9.84,52.32,21.93c13.98,12.38,22.03,14.43,35.88,17.82c13.86,3.37,9.79,18.76-7.95,18
      c-17.76-0.76-27.07-3.87-38.58-7.41l0,0c0,0,0,0,0,0c16.6,30.37,25.88,53.48,32.12,81.39c1.58,18.2-30.07,17.93-42.86,13.02
      c-24.33-8.33-44.66-50.43-56.15-62.67C-217.17,873.65-224.32,845.38-222.33,824.07z"
              />
              <path
                class="st0"
                d="M-138.82,855.16c-14.48-1.78-34.85,0.46-53.72-18.62"
              />
            </g>
          </g>
          <path
            class="st4"
            d="M-290.91,467.6c-1.07,4.2-5.51,6.57-9.6,5.14c-28.91-10.09-35.36-18.87-46.12-24.54
  c-43.5-22.91-68.18-82.04-74.83-99.95c-1.18-3.17-0.05-6.73,2.77-8.6c9.26-6.18,31.6-18.19,59.23-12.66
  C-323.66,334.14-260.31,347.08-290.91,467.6z"
          />
          <path
            class="st4"
            d="M-325.99,456.52c-4.15-1.51-8.67,1.02-9.64,5.33c-2.89,12.85-6.33,41.89,4.48,89.01
  c11.29,49.25,42.5,76.58,57.8,87.38c4.22,2.98,10.11,0.83,11.35-4.19C-253.68,600.6-233.95,490.09-325.99,456.52z"
          />
          <path
            class="st4"
            d="M-789.66,467.6c1.07,4.2,5.51,6.57,9.6,5.14c28.91-10.09,35.36-18.87,46.12-24.54
  c43.5-22.91,68.18-82.04,74.83-99.95c1.18-3.17,0.05-6.73-2.77-8.6c-9.26-6.18-31.6-18.19-59.23-12.66
  C-756.91,334.14-820.26,347.08-789.66,467.6z"
          />
          <path
            class="st4"
            d="M-754.58,456.52c4.15-1.51,8.67,1.02,9.64,5.33c2.89,12.85,6.33,41.89-4.48,89.01
  c-11.29,49.25-42.5,76.58-57.8,87.38c-4.22,2.98-10.11,0.83-11.35-4.19C-826.9,600.6-846.62,490.09-754.58,456.52z"
          />
        </g>
      </g>
    </svg>
  );
};
export default App;
