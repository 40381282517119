import { useEffect, useRef } from "react";
import "./style.css";

function Index({ focus, ...props }) {
  const txt = useRef()
  useEffect(() => {
    if (focus) {
      txt.current.focus()
    }

  }, [])
  return (
    <>
      <input ref={txt}  {...props} className={`input ${props.shape === "round" && "round"}`} />
    </>
  );
}

export default Index;
