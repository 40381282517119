import React from "react";
import { baseFileUrl, svgFileUrl } from "../../../config";
import { Link } from "react-router-dom";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ id, image, title, duration, carbohydrate, protein, fat,calories }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <Link to={`/recipe/${id}`}>
      <section className="w-[100%] h-[275px] bg-white shadow-[0px 4px 20px -6px] shadow-[0_4px_20px_-6px_#A193F8B2] rounded-[16px] p-1">
        <section className="m-auto mt-[-75px] w-[138px] h-[138px] flex justify-center items-center shadow-[0px_0px_5px_2px_rgba(153,144,153,1)] border border-silver overflow-hidden rounded-full">
          <img src={image} className="w-[138px]" alt="" />
        </section>

        <span className="block text-center font-bold text-[16px] mt-[16px]">{title}</span>
        <section className="w-[156px] h-[37px] flex justify-between  mx-auto mt-4">
          <section className="self-center text-[14px] text-right">
            <img src={baseFileUrl + "/images/icons/clock.png"} className="w-[16px] h-[16px] inline-block" alt="" />
            <span className="text-textGray mr-2">{duration}</span>
           
          </section>
          <span className="inline-block w-[2px] h-[25px] bg-[#E6E8E9]"></span>
          <section className="self-center text-[14px] w-[60px] text-left">
            <img src={svgFileUrl + "/restaurant1.svg"} className="w-[16px] h-[16px] inline-block" alt="" />
            <span className="mr-2">{calories}</span>
            <section className="text-textGray">دریافتی</section>
          </section>
        </section>
        <section className="w-[191px] h-[77px] rounded-[14px] border border-[#E6E8E9] mx-auto mt-2 flex justify-around p-1">
          <section className="flex flex-col justify-around items-center w-[49px]">
            <span className="text-[11px] font-semibold text-[#0097C1]">کربوهیدرات</span>
            <span className="text-[#0097C1] font-bold">{carbohydrate}</span>
          </section>
          <span className="inline-block w-[2px] h[45px] bg-[#E6E8E9]"></span>
          <section className="flex flex-col justify-around items-center w-[49px]">
            <span className="text-[11px] font-semibold text-[#F02484]">چربی</span>
            <span className="text-[#F02484] font-bold">{fat}</span>
          </section>
          <span className="inline-block w-[2px] h[45px] bg-[#E6E8E9]"></span>
          <section className="flex flex-col justify-around items-center w-[49px]">
            <span className="text-[11px] font-semibold text-[#7639BA]">پروتئین</span>
            <span className="text-[#7639BA] font-bold">{protein}</span>
          </section>
        </section>
      </section>
    </Link>
  );
}
