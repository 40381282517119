import React from "react";
import { baseFileUrl } from "../../../../../config";
import { Link } from "react-router-dom";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({img,title,display}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
   <section className={`${display} w-[98px] xl:w-[109px] text-[#3E4151] text-sm leading-[21.7px] gap-3 flex flex-col justify-center items-center  rounded-[11px]  xl:h-[110px]  bg-white`}>
   <img
        src={baseFileUrl + "/images/icons/"+img}
        alt=""
        className=" w-[50%] "
      />
      <span>{title}</span>
   </section>
    </>
  );
}