"use client";
import React from "react";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({basicInfo}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return <>
   <section className="xl:px-[32px] py-[26px]  w-[95%] mx-auto text-[#3E4151] xl:w-full h-auto xl:h-[168px] rounded-[18px] bg-[#F9F5FE] mb-6 border border-solid border-[#DDCEEE]">
        <h2 className="w-[90%] mx-auto xl:w-full font-bold  mb-6">
          کالری و ماکروی هدف
          <span className="font-normal mr-[14px]">(۲۸ روز)</span>
        </h2>
        <section className="grid w-[90%] gap-6 mx-auto xl:w-full  xl:grid-cols-2 xl:gap-x-[70px] xl:gap-y-6">
          <span className="">
            کالری هدف
            <span className="mr-[42px] text-[#5408A9]  font-bold">{Math.ceil(basicInfo?.recommendedCaloriesInTheMonth)}</span>
          </span>
          <span className="">
            کربوهیدرات {Math.ceil(basicInfo?.recommendedGramCarbohydrateInTheMonth)} گرم
            <span className="mr-[17px] text-[#5408A9] font-bold">{Math.ceil(basicInfo?.percentUsedCarbohydrateInTheMonth)}٪</span>
          </span>
          <span className="">
            پروتئین {Math.ceil(basicInfo?.recommendedGramProteinInTheMonth)} گرم
            <span className="mr-[16px] text-[#5408A9]  font-bold">{Math.ceil(basicInfo?.percentUsedProteinInTheMonth)}%</span>
          </span>
          <span className="">
            چربی {Math.ceil(basicInfo?.recommendedGramFatInTheMonth)} گرم
            <span className="mr-[73px] text-[#5408A9] font-bold">{Math.ceil(basicInfo?.percentUsedFatInTheMonth)}٪</span>
          </span>
        </section>
      </section>
  </>;
}