import React, { useState, useContext, useEffect } from "react";
import { imagesFileUrl, svgFileUrl } from "../../../../../../config";
import MacroChart from "./components/MacroChart";
import Select from "react-select";
import Loading from "../../../../../../components/common/componentLoading";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ loading, userInfo }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const date = [
    { value: 0, label: "روزانه" },
    { value: 1, label: "هفتگی" },
    { value: 2, label: "ماهانه" },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [chartMode, setChartMode] = useState(date[0]);
  const [chartInfo, setChartInfo] = useState({});
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (userInfo.monthsList) {
      if (chartMode.value === 0) {
        setChartInfo({
          carboRecommend: userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.recommendedGramCarbohydrateInTheDay,
          carboConsume: userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.consumedGramCarbohydrateInTheDay,
          carboPercent: userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.percentUsedCarbohydrateInTheDay,
          fatRecommend: userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.recommendedGramFatInTheDay,
          fatConsume: userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.consumedGramFatInTheDay,
          fatPercent: userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.percentUsedFatInTheDay,
          proteinRecommend: userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.recommendedGramProteinInTheDay,
          proteinConsume: userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.consumedGramProteinInTheDay,
          proteinPercent: userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.percentUsedProteinInTheDay,
        });
      } else if (chartMode.value === 1) {
        setChartInfo({
          carboRecommend: userInfo?.currentMonthInfoList?.weekData[0]?.recommendedGramCarbohydrateInTheWeek,
          carboConsume: userInfo?.currentMonthInfoList?.weekData[0]?.consumedGramCarbohydrateInTheWeek,
          carboPercent: userInfo?.currentMonthInfoList?.weekData[0]?.percentUsedCarbohydrateInTheWeek,
          fatRecommend: userInfo?.currentMonthInfoList?.weekData[0]?.recommendedGramFatInTheWeek,
          fatConsume: userInfo?.currentMonthInfoList?.weekData[0]?.consumedGramFatInTheWeek,
          fatPercent: userInfo?.currentMonthInfoList?.weekData[0]?.percentUsedFatInTheWeek,
          proteinRecommend: userInfo?.currentMonthInfoList?.weekData[0]?.recommendedGramProteinInTheWeek,
          proteinConsume: userInfo?.currentMonthInfoList?.weekData[0]?.consumedGramProteinInTheWeek,
          proteinPercent: userInfo?.currentMonthInfoList?.weekData[0]?.percentUsedProteinInTheWeek,
        });
      } else if (chartMode.value === 2) {
        setChartInfo({
          carboRecommend: userInfo?.currentMonthInfoList?.recommendedGramCarbohydrateInTheMonth,
          carboConsume: userInfo?.currentMonthInfoList?.consumedGramCarbohydrateInTheMonth,
          carboPercent: userInfo?.currentMonthInfoList?.percentUsedCarbohydrateInTheMonth,
          fatRecommend: userInfo?.currentMonthInfoList?.recommendedGramFatInTheMonth,
          fatConsume: userInfo?.currentMonthInfoList?.consumedGramFatInTheMonth,
          fatPercent: userInfo?.currentMonthInfoList?.percentUsedFatInTheMonth,
          proteinRecommend: userInfo?.currentMonthInfoList?.recommendedGramProteinInTheMonth,
          proteinConsume: userInfo?.currentMonthInfoList?.consumedGramProteinInTheMonth,
          proteinPercent: userInfo?.currentMonthInfoList?.percentUsedProteinInTheMonth,
        });
      }
    }
  }, [userInfo, chartMode]);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className=" h-[301px] mt-[38px] xl:mt-0 bg-white rounded-[18px] shadow-md py-8 px-8 relative overflow-hidden">
      <Loading loading={loading} />
      <img src={imagesFileUrl + "dashboard/shape.png"} className="absolute top-0 right-1 z-0" alt="" />
      <section className="z-10 relative w-full flex justify-between items-center">
        <span className="text-lg font-bold">ماکرو</span>
        <Select value={chartMode} onChange={setChartMode} options={date} placeholder="روز" />
      </section>
      <section className="xl:w-[326px] h-[156px] relative mt-12 flex justify-between items-center">
        <MacroChart title="کربوهیدرات" chartColor="#4CC9EC" chartBackColor="#EEFAFE" percent={chartInfo.carboPercent} goal={chartInfo.carboRecommend} remain={chartInfo.carboConsume} />
        <MacroChart title="چربی" chartColor="#F02484" chartBackColor="#FEEAF3" percent={chartInfo.fatPercent} goal={chartInfo.fatRecommend} remain={chartInfo.fatConsume} />
        <MacroChart title="پروتئین" chartColor="#7639BA" chartBackColor="#F1E8FC" percent={chartInfo.proteinPercent} goal={chartInfo.proteinRecommend} remain={chartInfo.proteinConsume} />
      </section>
    </section>
  );
}
