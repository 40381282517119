import React, { useEffect, useState } from 'react';
import StateFilter from '../components/StateFilter';
import ChoosePackage from '../components/ChoosePackage';
import ChooseSelection from '../components/ChooseSelection';
import DietList from '../components/DietList';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  img,
  type,
  title,
  time,
  desc,
  disabled,
  status,
  foodCatList,
  catLoading,
  activeTab,
  tab,
  setActiveTab,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [filterState, setFilterState] = useState(2);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const toggleTab = () => {
    if (activeTab === tab) {
      setActiveTab(0)
    }
    else {
      setActiveTab(tab)
    }
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="relative">
      {/* <span
        className="absolute top-10 left-10 cursor-pointer"
        onClick={() => setActiveTab(0)}
      >
        بستن
      </span> */}
      <section
        onClick={toggleTab}
        className="dietFoodBox mt-4 w-full flex items-center h-[110px] px-[12px] py-[10px] bg-white shadow-md rounded-xl cursor-pointer"
      >
        <img src={img} alt="" />
        <section className="flex flex-col">
          <span className="w-[94px] text-purple mr-[18px] font-bold text-lg">
            {title}
          </span>
          <span className="w-[94px] text-purple mr-[18px] font-light text-sm">
            {time}
          </span>
        </section>
      </section>
      <section
        className={`xl:w-[692px] min-h-[110] mt-2 ${activeTab === tab
          ? 'h-[510px] overflow-auto'
          : 'h-[0px] overflow-hidden'
          } bg-white rounded-[12px]  transition-all duration-700 ease `}
      >
        <section
          className={`px-[32px] py-[25px] ${activeTab === tab ? 'opacity-1' : 'opacity-0'
            }`}
        >
          <StateFilter
            type={type}
            state={filterState}
            setState={setFilterState}
          />
          {filterState === 1 && <ChoosePackage />}
          {filterState === 2 && (
            <ChooseSelection
              type={type}
              foodCatList={foodCatList}
              loading={catLoading}
            />
          )}
          {filterState === 3 && <DietList type={type} />}
        </section>

        {/* <span className="w-[325px] font-normal text-sm text-[#666B72]  mr-[20px]">{desc}</span> */}
        {/*  {
       status !=="none" && <button disabled={disabled ? true : false} className="w-[87px] h-[30px] border border-purple text-purple bg-white rounded-full font-normal text-sm mr-auto disabled:text-[#A6A9BD] disabled:border-[#A6A9BD]">
       {status==="success" && "انجام شد"}
     </button>
     } */}
      </section>
    </section>
  );
}
