import React from 'react';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({icon1,icon2,title1,title2,value1,value2,bigSize}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    
      <section className="w-[267px] h-[87px] flex justify-center items-center bg-white rounded-[9px] shadow-md mt-5">
        <section className="w-[216px] h-[63px]  flex justify-between items-center">
          <section className="w-[75px] h-[63px]">
            <section className="w-[32px] h-[32px] shadow-lg rounded-[8px] flex justify-center items-center">
              <img
                src={icon1}
                className="w-[18px] h-[18px]"
                alt=""
              />
            </section>
            <section className="w-[75px] h-[25px] flex justify-between items-center mt-2">
              <span className="text-[12px] font-semibold text-textGray">
              {title1}:
              </span>
              <span className="font-bold text-textGray">{value1}</span>
            </section>
          </section>
          <span className="block h-[36px] w-[2px] bg-[#F1E8FC]"></span>
          <section className="w-[75px] h-[63px]">
            <section className="w-[75px] h-[63px]">
              <section className="w-[32px] h-[32px] shadow-lg rounded-[8px] flex justify-center items-center">
                <img
                  src={icon2}
                  className="w-[18px] h-[18px]"
                  alt=""
                />
              </section>
              <section className="w-[75px] h-[25px] flex justify-between items-center mt-2">
                <span className="text-[12px] block w-[55px] font-semibold text-textGray">
                {title2}:
                </span>
                <span className="font-bold text-textGray">{value2}</span>
              </section>
            </section>
          </section>
        </section>
      </section>
    
  );
}
