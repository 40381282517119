import React from 'react';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ fill }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.77634 0.5C2.10632 0.5 1.46373 0.766168 0.989949 1.23995C0.516168 1.71373 0.25 2.35632 0.25 3.02634V14.816C0.25 15.486 0.516168 16.1286 0.989949 16.6023C1.46373 17.0761 2.10632 17.3423 2.77634 17.3423H7.82903C8.49906 17.3423 9.14165 17.0761 9.61543 16.6023C10.0892 16.1286 10.3554 15.486 10.3554 14.816V3.02634C10.3554 2.35632 10.0892 1.71373 9.61543 1.23995C9.14165 0.766168 8.49906 0.5 7.82903 0.5H2.77634ZM11.4442 4.95731C11.6022 4.79944 11.8163 4.71075 12.0396 4.71075C12.2629 4.71075 12.4771 4.79944 12.635 4.95731L16.0034 8.32577C16.1613 8.48369 16.25 8.69785 16.25 8.92115C16.25 9.14445 16.1613 9.3586 16.0034 9.51652L12.635 12.885C12.4762 13.0384 12.2634 13.1233 12.0426 13.1213C11.8218 13.1194 11.6106 13.0309 11.4545 12.8747C11.2984 12.7186 11.2098 12.5074 11.2079 12.2866C11.206 12.0658 11.2908 11.8531 11.4442 11.6942L13.3752 9.76326H6.1448C5.92146 9.76326 5.70727 9.67454 5.54934 9.51661C5.39141 9.35869 5.30269 9.14449 5.30269 8.92115C5.30269 8.6978 5.39141 8.48361 5.54934 8.32568C5.70727 8.16776 5.92146 8.07903 6.1448 8.07903H13.3752L11.4442 6.14806C11.2864 5.99014 11.1977 5.77599 11.1977 5.55269C11.1977 5.32939 11.2864 5.11523 11.4442 4.95731Z" fill={fill}/>
    </svg>
    
  );
}
