import React, { useState, useContext, useEffect } from "react";
import { imagesFileUrl } from "../../../../../config";
import InnerTabs from "./components/InnerTabs";
import InnerContent from "./components/InnerContent";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeBtn, setActiveBtn] = useState(1)
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full h-auto xl:h-[993px] mt-[30px] xl:mt-[40px] px-[8px] xl:px-[40px] mx-auto rounded-[10px] bg-[#DBF4FB] pb-[20px]">
        <InnerTabs activeBtn={activeBtn} setActiveBtn={setActiveBtn} />
        <InnerContent activeBtn={activeBtn} />
      </section>
    </>
  );
}
