import React from 'react'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='w-full  py-[27px] px-[16px] items-center h-[94px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]'>
            <span className='w-[130px] text-[#3E4151] font-bold absolute top-[-12px] bg-white right-4'>بیماری های خاص</span>
            <section className='flex gap-5'>
                <span className='flex justify-center items-center rounded-[12px] bg-[#F1E8FC] text-[#5408A9] h-[39px] py-[15px] px-[6px]'>فیبروز کبدی</span>
                <span className='flex justify-center items-center rounded-[12px] bg-[#F1E8FC] text-[#5408A9] h-[39px] py-[15px] px-[6px]'>دیابت نوع 2</span>
                <span className='flex justify-center items-center rounded-[12px] bg-[#F1E8FC] text-[#5408A9] h-[39px] py-[15px] px-[6px]'>دیالیز</span>
                <span className='flex justify-center items-center rounded-[12px] bg-[#F1E8FC] text-[#5408A9] h-[39px] py-[15px] px-[6px]'>جراحی کیسه صفرا</span>
            </section>
        </section>
    )
}
