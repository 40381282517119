import React from "react";
import { imagesFileUrl } from "../../../../../../../../config";
import InfoInputDaily from "./InfoInputDaily";
import Item from "./Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full xl:w-[1053px] h-auto xl:h-[845px] pt-[44px] pb-[56px] px-8 mx-auto mt-[30px] mb-[44px] bg-[#FFFFFF] rounded-[10px] shadow-[0px_6px_14px_px_#0000000f] grid grid-cols-2 justify-between">

      </section>
    </>
  );
}
