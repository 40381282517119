import React, { useState, useContext, useEffect } from 'react';
import Plus from '../../../../../../assets/images/basicInfo/plus.png';
import Minus from '../../../../../../assets/images/basicInfo/minus.png';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({height,setHeight}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const increaseHeight = () => {
    if (height <= 220) setHeight(height + 1);
  };
  const decreaseHeight = () => {if (height > 120) {setHeight(height - 1)}};

  
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full h-[48px] flex  items-center bg-white border border-silver rounded-[12px] mt-4 justify-around">
      <img
        alt=""
        src={Plus}
        className="h-[19px] w-[19px] cursor-pointer"
        onClick={increaseHeight}
      />
      <section className="flex justify-center items-center">
        <span className="text-[30px] font-bold">{height}</span>
        <span className="text-[12px] font-normal mr-2">سانتی متر</span>
      </section>
      <section  onClick={decreaseHeight} className="w-[19px] h-[19px] cursor-pointer flex justify-center items-center">
        <img
          alt=""
          src={Minus}
          className="w-[19px] "
         
        />
      </section>
    </section>
  );
}
