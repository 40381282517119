import React, { useState, useContext, useEffect } from 'react';

import { imagesFileUrl } from '../../../../config';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setActiveTab, activeTab }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-[1132px] xl:gap-0 gap-[40px] h-[92px] mt-[64px] xl:mt-[166px] flex justify-between">
        <section
          onClick={() => {
            setActiveTab(4);
          }}
          className={`${activeTab === 4
            ? 'bg-[#7639BA]   text-white shadow-[0px_20px_24px_-10px_#7639BA66] '
            : ' bg-[#F9F5FE] text-[#5408A9]'
            } w-[258px]  cursor-pointer h-full rounded-[12px] overflow-hidden  relative border border-solid border-[#5408A9]  px-[32px] flex items-center justify-end`}
        >
          <img
            alt=""
            src={imagesFileUrl + 'bg-planner.png'}
            className={`${activeTab === 4
              ? 'mix-blend-luminosity opacity-60'
              : ' mix-blend-darken'
              }  w-[119px] h-auto absolute  left-[139px]`}
          />
          <span className="w-[91px] h-[39px] font-bold leading-[26px] text-[22px]  pb-[48px]">
            بادینس پلنر
          </span>
        </section>

        <section
          onClick={() => {
            setActiveTab(3);
          }}
          className={`${activeTab === 3
            ? 'bg-[#F3509D] shadow-[0px_20px_24px_-10px_#F3509D66] opacity-90 text-white'
            : 'text-[#F02484]  bg-[#FEF4F9] '
            } w-[235px] cursor-pointer h-full rounded-[12px] overflow-hidden relative border border-solid border-[#F3509D] flex items-center  justify-stretch`}
        >
          <img
            alt=""
            src={imagesFileUrl + 'bg-lbm.png'}
            className={`${activeTab === 3
              ? 'grayscale cursor-pointer opacity-100  shadow-[0px_11.18px_18.63px_-14.94px_#F456A133]'
              : ' bg-blend-luminosity opacity-70 grayscale-[10]'
              } w-[150px] h-auto `}
          />
          <span className="w-[48px] h-[13px] font-bold leading-[26px] text-[22px]  pb-[18px]">
            LBM
          </span>
        </section>

        <section
          onClick={() => {
            setActiveTab(2);
          }}
          className={`${activeTab === 2
            ? 'bg-[#931CA0E5] shadow-[0px_20px_24px_-10px_#9E33AA66] opacity-95 text-white'
            : 'text-[#8E119B]  bg-[#8e119b08] '
            } w-[271px]  h-full cursor-pointer rounded-[12px] overflow-hidden border border-solid border-[#8e119b99] flex items-center justify-stretch`}
        >
          <img
            alt=""
            src={imagesFileUrl + 'bg-bodymass.png'}
            className={`${activeTab === 2 ? ' ' : ' mix-blend-luminosity opacity-40'
              }  w-[115px] h-auto `}
          />
          <span className="w-[113px] h-[39px] font-bold leading-[26px] text-[22px] pr-[19px] pb-[48px]">
            شاخص توده بدنی
          </span>
        </section>

        <section
          onClick={() => {
            setActiveTab(1);
          }}
          className={`${activeTab === 1
            ? 'bg-[#0097C1] shadow-[0px_16px_24px_-10px_#0097C166] text-white'
            : 'text-[#0097C1]  bg-[#F6FCFE]'
            } w-[236px] cursor-pointer h-full rounded-[12px] overflow-hidden  border border-solid border-[#4CC9EC] flex items-center justify-stretch`}
        >
          <img
            alt=""
            src={imagesFileUrl + 'bg-watercounter.png'}
            className={`${activeTab === 1 ? '' : 'opacity-30 bg-blend-luminosity'
              } w-[137.46px] h-auto `}
          />
          <span className="w-[69px] h-[39px] font-bold leading-[26px] text-[22px] pb-[48px]">
            آب شمار
          </span>
        </section>
      </section>
    </>
  );
}
