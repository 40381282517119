import React, { useState, useContext, useEffect } from 'react';
import { imagesFileUrl, svgFileUrl } from '../../../../../config';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ img, muscle, duration, isFavorite, level }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="h-[420px] max-w-full border border-[#E6E8E9] rounded-[18px] flex flex-col items-center justify-center hover:shadow-lg bg-red cursor-pointer transition duration-500">
      <section className="w-[312px] h-[168px] max-w-full px-[10px] relative  rounded-[18px]">
        <span className="w-[32px] h-[32px] rounded-full flex justify-center items-center absolute top-4 right-4 bg-[#0000003D]">
          <img
            className="w-[16px] h-[16px]"
            src={
              isFavorite
                ? imagesFileUrl + '/svg/loveFill.svg'
                : imagesFileUrl + '/svg/love.svg'
            }
            alt=""
          />
        </span>
        <img src={img} className="rounded-[18px]" alt="" />
        <img src={muscle} className="absolute bottom-[-30px] left-2" alt="" />
      </section>
      <section className="w-full h-[129px]  mt-5">
        <section className="w-[171px] h-[24px] px-[10px] flex justify-between items-center">
          <section className="w-[100px] h-[24px] flex justify-between items-center">
            <section className="flex justify-center items-center">
              <img src={svgFileUrl + 'timer1.svg'} alt="" />
              <span className="block h-[12px] text-xs font-normal mr-1">
                {duration}
              </span>
            </section>
            <span className="block h-[12px] w-[1px] bg-[#E6E8E9]"></span>
            <section className="flex justify-center items-center">
              <img src={svgFileUrl + 'damble.svg'} alt="" />
              <span className="block h-[12px] text-xs font-normal mr-1">
                دمبل
              </span>
            </section>
          </section>
          {level === 1 ? (
            <button className="bg-[#F1E8FC] rounded-full w-[55px] h-[24px] text-xs font-normal text-purple">
              مبتدی
            </button>
          ) : level === 2 ? (
            <button className="bg-[#FEEAF3] rounded-full w-[55px] h-[24px] text-xs font-normal text-[#F02484]">
              پیشرفته
            </button>
          ) : (
            ''
          )}
        </section>
        <section className="w-full h-[89px] px-[10px]  mt-3">
          <span className="text-sm font-bold">تمرین</span>
          <section className="w-full h-[68px] text-[16px] text-[#3E4151] text-justify font-normal relative">
            توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین و
            حرکات که باید چه کاری انجام بدهد.

          </section>
        </section>

      </section>
      <section className="w-full px-[10px]">
        <section className="text-left">
          <span className="text-[22px] font-bold">2,500,000</span>
          <span className="mr-[6px]">تومان</span>
        </section>
        <section className="flex justify-between mt-[6px]">
          <button className="w-[130px] h-[30px] max-w-full border bg-white border-purple text-purple rounded-full bottom-[16px] z-50  transition-all duration-500">پیش نمایش</button>
          <button className="w-[130px] h-[30px] max-w-full border bg-purple border-purple text-white rounded-full bottom-[16px] z-50  transition-all duration-500">خرید برنامه</button>
        </section>

      </section>
    </section>
  );
}
