import React from "react";
import { imagesFileUrl, svgFileUrl } from "../../../../../../config";
import { Link } from "react-router-dom";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ img, muscle, duration, isFavorite, level }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="group w-[344px] h-[349px] border border-[#E6E8E9] rounded-[18px] relative flex flex-col items-center justify-center hover:shadow-lg hover:scale-105 cursor-pointer transition duration-500">
      <section className="w-[312px] h-[168px] relative  rounded-[18px]">
        <span className="w-[32px] h-[32px] rounded-full flex justify-center items-center absolute top-4 right-4 bg-[#0000003D]">
          <img className="w-[16px] h-[16px]" src={isFavorite ? imagesFileUrl + "/svg/loveFill.svg" : imagesFileUrl + "/svg/love.svg"} alt="" />
        </span>
        <img src={img} className="rounded-[18px]" alt="" />
        <img src={muscle} className="absolute bottom-[-65px] left-2" alt="" />
      </section>
      <section className="w-[312px] h-[129px] mt-5">
        <section className="w-[171px] h-[24px] flex justify-between items-center">
          <section className="w-[100px] h-[24px] flex justify-between items-center">
            <section className="flex justify-center items-center">
              <img src={svgFileUrl + "timer1.svg"} alt="" />
              <span className="block h-[12px] text-xs font-normal mr-1">{duration}</span>
            </section>
            <span className="block h-[12px] w-[1px] bg-[#E6E8E9]"></span>
            <section className="flex justify-center items-center">
              <img src={svgFileUrl + "damble.svg"} alt="" />
              <span className="block h-[12px] text-xs font-normal mr-1">دمبل</span>
            </section>
          </section>
          {level === 1 ? <button className="bg-[#F1E8FC] rounded-full w-[55px] h-[24px] text-xs font-normal text-purple">مبتدی</button> : level === 2 ? <button className="bg-[#FEEAF3] rounded-full w-[55px] h-[24px] text-xs font-normal text-[#F02484]">پیشرفته</button> : ""}
        </section>
        <section className="w-full h-[89px]  mt-3">
          <span className="text-sm font-bold">تمرین</span>
          <section className="w-full h-[68px] text-[16px] text-[#3E4151] text-justify font-normal relative">
            توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین و حرکات که باید چه کاری انجام بدهد.
            <section className="absolute top-0 left-0 w-full h-full overflow-hidden bg-gradient-to-t from-[#fefeffb6] to-[#70727a00]"></section>
          </section>
        </section>
      </section>
      <Link to="/dashboard/single-exercise">
        <button className="opacity-0 w-[105px] h-[30px] border bg-white border-[#5408A9] text-[#5408A9] rounded-full absolute right-[16px] bottom-[16px] z-50 group-hover:opacity-100 transition-all duration-500">پیش نمایش</button>

      </Link>
    </section>
  );
}
