import BaseIcon from "./base-icon";



export default function closeIcon(props) {
  return (
    <BaseIcon {...props}>
      <path clipRule="evenodd" d="M5.00049 17.5864C4.60999 17.977 4.61005 18.6101 5.00061 19.0006C5.39117 19.3911 6.02433 19.3911 6.41482 19.0005L12.0001 13.4143L17.5858 19C17.9763 19.3905 18.6095 19.3905 19 19C19.3905 18.6095 19.3905 17.9763 19 17.5858L13.4142 12L18.9996 6.41359C19.39 6.02303 19.39 5.38987 18.9994 4.99938C18.6089 4.60888 17.9757 4.60894 17.5852 4.9995L11.9999 10.5858L6.41414 4.99998C6.02362 4.60946 5.39045 4.60946 4.99993 4.99998C4.6094 5.39051 4.6094 6.02367 4.99993 6.4142L10.5859 12.0001L5.00049 17.5864Z"/>
    </BaseIcon>
  );
}