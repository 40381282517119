import React from "react";
import { imagesFileUrl } from "../../../../../../../../config";
import InfoInputWorkout from "./InfoInputWorkout";
import Item from "./Item";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full xl:w-[1053px] px-4 pt-[20px] xl:pt-[44px] pb-[56px] xl:px-8 h-auto xl:h-[845px] mx-auto mt-[30px] xl:mb-[44px] bg-[#FFFFFF] rounded-[10px] shadow-[0px_6px_14px_px_#0000000f] flex flex-col xl:grid xl:grid-cols-2 xl:justify-between">
        <section className="w-full xl:w-[513px] mt-3 flex flex-col items-center xl:items-start">
          <section className="h-auto xl:h-[103px]">
            <h2 className=" text-[20px] text-[#191919] font-bold leading-[31px]">
              محاسبه گر آب مصرفی در ورزش چیست؟
            </h2>
            <p className=" text-[#191919]  mt-[22px] leading-[24.8px]">
              اینجا یادت میندازیم در طول روز چقدر آب خوردی!
              <br />
              شنیدی میگن آب برای سلامتی ضروریه؟
            </p>
          </section>
          <img
            src={imagesFileUrl + "bg-calcWater.png"}
            className="w-[80%] h-[50%] xl:w-[387px] xl:h-[203.87px] mt-[40px] xl:mt-[46.39px]"
            alt=""
          />
        </section>

        <InfoInputWorkout />
        <section className="h-[256px] mt-[45px]">
          <ul className="text-[#191919] font-normal flex flex-col gap-[4px]">
            <Item text="به ایجاد بزاق کمک می کنه" />
            <Item text="دمای بدن شما را تنظیم می کنه" />
            <Item text="از بافت ها، نخاع و مفاصل شما محافظت می کنه" />
            <Item text="به دفع مواد زائد از طریق تعریق، ادرار و مدفوع کمک می کنه" />
            <Item text="به حداکثر رساندن عملکرد فیزیکی کمک می کنه" />
            <Item text="به جلوگیری از یبوست کمک می کنه" />
            <Item text="به هضم غذا کمک می کنه" />
            <Item text="به جذب مواد مغذی کمک می کنه" />
          </ul>
        </section>
        <section className="h-[256px] mt-[45px]">
          <ul className=" text-[#191919] font-normal flex flex-col gap-[4px]">
            <Item text="به کاهش وزن کمک می کنه" />
            <Item text="گردش اکسیژن خون را بهبود می بخشه" />
            <Item text="به مبارزه با بیماری کمک می کنه" />
            <Item text="به افزایش انرژی کمک می کنه" />
            <Item text="به عملکرد شناختی کمک می کنه" />
            <Item text="به روشن نگه داشتن پوست کمک می کنه" />
            <Item text="به بهبود خلق و خو کمک می کنه" />
            <Item text="به جذب مواد مغذی کمک می کنه" />
          </ul>
        </section>
      </section>
    </>
  );
}
