import React from "react";


//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({setActiveBtn,activeBtn}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
    <section className="w-full xl:w-[1053px] pt-8 xl:pt-[44px] flex justify-center xl:justify-start md:justify-start gap-5 mx-auto">

        <button onClick={()=>{
          setActiveBtn(1)
        }} className={`${activeBtn===1?"text-white bg-[#5408A9]":"text-[#5408A9]"} w-[168px] h-[30px] rounded-full font-semibold text-sm border border-solid border-[#5408A9]`}>
        شاخص توده بدنی BMI
        </button>
        
        <button onClick={()=>{
          setActiveBtn(2)
        }} className={`${activeBtn===2?"text-white bg-[#5408A9]":"text-[#5408A9]"} w-[153px] h-[30px] rounded-full font-semibold text-sm border border-solid border-[#5408A9]`}>
        متابولیسم پایه BMR
        </button>
    </section>
   
    </>
  );
}