import React, { useEffect, useState } from 'react';
import Step from '../../../assets/images/basicInfo/step78910L.png';
import Progress from '../components/Progress';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setStep, activeStep, defaultValue, setDiet, saveBasicInfo }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const { t } = useTranslation();
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [reply, setReply] = useState();
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (reply === "NOT_TAKEN_DIET") {
      saveBasicInfo()
    } else if (reply === "DIETED_MORE_THAN_ONCE") {
      setStep(8);
    }
  }, [reply]);
  const setMainReply = (value) => {
    setReply(value);
    setDiet(value);

  };
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Progress stepPic={Step} activeStep={activeStep} setStep={setStep} />
      <span className="block text-center font-bold text-xl xl:text-3xl mt-7">
        چند بار رژیم گرفته اید؟
      </span>
      <section className="mt-10 w-[90%] xl:w-[500px] m-auto">
        <button
          className="bg-[#f4f4f4] rounded-[16px] h-[70px] w-full"
          onClick={() => setMainReply('NOT_TAKEN_DIET')}
        >
          رژیم نگرفته ام
        </button>
        {/*  <button className="replyBox" onClick={() => setReply(2)}>
          1 بار
        </button> */}

        <button
          className="bg-[#f4f4f4] rounded-[16px] h-[70px] w-full mt-2"
          onClick={() => setMainReply('DIETED_MORE_THAN_ONCE')}
        >
          بیش از یک بار
        </button>
      </section>
    </>
  );
}
