import React, { useState, useContext, useEffect } from 'react'
import { svgFileUrl } from '../../../../../../../../config'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ index, title }) {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='grid grid-cols-2 gap-12 mt-[25px] items-center'>
            <section className='flex justify-between'>
                <span>{index}</span>
                <span className='text-purple font-semibold'>{title}</span>
                <span>127 کالری</span>
            </section>
            <section className='flex justify-between'>
                <input placeholder='توضیحات خود را وارد کنید' type='text' className='w-[440px] border border-[#A6A9BD] rounded-[10px]' />
                <button><img src={svgFileUrl + "trash.svg"} alt='' /></button>
            </section>
        </section>
    )
}
