import React from "react";
import "./style.css";
import { Outlet } from "react-router-dom";
import SideBar from "./components/SideBar";
import Header from "./components/Header";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section id="dashBox" className="fixed w-full h-full overflow-auto pb-[100px] bg-[#f5f7f8]">
      <section className="w-[95%] xl:w-[1320px] mt-10 m-auto flex justify-between flex-wrap relative">
        <SideBar />
        <section className="w-[100%] xl:w-[1100px]">
          <Header />
          <main className="mt-5">
            <Outlet />
          </main>
        </section>

      </section>
    </section>
  );
}
