import React, { useState, useContext, useEffect } from "react";
import { imagesFileUrl } from "../../../config";
import Item from "./components/Item";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <span className="text-[14px]">افراد بدون برنامه</span>
      <section className=" mt-[14px] bg-white rounded-[16px] shadow-md">
        <section className="grid grid-cols-1 p-[20px] xl:grid-cols-4 gap-4">
          <Item image={imagesFileUrl + "/dashboard/Avatar.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar3.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar4.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar3.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar3.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar4.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar3.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar.png"} />
          <Item image={imagesFileUrl + "/dashboard/Avatar4.png"} />
        </section>
        <section className="text-center">
          <button className="w-[149px] h-[40px] border border-purple mt-[31px] mb-[32px] rounded-full ">مشاهده بیشتر</button>
        </section>
      </section>
    </>
  );
}
