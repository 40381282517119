import React, { useState, useContext, useEffect } from "react";
import { svgFileUrl } from "../../../../../config";
import ReactApexChart from "react-apexcharts";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ title, goalCalory, consumedCalory, percent }) {
  const getColor = () => {
    if (consumedCalory > goalCalory + 1000) {
      return "#ff0000";
    } else if (consumedCalory > goalCalory) {
      return "#ff9a20";
    } else {
      return "#4CC9EC";
    }
  };
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const pp = {
    series: [percent ? percent : 0],
    options: {
      chart: {
        animations: {
          enabled: true,
          easing: "linear",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "65%",
          },
          dataLabels: {
            show: false,
          },
          /*     track: {
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              blur: 4,
              opacity: 0.15
            }
          }, */
        },
      },
      stroke: {
        lineCap: "round",
      },
      fill: {
        colors: getColor(),
      },
    },
  };
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[156px] h-[235px] flex flex-col justify-between items-center">
      <span className="font-semibold text-sm w-[87px] h-[33px] border flex justify-center items-center text-black bg-white border-[#E6E8E9] rounded-full">{title}</span>
      <section className="relative">
        <section className="w-[115px] h-[115px] rounded-full bg-white absolute top-[31px] left-[20px] flex flex-col justify-center items-center">
          <section className="w-[80px] h-[89px] flex flex-col justify-between items-center">
            <span className={`block w-full text-center h-[24px] text-[22px] font-bold`} style={{ color: getColor() }}>
              {consumedCalory}
            </span>
            <span className="font-normal text-[11px] text-textGray">کالری دریافتی</span>
            <span className="block w-[73px] h-[1px] bg-[#E6E8E9]"></span>
            <span className="block w-full h-[11px] text-[14px] font-bold text-center">{goalCalory}</span>
            <span className="font-normal text-[11px] text-textGray">کالری هدف</span>
          </section>
        </section>
        {/*  <CircularProgressBar {...props} /> */}
        <ReactApexChart options={pp.options} series={pp.series} type="radialBar" height={200} />
      </section>
      <section className="h-[37px] w-[112px]  flex justify-between items-center">
        <section className="w-[43px] h-[31px]">
          <section className="w-full h-[16px] flex items-center justify-between">
            <img src={svgFileUrl + "armDamble.svg"} className="w-[16px] h-[16px]" alt="" />
            <span className="text-xs font-semibold mt-1">{0}</span>
          </section>
          <span className="font-semibold text-xs">مصرفی</span>
        </section>
        <span className="block w-[1px] h-[25px] bg-[#E6E8E9]"></span>
        <section className="w-[43px] h-[31px]">
          <section className="w-full h-[16px] flex items-center justify-between">
            <img src={svgFileUrl + "restaurant1.svg"} className="w-[16px] h-[16px]" alt="" />
            <span className="text-xs font-semibold mt-1">{consumedCalory}</span>
          </section>
          <span className="font-semibold text-xs">دریافتی</span>
        </section>
      </section>
    </section>
  );
}
