import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl } from "../../../../../config";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ onClick, title, index, activeSection }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section
        onClick={onClick}
        className={`${
          index === activeSection
            ? "bg-white shadow-[0px_4px_4px_0px_#5521B41F]"
            : "shadow-[0px_1px_4px_0px_#0000001A] bg-[#FFFFFF03]"
        } w-full  rounded-[9px] flex gap-1 xl:gap-[10px] items-center px-[3px] xl:px-3 h-[34px] xl:h-[45.51px]`}
      >
        <img
          src={baseFileUrl + "/next 1 (1).png"}
          alt=""
          className="xl:w-[21.51px] w-[13px] h-auto"
        />
        <p
          className={`${
            index === activeSection ? "text-[#5521B4]" : "text-[#3E4151]"
          } xl:leading-[24.8px] leading-[21.7px] text-sm xl:text-[16px]`}
        >
          {title}
        </p>
      </section>
    </>
  );
}
