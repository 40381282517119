import React, { useState, useEffect } from "react";
import Select from "react-select";
import { svgFileUrl } from "../../../config";
import FoodBox from "./components/FoodBox";
import axios from "axios";
import { Pagination } from "antd";
import api from "../../../api";
import Loading from "../../../components/common/componentLoading";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  const options = [
    { value: "BEFORE", label: "قبل" },
    { value: "AFTER", label: "بعد" },
    { value: "BREAKFAST", label: "صبحانه" },
    { value: "LUNCH", label: "ناهار" },
    { value: "DINNER", label: "شام" },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [foodStep, setFoodStep] = useState();
  const [recipeList, setRecipeList] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getRecipe = (currentPage) => {
    setLoading(true);
    axios
      .get(`${api.recipe.searchRecipe}?language=PERSIAN&pageSize=12&pageNo=${currentPage - 1}&foodStep=${foodStep ? foodStep?.value : ""}`, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((res) => {
        setLoading(false);
        setRecipeList(res.data.elements);
        setTotalElements(res.data.totalElements);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getRecipe(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-[100px] xl:mb-12 relative">
      <Loading loading={loading} />
      <section className="grid grid-cols-2 xl:grid-cols-5 gap-x-[23px] xl:gap-3 content-center px-[30px] py-[20px]  h-auto mx-auto  xl:h-[117px] rounded-[30px] xl:rounded-[186px] bg-gradient-to-r from-[#E4E9FE] to-[#DEBFFA] m-auto mt-14 xl:mt-12 mb-[30px] xl:mb-10">
        <section>
          <span className="font-bold xl:font-semibold text-[14px] xl:text-[16px]">وعده غذایی</span>
          <Select options={options} onChange={(value) => setFoodStep(value)} className="mt-1 text-[12px]" placeholder="انتخاب کنید" />
        </section>
        <section>
          <span className="font-bold xl:font-semibold text-[14px] xl:text-[16px]">روش پخت</span>
          <Select className="mt-1 text-[12px]" placeholder="انتخاب کنید" />
        </section>
        <section>
          <span className="font-bold xl:font-semibold text-[14px] xl:text-[16px]">نوع رژیم غذایی</span>
          <Select className="mt-1 text-[12px]" placeholder="انتخاب کنید" />
        </section>
        <section>
          <span className="font-bold xl:font-semibold text-[14px] xl:text-[16px]">ماده غذایی</span>
          <Select className="mt-1 text-[12px]" placeholder="انتخاب کنید" />
        </section>
        <section className="flex justify-end">
          <button
            onClick={() => {
              getRecipe(1);
              setCurrentPage(1);
            }}
            className="bg-purple rounded-[150px] text-white w-[122px] h-[44px] mt-6 flex justify-center items-center"
          >
            <img src={svgFileUrl + "search.svg"} alt="" />
            <span className="mr-2">جستجو</span>
          </button>
        </section>
      </section>
      <section className="w-[85%] mx-auto xl:w-[1133px] max-w-full m-auto relative">
        <section className="flex flex-col xl:flex-row xl:justify-between pr-3 md-pr-0">
          {/*  <section class="flex items-center xl:w-[800px]">
            <input checked id="default-radio-1" type="radio" value="" name="default-radio" className="w-4 h-4 text-purple bg-gray-100 border-gray-300 focus:ring-purple dark:focus:ring-purple dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label for="default-radio-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 font-normal text-sm">
              همه
            </label>
            <input checked id="default-radio-2" type="radio" value="" name="default-radio" className="w-4 h-4 mr-2 text-purple bg-gray-100 border-gray-300 focus:ring-purple dark:focus:ring-purple dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label for="default-radio-2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 font-normal text-sm">
              رژیم پروتئین زیاد
            </label>
            <input checked id="default-radio-3" type="radio" value="" name="default-radio" className="w-4 h-4 mr-2 text-purple bg-gray-100 border-gray-300 focus:ring-purple dark:focus:ring-purple dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label for="default-radio-3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 font-normal text-sm">
              وعده قبل ورزش
            </label>
          </section> */}
          <span className="order-first xl:order-last font-normal mb-[24px] xl:mb-0 text-md">{totalElements} رژیم غذایی</span>
        </section>
        <section className="mt-[100px] grid xs:grid-cols-1 md:grid-cols-4 lg:grid-cols-3 gap-x-4 gap-y-[100px]">
          {recipeList.map((item, index) => (
            <FoodBox id={item.id} calories={item.calories} carbohydrate={item.carbohydrate} protein={item.protein} fat={item.fat} duration={item.duration} title={item.foodName} image={item.photoUrl} />
          ))}
        </section>
        <Pagination className="ltr text-center mt-5 sans" defaultCurrent={1} current={currentPage} pageSize={12} onChange={(value) => setCurrentPage(value)} showSizeChanger={false} total={totalElements} />
        {/*   <section className="text-center">
          <button className="w-[149px] h-[40px] bg-purple rounded-[150px] text-white mt-10">مشاهده بیشتر</button>
        </section> */}
      </section>
    </section>
  );
}
