import React from 'react';
import { baseFileUrl } from '../../../../config';
import { Link } from 'react-router-dom';
import { useStartLink } from '../../../../hooks';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [startLink] = useStartLink();
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full h-auto xl:hidden relative bg-heroGr  overflow-hidden">
        <section className="w-[90%] h-full m-auto flex flex-col mt-[77px]">
          <img
            src={baseFileUrl + '/images/light.png'}
            alt=""
            className="absolute top-0 left-0 "
          />
          <section className="relative">
            <section className="flex w-[152px] h-[54px] glass rounded-lg absolute top-[5%] right-[5%]">
              <span className=" flex items-center text-white p-2  text-xs font-semibold">
                مصرف مناسب ویتامین‌ها و مواد معدنی
              </span>
              <img
                src={baseFileUrl + '/images/chemical.png'}
                className="w-[63.81px] h-[62.59px] mt-1"
                alt=""
              />
            </section>
            <section className="flex justify-between w-[127px] h-[47px] glass rounded-lg  absolute right-[8%] top-[80%] ">
              <span className=" flex items-center text-white text-xs font-semibold p-2">
                کنترل تناسب اندام
              </span>
              <img
                src={baseFileUrl + '/images/pressure.png'}
                className="w-[38.86px] h-[51.82px] mt-[4px] ml-[5.73px]"
                alt=""
              />
            </section>
            <section className="flex justify-between w-[109px] h-[50px] rounded-lg glass absolute left-[13%] top-[85%] ">
              <span className=" flex items-center text-white p-1 text-xs font-semibold">
                برنامه غذایی مناسب
              </span>
              <img
                src={baseFileUrl + '/images/plate.png'}
                className="w-[50px] h-[50px] mt-[5px] ml-[-21px]"
                alt=""
              />
            </section>
            <section className="flex justify-between w-[125px] h-[44px] p-1 glass rounded-lg absolute left-0 top-[10%] ">
              <span className=" flex items-center  text-white p-1  text-xs font-semibold">
                برنامه ورزشی منظم
              </span>
              <img
                src={baseFileUrl + '/images/calender.png'}
                className="w-[28.85px] h-[31.96px] "
                alt=""
              />
            </section>
            <img
              src={baseFileUrl + '/images/hero.png'}
              className="w-full  max-w-full mt-[70px] "
              alt=""
            />
          </section>
          <img
            src={baseFileUrl + '/images/damble.png'}
            className="absolute w-[289.55px] h-[255.28px] right-[-50px] top-[300px] opacity-20"
            alt=""
          />
          <section className="w-[90%] mt-[10px] mb-[96.59px] mx-auto flex flex-col items-center justify-center  text-white z-10 top-0 right-0">
            <h3 className="font-semibold text-[40px]">برای زندگیت</h3>
            <h3 className="font-semibold text-[40px]">حرکت کن !</h3>
            <p className="font-semibold leading-[24.95px] mt-[5px]">
              بادینس ، مسیری هوشمند برای سبک زندگی بهتر
            </p>
            <section className="mt-2">
              <Link to={startLink}>
                <button className="w-[118px] h-[44px] font-semibold  bg-white text-purple rounded-full">
                  بزن بریم
                </button>
              </Link>

              {/*  <button className="w-[125.04px] h-[31.95px] border-[1.47px] font-bold text-xs border-solid border-white rounded-full mr-[22.02px]">دانلود اپلیکیشن</button> */}
            </section>
          </section>
        </section>
      </section>
      <section className="hidden xl:block h-[721px] relative bg-heroGr overflow-hidden">
        <section className="container h-full m-auto flex items-center pt-[80px]">
          <img
            src={baseFileUrl + '/images/light.png'}
            alt=""
            className="absolute top-0 "
          />
          <section className="grid grid-cols-2">
            <section className="w-[412px] flex items-center justify-center  text-white z-10 top-0 right-0">
              <section>
                <h3 className="font-semibold text-[59px]">برای زندگیت</h3>
                <h3 className="font-semibold text-[59px]">حرکت کن !</h3>
                <p className="text-[24px] font-normal mt-5">
                  بادینس ، مسیری هوشمند برای سبک زندگی بهتر
                </p>
                <section className="mt-10">
                  <Link to={startLink}>
                    <button className="w-[250px] h-[60px] border-2 font-bold text-xl border-solid bg-white text-purple border-white rounded-full">
                      بزن بریم
                    </button>
                  </Link>

                  {/* <button className="w-[170px] h-[44px] border-2 font-semibold text-base border-solid border-white rounded-full mr-9">دانلود اپلیکیشن</button> */}
                </section>
              </section>
            </section>
            <section className="relative">
              <section className="flex w-[211px] h-[83px] glass rounded-2xl absolute top-[50%] ">
                <span className=" flex items-center text-white p-2 text-base font-semibold">
                  مصرف مناسب ویتامین‌ها و مواد معدنی
                </span>
                <img
                  src={baseFileUrl + '/images/chemical.png'}
                  className="w-[99px] h-[97px] mt-3"
                  alt=""
                />
              </section>
              <section className="flex justify-between w-[222px] h-[57px] rounded-2xl glass absolute right-[13%] top-[80%] ">
                <span className=" flex items-center text-white p-2 text-base font-semibold mr-3">
                  کنترل تناسب اندام
                </span>
                <img
                  src={baseFileUrl + '/images/pressure.png'}
                  className="w-[54px] h-[72px] mt-[-10px] ml-2"
                  alt=""
                />
              </section>
              <section className="flex justify-between w-[179px] h-[70px] glass rounded-2xl absolute left-[13%] top-[73%] ">
                <span className=" flex items-center w-[95px] h-[30px] text-white p-1 mt-5 mr-2 text-base font-semibold">
                  برنامه غذایی مناسب
                </span>
                <img
                  src={baseFileUrl + '/images/plate.png'}
                  className="w-[78px] h-[78px] ml-[-10px] mt-2"
                  alt=""
                />
              </section>
              <section className="flex justify-between w-[179px] h-[78px] glass rounded-2xl absolute left-0 top-[20%] ">
                <span className=" flex items-center w-full h-[30px] text-white p-1 mt-5 mr-2 text-base font-semibold">
                  برنامه ورزشی منظم
                </span>
                <img
                  src={baseFileUrl + '/images/calender.png'}
                  className="w-[50px] h-[56px] ml-3 mt-2"
                  alt=""
                />
              </section>
              <img
                src={baseFileUrl + '/images/hero.png'}
                className="w-[730px] max-w-full"
                alt=""
              />
            </section>
          </section>
          <img
            src={baseFileUrl + '/images/damble.png'}
            className="absolute bottom-[-100px] opacity-20"
            alt=""
          />
        </section>
      </section>
    </>
  );
}
