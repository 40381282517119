import React from 'react';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import './style.css'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setState }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const style = {
    /*  width: 150 */
  };
  const marks = {
    0: <strong>فعالیت کم</strong>,
    50: <strong>فعالیت متوسط</strong>,
    100: <strong>فعالیت شدید</strong>,
  };
  /* 0.3 0.4 0.5 */

  function setValue(value) {
    if (value === 0) {
      setState(0.3)
    }
    else if (value === 50) {
      setState(0.4)
    }
    else if (value) {
      setState(0.5)
    }
  }
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────


  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className='activitySelector w-[200px] m-auto xl:w-full' style={style}>
        <Slider min={0} marks={marks} step={null} onChange={setValue} defaultValue={0} />
      </section>
    </>
  );
}
