import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl } from "../../../../config";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeSection, setActiveSection] = useState();
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[1128px] w-[90%] p-5 mx-auto xl:h-[545px] h-auto xl:mb-[150px] gap-8 xl:gap-[60px] flex xl:flex-row flex-col justify-center items-center rounded-[10px] xl:rounded-[16px] bg-[linear-gradient(102.23deg,_#2141b405_5.56%,_#0eddc440_106.65%)]">
      <section className="xl:w-[570px] w-full mx-auto flex p-3 gap-4 xl:gap-0 xl:p-6 order-last xl:order-none justify-between h-auto xl:h-[391px] shadow-[10px_12px_20px_0px_#0848A929] rounded-2xl bg-[linear-gradient(240.28deg,_#F5F2FB_43.16%,_#FFFFFF_106.36%)]">
        <section className="xl:w-[269px] w-full gap-8 h-full">
          <img
            src={baseFileUrl + "/pic-salad.png"}
            alt=""
            className="w-full xl:h-[206px]"
          />
          <p className="xl:leading-[21.7px] leading-5 text-xs text-justify xl:text-sm mt-4 xl:mt-7">
            درمان تغذیه‌ای برای بیماران کلیوی شامل تنظیم رژیم غذایی به منظور
            کنترل مصرف پروتئین، سدیم، پتاسیم و فسفر است تا بار کلیه‌ها کاهش یابد
            و عملکرد کلیه‌ها بهبود یابد.
          </p>
        </section>
        <section className="xl:w-[225px] w-full gap-3 xl:gap-[14px] h-full flex flex-col">
          <Item
            onClick={() => {
              setActiveSection(1);
            }}
            activeSection={activeSection}
            index={1}
            title="رژیم مدیترانه‌ای"
          />
          <Item
            onClick={() => {
              setActiveSection(2);
            }}
            activeSection={activeSection}
            index={2}
            title="رژیم کتوژنیک"
          />
          <Item
            onClick={() => {
              setActiveSection(3);
            }}
            activeSection={activeSection}
            index={3}
            title="رژیم فستینگ"
          />
          <Item
            onClick={() => {
              setActiveSection(4);
            }}
            activeSection={activeSection}
            index={4}
            title="رژیم کتوویژن"
          />
          <Item
            onClick={() => {
              setActiveSection(5);
            }}
            activeSection={activeSection}
            index={5}
            title="رژیم سفره خانواده"
          />
          <Item
            onClick={() => {
              setActiveSection(6);
            }}
            activeSection={activeSection}
            index={6}
            title="رژیم پالئو"
          />
        </section>
      </section>
      <section className="xl:w-[398px] xl:h-[297px] flex flex-col gap-6 text-[#3E4151] leading-[24.8px]">
        <h3 className="xl:text-[32px] text-[22px] font-bold text-[#191919] leading-[34.1px] xl:leading-[49.6px]">
          معرفی رژیم‌های خاص
        </h3>
        <p className="text-sm xl:text-base">
          این دسته از رژیم‌ها که با نام رژیم‌های خاص (Special Diets) معرفی
          می‌شوند، معمولاً برای اهداف بهداشتی یا کاهش وزن خاص طراحی شده‌اند. به
          طور کلی، این رژیم‌ها به عنوان "رژیم‌های غذایی هدفمند" یا "رژیم‌های
          تخصصی" شناخته می‌شوند.
          <p className="mt-3">
            این رژیم‌ها با رویکردهای متفاوت به تغذیه، نیازهای خاص فردی را مورد
            توجه قرار می‌دهند و می‌توانند تاثیرات مثبتی بر سلامت عمومی و مدیریت
            وزن داشته باشند. انتخاب مناسب‌ترین رژیم بسته به شرایط فردی و نیازهای
            بهداشتی هر شخص متفاوت است.
          </p>
        </p>
      </section>
    </section>
  );
}
