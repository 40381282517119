import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl } from "../../../../config";
import Item from "./components/Item";
import { imagesFileUrl } from "../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className=" relative w-[90%]  xl:w-[1132px] overflow-x-clip mx-auto h-[670px] xl:h-[516.86px] mb-[50px] xl:mb-[150px] flex flex-col items-center">
      <img
        src={baseFileUrl + "/pic-secton7-exer.png"}
        alt=""
        className={`xl:w-[251px] w-[80%] left-[50%]  xl:bottom-0 bottom-[30px] translate-x-[-50%] absolute`}
      />
      <img
        src={baseFileUrl + "/Group-secton7-exer.png"}
        alt=""
        className={`xl:w-[637px] hidden xl:flex w-full absolute top-0 -z-10 xl:left-[50%] xl:translate-x-[-50%]`}
      />
      <h2 className=" mb-[38px] text-[#191919] text-[30px] xl:text-[40px] xl:leading-[62px] text-center font-bold">
        نظرات کاربران
      </h2>
      <p className=" text-center">
        تجربیات و بازخوردهای واقعی افرادی که از خدمات ما استفاده کرده‌اند، را
        بخوانید و از دیدگاه‌هایشان بهره‌مند شوید.
      </p>
      <section className=" flex mt-8 xl:mt-[40px] relative gap-8">
      <section className="xl:flex hidden justify-between z-[1000] w-[90%] mx-auto xl:w-[1132px] bottom-0 right-[50%] translate-x-[50%] xl:top-[45%] absolute ">
          <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#0000001F] active:bg-[#BB9CDD]">
            <img
              src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
              alt=""
              className="w-[8px] h-auto"
            />
          </div>
          <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#0000001F] active:bg-[#BB9CDD]">
            <img
              src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
              alt=""
              className="w-[8px] h-auto rotate-180"
            />
          </div>
        </section>
        <Item
          firstName=""
          display='hidden xl:block'
          content="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد.این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد.این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد.این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        <Item
          display='hidden xl:block'
          firstName="سارا حسینی"
          content="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد.این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد.این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد.این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        <Item
          firstName="حامد ضیایی"
          content="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد.این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد.این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد.این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
      </section>
      <section className="flex xl:hidden justify-between z-[1000] w-[30%] mx-auto xl:w-[1132px] -bottom-[5%] right-[50%] translate-x-[50%] xl:top-[45%] absolute ">
          <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#0000001F] active:bg-[#BB9CDD]">
            <img
              src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
              alt=""
              className="w-[8px] h-auto"
            />
          </div>
          <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#0000001F] active:bg-[#BB9CDD]">
            <img
              src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
              alt=""
              className="w-[8px] h-auto rotate-180"
            />
          </div>
        </section>
    </section>
  );
}
