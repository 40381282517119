import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl, imagesFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[1128px] w-[90%]  mx-auto mb-[50px] xl:mb-[150px] h-auto ">
      <section className="grid grid-cols-[40%_60%] text-xs gap-y-4 xl:gap-y-0 xl:flex w-full items-center justify-between xl:text-sm text-[#191919] ">
        <span className="">مرتب‌سازی بر اساس:</span>
        <select className="xl:w-[151px] h-[43px] xl:ml-[750px] rounded-[48px] border border-solid border-[#A6A9BD]">
          <option>محبوب‌ترین</option>
        </select>
        <span className="col-span-2 justify-self-end text-[#000814] ">۱۵ برنامه غذایی</span>
      </section>
      <section className="grid xl:grid-cols-2  mt-[40px] w-full  xl:w-[1128px]  mx-auto gap-6">
        <Item
          id={1}
          src={imagesFileUrl + "coach/zivari.png"}
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج آقای زیوری "
          backGround="bg-white"
          textColor="text-[#5521B4]"
          bgColorTag="bg-[#F9F5FE]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />

        <Item
          id={2}
          src={imagesFileUrl + "coach/hosseini.png"}
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج خانم حسینی "
          backGround="bg-white"
          textColor="text-[#5521B4]"
          bgColorTag="bg-[#F9F5FE]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        <Item
          id={3}
          src={imagesFileUrl + "coach/ganji.png"}
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج خانم گنجی "
          backGround="bg-white"
          textColor="text-[#5521B4]"
          bgColorTag="bg-[#F9F5FE]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        <Item
          id={4}
          src={imagesFileUrl + "coach/tarverdi.png"}
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج خانم تاروردی "
          backGround="bg-white"
          textColor="text-[#5521B4]"
          bgColorTag="bg-[#F9F5FE]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        <Item
          id={4}
          src={imagesFileUrl + "coach/tarverdi2.png"}
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج خانم تاروردی "
          backGround="bg-white"
          textColor="text-[#5521B4]"
          bgColorTag="bg-[#F9F5FE]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        <Item
          id={4}
          src={imagesFileUrl + "coach/tarverdi3.png"}
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج خانم تاروردی "
          backGround="bg-white"
          textColor="text-[#5521B4]"
          bgColorTag="bg-[#F9F5FE]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        <Item
          id={5}
          src={imagesFileUrl + "coach/karimi.png"}
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج آقای کریمی "
          backGround="bg-white"
          textColor="text-[#5521B4]"
          bgColorTag="bg-[#F9F5FE]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        <Item
          src="/pic-5.png"
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج عادی/ شماره ۱"
          backGround="bg-[#A6A9BD1A]"
          textColor="text-[#3E4151]"
          bgColorTag="bg-[#FFFFFF]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        <Item
          src="/pic-9.png"
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج عادی/ شماره 2"
          backGround="bg-[#A6A9BD1A]"
          textColor="text-[#3E4151]"
          bgColorTag="bg-[#FFFFFF]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        {/*  <Item
          src="/pic-6.png"
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج عادی/ شماره 3"
          backGround="bg-[#A6A9BD1A]"
          textColor="text-[#3E4151]"
          bgColorTag="bg-[#FFFFFF]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        /> */}




      </section>
    </section>
  );
}
