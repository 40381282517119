import React, { useState, useEffect } from 'react';
import api from '../../../../../api';
import Loading from '../../../../../components/common/componentLoading';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useDietStore } from '../../../../../store/dashboard/diet';
import { useAxiosWithToken } from '../../../../../hooks';
import { useBasicInfoStore } from '../../../../../store/dashboard/basicInfo';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ type }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const dietInfo = useDietStore(state => state.dietInfo)
  const reloadDietAllInfo = useDietStore(state => state.reloadDietAllInfo)
  const reloadBasicInfo = useBasicInfoStore((state) => state.reloadBasicInfo);
  const month = dietInfo?.month;
  const week = dietInfo?.week;
  const day = dietInfo?.day;
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [list, setList] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false)
  // ─── Functions ──────────────────────────────────────────────────────────────────

  const getList = () => {
    setActionLoading(true);
    useAxiosWithToken
      .get(
        api.foodsConsumed.searchCategories +
        `?monthEnum=${month}&weekEnum=${week}&dayEnum=${day}&pageNo=0&pageSize=30`
      )
      .then((res) => {
        setActionLoading(false);
        setList(res.data.elements);
      })
      .catch((err) => {
        setActionLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getList();
  }, []);
  const deleteDiet = (id) => {
    setDeleteLoading(true)
    let params = {
      "dairyId": id,
      "monthEnum": month,
      "weekEnum": week,
      "dayEnum": day,
      "typeOfMealEnum": type
    }
    useAxiosWithToken.put(api.personalProgram.removeDairy, params).then(res => {
      getList()
      setDeleteLoading(false)
      reloadDietAllInfo()
      reloadBasicInfo()
      document.getElementById("dashBox").scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }).catch(err => {
      setDeleteLoading(false)
    });
  }

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-12 relative">
      <Loading loading={actionLoading} />
      {list.length > 0 && list.map(
        (item, index) =>
          item.typeOfMealEnum === type && (
            <section className="flex justify-between items-center px-4 mt-[5px] border border-[#E8E7EB] h-[40px]  rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
              {item.mainFoodDescription}
              <Popconfirm
                title="حذف غذا"
                okButtonProps={{ className: 'bg-[red] hover:bg-silver' }}
                description="آیا مایلید رکورد مورد نظر حذف شود؟"
                onConfirm={() => deleteDiet(item.id)}
                okText="بله"
                cancelText="خیر"
                onOpenChange={() => console.log('open change')}
              >
                <Button loading={deleteLoading} type='primary' danger shape="circle" icon={<DeleteOutlined />} />
              </Popconfirm>
            </section>
          )
      )}
      {/*    {list.length===0 && <section className='text-center text-purple font-bold text-lg'>موردی یافت نشد</section>} */}

    </section>
  );
}
