import React, { useState, useEffect } from 'react'
import { imagesFileUrl } from '../../../config';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ bmi }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────


  // ─── States ─────────────────────────────────────────────────────────────────────
  const [value, setValue] = useState(0);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (bmi <= 15) {
      setValue("0%");
    }
    if (bmi > 15 && bmi < 18.5) {
      setValue("13%");
    }
    else if (bmi === 18.5) {
      setValue("24%");
    }
    else if (bmi > 18.5 && bmi < 25) {
      setValue("36%");
    }
    else if (bmi === 25) {
      setValue("48%");
    }
    else if (bmi > 25 && bmi < 30) {
      setValue("59%");
    }
    else if (bmi === 30) {
      setValue("71%");
    }
    else if (bmi > 30 && bmi < 40) {
      setValue("82%");
    }
    else if (bmi >= 40) {
      setValue("96%");
    }
  }, [bmi]);

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:w-[326px] mt-5 relative">
      <span
        className="block absolute w-[15px] h-[15px] border-[3px] shadow-2xl shadow-black border-white bg-blue-500 rounded-full top-[-18px] transition-all ease-in-out z-20"
        style={{ left: value }}
      ></span>
      <img
        src={imagesFileUrl + 'bmiBar.png'}
        alt=""
        className="w-[326px]  col-start-1 xl:col-start-2 col-end-4"
      />
      <section className="flex justify-between ltr text-sm mt-1 items-center">
        <span>15</span>
        <span>18.5</span>
        <span>25</span>
        <span>30</span>
        <span>40</span>
      </section>
    </section>
  )
}
