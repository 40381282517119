import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseFileUrl, imagesFileUrl, svgFileUrl } from "../../../config";
import ProfileBtn from "../ProfileBtn";
import MobileMenu from './components/MobileMenu'
import { useScroll } from '../../../hooks'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ mode }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [scrollYPosition] = useScroll()
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [menuClass, setMenuClass] = useState(mode === "dark" ? "#5606a8" : mode === "light" ? "#FFFFFF26" : "");
  const [menuOpen, setMenuOpen] = useState(false)
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const listenScrollEvent = (e) => {
    if (window.scrollY > 0) {
      setMenuClass("#5606a8");
    } else {
      setMenuClass(mode === "dark" ? "#5606a8" : mode === "light" ? "#FFFFFF26" : "");
    }
  };
  useEffect(() => {
    console.log(scrollYPosition)
  }, [scrollYPosition])


  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className={`hidden fixed xl:flex items-center w-full left-0 top-0 h-[80px] transition-all duration-500 z-[1000]`} style={{ backgroundColor: menuClass }}>
        <section className="container mx-auto">
          <section className="grid grid-cols-6">
            <Link className="flex justify-start items-center" to="/">
              <img src={baseFileUrl + "/images/logo.png"} className="h-[46px]" alt="" />

            </Link>
            <section className="col-span-4">
              <nav className="flex justify-center gap-5 items-center xl:h-[60px] 2xl:h-[80px] text-[15px]">
                <Link to="/" className="text-white font-normal ">
                  خانه
                </Link>
                <Link to="/exercise-plan" className="text-white font-normal  ">
                  برنامه ورزشی
                </Link>
                <Link to="/meal-plan" className="text-white font-normal ">
                  برنامه غذایی
                </Link>
                <Link to="/exercise/choose-exercise" className="text-white font-normal ">
                  حرکات ورزشی
                </Link>
                <Link to="/tools" className="text-white font-normal">
                  سلامت سنج ها
                </Link>
                <Link to="/recipe" className="text-white font-normal">
                  رسپی ها
                </Link>
                {/*  <Link to="/my-services" className="text-white font-normal">
                  خدمات ما
                </Link> */}
                <Link to="/contact-us" className="text-white font-normal">
                  تماس با ما
                </Link>
                {/*  <button onClick={() => window.scrollTo({ top: 1200, left: 0, behavior: "smooth" })} to="" className="text-white font-normal text-[14px] mr-7">
                  حرکات ورزشی
                </button> */}
                {/*   <Link to="" className="text-white font-normal text-[18px] mr-7">
                  درباره ی ما
                </Link> */}
              </nav>
            </section>
            <section className="flex justify-end items-center">
              {localStorage.firstName ? (
                <ProfileBtn mode="firstName" />
              ) : localStorage.mobileNumber ? (
                <ProfileBtn mode="mobile" />
              ) : (
                <Link to="/login" className="bg-white text-purple p-2 px-4 rounded-full">
                  ورود / ثبت نام
                </Link>
              )}
            </section>
          </section>
        </section>
      </section>
      {/* --Menu class-- */}
      <section className={`flex justify-between px-[18px] fixed xl:hidden items-center w-full left-0 top-0 h-[64px] transition-all duration-500 z-[1000]`} style={{ backgroundColor: menuClass }}>
        <img onClick={() => setMenuOpen(!menuOpen)} src={svgFileUrl + "menuWhite.svg"} className="mobileMenuBtn cursor-pointer" alt="" />
        <Link to="/">
          <img className="w-[46px] h-[46px]" src={imagesFileUrl + "logoMobile.png"} alt="" />
        </Link>
        <MobileMenu open={menuOpen} setOpen={setMenuOpen} />
      </section>
    </>
  );
}
