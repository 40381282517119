import React, { useState, useContext, useEffect } from 'react'
import { svgFileUrl } from '../../../../../../../config'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='w-full flex justify-between mt-[35px]'>
            <section className='flex justify-center items-center w-[270px]'>
                <span className='text-xl'>تعداد روز رژیم</span>
                <button className="w-[117px] h-[40px] border border-purple text-purple  rounded-full mr-[16px] flex justify-center items-center">24</button>
            </section>
            <button className="bg-purple text-white w-[164px] h-[40px] rounded-full text-sm flex justify-center gap-1 items-center">
                <img src={svgFileUrl + "plus-white.svg"} alt="" />
                انتخاب پکیج</button>
        </section>
    )
}
