import React, { useState, useContext, useEffect } from 'react'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
   
  // ─── States ─────────────────────────────────────────────────────────────────────
   const [startLink, setStartLink] = useState("")
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
   useEffect(()=>{
    if (localStorage.firstName) {
      setStartLink('/dashboard');
    } else {
      setStartLink('/basicInfo');
    }
    
     },[])
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return [startLink]
}
