import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment-jalaali';
import { Link } from 'react-router-dom';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ rowData }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="grid grid-cols-3 xl:grid-cols-9 gap-1 mt-[12px] border border-[#E8E7EB] h-[78px] xl:h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
      <section className="w-full">{rowData?.id}</section>
      <section className="w-full xl:col-span-2">{rowData?.ticketSubject}</section>
      <section className="w-full">{rowData?.departmentName}</section>
      <section className="w-full xl:col-span-2">
        {rowData?.ticketStatus === 'OPEN' && (
          <span className="flex items-center m-auto justify-center font-normal w-[69px] h-[26px] rounded-full bg-[#FEEAF3] text-[#F02484] ">
            باز
          </span>
        )}
        {rowData?.ticketStatus === 'IN_PROGRESS' && (
          <span className="flex items-center m-auto justify-center font-normal w-[98px] h-[26px] rounded-full bg-[#FFF0E6] text-[#FB8733]">
            در حال بررسی
          </span>
        )}
        {rowData?.ticketStatus === 'RESOLVED' && (
          <span className="flex items-center justify-center m-auto font-normal w-[115px] h-[26px] rounded-full bg-[#E9F8ED] text-[#21B249]">
            پاسخ داده شده
          </span>
        )}
        {rowData?.ticketStatus === 'CLOSED' && (
          <span className="flex items-center justify-center m-auto font-normal w-[69px] h-[26px] rounded-full bg-[#EEFAFE] text-[#0097C1]">
            بسته
          </span>
        )}

      </section>
      <section className="w-full">{moment(rowData?.createdOn).format("jYYYY/jMM/jDD")}</section>
      <section className="w-full xl:col-span-2">
        <Link to={`/dashboard/ticketchat/${rowData?.id}`}>
          <button className="w-[83px] h-[30px] bg-purple text-white rounded-full">
            مشاهده
          </button>
        </Link>

      </section>
    </section>
  );
}
