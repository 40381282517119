import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl } from "../../../../config";
import { imagesFileUrl } from "../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[1128px] w-[90%] mb-[50px] xl:mb-[150px] relative h-auto xl:h-[334px] overflow-hidden gap-[30px]  flex-col xl:flex-row flex rounded-[15px] radialGradient mx-auto">
      <section className="xl:w-[567px] order-last xl:order-none h-full flex flex-col justify-between py-10 px-8">
        <h3 className="text-white font-bold text-[22px] leading-[34.1px]">
          پکیج جامع رژیم غذایی و ورزشی برای{" "}
          <span className="inline-block text-transparent  bg-[linear-gradient(270.85deg,_#00FFB3_65.85%,_#A5F81F_98.16%)] bg-clip-text">
            تناسب اندام پایدار
          </span>
        </h3>
        <p className=" xl:h-[112px] font-normal text-sm leading-[24.8px] text-white">
          برای تثبیت رژیم غذایی، ترکیب برنامه‌های غذایی متعادل با فعالیت‌های
          ورزشی منظم ضروری است. این ترکیب به افزایش متابولیسم، بهبود عملکرد بدن،
          و دستیابی به نتایج پایدار در کاهش وزن و سلامت کلی کمک می‌کند. این پکیج{" "}
          <span className="font-semibold">
            ترکیبی از برنامه‌های غذایی متعادل و تمرینات ورزشی منظم
          </span>{" "}
          است که به شما کمک می‌کند وزن خود را پایدار نگه داشته و سلامت خود را
          حفظ کنید.
        </p>
        <button className="bg-white w-[142px] h-[49px] flex justify-center items-center text-[#5521B4] font-bold mt-3 text-[18px] rounded-[150px] ">
          بزن بریم!
        </button>
      </section>
      <img
        src={baseFileUrl + "/pic-bg-package-1.png"}
        alt=""
        className="h-full hidden xl:block xl:w-[477px] z-10"
      />
      <img
        src={imagesFileUrl + "/pic-mobile-bg.png"}
        alt=""
        className="h-full xl:hidden xl:w-[477px] z-10"
      />
      <img
        src={baseFileUrl + "/pic-bg-package-2.png"}
        alt=""
        className="h-full absolute xl:w-[986px] -z-10  left-0 "
      />
      <img
        src={baseFileUrl + "/pic-bg-package-3.png"}
        alt=""
        className=" absolute left-[10%] xl:left-[45%] top-[40%] w-[20%] xl:top-[20px] xl:w-[80px] "
      />
      <img
        src={baseFileUrl + "/pic-bg-package-4.png"}
        alt=""
        className="h-full absolute xl:w-[986px] z-10  right-0 "
      />
    </section>
  );
}
