import React from 'react';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ fill }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 2.75641C0.25 2.15797 0.487728 1.58405 0.910887 1.16089C1.33405 0.737728 1.90797 0.5 2.50641 0.5H13.9936C14.592 0.5 15.166 0.737728 15.5891 1.16089C16.0123 1.58405 16.25 2.15797 16.25 2.75641V10.9615C16.25 11.56 16.0123 12.1339 15.5891 12.5571C15.166 12.9802 14.592 13.2179 13.9936 13.2179H4.93595C4.62415 13.2179 4.32959 13.3599 4.13513 13.6036L2.22333 15.9937C1.56856 16.811 0.25 16.349 0.25 15.3021V2.75641Z"
        fill={fill}
      />
    </svg>
  );
}
