import React, { useState, useContext, useEffect } from 'react'

import { svgFileUrl } from '../../../../../../../../../config'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='flex justify-between'>
            <section className='flex justify-center items-center'>
                <span className='text-[#3E4151]'>انتخاب روز:</span>
                <section className='w-[555px] h-[38px] mr-[24px] flex justify-between'>
                    <button className='w-[92px] h-[38px] bg-purple text-white rounded-full'>روز اول</button>
                    <button className='w-[92px] h-[38px] text-purple border border-purple rounded-full'>روز دوم</button>
                    <button className='w-[92px] h-[38px] text-purple border border-purple rounded-full'>روز سوم</button>
                    <button className='w-[92px] h-[38px] text-purple border border-purple rounded-full'>روز چهارم</button>
                    <button className='w-[92px] h-[38px] text-purple border border-purple rounded-full'>روز پنجم</button>
                </section>
            </section>
            <button className="bg-purple text-white w-[164px] h-[40px] rounded-full text-sm flex justify-center gap-1 items-center">
                <img src={svgFileUrl + "plus-white.svg"} alt="" />
                افزودن تمرین</button>
        </section>
    )
}
