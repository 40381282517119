import React, { useState, useContext, useEffect } from 'react'
import Header from "./components/Header";
import UserInfo from "./components/UserInfo";
import SpecialSickness from "./components/SpecialSickness";
import SpecialSicknessDtl from "./components/SpecialSicknessDtl";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className="min-h-[400px] mt-[40px] py-[32px] px-[26px] bg-white rounded-[16px] shadow-md">
            <Header />
            <UserInfo />
            <SpecialSickness />
            <SpecialSicknessDtl />
        </section>
    )
}
