import React from 'react';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import './style.css'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setState }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const style = {};
  const marks = {
    0: <strong>{"<15%"}</strong>,
    20: <strong>{"15-20%"}</strong>,
    40: <strong>{"21-25%"}</strong>,
    60: <strong>{"26-31%"}</strong>,
    80: <strong>{"31-40%"}</strong>,
    100: <strong>{">40%"}</strong>,
  };
  /* 0.3 0.4 0.5 */

  function setValue(value) {
    if (value === 0) {
      setState(1)
    }
    else if (value === 20) {
      setState(2)
    }
    else if (value === 40) {
      setState(3)
    }
    else if (value === 60) {
      setState(4)
    }
    else if (value === 80) {
      setState(5)
    }
    else if (value === 100) {
      setState(6)
    }

  }
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────


  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className='bodyFatSelector' style={style}>
        <Slider min={0} marks={marks} step={null} onChange={setValue} defaultValue={0} />
      </section>
    </>
  );
}
