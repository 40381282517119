import React, { useState, useContext, useEffect } from 'react';
import './style.css';
import { calcBMI } from '../../../../helper';
import BMIRangeBar from '../../../../components/common/BMIRangeBar';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  defaultValue,
  defaultGoalValue,
  setWeight,
  setGoalWeight,
  weight,
  goalWeight,
  height,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const min = 35;
  const max = 250;

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [myWeight, setMyWeight] = useState(
    defaultValue ? defaultValue : 35
  ); /* cm */
  const [myGoalWeight, setMyGoalWeight] = useState(
    defaultGoalValue ? defaultGoalValue : 35
  );
  const [lb, setLb] = useState();
  const [goalLb, setGoalLb] = useState();
  const [unit, setUnit] = useState('kg');
  const [targetInfo, setTargetInfo] = useState('');
  const [goalBmi, setGoalBmi] = useState(0);
  const [bmi, setBmi] = useState(0);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    let num = String(myWeight * 0.45359237);
    let goalNum = String(myGoalWeight * 0.45359237);
    setLb(num.slice(0, 4));
    setGoalLb(goalNum.slice(0, 4));
    if (myWeight > myGoalWeight) {
      setTargetInfo('هدف شما کاهش وزن است');
    } else if (myWeight < myGoalWeight) {
      setTargetInfo('هدف شما افزایش وزن است');
    } else if (myWeight === myGoalWeight) {
      setTargetInfo('هدف شما تثبیت وزن است');
    }
  }, [myWeight, myGoalWeight]);
  useEffect(() => {
    setBmi(calcBMI(height, weight));
    setGoalBmi(calcBMI(height, goalWeight));
  }, [weight, height, goalWeight]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const setAllWeight = (value) => {
    setWeight(value);
    setMyWeight(value);
    if (!value) {
      setWeight(value);
    } else {
      setWeight(value);
      setMyWeight(value);
    }
  };
  const setGoalAllWeight = (value) => {
    setGoalWeight(value);
    setMyGoalWeight(value);
    if (!value) {
      setGoalWeight(value);
    } else {
      setGoalWeight(value);
      setMyGoalWeight(value);
    }
  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="weightPicker">
        <section className="unitBox">
          <button
            className={`item ${unit === 'kg' ? 'active' : ''}`}
            onClick={() => setUnit('kg')}
          >
            kg
          </button>
          <button
            className={`item ${unit === 'lb' ? 'active' : ''}`}
            onClick={() => setUnit('lb')}
          >
            lb
          </button>
        </section>
        <section className="flex">
          <section className="rangeWrapper">
            <section className="wightInfo">
              <span className="text-[25px] ml-2">وزن فعلی شما:</span>
              {unit === 'kg' ? myWeight : lb}
              <span style={{ fontSize: 14 }} className="rtl:mr-2 ltr:ml-2">
                {unit === 'kg' ? 'کیلوگرم' : 'پوند'}
              </span>
            </section>
            <input
              type="range"
              min={String(min)}
              max={String(max)}
              value={myWeight}
              onChange={(e) => setAllWeight(parseInt(e.target.value))}
            />
            <section className="weightRuler">
              <span className="item" style={{ marginTop: 0 }}></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              {/*  <span className="item-big"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item-big"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item-big"></span>
            <span className="item"></span>
            <span className="item"></span> */}
            </section>
            <section className="flex justify-between items-center font-bold text-2xl mt-2 rtl">
              <section className='flex'>
                <span className="rtl">BMI وزن فعلی شما: </span>
                <span className="mr-3 text-[35px] text-purple"> {bmi}</span>
              </section>
              <BMIRangeBar bmi={bmi} />
            </section>
          </section>
          <section className="rangeWrapper">
            <section className="wightInfo">
              <span className="text-[25px] ml-2">وزن هدف شما:</span>
              {unit === 'kg' ? myGoalWeight : goalLb}
              <span style={{ fontSize: 14 }} className="rtl:mr-2 ltr:ml-2">
                {unit === 'kg' ? 'کیلوگرم' : 'پوند'}
              </span>
            </section>
            <input
              type="range"
              min={String(min)}
              max={String(max)}
              value={myGoalWeight}
              onChange={(e) => setGoalAllWeight(parseInt(e.target.value))}
            />
            <section className="weightRuler">
              <span className="item" style={{ marginTop: 0 }}></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item-big"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              <span className="item"></span>
              {/*  <span className="item-big"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item-big"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item-big"></span>
            <span className="item"></span>
            <span className="item"></span> */}
            </section>
            <section className="flex justify-between items-center font-bold text-2xl mt-2 rtl">
              <section className='flex'>
                <span className="rtl">BMI وزن هدف شما: </span>
                <span className="mr-3 text-[35px] text-purple"> {goalBmi}</span>
              </section>
              <BMIRangeBar bmi={goalBmi} />
            </section>
          </section>
        </section>

        <section
          className={`flex justify-center mt-[-60px] mb-2 text-lg  ${
            myWeight > myGoalWeight
              ? 'text-green-500'
              : myWeight === myGoalWeight
              ? 'text-purple'
              : 'text-blue-500'
          } font-bold`}
        >
          {targetInfo}
        </section>
      </section>
    </>
  );
}
