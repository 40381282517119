import React, { useState } from "react";
import Contents from "./components/Contents";
import Tabs from "./components/Tabs";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState(1);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-[90%] xl:w-[1132px] mb-[30px] xl:mb-[100px] mx-auto ">
        <section className="w-full mx-auto overflow-x-scroll">
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        </section>

        <Contents activeTab={activeTab} />
      </section>
    </>
  );
}
