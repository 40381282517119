import React from 'react';
import { svgFileUrl } from '../../../../config';
import ReactApexChart from 'react-apexcharts';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ title, goalCalory, consumedCalory, percent }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const pp = {
    series: [percent ? percent : 0],
    options: {
      chart: {
        animations: {
          enabled: true,
          easing: 'linear',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: false,
          },
          /*     track: {
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              blur: 4,
              opacity: 0.15
            }
          }, */
        },
      },
      stroke: {
        lineCap: 'round',
      },
      fill: {
        colors: '#4CC9EC',
      },
    },
  };
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[225px] h-[159px] flex justify-between items-center">
      <section className="h-[159px] w-[55px] flex flex-col justify-center">
        <section className="w-[43px] h-[31px] ">
          <section className="w-full h-[16px] flex items-center justify-between">
            <img
              src={svgFileUrl + 'restaurant1.svg'}
              className="w-[16px] h-[16px]"
              alt=""
            />
            <span className="text-xs font-semibold mt-1">{consumedCalory}</span>
          </section>
          <span className="font-semibold text-xs">دریافتی</span>
        </section>
        <section className="block w-[40px] h-[1px] bg-[#E6E8E9] mt-2"></section>

        <section className="w-[43px] h-[31px] mt-1">
          <section className="w-full h-[16px] flex items-center justify-between">
            <img
              src={svgFileUrl + 'armDamble.svg'}
              className="w-[16px] h-[16px]"
              alt=""
            />
            <span className="text-xs font-semibold mt-1">67</span>
          </section>
          <span className="font-semibold text-xs">مصرفی</span>
        </section>
      </section>
      {/* -----------------------Chart-------- */}
      <section className="relative">
        <section className="w-[115px] h-[115px] rounded-full bg-white absolute top-[31px] left-[31px] flex flex-col justify-center items-center">
          <section className="w-[80px] h-[89px] flex flex-col justify-between items-center">
            <span className="block w-full text-center h-[24px] text-[22px] font-bold text-[#0097C1]">
              {goalCalory ? goalCalory - consumedCalory : 0}
            </span>
            <span className="font-normal text-[11px] text-textGray">
              کالری باقی مانده
            </span>
            <span className="block w-[73px] h-[1px] bg-[#E6E8E9]"></span>
            <span className="block w-full h-[11px] text-[14px] font-bold text-center">
              {goalCalory}
            </span>
            <span className="font-normal text-[11px] text-textGray">
              کالری هدف
            </span>
          </section>
        </section>
        {/*  <CircularProgressBar {...props} /> */}
        <ReactApexChart
          options={pp.options}
          series={pp.series}
          type="radialBar"
          height={200}
        />
      </section>
    </section>
  );
}
