import React from "react";
import WaterCalculator from "./WaterCalculatorContent";
import BMI from "./BmiContent";
import LBM from "./LBMContent";
import Planner from "./PlannerContent";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ activeTab }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {activeTab === 1 && <WaterCalculator />}
      {activeTab === 2 && <BMI />}
      {activeTab === 3 && <LBM />}
      {activeTab === 4 && <Planner />}
    </>
  );
}
