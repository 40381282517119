import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  const location = useLocation();
  TopBarProgress.config({
    barColors: {
      0: '#ff7f50',
      '1.0': '#ff7f50',
    },
    barThickness: 5,
    shadowBlur: 2,
  });
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState('');
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    if (location.pathname === prevLoc) {
      setPrevLoc('');
    }
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return <>{progress ? <TopBarProgress /> : ''}</>;
}
