import { useEffect, useState } from 'react';
import Textbox from '../../../../components/common/inputs/Textbox';
import { imagesFileUrl } from '../../../../config';
import { mobile_Regex, showNotify } from '../../../../helper';
import '../../style.css';
import axios from 'axios';
import api from '../../../../api';
import { Link } from 'react-router-dom';
import { NotifyMessage, NotifyType } from '../../../../enums';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setPanelMode, mobileNumber, setMobileNumber }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [btnDisable, setBtnDisable] = useState(true);
  const [loading, setLoading] = useState(false);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (mobile_Regex.test(mobileNumber)) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [mobileNumber]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const sendMobileNumber = () => {
    setLoading(true);
    axios
      .post(api.auth.sendMobileNumber, { mobileNumber: mobileNumber })
      .then((res) => {
        setLoading(false);
        setPanelMode('activeCode');
      })
      .catch((e) => {
        setLoading(false);
        showNotify(NotifyMessage.GLOBAL_ERROR, NotifyType.ERROR);
      });
  };
  const handleKeyUp = (e) => {
    if (e.keyCode === 13 && !btnDisable) {
      sendMobileNumber();
    }
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="flex flex-col items-center justify-center h-[530px] w-[90%] lg:w-[420px]  rounded-lg py-8 px-6 text-gray-200 bg-white z-10">
      <section className="w-[160px] h-[160px] rounded-lg mb-5 p-2  bg-white">
        <Link to="/">
          <img src={imagesFileUrl + 'logoWhite.png'} alt="" />
        </Link>
      </section>
      {/* <h2 className="mb-[20px] text-[30px] font-semibold  text-white">
        خوش آمدید!
      </h2> */}
      <section className="mb-[9px] flex gap-1 text-purple">
        <span className="title">ورود | ثبت نام</span>
        {/* <span>|</span> <span>ثبت نام</span> */}
      </section>
      <h2 className="title text-purple mb-[35px]">
        برای ادامه شماره موبایل خود را وارد نمایید
      </h2>
      <label className='w-full mb-1 text-[12px]'>
        شماره موبایل :
      </label>
      <Textbox

        title="0912 XXXXX"
        type="tel"
        focus={true}
        style={{ height: "48px", fontSize: "20px", textAlign: "center" }}
        value={mobileNumber}
        placeHolder="0912 XX XX XXX"
        onKeyUp={handleKeyUp}
        onChange={(e) => setMobileNumber(e.target.value)}
      />
      <section className="flex text-[12px] justify-center items-center mt-3">
        <label className="text-black">
          ورود شما به معنای پذیرش شرایط بادینس و قوانین حریم‌ خصوصی است
        </label>
      </section>
      <button
        onClick={() => {
          sendMobileNumber();
        }}
        disabled={btnDisable}
        className="mb-[40px]  mt-[30px] w-[210px] rounded-full flex justify-center items-center bg-gradient-to-t from-[#f02483] to-[#5408aa] py-3 text-sm text-white  xl:mb-[40px] xl:mt-[30px]  xl:px-4 xl:py-[0.7rem] xl:text-lg transition-all disabled:opacity-50"
      >
        تایید و دریافت کد
        {loading && <div className="lds-dual-ring"></div>}
      </button>
    </section>
  );
}
