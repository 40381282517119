import axios from "axios";
import { showNotify } from "../helper";
import { NotifyMessage, NotifyType } from "../enums";



const useAxiosWithToken = axios.create();
useAxiosWithToken.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token;
  return config;
});
useAxiosWithToken.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.code === "ERR_NETWORK") {
      showNotify(NotifyMessage.NETWORK_ERROR, NotifyType.ERROR)
    }
    if (error) {
      if (error?.response?.status === 401) {
        window.location.href = "/login";
        localStorage.clear()
      }
      else if (error?.response?.status === 500) {
        showNotify(NotifyMessage.GLOBAL_ERROR, NotifyType.ERROR)
      }
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    return Promise.reject(error);
  },
);
export default useAxiosWithToken;
