import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:mb-[150px] my-[60px] h-auto xl:mt-[150px]">
      <h3 className="text-center leading-[34.1px] xl:leading-[49.6px] text-[#191919] font-bold text-[22px] xl:text-[32px]">
        رژیم‌های تناسب اندام
      </h3>
      <p className="text-center xl:block hidden leading-[24.8px] mt-8 text-[#3E4151] font-bold text-[16px]">
        با استفاده از ورزش‌های تناسب اندام، به بهترین نتایج در تناسب اندام دست
        یابید.
      </p>
      <section className="grid xl:grid-cols-3 mt-8  xl:mt-[64px] w-[90%] xl:w-[1184px] xl:px-8 mx-auto gap-6">
        <Item
          src="/images/weight.png"
          price="300,000"
          title="کالری شمار روزانه"
          backGround="bg-[#FEF4F9]"
          border="border-[#5521B40D]"
          text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
        />
        <Item
          src="/images/teenager.png"
          price="300,000"
          title="رژیم نوجوانان"
          backGround="bg-[#F6FCFE]"
          border="border-[#5521B40D]"
          text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
        />
        <Item
          src="/images/Athletes.png"
          price="300,000"
          title="رژیم ورزشکاران"
          backGround="bg-[#F9F5FE]"
          border="border-[#5521B40D]"
          text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
        />
        <section className="xl:col-span-3 flex-col xl:flex-row flex gap-6">
          <Item
            flex="xl:flex-row flex-col"
            height="xl:h-[270px] h-auto"
            absolute="xl:absolute sticky xl:-top-[5px] xl:left-0"
            width="xl:w-[348px] w-full"
            src="/images/kettlebell.png "
            price="300,000"
            title="رژیم کالری شماری"
            backGround="bg-[#F9F5FE]"
            border="border-[#5521B40D]"
            text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
          />
          <Item
            height="xl:h-[270px] h-auto"
            absolute="xl:absolute sticky xl:-top-[25px]  xl:left-0"
            width="xl:w-[252px] w-full"
            flex="xl:flex-row flex-col"
            src="/images/shape-mother.png"
            price="300,000"
            title="رژیم شیردهی"
            backGround="bg-[#FEF4F9]"
            border="border-[#5521B40D]"
            text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
          />
        </section>

        <Item
          src="/images/cherry.png"
          price="300,000"
          title="رژیم افزایش وزن"
          backGround="bg-[#FEF4F9]"
          border="border-[#5521B40D]"
          text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
        />
        <Item
          src="/images/pregnant.png"
          price="300,000"
          title="رژیم بارداری"
          backGround="bg-[#F9F5FE]"
          border="border-[#5521B40D]"
          text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
        />
        <Item
          src="/images/spoon.png"
          price="300,000"
          title="رژیم کاهش وزن"
          backGround="bg-[#F6FCFE]"
          border="border-[#5521B40D]"
          text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
        />
      </section>
    </section>
  );
}
