import React, { useState, useContext, useEffect } from "react";
import { Progress } from "antd";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ weight, percent, title, color, unit, ...rest }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="flex justify-between items-center w-full" {...rest}>
      <section className="w-[150px] xl:w-[218px] mt-4 xl:mt-0">
        <section className="font-normal text-sm text-left">{weight} {unit ? unit : "گرم"}</section>
        <Progress status="active" strokeColor={color} showInfo={false} style={{ direction: "ltr" }} percent={percent} />
      </section>
      <span className={`block w-[80px] font-bold text-sm mr-3 mt-4 text-[${String(color)}]`}>{title}</span>
    </section>
  );
}
