import React, { useState, useContext, useEffect } from 'react'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <table class="border-collapse border border-slate-200 w-full mt-[40px]">
            <thead>
                <tr className='bg-[#F1E8FC] text-[#3E4151]'>
                    <th className='p-[24px]'>شماره</th>
                    <th className='p-[24px] w-[150px]'>نام غذا</th>
                    <th className='p-[24px]'>کالری</th>
                    <th className='p-[24px]'>توضیحات</th>
                </tr>
            </thead>
            <tbody>
                <tr className='text-center text-xs'>
                    <td className='p-[24px]'>1</td>
                    <td className='p-[24px]'>املت</td>
                    <td className='p-[24px]'>165</td>
                    <td className='p-[24px]'>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و</td>
                </tr>
                <tr className='text-center text-xs bg-[#F9F5FE]'>
                    <td className='p-[24px]'>2</td>
                    <td className='p-[24px]'>عدسی</td>
                    <td className='p-[24px]'>109</td>
                    <td className='p-[24px]'>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراح</td>
                </tr>
                <tr className='text-center text-xs'>
                    <td className='p-[24px]'>3</td>
                    <td className='p-[24px]'>تخم مرغ</td>
                    <td className='p-[24px]'>500</td>
                    <td className='p-[24px]'>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با و</td>
                </tr>
            </tbody>
        </table>
    )
}
