import React, { useState } from 'react'
import Modal from '../../../../../components/common/Modal'
import Button from '../../../../../components/common/Button';
import TypeSelector from './TypeSelector'
import { useAxiosWithToken } from '../../../../../hooks'
import api from '../../../../../api';
import { showNotify } from '../../../../../helper';
import { NotifyMessage, NotifyType } from '../../../../../enums';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ open, closeModal, rowData }) {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────
    const [gram, setGram] = useState()
    const [type, setType] = useState("BREAKFAST")
    const [actionLoading, setActionLoading] = useState(false)
    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────
    const addFood = () => {
        setActionLoading(true);
        let params = {
            typeOfMealEnum: type,
            recipeId: rowData.id,
            consumedGram: gram,
        };

        useAxiosWithToken.put(api.personalProgram.addDairyToPersonalProgram, params)
            .then((res) => {
                setActionLoading(false);
                closeModal();
                showNotify(NotifyMessage.SUCCESS_ACTION, NotifyType.SUCCESS)
            })
            .catch((err) => {
                setActionLoading(false);
            });
    };
    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <Modal width={600} title="افزودن به برنامه غذایی" open={open} onClose={closeModal}>
            <section className="flex items-center justify-between mt-4">
                <span className="font-bold text-sm text-purple">
                    {rowData?.foodName}
                </span>
                <span className="font-normal text-sm">
                    {'به ازای صد گرم - ' + rowData?.calories + ' کالری'}
                </span>
            </section>
            <section className="mt-5 relative">
                <span className="absolute left-5 top-[10px] text-sm">گرم</span>
                <input
                    onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, '');
                        setGram(value);
                    }}
                    maxLength={20}
                    value={gram}
                    type="text"
                    className="w-full xl:w-full h-[40px] mx-auto border border-[#E6E8E9] text-sm rounded-full pr-3 pl-12"
                    placeholder="مقدار مصرف خود را به گرم وارد کنید"
                />
            </section>
            {gram > 3000 && <span className='block text-red-600 font-light text-sm pr-3 mt-2'>مقدار ورودی نباید بیشتر از 3000 گرم باشد</span>}
            <TypeSelector type={type} setType={setType} />
            <section className="ltr">
                <Button
                    disabled={!(gram > 0 && gram <= 3000)}
                    onClick={addFood}
                    loading={actionLoading}
                    type="primary"
                    round
                    className="w-[100px]  bg-purple text-white rounded-full font-normal text-sm mr-auto mt-4"
                >
                    ذخیره
                </Button>
            </section>
        </Modal>
    )
}
