import React, { useState, useContext, useEffect } from "react";
import { CircularProgressBar } from "@tomik23/react-circular-progress-bar";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ percent, color, title, value }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const props = {
    percent: percent, // is require
    colorSlice: color ? color : "#4CC9EC",
    colorCircle: "#E6E8E9",
    fontWeight: 400,
    size: 96,
    stroke: 8,
    strokeBottom: 8,
    speed: 200,
    rotation: -90,
    round: true,
    number: true,
  };

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <span className={`text-[${String(color)}] text-[16px] font-bold block w-full text-center`}>{title}</span>
      <span className={`block text-center font-bold text-lg text-[${color}]`}>{value}</span>
      {/* <CircularProgressBar {...props} /> */}
    </>
  );
}
