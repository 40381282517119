import React from 'react';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ fill }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1250_654)">
        <path
          d="M14.4612 2.86312L16.1372 3.19812C16.2272 3.21612 16.3012 3.28212 16.3272 3.37112C16.3404 3.41435 16.3415 3.46034 16.3306 3.50418C16.3197 3.54803 16.2971 3.58809 16.2652 3.62012L14.8922 4.99412C14.7279 5.15809 14.5053 5.25016 14.2732 5.25012H13.1342L10.2742 8.11112C10.3288 8.31714 10.3388 8.53242 10.3037 8.74263C10.2685 8.95283 10.1889 9.15313 10.0703 9.33018C9.95165 9.50723 9.79664 9.65696 9.61559 9.7694C9.43454 9.88184 9.23161 9.95441 9.02031 9.98227C8.80901 10.0101 8.59421 9.99265 8.3902 9.93098C8.18619 9.86932 7.99766 9.76489 7.83718 9.62465C7.6767 9.48441 7.54794 9.31158 7.45949 9.11767C7.37104 8.92377 7.32493 8.71324 7.32422 8.50012C7.32437 8.27001 7.37742 8.04302 7.47928 7.83668C7.58114 7.63034 7.72908 7.45019 7.91166 7.31013C8.09424 7.17008 8.30657 7.07388 8.53226 7.02897C8.75794 6.98406 8.99093 6.99164 9.21322 7.05112L12.0742 4.18912V3.05212C12.0742 2.82012 12.1662 2.59712 12.3302 2.43312L13.7042 1.05912C13.7362 1.02725 13.7763 1.00465 13.8202 0.993735C13.864 0.982818 13.91 0.983988 13.9532 0.997119C14.0422 1.02312 14.1082 1.09712 14.1262 1.18712L14.4612 2.86312Z"
          fill={fill}
        />
        <path
          d="M2.8249 8.50078C2.82592 9.35874 3.01094 10.2065 3.36746 10.9869C3.72399 11.7673 4.24373 12.4621 4.89162 13.0245C5.53952 13.587 6.3005 14.0039 7.12323 14.2472C7.94597 14.4906 8.81132 14.5546 9.66091 14.4351C10.5105 14.3155 11.3246 14.0152 12.0482 13.5542C12.7719 13.0933 13.3882 12.4826 13.8558 11.7632C14.3233 11.0438 14.6311 10.2325 14.7584 9.38407C14.8858 8.53561 14.8297 7.66971 14.5939 6.84478C14.5608 6.7484 14.5477 6.64629 14.5553 6.54468C14.5629 6.44306 14.5911 6.34405 14.6382 6.25369C14.6853 6.16333 14.7503 6.08349 14.8293 6.01905C14.9082 5.95461 14.9994 5.90691 15.0974 5.87885C15.1954 5.85079 15.298 5.84297 15.3991 5.85585C15.5002 5.86873 15.5976 5.90204 15.6854 5.95377C15.7732 6.0055 15.8495 6.07455 15.9098 6.15673C15.9701 6.23891 16.013 6.33249 16.0359 6.43178C16.4924 8.0293 16.4057 9.73319 15.7893 11.2761C15.173 12.819 14.0619 14.1137 12.6303 14.957C11.1987 15.8003 9.52774 16.1445 7.87945 15.9356C6.23115 15.7267 4.69884 14.9765 3.5229 13.8028C2.34824 12.6271 1.59727 11.0947 1.3879 9.44599C1.17853 7.79732 1.52261 6.12579 2.36612 4.69385C3.20963 3.26191 4.5048 2.15064 6.04829 1.53452C7.59178 0.918395 9.29618 0.83231 10.8939 1.28978C11.0841 1.34539 11.2446 1.47404 11.3403 1.64763C11.436 1.82121 11.459 2.02561 11.4045 2.21615C11.3499 2.4067 11.2221 2.56789 11.0491 2.66451C10.876 2.76112 10.6717 2.78531 10.4809 2.73178C9.58748 2.47544 8.64673 2.43014 7.73281 2.59944C6.8189 2.76874 5.9568 3.14802 5.21449 3.70737C4.47217 4.26673 3.86994 4.99086 3.45526 5.82269C3.04057 6.65453 2.82478 7.57131 2.8249 8.50078Z"
          fill={fill}
        />
        <path
          d="M5.8247 8.49974C5.82476 9.04633 5.97405 9.58253 6.25646 10.0505C6.53888 10.5185 6.9437 10.9005 7.42727 11.1553C7.91083 11.4101 8.45479 11.528 9.00046 11.4964C9.54614 11.4647 10.0728 11.2847 10.5237 10.9757C10.9745 10.666 11.332 10.2389 11.5576 9.7406C11.7832 9.24234 11.8683 8.69187 11.8037 8.14874C11.7862 8.01694 11.8042 7.88287 11.8557 7.76032C11.9073 7.63777 11.9906 7.53118 12.097 7.45152C12.2035 7.37187 12.3292 7.32202 12.4614 7.30712C12.5935 7.29222 12.7272 7.3128 12.8487 7.36674C12.9702 7.41998 13.0754 7.5045 13.1536 7.61166C13.2317 7.71882 13.2801 7.8448 13.2937 7.97674C13.4024 8.90484 13.2194 9.84375 12.7702 10.6631C12.321 11.4825 11.6279 12.1418 10.787 12.5494C9.94618 12.957 8.99929 13.0927 8.07779 12.9377C7.1563 12.7827 6.30591 12.3446 5.64466 11.6844C4.98341 11.0242 4.5441 10.1744 4.3877 9.25318C4.2313 8.33192 4.36557 7.38482 4.77188 6.54334C5.17819 5.70186 5.83638 5.00773 6.65509 4.55731C7.4738 4.10688 8.41243 3.92249 9.3407 4.02974C9.44009 4.03896 9.53665 4.06793 9.6247 4.11496C9.71275 4.16198 9.79053 4.2261 9.85348 4.30358C9.91643 4.38105 9.96328 4.47031 9.99129 4.56612C10.0193 4.66193 10.0279 4.76237 10.0166 4.86155C10.0053 4.96073 9.97426 5.05665 9.92539 5.1437C9.87652 5.23074 9.81077 5.30715 9.73199 5.36845C9.6532 5.42975 9.56298 5.47471 9.4666 5.50069C9.37021 5.52667 9.26961 5.53315 9.1707 5.51974C8.75035 5.47094 8.32444 5.51161 7.92093 5.63909C7.51743 5.76657 7.14545 5.97797 6.82944 6.25942C6.51344 6.54086 6.26055 6.88597 6.08738 7.27208C5.91421 7.65819 5.82469 8.07657 5.8247 8.49974Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1250_654">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.824219 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
