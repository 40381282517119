"use client";
import React, { useEffect, useReducer, useState } from "react";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  totalCount,
  setStopState,
  reload,
  resendState,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  /* const [count, setCount] = useState(); */
  const [second, setSecond] = useState(0);
  const [minute, setMinute] = useState(Math.trunc(totalCount / 60));
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    let myCount = totalCount;
    let mySecond;
    let myMinute;
    const myIntrval = setInterval(() => {
      if (myCount > 0) {
        myCount = myCount - 1;
        myMinute = Math.trunc(myCount / 60);
        mySecond = myCount - myMinute * 60;
        /*setCount(myCount); */
        setMinute(myMinute);
        setSecond(mySecond);
      } else {
        clearInterval(myIntrval);
        setStopState(true);
      }
    }, 1000);
  }, [reload]);

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const formatNumber = (value) => {
    let num = String(value);
    if (num.length === 1) {
      num = "0" + num;
    } else {
      num = num;
    }
    return num;
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="flex items-center justify-center">
      <section className=" h-[24px]">
        {!resendState && (
          <section className="flex">
            <span className="block w-[50px]">{formatNumber(minute) + ":" + formatNumber(second)}</span>

            <span className="mr-2">مانده تا دریافت مجدد کد</span>
          </section>
        )}
      </section>
    </section>
  );
}
