'use client';
import React from 'react';
import { imagesFileUrl } from '../../../../../config';
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ state, setState }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const increaseWeight = () => {
    if (state < 250) {
      setState(state + 1);
    }
  };
  const decreaseWeight = () => {
    if (state > 35) {
      setState(state - 1);
    }
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full h-auto xl:w-[324px] flex flex-col items-center py-10 px-[70px] xl:h-full bg-[#F6FCFE] border border-solid border-[#94DFF4] rounded-[18px]">
        <section className="flex gap-[15px] mb-[14px] items-center">
          <img
            src={imagesFileUrl + 'Vector.png'}
            className="w-[28.79px] h-[28.79px] cursor-pointer"
            onClick={increaseWeight}
            alt=""
          />
          <section className="w-[70px] h-[44px] flex justify-center items-center bg-white border border-solid border-[#D8D8D8] rounded-lg">
            {state}
          </section>
          <img
          onClick={decreaseWeight}
            src={imagesFileUrl + 'Vector-.png'}
            className="w-[28.79px] h-[28.79px] cursor-pointer"
            alt=""
          />
        </section>
        <h2 className="mb-[30px] text-[#191919] ">کیلوگرم</h2>
        <img
          
          src={imagesFileUrl + 'Scale.png'}
          className="w-[70%] xl:w-[161.53px] h-auto xl:h-[165px] "
          alt=""
        />
      </section>
    </>
  );
}
