import React, { useState, useContext, useEffect } from 'react';
import Line from './Line'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({activeNumber}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
    <Line number={10} activeNumber={activeNumber}/>
    <Line number={9} activeNumber={activeNumber}/>
    <Line number={8} activeNumber={activeNumber}/>
    <Line number={7} activeNumber={activeNumber}/>
    <Line number={6} activeNumber={activeNumber}/>
    <Line number={5} activeNumber={activeNumber}/>
    <Line number={4} activeNumber={activeNumber}/>
    <Line number={3} activeNumber={activeNumber}/>
    <Line number={2} activeNumber={activeNumber}/>
    <Line number={1} activeNumber={activeNumber}/>
    </>
  );
}
