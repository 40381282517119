import { create } from "zustand";
import { devtools } from "zustand/middleware";
export const useBasicInfoStore = create(
  devtools((set) => ({
    basicInfo: {},
    updateBasicInfo: (data) => set({ basicInfo: data }),
    basicInfoLoading:false,
    showBasicInfoLoading: () => set(() => ({ basicInfoLoading: true })),
    hideBasicInfoLoading: () => set(() => ({ basicInfoLoading: false })),
    reloadBasicInfoState: false,
    reloadBasicInfo: () => set((state) => ({ reloadBasicInfoState: !state.reloadBasicInfoState })),
  })),
);
