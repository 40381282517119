import React from 'react';
import BMIRangeBar from '../../../../../components/common/BMIRangeBar'
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ bmi }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────


  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>

      <section className="px-[32px] py-[26px] w-[95%] mx-auto  text-[#3E4151] xl:w-full h-auto xl:h-[168px] rounded-[18px] bg-[#F6FCFE] mb-[44px] border border-solid border-[#B7E9F7]">
        <section className="justify-between xl:gap-[39px]">
          <section className='flex justify-between items-center'>
            <span className="font-bold">شاخص توده‌بندی</span>
            <span className="text-[12px] xl:text-[16px] justify-self-end text-[#3E3657]">
              Body Mass Index (BMI)
            </span>
          </section>
          <section className='flex flex-wrap justify-between mt-10'>
            <span className="text-[#0097C1] block w-full xl:w-auto text-center xl:text-right col-start-1 my-[12px] xl:my-0 xl:col-end-2 col-end-4 justify-self-center  xl:justify-self-start  text-[40px] font-bold">
              {bmi}
            </span>
            <BMIRangeBar bmi={bmi} />
          </section>

        </section>
      </section>
    </>
  );
}
