import React, { useState, useContext, useEffect } from 'react'
import Item from './components/Item'
import { imagesFileUrl } from '../../../../../../../config'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='flex gap-3 mt-[10px]'>
            <Item textColor="#191919"
                title="جنسیت"
                value="مرد"
                color="#191919"
                imgUrl={imagesFileUrl + "svg/ai-gender.svg"} />
            <Item textColor="#191919"
                title="سن"
                value="28"
                color="#0097C1"
                imgUrl={imagesFileUrl + "svg/ai-age.svg"} />
            <Item textColor="#191919"
                title="وزن شروع"
                value="80"
                color="#FA6900"
                imgUrl={imagesFileUrl + "svg/ai-weight.svg"} />
            <Item textColor="#191919"
                title="هدف"
                value="کاهش وزن"
                color="#ED1C24"
                imgUrl={imagesFileUrl + "svg/ai-target.svg"} />
            <Item textColor="#191919"
                title="قد"
                value="180"
                color="#5408A9"
                imgUrl={imagesFileUrl + "svg/ai-height.svg"} />
            <Item textColor="#191919"
                title="سطح فعالیت"
                value="کم"
                color="#F02484"
                imgUrl={imagesFileUrl + "svg/ai-activity.svg"} />
            <Item textColor="#191919"
                title="BMI"
                value="28"
                color="#006334"
                imgUrl={imagesFileUrl + "svg/ai-bmi.svg"} />
            <Item textColor="#191919"
                title="وزن هدف"
                value="28"
                color="#B32F9F"
                imgUrl={imagesFileUrl + "svg/ai-target-weight.svg"} />
        </section>
    )
}
