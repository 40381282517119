import React, { useState, useContext, useEffect } from 'react';
import { baseFileUrl } from '../../../../config';
import { imagesFileUrl } from "../../../../config";
import { Link } from 'react-router-dom';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className='xl:w-[1128px] mt-[70px] mb-[50px] xl:mt-0 xl:mb-[150px]  h-[620px] xl:py-[28px] xl:px-[58px] relative xl:h-[230px] gap-[34px] items-center flex xl:flex-row flex-col rounded-[15px]  mx-auto'>
      <img
        src={baseFileUrl + "/pic (8)-section8.png"}
        alt=""
        className="xl:w-[240px] pt-10 xl:pt-0 w-[60%]"
      />
      <section className=' xl:h-[102px] w-[80%] mx-auto   xl:w-[615px] '>
        <h3 className='text-white font-bold text-[32px] leading-[40px] xl:leading-[34.1px]'>دریافت برنامه تناسب اندام از بادینس</h3>
        <p className='  font-normal mt-[15px] text-[22px]  leading-[33px] text-white'>برای رسیدن به تناسب اندام، برنامه غذایی مخصوص خودت رو از ما دریافت کن.</p>
      </section>
      <Link to="/meal-plan" className='text-white w-[137px] h-[49px] flex justify-center items-center bg-[#5521B4] font-bold text-[18px] rounded-[150px] '>بزن بریم</Link>
      <img
        src={baseFileUrl + "/Section-8.png"}
        alt=""
        className="h-full hidden xl:flex  absolute w-full -z-10  left-0 "
      />
      <img
        src={imagesFileUrl + "/Section-6-1.png"}
        alt=""
        className="h-full xl:hidden flex  absolute w-full -z-10  left-0 "
      />

    </section>
  );
}
