import React, { useEffect, useState } from "react";
import Calory from "./components/Calory";
import MacroWeightChart from "./components/MacroWeightChart";
import Diet from "./components/Diet";
import Recipe from "./components/Recipe";
import SportTraining from "./components/SportTraining";

import { useBasicInfoStore } from "../../store/dashboard/basicInfo";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const basicInfo = useBasicInfoStore((state) => state.basicInfo);
  const basicInfoLoading = useBasicInfoStore((state) => state.basicInfoLoading);

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    /*  getUserInfo(); */
  }, []);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {/* <Loading/> */}
      {/*  <Calender /> */}
      <Calory loading={basicInfoLoading} userInfo={basicInfo} />
      <MacroWeightChart loading={basicInfoLoading} userInfo={basicInfo} />
      <Diet userInfo={basicInfo} />
      <Recipe />
      <SportTraining />
      {/*   <section
        style={{ scrollSnapType: "y mandatory", aspectRatio: "2/1" }}
        className="w-[300px] h-[60px]  flex-col justify-center items-center overflow-scroll bg-yellow-400 scale-x-100"
        type="text"
        onWheel={(e) => {
          e.preventDefault();
          console.log(e.deltaY);
        }}
      >
        <section className="w-[100%] h-[60px] bg-slate-400">1</section>
        <section className="w-[100%] h-[60px] bg-slate-400">2</section>
        <section className="w-[100%] h-[60px] bg-slate-400">3</section>
        <section className="w-[100%] h-[60px] bg-slate-400">4</section>
        <section className="w-[100%] h-[60px] bg-slate-400">5</section>
      </section> */}
      {/*   <div
        onWheel={(e) => {
          console.log(e.deltaY);
        }}
     
        onDragCapture={(e) => {
          console.log(e);
        }}
        class="scContainer y-scroll y-mandatory"
      >
        <div class="wrapper">
          <div class="element">1</div>
          <div class="element">2</div>
          <div class="element">3</div>
          <div class="element">4</div>
          <div class="element">5</div>
          <div class="element">6</div>
          <div class="element">7</div>
          <div class="element">8</div>
          <div class="element">9</div>
          <div class="element">10</div>
          <div class="element">11</div>
          <div class="element">12</div>
        </div>
      </div> */}
    </>
  );
}
