import React from "react";
import { baseFileUrl, imagesFileUrl } from "../../../../config";


//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className=" mb-[50px] xl:mb-[150px] mx-auto h-auto xl:h-[680px] bg-[linear-gradient(296.81deg,_#F02484_-60.12%,_#5408A9_32.13%,_#4CC9EC_132.28%)]">

      <section className="w-[1440px] max-w-full h-full m-auto">
        <section className="grid grid-cols-1 xl:grid-cols-2  h-full">
          <section>
            <section className="relative xl:w-[430px] h-[380px] flex justify-center items-center">
              <img
                src={baseFileUrl + "/shape-circle.png"}
                alt=""
                className="absolute top-0 z-10  "
              />
              <section className="w-[200px] h-[200px] overflow-hidden rounded-full border-4 border-[#d8ccee]">
                <img src={imagesFileUrl + "coach/imani.png"} alt="" />
              </section>
            </section>
            <section className="px-[40px]">
              <h3 className="xl:leading-[62px] leading-[34.1px] text-[22px] text-white  xl:text-[40px] font-bold">
                دکتر حسین ایمانی
              </h3>
              <p className="xl:leading-[21.7px] mt-6 xl:mt-[30px] leading-[21.1px] text-white text-sm ">
                پزشکان تیم ما با تخصص و تجربه فراوان، متعهد به ارائه بهترین خدمات
                درمانی و مشاوره‌ای برای حفظ و ارتقای سلامت شما هستند.پزشکان تیم ما
                با تخصص و تجربه فراوان، متعهد به ارائه بهترین خدمات درمانی و
                مشاوره‌ای برای حفظ و ارتقای سلامت شما هستند.پزشکان تیم ما با تخصص و
                تجربه فراوان، متعهد به ارائه بهترین خدمات درمانی و مشاوره‌ای برای
                حفظ و ارتقای سلامت شما هستند.
              </p>
            </section>
          </section>
          <section className="grid grid-cols-2 gap-3 py-[30px] px-[60px] items-center">
            <section className="flex flex-col items-center">
              <section className="xl:w-[200px] xl:h-[200px] overflow-hidden rounded-full border-4 border-[#d8ccee]">
                <img src={imagesFileUrl + "coach/sadeghi.png"} alt="" />
              </section>
              <span className="text-[20px] font-bold text-white mt-[17px]">دکتر صادقی</span>
              <span className="text-[15px] font-normal text-white">پزشک تغذیه</span>
            </section>
            <section className="flex flex-col items-center">
              <section className="xl:w-[200px] xl:h-[200px] overflow-hidden rounded-full border-4 border-[#d8ccee]">
                <img src={imagesFileUrl + "coach/mohajerani.png"} alt="" />
              </section>
              <span className="text-[20px] font-bold text-white mt-[17px]">دکتر مهاجرانی</span>
              <span className="text-[15px] font-normal text-white">پزشک تغذیه</span>
            </section>
            <section className="flex flex-col items-center">
              <section className="xl:w-[200px] xl:h-[200px] overflow-hidden rounded-full border-4 border-[#d8ccee]">
                <img src={imagesFileUrl + "coach/lajevardi.png"} alt="" />
              </section>
              <span className="text-[20px] font-bold text-white mt-[17px]">دکتر لاجوردی</span>
              <span className="text-[15px] font-normal text-white">پزشک تغذیه</span>
            </section>

          </section>
        </section>
      </section>

      {/* <section className="text-white relative z-10 w-[90%] mx-auto xl:w-[40%] ">
        <img
          src={baseFileUrl + "/Picture.png"}
          alt=""
          className="absolute top-[5%] left-[20%] w-[50%] xl:top-[10%] xl:left-[38%] z-10 xl:w-[242.51px] xl:h-[242.51px]"
        />
        <img
          src={baseFileUrl + "/shape-circle.png"}
          alt=""
          className="absolute xl:-top-[1%] top-[4%] left-[10%] xl:right-[5%] w-[70%] z-10 xl:w-[438px] xl:h-[430.06px]"
        />
        <section className=" xl:mt-[400px] mt-[200px] xl:mr-[20%] ">
          <h3 className="xl:leading-[62px] leading-[34.1px] text-[22px]  xl:text-[40px] font-bold">
            دکتر حسین ایمانی
          </h3>
          <p className="xl:leading-[21.7px] mt-6 xl:mt-0 leading-[21.1px] text-sm ">
            پزشکان تیم ما با تخصص و تجربه فراوان، متعهد به ارائه بهترین خدمات
            درمانی و مشاوره‌ای برای حفظ و ارتقای سلامت شما هستند.پزشکان تیم ما
            با تخصص و تجربه فراوان، متعهد به ارائه بهترین خدمات درمانی و
            مشاوره‌ای برای حفظ و ارتقای سلامت شما هستند.پزشکان تیم ما با تخصص و
            تجربه فراوان، متعهد به ارائه بهترین خدمات درمانی و مشاوره‌ای برای
            حفظ و ارتقای سلامت شما هستند.
          </p>
        </section>
      </section>

      <img
        src={baseFileUrl + "/BG.png"}
        alt=""
        className="absolute bg-radial-doctor opacity-10 bg-transparent from-white  h-auto right-0 -z-0  w-full "
      />
      <img
        src={baseFileUrl + "/teamates.png"}
        alt=""
        className=" xl:mr-[338px] mt-10 xl:mt-0 w-[70%] mx-auto xl:w-[389px] "
      /> */}
    </section>
  );
}
