import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
export const useDietStore = create(
  persist(
    devtools((set) => ({
      dietInfo: {},
      updateDietInfo: (data) => set({ dietInfo: data }),
      reloadDietAllInfoState: false,
      reloadDietAllInfo: () => set((state) => ({ reloadDietAllInfoState: !state.reloadDietAllInfoState })),
    })),
    {
      name: "diet-storage", // name of the item in the storage (must be unique)
      /* storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used */
    }
  )
);
