import React, { useState, useContext, useEffect } from 'react'
import { imagesFileUrl } from '../../../config'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
const Item = ({ children }) => {
    return (<span className='bg-[#F1E8FC] px-[14px] text-[14px] font-normal py-[12px] h-[33px] text-purple rounded-full flex justify-center items-center'>{children}</span>
    )
}
export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='rounded-[10px] border border-[#E6E8E9] p-[20px]  mt-[32px]'>
            <h2 className='text-[18px] font-bold '>فعالیت ها</h2>
            <section className='mt-[32px]'>
                <section className='flex gap-2 items-center'>
                    <img src={imagesFileUrl + "doctor-profile-activity.png"} alt='' />
                    <section>
                        <span className='block text-[14px] font-bold'>عضو انجمن تغذیه ایران</span>
                        <span className='block text-[12px] font-normal'>از سال ۱۳۹۸ تا کنون</span>
                    </section>
                </section>
                <section className='flex gap-2 items-center mt-[12px]'>
                    <img src={imagesFileUrl + "doctor-profile-activity.png"} alt='' />
                    <section>
                        <span className='block text-[14px] font-bold'>عضو انجمن تغذیه ایران</span>
                        <span className='block text-[12px] font-normal'>از سال ۱۳۹۸ تا کنون</span>
                    </section>
                </section>
            </section>
        </section>
    )
}
