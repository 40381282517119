import React, { useState, useContext, useEffect } from "react";
/* import CaloryItem from './components/CalloryItem' */
import CaloryItem from "./components/CalloryItemApex";
import Loading from "../../../../components/common/componentLoading";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ loading, userInfo }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className='w-full h-[350px] rounded-[18px]  bg-white shadow-md mt-6 py-8 px-8 bg-no-repeat relative overflow-hidden bg-[url("/public/images/dashboard/caloryBg.png")]'>
      <Loading loading={loading} />
      <span className="text-lg font-bold">کالری</span>
      <section className="overflow-x-auto">
        {userInfo.monthsList && (
          <section className="flex justify-between items-center h-[244px] w-[500px] xl:w-[628px] m-auto overflow-hidden">
            <CaloryItem goalCalory={Math.round(userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.recommendedCaloriesInTheDay)} consumedCalory={Math.round(userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.consumedCaloriesInTheDay)} percent={Math.round(userInfo?.currentMonthInfoList?.weekData[0]?.days[0]?.percentUsedCaloriesInTheDay)} title={userInfo.currentDayFa} />
            <CaloryItem goalCalory={Math.round(userInfo?.currentMonthInfoList?.weekData[0]?.recommendedCaloriesInTheWeek)} consumedCalory={Math.round(userInfo?.currentMonthInfoList?.weekData[0]?.consumedCaloriesInTheWeek)} percent={userInfo?.currentMonthInfoList?.weekData[0]?.percentUsedCaloriesInTheWeek} title={userInfo.currentWeekFa} />
            <CaloryItem goalCalory={Math.round(userInfo?.currentMonthInfoList?.recommendedCaloriesInTheMonth)} consumedCalory={Math.round(userInfo?.currentMonthInfoList?.consumedCaloriesInTheMonth)} percent={userInfo?.currentMonthInfoList?.percentUsedCaloriesInTheMonth} title={userInfo.currentMonthFa} />
          </section>
        )}
      </section>

    </section>
  );
}
