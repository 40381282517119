import React, { useState, useContext, useEffect } from 'react'
import Header from './components/Header'
import DietList from './components/DietList'
import DietListView from './components/DietListView'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────
    const [activeTab, setActiveTab] = useState(1);
    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <>
            <section className=" h-auto mt-[40px] py-[32px] px-[26px] bg-white rounded-[16px] shadow-md">
                <section className="w-full h-[80px] flex justify-between items-center rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] px-[86px]">
                    <button onClick={() => setActiveTab(1)} className={`w-[200px] h-[48px] rounded-full ${activeTab === 1 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xl`}>
                        صبحانه
                    </button>
                    <button onClick={() => setActiveTab(2)} className={`w-[200px] h-[48px] rounded-full ${activeTab === 2 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xl`}>
                        ناهار
                    </button>
                    <button onClick={() => setActiveTab(3)} className={`w-[200px] h-[48px] rounded-full ${activeTab === 3 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xl`}>
                        شام
                    </button>
                    <button onClick={() => setActiveTab(4)} className={`w-[200px] h-[48px] rounded-full ${activeTab === 4 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xl`}>
                        میان وعده
                    </button>
                </section>
                <Header />
                <DietList />
                <textarea placeholder='توضیحات کلی خود را وارد کنید' className='w-full block py-[27px] px-[16px] items-center h-[94px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]'></textarea>
                <button className="bg-purple m-auto mt-[35px] text-white w-[164px] h-[40px] rounded-full text-sm flex justify-center gap-1 items-center">
                    ثبت نهایی صبحانه
                </button>
            </section>
            <section className=" h-auto mt-[40px] p-[24px] bg-white rounded-[16px] shadow-md">
                <section className='text-lg font-bold'>برنامه غذایی نهایی</section>
                <DietListView />
            </section>
        </>
    )
}
