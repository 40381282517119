import React, { useEffect, useState } from "react";
import { svgFileUrl } from "../../../../config";
import Day from "./components/Day";
import Select from "react-select";
import { data } from "autoprefixer";
import { isObjEmpty } from "../../../../helper";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ userInfo }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  const date = [
    { value: "FIRST", label: "ماه اول", id: 0 },
    { value: "SECOND", label: "ماه دوم", id: 1 },
    { value: "THIRD", label: "ماه سوم", id: 2 },
    { value: "FOURTH", label: "ماه چهارم", id: 3 },
    { value: "FIFTH", label: "ماه پنجم", id: 4 },
    { value: "SIXTH", label: "ماه ششم", id: 5 },
    { value: "SEVENTH", label: "ماه هفتم", id: 6 },
    { value: "EIGHTH", label: "ماه هشتم", id: 7 },
    { value: "NINTH", label: "ماه نهم", id: 8 },
    { value: "TENTH", label: "ماه دهم", id: 9 },
    { value: "ELEVENTH", label: "ماه یازدهم", id: 10 },
    { value: "TWELFTH", label: "ماه دوازدهم", id: 11 },
    /*  { value: 2, label: 'ماهانه' }, */
  ];
  /*   const days = ["اول", "دوم", "سوم", "چهارم", "پنجم", "ششم", "هفتم", "هشتم", " نهـم ", "دهم", "یازدهم", "دوازدهم", "سیزدهم", "چهاردهم", "پانزدهم", "شانزدهم", "هفدهم", "هجدهم", "نوزدهم", "بیستم", "بیست و یکم", "بیست و دوم", "بیست و سوم", "بیست و چهارم", "بیست و پنجم", "بیست و ششم", "بیست و هفتم", "بیست و هشتم", "بیست و نهم", "سی ام", "سی و یکم", "سی و دوم", "سی و سوم", "سی و چهارم", "سی و پنجم"];
   */ // ─── States ─────────────────────────────────────────────────────────────────────
  const [month, setMonth] = useState(date[0]);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (!isObjEmpty(userInfo)) {
      const currentMonthIndex = date.find((el => el.value === userInfo.currentMonth))?.id;
      setMonth(date[currentMonthIndex])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:h-[365px] bg-white mt-6 rounded-[18px] relative py-8 xl:px-8">
      <section className="z-10 relative w-full flex justify-between items-center px-5 xl:px-0">
        <span className="text-lg font-bold">برنامه غذایی و ورزشی</span>
        {/* <button className="w-[105px] h-[32px] flex justify-around items-center rounded-full border px-2 border-[#DDCEEE] font-medium text-sm text-[#986BCB]">
          <span>ماه ششم</span>
          <img src={svgFileUrl + 'arrowDown.svg'} alt="" />
        </button> */}
        <Select
          value={month}
          onChange={setMonth}
          options={date}
          placeholder="روز"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: 100,
              border: "1px solid #DDCEEE !important",
              width: 140,
              textAlign: "center",
            }),
          }}
        />
      </section>
      {userInfo.monthsList && (
        <section className={`grid grid-cols-1 px-1 xl:px-0 gap-2 xl:grid-cols-2 mt-4 ${userInfo.monthsList[month.id]?.weekData?.length === 5 ? "xl:h-[255px]" : "xl:h-[168px]"} flex-wrap`}>
          <section className="flex justify-between h-[76px] bg-gradient-to-r rounded-[18px] from-[#dbf4fb26] to-[#dbf4fbcc]  xl:w-[355px]">
            {userInfo.monthsList[month.id]?.weekData[0]?.days.map((item, index) => (
              <Day
                /* mode={item.isLock ? "deActive" : "current"} */
                item={item}
                currentWeek="FIRST"
                currentMonth={month?.value}
                /*  mode="active"
                 */
                dayNumber={index + 1}
              />
            ))}

          </section>
          <section className="flex justify-between h-[76px] bg-gradient-to-r rounded-[18px] from-[#dbf4fb26] to-[#dbf4fbcc]  xl:w-[355px]">
            {userInfo.monthsList[month.id]?.weekData[1]?.days.map((item, index) => (
              <Day
                /* mode={item.isLock ? "deActive" : "current"} */
                item={item}
                currentMonth={month?.value}
                /*mode="active" */
                currentWeek="SECOND"
                dayNumber={index + 8}
              />
            ))}

          </section>
          <section className="flex justify-between h-[76px] bg-gradient-to-r rounded-[18px] from-[#dbf4fb26] to-[#dbf4fbcc]  xl:w-[355px]">
            {userInfo.monthsList[month.id]?.weekData[2]?.days.map((item, index) => (
              <Day
                /*  mode={item.isLock ? "deActive" : "current"} */
                item={item}
                currentWeek="THIRD"
                currentMonth={month?.value}
                /* mode="active" */
                dayNumber={index + 15}
              />
            ))}
          </section>
          <section className="flex justify-between h-[76px] bg-gradient-to-r rounded-[18px] from-[#dbf4fb26] to-[#dbf4fbcc]  xl:w-[355px]">
            {userInfo.monthsList[month.id]?.weekData[3]?.days.map((item, index) => (
              <Day
                /*  mode={item.isLock ? "deActive" : "current"} */
                key={index}
                item={item}
                currentWeek="FOURTH"
                currentMonth={month?.value}
                /* mode="active" */
                dayNumber={index + 22}
              />
            ))}

          </section>
          {
            userInfo.monthsList[month.id]?.weekData?.length === 5 && <section className="flex justify-between h-[76px] bg-gradient-to-r rounded-[18px] from-[#dbf4fb26] to-[#dbf4fbcc]  xl:w-[355px]">
              {userInfo.monthsList[month.id]?.weekData[4]?.days.map((item, index) => (
                <Day
                  key={index}
                  item={item}
                  currentWeek="FOURTH"
                  currentMonth={month?.value}
                  dayNumber={index + 29}
                />
              ))}

            </section>
          }


        </section>
      )}
      <img src={svgFileUrl + "timer.svg"} className="absolute hidden xl:block bottom-0 left-[90px]" alt="" />
    </section>
  );
}
