import React, { useState, useContext, useEffect } from 'react'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
const Item = ({ children }) => {
    return (<span className='bg-[#F1E8FC] px-[14px] text-[14px] font-normal py-[12px] h-[33px] text-purple rounded-full flex justify-center items-center'>{children}</span>
    )
}
export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='rounded-[10px] border border-[#E6E8E9] p-[20px]  mt-[32px]'>
            <h2 className='text-[18px] font-bold '>تخصص ها</h2>
            <section className='flex flex-wrap gap-[12px] mt-[32px]'>
                <Item>تغذیه سالمندان</Item>
                <Item>تغذیه بالینی</Item>
                <Item>تغذیه کودکان و نوجوانان</Item>
                <Item>تغذیه ورزشی</Item>
                <Item>تحقیقات و آموزش تغذیه</Item>
                <Item>تغذیه گیاه‌خواری</Item>
                <Item>تغذیه و سلامت روان</Item>
                <Item>پیشگیری و مدیریت بیماری‌های مزمن</Item>
            </section>
        </section>
    )
}
