import React, { useState, useContext, useEffect } from "react";
import { imagesFileUrl } from "../../../../../config";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  src,

  activeSection,
  index,
  title,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section
        className={`${
          activeSection === index
            ? "border-[#BB9CDD]  shadow-[0px_20.5px_44.42px_-11.96px_#5408A947] "
            : "border-[#A6A9BD] "
        } w-[236px] h-[116px] flex  items-center justify-center gap-4  border-[1.38px]  rounded-[20px] border-solid `}
      >
            <h3
          className={`${
            activeSection === index ? "text-[#5408A9]  " : "text-[#3E4151]"
          } leading-[34.1px] text-[22px] font-bold`}
        >
          {title}
        </h3>
        <section
          className={`${
            activeSection === index ? "bg-[#F9F5FE]" : "bg-[#F7F9FE]"
          } flex justify-center items-center w-[58px] h-[58px] rounded-full`}
        >
          <img src={imagesFileUrl + src} alt="" className="w-[32px] h-[32px]" />
        </section>

    
    
      </section>
    </>
  );
}
