import React, { useState, useContext, useEffect } from 'react'
import { imagesFileUrl } from '../../../config'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({setTools,tools}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
 
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
 
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-[30px]">
    <span className="block text-center font-bold text-[22px]">
      انتخاب تجهیزات
    </span>
    <section className="flex justify-between flex-wrap gap-y-[25px] mt-[12px]">
      <section
        onClick={() => setTools(5)}
        className={`w-[118px] h-[126px] cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-[#4CC9EC] hover:shadow-xl transition-all ${
          tools === 5 ? 'border-[#4CC9EC]' : 'border-[#E6E8E9]'
        }`}
      >
        <img
          src={imagesFileUrl + 'ketelball.png'}
          className="w-[43px] h-[47px]"
          alt=""
        />
        <span className="text-sm font-bold mt-[14px]">کتل بل</span>
      </section>
      <section
        onClick={() => setTools(3)}
        className={`w-[118px] h-[126px] cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-[#4CC9EC] hover:shadow-xl transition-all ${
          tools === 3 ? 'border-[#4CC9EC]' : 'border-[#E6E8E9]'
        }`}
      >
        <img
          src={imagesFileUrl + 'damble1.png'}
          className="w-[68px] "
          alt=""
        />
        <span className="text-sm font-bold mt-[14px]">دمبل</span>
      </section>
      <section
        onClick={() => setTools(4)}
        className={`w-[118px] h-[126px] cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-[#4CC9EC] hover:shadow-xl transition-all ${
          tools === 4 ? 'border-[#4CC9EC]' : 'border-[#E6E8E9]'
        }`}
      >
        <img
          src={imagesFileUrl + 'halter.png'}
          className="w-[75px]"
          alt=""
        />
        <span className="text-sm font-bold mt-[14px]">هالتر</span>
      </section>
      <section
        onClick={() => setTools(1)}
        className={`w-[118px] h-[126px] cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-[#4CC9EC] hover:shadow-xl transition-all ${
          tools === 1 ? 'border-[#4CC9EC]' : 'border-[#E6E8E9]'
        }`}
      >
        <img
          src={imagesFileUrl + 'rubber.png'}
          className="w-[59px]"
          alt=""
        />
        <span className="text-sm font-bold mt-[14px]">کش ها</span>
      </section>
    </section>
  </section>
  )
}
