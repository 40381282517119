import React, { useState, useContext, useEffect, useRef } from "react";

import GenderSelector from "./components/GenderSelector";
import MuscleSelector from "./components/MuscleSelector";
import ToolsSelector from "./components/ToolsSelector";
import LevelSelector from "./components/LevelSelector";
import { useSearchParams } from "react-router-dom";
import VideoPlayer from "./components/VideoPlayer";
import { useGlobalContext } from "../../../context/GlobalContext";
import api from "../../../api";
import { Pagination } from "antd";
import { useAxios, useWindowSize } from "../../../hooks";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const { showLoading, hideLoading } = useGlobalContext();
  const [searchParams] = useSearchParams();

  const [windowHeight, windowWidth] = useWindowSize();
  // ─── States ─────────────────────────────────────────────────────────────────────

  const [gender, setGender] = useState(searchParams.get("gender"));
  const [trainingLevel, setTrainingLevel] = useState(1);
  const [exList, setExList] = useState([]);
  const [muscle, setMuscle] = useState(searchParams.get("muscleId"));
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getExr = (currentPage) => {
    if (gender === "FEMALE") {
      showLoading();
      setExList([]);
      useAxios
        .get(`${api.exercise.searchExercise}?pageNo=${currentPage - 1}&pageSize=2&muscleId=${muscle}`)
        .then((res) => {
          hideLoading();
          setExList(res.data.elements);
          setTotalElements(res.data.totalElements);
          if (windowWidth < 1280) {
            window.scrollTo({ top: 1319, left: 0, behavior: "smooth" });
          } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }
        })
        .catch((e) => {
          hideLoading();
        });
    } else {
      setTotalElements(0);
      setExList([]);
    }
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    console.log(windowWidth);
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    getExr(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  useEffect(() => {
    getExr(1);
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muscle]);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="max-w-[96%] w-[1850px] m-auto xl:mt-[60px] 2xl:mt-[120px] mb-[160px]">
      <section className="hidden xl:block">
        <span className="font-normal text-[16px] text-purple">خانه</span>
        <span> / </span>
        <span className="font-normal text-[16px] text-[#A6A9BD]">تمرینات ورزشی</span>
      </section>
      <section className="grid grid-cols-1 xl:grid-cols-3 xl:gap-3 mt-[32px]">
        {/* Right Side */}
        <section className="col-span-1 py-[40px] xl:h-[1001px] flex flex-col justify-end items-end border border-[#B7E9F7] bg-[#F6FCFE] rounded-[18px] xl:py-[10px] px-[32px]">
          <section className="m-auto">
            <GenderSelector state={gender} setState={setGender} />
            <MuscleSelector setMuscle={setMuscle} gender={gender} />
            <section className="w-[250px] m-auto">
              <ToolsSelector />
            </section>
          </section>
        </section>
        {/*  Left Side */}
        <section className="col-span-2">
          <section className="hidden xl:block">
            <LevelSelector level={trainingLevel} setLevel={setTrainingLevel} total={totalElements} />
          </section>
          {gender === "FEMALE" ? (
            <section className="mt-[48px]">
              {exList.map((item, index) => (
                <section key={index}>
                  <h3 className="font-bold text-lg mt-10 bg-[#c32182] p-3  rounded-tr-xl rounded-tl-xl  text-white">{item.persianTitle}</h3>
                  <section className="grid grid-cols-1 xl:grid-cols-2 xl:gap-3">
                    <section className="border  flex justify-center overflow-hidden items-center border-[#E6E8E9]  xl:rounded-b-2xl">
                      <VideoPlayer url={item?.muscleVideoOutputDto[0]?.downloadUrl} />
                    </section>
                    <section className="border  flex justify-center items-center overflow-hidden border-[#E6E8E9]  xl:rounded-b-2xl">
                      <VideoPlayer url={item?.muscleVideoOutputDto[1]?.downloadUrl} />
                    </section>
                  </section>
                </section>
              ))}
            </section>
          ) : gender === "MALE" ? (
            <section className="text-2xl font-extrabold text-center text-purple h-[300px] flex justify-center items-center">به زودی</section>
          ) : (
            ""
          )}
          {exList.length > 0 && gender === "FEMALE" && <Pagination className="ltr text-center mt-5 sans" defaultCurrent={1} showSizeChanger={false} current={currentPage} pageSize={2} onChange={(value) => setCurrentPage(value)} total={totalElements} />}
        </section>
      </section>
    </section>
  );
}
