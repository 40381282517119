"use client";
import React from "react";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ answer }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="flex items-center shadow-[0px_4px_14px_0px_#5408A91A] gap-2 border-r-[8px] h-auto xl:h-[71px] rounded-[16px] border-[#5521B4] p-5 xl:px-[40px] ">
        <p className="text-justify text-[14px] font-normal xl:text-[14px] xl:font-medium dark:text-white text-[#3E4151] xl:leading-[24.18px] leading-[21.6px]">
          {answer}
        </p>
      </section>
    </>
  );
}
