import React, { useState, useContext, useEffect } from 'react'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <table class="border-collapse border border-slate-200 w-full mt-[40px]">
            <thead>
                <tr className='bg-[#FEEAF3] text-[#3E4151]'>
                    <th className='p-[24px]'>شماره</th>
                    <th className='p-[24px] w-[150px]'>سیستم تمرینی</th>
                    <th className='p-[24px]'>نام حرکت</th>
                    <th className='p-[24px]'>تعداد ست</th>
                    <th className='p-[24px]'>تکرار</th>
                    <th className='p-[24px]'>Rir</th>
                    <th className='p-[24px]'>Tempo</th>
                    <th className='p-[24px]'>استراحت</th>
                    <th className='p-[24px]'>توان با وزن به کیلو</th>
                </tr>
            </thead>
            <tbody>
                <tr className='text-center text-xs'>
                    <td className='p-[24px]'>1</td>
                    <td className='p-[24px]'>superset</td>
                    <td className='p-[24px]'>دمبل جلوبازو</td>
                    <td className='p-[24px]'>3</td>
                    <td className='p-[24px]'>2</td>
                    <td className='p-[24px]'>5</td>
                    <td className='p-[24px]'>2121</td>
                    <td className='p-[24px]'>90s</td>
                    <td className='p-[24px]'>10</td>
                </tr>
                <tr className='text-center text-xs'>
                    <td className='p-[24px]'>2</td>
                    <td className='p-[24px]'>fst-7</td>
                    <td className='p-[24px]'>پرس سینه</td>
                    <td className='p-[24px]'>3</td>
                    <td className='p-[24px]'>2</td>
                    <td className='p-[24px]'>5</td>
                    <td className='p-[24px]'>2121</td>
                    <td className='p-[24px]'>90s</td>
                    <td className='p-[24px]'>8</td>
                </tr>
                <tr className='text-center text-xs'>
                    <td className='p-[24px]'>3</td>
                    <td className='p-[24px]'>dropset</td>
                    <td className='p-[24px]'>پرس سینه</td>
                    <td className='p-[24px]'>3</td>
                    <td className='p-[24px]'>2</td>
                    <td className='p-[24px]'>5</td>
                    <td className='p-[24px]'>2121</td>
                    <td className='p-[24px]'>90s</td>
                    <td className='p-[24px]'>7</td>
                </tr>
            </tbody>
        </table>
    )
}
