import React, { useEffect, useState } from 'react'
import CaloryItem from './CalloryItemApex';
import Progress from './Progressbar';
import Loading from '../../../../components/common/componentLoading'

import api from '../../../../api';
import { useDietStore } from '../../../../store/dashboard/diet';
import { useAxiosWithToken } from '../../../../hooks';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────
    const dietInfo = useDietStore(state => state.dietInfo)
    const reloadDietAllInfoState = useDietStore((state) => state.reloadDietAllInfoState);
    const month = dietInfo?.month;
    const week = dietInfo?.week;
    const day = dietInfo?.day;
    // ─── States ─────────────────────────────────────────────────────────────────────
    const [currentItem, setCurrentItem] = useState({})
    const [loading, setLoading] = useState(false)

    // ─── Functions ──────────────────────────────────────────────────────────────────
    const getDietAllInfo = () => {
        setLoading(true)
        useAxiosWithToken.get(api.personalProgram.getCurrentProgram + `?monthEnum=${month}&dayEnum=${day}&weekEnum=${week}`).then(res => {
            setLoading(false)
            setCurrentItem(res.data)
        }).catch(err => {
            setLoading(false)
        })
    }
    // ─── Life Cycle ─────────────────────────────────────────────────────────────────
    useEffect(() => {
        getDietAllInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadDietAllInfoState])
    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className="xl:w-[741px] xl:h-[300px] py-[36px] relative flex flex-wrap justify-between items-center border border-[#E6E8E9] bg-[#F7F9FE] rounded-[18px] m-auto mt-3 px-10">
            <Loading loading={loading} />
            <section className="xl:w-[306px] xl:h-[210px] flex flex-col justify-between items-center order-2 xl:order-1">
                <Progress color="#4CC9EC" title="کربوهیدرات" percent={Math.floor(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.percentUsedCarbohydrateInTheDay)} weight={Math.floor(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.consumedGramCarbohydrateInTheDay)} />
                <Progress
                    color="#F02484"
                    style={{ marginTop: 10 }}
                    title="چربی"
                    percent={Math.floor(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.percentUsedFatInTheDay)}
                    weight={Math.floor(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.consumedGramFatInTheDay)}
                />
                <Progress
                    color="#7639BA"
                    style={{ marginTop: 10 }}
                    title="پروتئین"
                    percent={Math.floor(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.percentUsedProteinInTheDay)}
                    weight={Math.floor(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.consumedGramProteinInTheDay)}
                />
                <Progress
                    color="#286aff"
                    style={{ marginTop: 10 }}
                    title="آب"
                    unit="میلی لیتر"
                    percent={Math.floor(Math.floor(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.consumedWater) * 100) / 1500}
                    weight={Math.floor(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.consumedWater)}
                />
            </section>
            <section className='order-1 xl:order-2'>
                <CaloryItem
                    goalCalory={parseInt(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.recommendedCaloriesInTheDay)}
                    consumedCalory={parseInt(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.consumedCaloriesInTheDay)}
                    percent={parseInt(currentItem.currentMonthInfoList?.weekData[0]?.days[0]?.percentUsedCaloriesInTheDay)}
                />
            </section>

        </section>
    )
}
