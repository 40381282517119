import React, { useState, useEffect } from "react";
import { baseFileUrl } from "../../../../config";
import { imagesFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
import { useAxios } from "../../../../hooks";
import api from "../../../../api";
import Loading from "../../../../components/common/componentLoading";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full flex flex-col items-center text-white relative   overflow-hidden mb-[50px] xl:mb-[150px] mx-auto h-auto xl:h-[776px]">
      <img
        src={baseFileUrl + "/Section-3-bg-exer.png"}
        alt=""
        className="absolute -z-10 w-full h-full"
      />
      <h3 className="xl:leading-[93px] leading-[60px] mt-[30px] xl:mt-[83px] text-center text-[22px] xl:text-[60px] font-bold">
        مربیان تیم ما
      </h3>
      <p className="leading-[24.8px] w-[90%] mx-auto xl:w-full xl:text-base text-[12px] text-center  xl:mt-10 font-semibold ">
        مربیان تیم ما با تخصص و تجربه فراوان، متعهد به ارائه بهترین خدمات درمانی و مشاوره‌ای برای حفظ و ارتقای سلامت شما هستند.
      </p>
      <section className="grid grid-cols-1 xl:grid-cols-4 gap-3 py-[30px] px-[60px] items-center">
        <section className="flex flex-col items-center">
          <section className="xl:w-[250px] xl:h-[250px] overflow-hidden rounded-full border-4 border-[#d8ccee]">
            <img src={imagesFileUrl + "coach/zivari.png"} alt="" />
          </section>
          <span className="text-[20px] font-bold text-white mt-[17px]">آقای زیوری</span>
          <span className="text-[15px] font-normal text-white">مربی بدنسازی</span>
        </section>
        <section className="flex flex-col items-center">
          <section className="xl:w-[250px] xl:h-[250px] overflow-hidden rounded-full border-4 border-[#d8ccee]">
            <img src={imagesFileUrl + "coach/tarverdi.png"} alt="" />
          </section>
          <span className="text-[20px] font-bold text-white mt-[17px]">خانم تاروردی</span>
          <span className="text-[15px] font-normal text-white">مربی بدنسازی</span>
        </section>
        <section className="flex flex-col items-center">
          <section className="xl:w-[250px] xl:h-[250px] overflow-hidden rounded-full border-4 border-[#d8ccee]">
            <img src={imagesFileUrl + "coach/hosseini.png"} alt="" />
          </section>
          <span className="text-[20px] font-bold text-white mt-[17px]">خانم حسینی</span>
          <span className="text-[15px] font-normal text-white">مربی بدنسازی</span>
        </section>
        <section className="flex flex-col items-center">
          <section className="xl:w-[250px] xl:h-[250px] overflow-hidden rounded-full border-4 border-[#d8ccee]">
            <img src={imagesFileUrl + "coach/ganji.png"} alt="" />
          </section>
          <span className="text-[20px] font-bold text-white mt-[17px]">خانم گنجی</span>
          <span className="text-[15px] font-normal text-white">مربی بدنسازی</span>
        </section>

      </section>
      {/* <div className="w-8 h-8 flex top-[80%] right-[40%] xl:top-[60%] xl:right-[10%] absolute justify-center items-center rounded-full bg-[#0000001F] active:bg-[#BB9CDD]">
        <img
          src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
          alt=""
          className="w-[8px] h-auto"
        />
      </div>
      <div className="w-8 h-8 flex absolute top-[80%] xl:top-[60%] left-[40%]  xl:left-[10%]  justify-center items-center rounded-full bg-[#0000001F] active:bg-[#BB9CDD]">
        <img
          src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
          alt=""
          className="w-[8px] h-auto rotate-180"
        />
      </div> */}
      {/*   <img
        src={baseFileUrl + "/section3-profile-exer.png"}
        alt=""
        className="w-full xl:w-[1078.74px] mb-[80px] xl:mb-0 xl:mt-100 h-auto xl:h-[433px]"
      /> */}
    </section>
  );
}
