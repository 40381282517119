import React from 'react'


//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ image }) {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className="h-[259px] rounded-[16px] border mt-[40px] border-[#E6E8E9] shadow-md">
            {/*  <section className="border-[2px] w-[85px] h-[85px] mt-[-44px] bg-white  rounded-full shadow-md m-auto border-[#E6E8E9]">
            </section> */}
            <img src={image} className="mt-[-44px] m-auto h-[100px] block" alt="" />
            <span className="font-semibold block text-center mt-[-10px]">محسن زیوری</span>
            <section className="w-[203px] flex justify-between items-center h-[73px] mt-[32px]  m-auto">
                <section className="h-[73px] w-[21px] flex flex-col justify-between items-center">
                    <img alt="" src="/images/svg/agePink.svg" />
                    <span className="text-[12px] text-[#F02484] font-semibold">سن</span>
                    <span className="text-[12px] text-[#F02484] font-semibold">24</span>
                </section>
                <span className="w-[1px] h-[31px] block bg-[#E6E8E9]"></span>
                <section className="h-[73px] w-[21px] flex flex-col justify-between items-center">
                    <img alt="" src="/images/svg/weight.svg" />
                    <span className="text-[12px] text-purple font-semibold">BMI</span>
                    <span className="text-[12px] text-purple font-semibold">145</span>
                </section>
                <span className="w-[1px] h-[31px] block bg-[#E6E8E9]"></span>
                <section className="h-[73px] w-[21px] flex flex-col justify-between items-center">
                    <img alt="" src="/images/svg/weightOrange.svg" />
                    <span className="text-[12px] text-[#FA6900] font-semibold">وزن</span>
                    <span className="text-[12px] text-[#FA6900] font-semibold">52</span>
                </section>
                <span className="w-[1px] h-[31px] block bg-[#E6E8E9]"></span>
                <section className="h-[73px] w-[21px] flex flex-col justify-between items-center">
                    <img alt="" src="/images/svg/height1.svg" />
                    <span className="text-[12px] text-purple font-semibold">قد</span>
                    <span className="text-[12px] text-purple font-semibold">165</span>
                </section>
            </section>
            <button className="bg-purple text-white w-[117px] h-[40px] rounded-full m-auto block mt-[25px]">ثبت برنامه</button>
        </section>
    )
}
