import React from "react";
import { imagesFileUrl } from "../../../../../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({text}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <li className="flex gap-[10px] items-center leading-[24.8px]">
        <img
          src={imagesFileUrl + "bg-waterDroplet.png"}
          className="w-[16px] h-[25px]"
          alt=""
        />
        <span>{text}</span>
      </li>
    </>
  );
}
