import React from 'react';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ fill }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.94548 0.5C1.10967 0.5 0.429688 1.17998 0.429688 2.01579V2.72316H1.60863C1.88767 2.72316 2.1139 2.94938 2.1139 3.22842C2.1139 3.50746 1.88767 3.73368 1.60863 3.73368H0.429688V5.08105H1.60863C1.88767 5.08105 2.1139 5.30728 2.1139 5.58632C2.1139 5.86536 1.88767 6.09158 1.60863 6.09158H0.429688V7.43895H1.60863C1.88767 7.43895 2.1139 7.66517 2.1139 7.94421C2.1139 8.22325 1.88767 8.44947 1.60863 8.44947H0.429688V9.79684H1.60863C1.88767 9.79684 2.1139 10.0231 2.1139 10.3021C2.1139 10.5811 1.88767 10.8074 1.60863 10.8074H0.429688V12.1547H1.60863C1.88767 12.1547 2.1139 12.381 2.1139 12.66C2.1139 12.939 1.88767 13.1653 1.60863 13.1653H0.429688V14.5126H1.60863C1.88767 14.5126 2.1139 14.7389 2.1139 15.0179C2.1139 15.2969 1.88767 15.5232 1.60863 15.5232H0.429688V16.2305C0.429688 17.0663 1.10967 17.7463 1.94548 17.7463H3.46127V0.5H1.94548ZM14.7455 0.5H4.47179V17.7463H14.7455C15.0245 17.7463 15.2507 17.5201 15.2507 17.2411V1.00526C15.2507 0.726223 15.0245 0.5 14.7455 0.5ZM12.556 9.35336V12.9632C12.556 13.2422 12.3298 13.4684 12.0507 13.4684H7.67179C7.39275 13.4684 7.16653 13.2422 7.16653 12.9632V9.35336C6.48469 9.14408 5.98758 8.50849 5.98758 7.75895C5.98758 6.83957 6.73557 6.09158 7.65495 6.09158H7.82644C8.14873 5.28326 8.93936 4.71053 9.86127 4.71053C10.7832 4.71053 11.5738 5.28326 11.8961 6.09158H12.0676C12.987 6.09158 13.735 6.83957 13.735 7.75895C13.735 8.50849 13.2378 9.14408 12.556 9.35336Z" fill={fill}/>
    </svg>
  );
}
