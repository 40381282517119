import React, { useState } from 'react';
import api from '../../../../api';
import FoodLoader from './FoodLoader';
import Modal from '../../../../components/common/Modal';
import Button from '../../../../components/common/Button';
import { useDietStore } from '../../../../store/dashboard/diet';
import { svgFileUrl } from '../../../../config';
import { showNotify } from '../../../../helper';
import { NotifyMessage, NotifyType } from '../../../../enums';
import { useBasicInfoStore } from '../../../../store/dashboard/basicInfo';
import { useAxiosWithToken } from '../../../../hooks';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ item, type }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const dietInfo = useDietStore((state) => state.dietInfo);
  const reloadDietAllInfo = useDietStore((state) => state.reloadDietAllInfo);
  const reloadBasicInfo = useBasicInfoStore((state) => state.reloadBasicInfo);
  const month = dietInfo?.month;
  const week = dietInfo?.week;
  const day = dietInfo?.day;
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [list, setList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [gram, setGram] = useState(null);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const searchFoodsNutrition = (value) => {
    /* setLoading(true); */
    if (!open && list.length > 0) {
      setOpen(true);
    } else if (!open && list.length === 0) {
      setLoading(true);
      useAxiosWithToken
        .get(
          api.foodsNutrition.searchFoodsNutrition +
          `?categoryName=${value}&pageNo=0&pageSize=1000`
        )
        .then((res) => {
          setLoading(false);
          setList(res.data.elements);
          setOpen(true);
        });
    } else {
      setOpen(false);
    }

    /* .catch((e) => setLoading(false)); */
  };
  const addFood = () => {
    setActionLoading(true);
    let params = {
      typeOfMealEnum: type,
      dairyId: rowData.id,
      consumedGram: gram,
      monthEnum: month,
      weekEnum: week,
      dayEnum: day,
    };
    useAxiosWithToken
      .put(api.personalProgram.addDairyToPersonalProgram, params)
      .then((res) => {
        setActionLoading(false);
        closeModal();
        reloadDietAllInfo();
        reloadBasicInfo();
        showNotify(NotifyMessage.SUCCESS_ACTION, NotifyType.SUCCESS)
        document.getElementById("dashBox").scrollTo({ top: 0, left: 0, behavior: "smooth" });
      })
      .catch((err) => {
        setActionLoading(false);
        closeModal();
      });
  };
  const openModal = (data) => {
    setModalOpen(true);
    setRowData(data);
  };
  const closeModal = () => {
    setGram('');
    setModalOpen(false);
    setRowData({});
  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section>
        <section
          className="w-full font-light flex justify-between p-2 bg-white border border-[#E6E8E9] cursor-pointer rounded-lg"
          onClick={() => searchFoodsNutrition(item?.identifier)}
        >
          {item.name}
          <section className="flex justify-center items-center">
            <FoodLoader loading={loading} />
            <img
              alt=""
              className={`${open ? 'rotate-180' : 'rotate-0'
                } transition-all duration-500`}
              src={svgFileUrl + 'arrowDown.svg'}
            />
          </section>
        </section>
        <section
          className={`${open ? 'max-h-40' : 'max-h-[1px] opacity-0'
            } duration-700 rounded-lg ease h-auto bg-[#F9F5FE]  overflow-auto transition-all`}
        >
          {list.map((item, index) => (
            <section
              onClick={() => openModal(item)}
              className="font-light hover:bg-[#ecdefc] transition-all duration-500 text-sm  px-2 py-2 cursor-pointer"
            >
              {item.mainFoodDescription}
            </section>
          ))}
        </section>
      </section>
      <Modal width={600} open={modalOpen} title="افزودن" onClose={closeModal}>
        <section className="flex items-center justify-between mt-4">
          <span className="font-bold text-sm text-purple">
            {rowData?.mainFoodDescription}
          </span>
          <span className="font-normal text-sm">
            {'به ازای صد گرم - ' + rowData?.energyKcal + ' کالری'}
          </span>
        </section>
        <section className="mt-5 relative">
          <span className="absolute left-5 top-[10px] text-sm">گرم</span>
          <input
            onChange={(e) => {
              let value = e.target.value.replace(/\D/g, '');
              setGram(value);
            }}
            maxLength={20}
            value={gram}
            type="text"
            className="w-full xl:w-full h-[40px] mx-auto border border-[#E6E8E9] text-sm rounded-full pr-3 pl-12"
            placeholder="مقدار مصرف خود را به گرم وارد کنید"
          />
        </section>
        {gram > 3000 && <span className='block text-red-600 font-light text-sm pr-3 mt-2'>مقدار ورودی نباید بیشتر از 3000 گرم باشد</span>}
        <section className="ltr">
          <Button
            disabled={!(gram > 0 && gram <= 3000)}
            onClick={addFood}
            loading={actionLoading}
            type="primary"
            round
            className="w-[100px]  bg-purple text-white rounded-full font-normal text-sm mr-auto mt-4"
          >
            ذخیره
          </Button>
        </section>
      </Modal>
    </>
  );
}
