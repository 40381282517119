import React from "react";
import { useEffect, useRef, useState } from "react";
/* import SearchIcon from "@mui/icons-material/Search"; */
import axios from "axios";
import './style.css'
import { isValue } from "../../../../../helper";
import FoodLoader from '../FoodLoader'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  title,
  setState,
  state,
  style,
  openModal,
  defaultValue,
  optionValue,
  optionTitle,
  optionCode,
  apiUrl,
  queryStringName,
  ...props
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const input = useRef();
  const label = useRef();
  const section = useRef();
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.token,
  };
  // ─── States ─────────────────────────────────────────────────────────────────────

  const [searchState, setSearchState] = useState(false);
  const [searchTitle, setSearchTitle] = useState();
  const [searchValue, setSearchValue] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  /* const [value, setValue] = useState(""); */

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

 
  useEffect(() => {
    setState({title:searchTitle,value:searchValue});
    // eslint-disable-next-line
  }, [searchTitle, searchValue]);
  useEffect(()=>{
     if(!isValue(state)){
      setSearchTitle("")
     }
    },[state])
 
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getData = (value) => {
    setLoading(true);
    setData([]);
    axios
      .get(apiUrl+`?${queryStringName}=${value}`, { headers })
      .then((res) => {
        setData(res?.data?.elements);
        setSearchState(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const setSearchData = (obj) => {
    openModal(obj)
    setSearchValue(obj);
    setSearchTitle(obj[optionTitle]);
    setSearchState(false);
  };
  const onchange = (e) => {
    section.current.className = "searchFieldBox focus";
    let value = e.target.value;
    if (value.length > 2) {
      getData(value);
    } else if (value.length < 2) {
      setSearchState(false);
    }
    setSearchTitle(value);
   
  };
  const onBlur = (e) => {
    section.current.className = "searchFieldBox";
    setTimeout(() => {
      setSearchState(false);
    }, 300);
  };

  const handleClick = (e) => {
    setSearchState(true)
  };
  

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="pb-3 mb-3" style={{position:"relative"}}>
      <section
        ref={section}
        onClick={handleClick}
        style={{ paddingLeft: 32 }}
        className="searchFieldBox"
      >
       {/*  <SearchIcon className="searchIcon" /> */}
        <input
          onChange={onchange}
          onBlur={onBlur}
          ref={input}
          style={{ ...style }}
          value={searchTitle}
          {...props}
        />
        <label ref={label} className="label top">
          {title}
        </label>
        {searchState ? (
          <section className="searchedItemWrapper">
            {data?.map((item, index) => (
              <span className="item" onClick={() => setSearchData(item)}>
                <span className="searchTitle">{item[optionTitle]}</span>
                {optionCode ? (
                  <span className="code">{item[optionCode]}</span>
                ) : (
                  ""
                )}
              </span>
            ))}
          </section>
        ) : (
          ""
        )}
        {loading &&   <section className="absolute top-[6px] left-0">
        <FoodLoader loading={true}/>
        </section>}
      
       
      </section>
    
    </section>
  );
}
