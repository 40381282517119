import React, { useEffect, useState } from 'react';
import { imagesFileUrl } from '../../../../config';
import FoodBox from './components/FoodBox'

import api from '../../../../api'
import { Link } from 'react-router-dom';
import Loading from '../../../../components/common/componentLoading'
import { useAxiosWithToken } from '../../../../hooks';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [recipeList, setRecipeList] = useState([])
  const [loading, setLoading] = useState(false)
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getRecipe = () => {
    setLoading(true);
    useAxiosWithToken
      .get(`${api.recipe.searchRecipe}?language=PERSIAN&pageSize=3&pageNo=0`)
      .then((res) => {
        setLoading(false);
        setRecipeList(res.data.elements);
        /*  setTotalElements(res.data.totalElements); */
      })
      .catch((e) => {
        setLoading(false);
        /* hideLoading(); */
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getRecipe()
  }, [])

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full relative  flex flex-col justify-between items-center  h-[500px] rounded-[18px] p-7 bg-gradient-to-l from-[#E4E9FE] to-[#DEBFFA] mt-6">
      <Loading loading={loading} />
      <img
        src={imagesFileUrl + '/dashboard/heart.png'}
        className="opacity-20 top-6 left-0 absolute z-0"
        alt=""
      />
      <img
        src={imagesFileUrl + '/dashboard/heart2.png'}
        className="opacity-80 top-1 right-0 absolute z-0"
        alt=""
      />
      <section className="z-10 relative w-full flex justify-between items-center">
        <span className="text-lg font-bold mr-7">دستور غذایی</span>
      </section>
      <section className='w-full overflow-x-auto z-10'>
        <section className='w-[712px]  mt-20 flex justify-between'>
          {recipeList.map((item, index) => (
            <FoodBox id={item.id} calories={item.calories} carbohydrate={item.carbohydrate} protein={item.protein} fat={item.fat} duration={item.duration} title={item.foodName} image={item.photoUrl} />
          ))}
        </section>
      </section>
      <Link to="/dashboard/recipe">
        <button className="w-[149px] h-[40px] bg-purple rounded-full text-white mt-1">
          مشاهده بیشتر
        </button>
      </Link>
    </section>
  );
}
