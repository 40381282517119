import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl } from "../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:hidden block h-[582px] mt-[100px] mb-[100px] rounded-[50px] overflow-hidden relative">
        <img src={baseFileUrl + "/images/section6-bg-7.png"} alt="" className="w-full h-full" />
        <section className="absolute flex flex-col justify-center items-center top-0 left-0  mt-[100px] px-[15px]">
          <section className="text-[30px] text-center font-bold">اپلیکیشن بادینس رو از اینجا دانلود کن!</section>

          <button className="bg-purple w-[100px] h-[40px]  rounded-full text-white font-semibold  mt-5">بزن بریم</button>
        </section>
      </section>
      <section className="hidden xl:block h-[351px] mt-[100px] mb-[100px] rounded-[50px] overflow-hidden relative">
        <img src={baseFileUrl + "/images/section7.png"} alt="" className="w-full" />
        <section className="absolute flex flex-col justify-center items-start w-[506px] h-full top-0 left-0  ml-40">
          <section className="text-[50px] font-bold">
            اپلیکیشن بادینس رو از
            <br />
            اینجا دانلود کن!
          </section>

          <button className="bg-purple  w-[250px] h-[60px] text-xl rounded-full text-white mt-10 font-bold">
            بزن بریم
          </button>
        </section>
      </section>
    </>
  );
}
