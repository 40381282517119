"use client";
import React, { useEffect, useState } from "react";
import { baseFileUrl } from "../../../../../../config";
import Descripion from "./components/Descripion";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  answer,
  question,
  tabIndex,
  activeTab,
  setActiveTab,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [open, setOpen] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (activeTab === tabIndex) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const toggle = () => {
    if (!open) {
      setActiveTab(tabIndex);
    } else {
      setActiveTab(0);
    }
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section
      onClick={toggle}
        className={`${
          open ? "grid" : ""
        } w-full items-center border-b-[1px] border-[#EFEFEF] bg-white px-4 py-6 xl:gap-6 xl:px-6 xl:py-4 dark:bg-black dark:text-white`}
      >
        <section className={`flex w-full gap-2 transition-all`}>
          <button >
            <img
              src={
                open
                  ? baseFileUrl + "/Line 944.png"
                  : baseFileUrl + "/Plus-Mobile size.png"
              }
              alt=""
              className="w-[15.8px] "
            />
          </button>
          <h5
            className={`${open?' text-[#5521B4] ':"text-[#3E4151]"} text-[14px]  font-medium xl:text-[16px] xl:font-bold  `}
          >
            {question}
          </h5>
        </section>
        <section className="">
          {open ? (
            <Descripion answer="برای انتخاب رژیم غذایی مناسب، ابتدا باید نیازها و هدف‌های خود را مشخص کنید. سپس با یک متخصص تغذیه مشورت کنید تا بتوانید بهترین برنامه را بر اساس نیازهای شخصی خود دریافت کنید." />
          ) : (
            ""
          )}
        </section>
      </section>
    </>
  );
}
