import React, { useEffect, useState } from 'react';

import Textbox from '../../../../components/common/inputs/Textbox';
import CountDown from '../Countdown';
import { imagesFileUrl, svgFileUrl } from '../../../../config';
import axios from 'axios';
import api from '../../../../api';
import { Link, useNavigate } from 'react-router-dom';
import { showNotify } from '../../../../helper';
import { NotifyMessage, NotifyType } from '../../../../enums';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  setPanelMode,
  mobileNumber,
  activeCode,
  setActiveCode,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [resendState, setResendState] = useState(false);
  const [reloadTimer, setReloadTimer] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (activeCode.length === 5) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [activeCode]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const ReloadTimer = () => {
    setResendState(false);
    setReloadTimer(!reloadTimer);
    sendMobileNumber();
  };
  const sendActiveCode = () => {
    setLoading(true);
    /* const headers = {
      Authorization: localStorage.token,
    }; */
    axios
      .post(
        api.auth.loginOrSignUp,
        { mobileNumber: mobileNumber, code: activeCode }
        /* { headers } */
      )
      .then((res) => {
        localStorage.token = `Bearer ${res.data.token}`;
        localStorage.userId = res?.data?.id;
        localStorage.mobileNumber = res?.data?.mobileNumber;

        if (res.data.firstName) {
          localStorage.firstName = res?.data?.firstName;
          localStorage.lastName = res?.data?.lastName;
        }

        if (res.data.goal) {
          navigation('/dashboard');
          setLoading(false);
        } else {
          navigation('/basicInfo');
          setLoading(false);
        }

        /*  getToken() */
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const sendMobileNumber = () => {
    setLoading(true);
    const headers = {
      Authorization: localStorage.token,
    };
    axios
      .post(
        api.auth.sendMobileNumber,
        { mobileNumber: mobileNumber },
        { headers }
      )
      .then((res) => {
        setLoading(false);
        setPanelMode('activeCode');
      })
      .catch((e) => {
        setLoading(false);
        showNotify(NotifyMessage.GLOBAL_ERROR, NotifyType.ERROR);
      });
  };
  const handleKeyUp = (e) => {
    if (e.keyCode === 13 && !btnDisable) {
      sendActiveCode();
    }
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="flex relative h-[530px] w-[90%] lg:w-[420px] flex-col items-center justify-center gap-[18px] rounded-lg py-8 px-8 text-gray-200 bg-white">
      <span
        className="absolute top-5 left-5 cursor-pointer hover:text-blue transition-all duration-200 font-semibold"
        onClick={() => setPanelMode('login')}
      >
        بازگشت
      </span>
      <section className="w-[160px] h-[160px] rounded-lg mb-5 p-2 bg-white">
        <Link to="/">
          <img src={imagesFileUrl + 'logoWhite.png'} alt="" />
        </Link>
      </section>
      <h2 className="title text-[16px] text-purple">
        کد فعالسازی را وارد نمایید
      </h2>
      <section className='w-full relative'>
        <Textbox
          focus={true}
          title="کد فعالسازی"
          type={showPassword ? "text" : "password"}
          placeHolder="XXXXX"
          style={{ height: "48px", fontSize: "20px", textAlign: "center" }}
          onChange={(e) => setActiveCode(e.target.value)}
          onKeyUp={handleKeyUp}
        />
        <img onClick={() => setShowPassword(!showPassword)} src={`${svgFileUrl}${showPassword ? `/eye-off.svg` : `/eye.svg`}`} alt='' className='absolute cursor-pointer top-[15px] left-[10px] w-[30px] h-[30px] ' />
      </section>


      <button
        onClick={ReloadTimer}
        className="text-blue font-bold disabled:text-silver transition-all duration-400 text-purple"
        disabled={!resendState}
      >
        ارسال مجدد
      </button>
      <button
        onClick={sendActiveCode}
        className="w-full flex justify-center items-center rounded-full  bg-gradient-to-t from-[#f02483] to-[#5408aa]  py-3 text-sm text-white   md:w-fit md:px-16 md:py-[0.7rem] md:text-lg disabled:opacity-50"
        disabled={btnDisable}

      >
        ورود
        {loading && <div class="lds-dual-ring"></div>}
      </button>
      <CountDown
        totalCount={180}
        setStopState={setResendState}
        reload={reloadTimer}
        resendState={resendState}
      />
    </section>
  );
}
