import React from 'react';


import Light from '../../../assets/images/basicInfo/light.png';
import Male from '../../../assets/images/home/man.png';
import Female from '../../../assets/images/home/woman.png';
import Progress from '../components/Progress';
import Step from '../../../assets/images/basicInfo/step1L.png';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setStep, activeStep, setGender }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const goToNext = (gender) => {
    setGender(gender);
    setStep(2);
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Progress stepPic={Step} activeStep={activeStep} setStep={setStep} />
      <section className='h-[80%] flex flex-col justify-between items-center xl:block'>

        <span className="block text-center font-bold text-xl xl:text-3xl mt-7">
          درباره خودت به ما بگو
        </span>
        <section className="m-auto xl:w-[565px] border border-[#efefef] rounded-[16px] min-h[100px] flex justify-center items-center p-3 mt-7">
          <img src={Light} alt="" />
          <p className='text-[#979797]'>
            این به ما کمک می کند تا میزان متابولیسم پایه شما را محاسبه کرده و با
            برنامه شخصی شما سازگار شویم
          </p>
        </section>
        <section className="flex justify-between xl:w-[500px] m-auto mt-[150px]">
          <section className="w-[46%] xl:h-[269px] rounded-[12px] flex flex-col justify-center cursor-pointer shadow-lg" onClick={() => goToNext("MALE")}>
            <img src={Male} className="mt-[-100px] xl:h-[300px]" alt="" />
            <span className="text-[16px] mt-[20px] block text-center">آقا</span>
          </section>
          <section className="w-[46%] xl:h-[269px] rounded-[12px] cursor-pointer flex flex-col justify-center  shadow-lg" onClick={() => goToNext("FEMALE")}>
            <img
              src={Female}
              alt=""
              className="mt-[-100px] xl:h-[300px]"

            />
            <span className="text-[16px] mt-[20px] block text-center">خانم</span>
          </section>
        </section>
      </section>
    </>

  );
}
