import React from 'react';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ fill }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.06944 3.55556C5.06944 2.61256 5.44405 1.70819 6.11084 1.0414C6.77764 0.374602 7.68201 0 8.625 0C9.56799 0 10.4724 0.374602 11.1392 1.0414C11.806 1.70819 12.1806 2.61256 12.1806 3.55556C12.1806 4.49855 11.806 5.40292 11.1392 6.06971C10.4724 6.73651 9.56799 7.11111 8.625 7.11111C7.68201 7.11111 6.77764 6.73651 6.11084 6.06971C5.44405 5.40292 5.06944 4.49855 5.06944 3.55556ZM5.06944 8.88889C3.8907 8.88889 2.76024 9.35714 1.92675 10.1906C1.09325 11.0241 0.625 12.1546 0.625 13.3333C0.625 14.0406 0.905952 14.7189 1.40605 15.219C1.90615 15.719 2.58442 16 3.29167 16H13.9583C14.6656 16 15.3439 15.719 15.844 15.219C16.344 14.7189 16.625 14.0406 16.625 13.3333C16.625 12.1546 16.1567 11.0241 15.3233 10.1906C14.4898 9.35714 13.3593 8.88889 12.1806 8.88889H5.06944Z"
        fill={fill}
      />
    </svg>
  );
}
