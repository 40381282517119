import React, { useState, useContext, useEffect } from "react";
import { imagesFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:w-[1360px] w-[90%] px-5 overflow-hidden rounded-[10px] h-[505px] xl:h-[539px] xl:pr-[114px] xl:pl-[200px] flex items-center justify-between bg-[#F9F5FE] mx-auto relative mt-[40px]">
        <img
          src={imagesFileUrl + "/Union.png"}
          alt=""
          className="xl:block hidden xl:w-[956px] absolute left-0 h-auto rotate-180"
        />
        <img
          src={imagesFileUrl + "/UnionMbile.png"}
          alt=""
          className=" xl:hidden w-full absolute left-0 h-full rotate-180"
        />
        <section className="xl:w-[575px] w-full z-[10] mt-[250px]  xl:h-[116px] flex xl:flex-row flex-col xl:items-center xl:justify-between">
          <span className="text-[70px] xl:hidden  font-extrabold text-[#5408A9]">1</span>
          <span className="xl:w-[476px] xl:mt-0 mt-[-20px] w-full text-[#191919]  xl:leading-[46.4px] text-[20px] xl:text-[32px] font-bold ">
            غذای سالم <br />
            به بهترین شکل زندگی خودت برس!
          </span>
        </section>
        <img
          src={imagesFileUrl + "/image1.png"}
          alt=""
          className="xl:w-[450px] w-[90%] absolute xl:left-[50px] top-[20%]"
        />
        <img
          src={imagesFileUrl + "/image2.png"}
          alt=""
          className="w-[90%] xl:w-[450px] absolute xl:left-[142px] top-[5px]"
        />
        <img
          src={imagesFileUrl + "/bg-my-service.png"}
          alt=""
          className="w-[90%] xl:w-[365px] absolute top-[9%] xl:left-[220px] xl:top-[100px]"
        />
      </section>
    </>
  );
}
