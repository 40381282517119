import toast from "react-hot-toast";
export const mobile_Regex = new RegExp(/^[0][9][0-9][0-9]{8}$/);
export const isValue = (value) => {
  if (value !== "" && value !== null && value !== undefined) {
    return true;
  } else {
    return false;
  }
};
export const showNotify = (text, type) => {
  if (type === "success") {
    /* JSX Content */
    /* toast((t) => (
      <span>
        Custom and <b>bold</b>
        <button onClick={() => toast.dismiss(t.id)}>Dismiss</button>
      </span>
    )); */
    toast.success(text, {
      style: {
        border: "1px solid #1CAE81",
        padding: "16px",
        color: "black",
        backgroundColor: "#DDF3EC",
      },
      iconTheme: {
        primary: "#1CAE81",
        secondary: "#FFFAEE",
      },
    });
  }
  if (type === "error") {
    toast.error(text, {
      style: {
        border: "1px solid red",
        padding: "16px",
        color: "black",
        backgroundColor: "#ffc7c7",
      },
      iconTheme: {
        primary: "red",
        secondary: "#ffc7c7",
      },
    });
  }
};
export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
export const calcBMI = (height, weight) => {
  let hei = height / 100;
  return Math.floor(weight / (hei * hei));
};
export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};
