import React, { useState, useContext, useEffect } from 'react'

import ExerciseList from './components/ExerciseList'
import ExerciseListView from './components/ExerciseListView'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────
    const [activeTab, setActiveTab] = useState(1);
    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <>
            <section className=" h-auto mt-[40px] py-[32px] px-[26px] bg-white rounded-[16px] shadow-md">

                <ExerciseList />

            </section>
            <section className=" h-auto mt-[40px] p-[24px] bg-white rounded-[16px] shadow-md">
                <section className='text-lg font-bold'>برنامه تمرینی نهایی</section>
                <ExerciseListView />
            </section>
        </>
    )
}
