import React from "react";
import Map from "./components/Map";
import CommentsModal from "./components/CommentsModal";
import Info from "./components/Info";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="relative h-[800px] xl:h-[620px] mt-[62px] xl:mt-[80px] w-full bg-[url('/public/images/ContactUs.jpg')] bg-cover">
        <section className="h-full w-full  bg-gradient-to-br from-black  to-[#5606a8] opacity-95"></section>
        <section className="absolute left-[50%] top-[5%] xl:top-[15%]  z-[900] w-full translate-x-[-50%]">
          <section className="mx-auto flex w-[80%] xl:w-[400px] flex-col justify-center justify-items-center gap-4 text-white">
            <h5 className="text-center text-lg"> ارتباط با بادینس</h5>
            <h2 className="text-center text-3xl font-bold leading-9">با ما در ارتباط باشید:</h2>
            <span className="text-center">اگر سوال یا نظری دارید لطفا با ما در میان بگذارید:</span>
          </section>
          <CommentsModal />
        </section>
      </section>
      <section className="flex flex-col xl:flex-row h-auto xl:h-[500px]">
        <section className="h-auto my-8 mx-auto flex xl:pl-[20px] xl:pr-[170px] xl:pt-[130px] items-center w-[90%] xl:w-full">
          <Info />
        </section>
        <Map />
      </section>
    </>
  );
}
