import React, { useState, useContext, useEffect } from 'react'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='w-full flex items-center py-[27px] px-[16px]  h-[94px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]'>
            <span className='w-[72px] text-[#3E4151] font-bold absolute top-[-12px] bg-white right-4'>توضیحات</span>
            <section className=' text-[14px] text-[#3E4151]'>
                لورم ایپسوم متن ساختگی با تولید سادگی....
            </section>
        </section>
    )
}
