import React, { useState, useContext, useEffect } from 'react'
import Filter from './components/Filter'
import List from './components/List'
import Description from './components/Description'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className='mt-[30px]'>
            <Filter />
            <List />
            <Description />
            <button className="bg-[#F3509D] m-auto mt-[35px] text-white w-full h-[40px] rounded-md text-sm flex justify-center gap-1 items-center">
                ذخیره  تمررین
            </button>
        </section>
    )
}
