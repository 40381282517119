import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl } from "../../../../../config";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  src,
  height,
  title,
  text,
  backGround,
  flex,
  border,
  price,
  width,
  absolute,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section
        className={`${
          flex ? flex : "flex-col"
        }  ${backGround} ${border} w-full flex  relative   ${height} border  p-6  rounded-[20px] border-solid `}
      >
        <section>
          <h3 className={` leading-[34.1px]  text-[22px] font-bold`}>
            {title}
          </h3>
          <section className="my-6 flex items-start gap-[6px]">
            <span className="text-[#5521B4] bg-white px-3 py-2 font-bold text-sm rounded-[150px]">
              ویدیوکال
            </span>
            <span className="text-[#5521B4] bg-white px-3 py-2 font-bold text-sm rounded-full">
              چت
            </span>
            <span className="text-[#5521B4] bg-white px-3 py-2 font-bold text-sm rounded-full">
              تمرین
            </span>
            <span className="text-[#5521B4] bg-white px-3 py-2 font-bold text-sm rounded-full">
              تغذیه
            </span>
          </section>
          <img
            src={baseFileUrl + "/images/rate-timer.png"}
            alt=""
            className="w-[126px] mb-6"
          />
          <section className="font-bold text-[#191919]">
            <span className="text-[24px] leading-[37.2px] mb-5">{price}</span>
            تومان
          </section>
          <button className="text-[#5521B4] h-10 font-bold text-sm leading-[16px] ">
            مشاهده بیشتر
          </button>
        </section>

        <img
          src={baseFileUrl + src}
          alt=""
          className={`${width ? width : "w-full"} ${
            absolute ? absolute : ""
          }  mt-6`}
        />
      </section>
    </>
  );
}
