import React from "react";
import { svgFileUrl } from "../../../../../config";
import { useNavigate } from "react-router-dom";
import { useDietStore } from "../../../../../store/dashboard/diet";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ title, dayNumber, mode, item, currentWeek, currentMonth }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  const updateDietInfo = useDietStore((state) => state.updateDietInfo);
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const setInfo = () => {

    let info = {
      month: currentMonth,
      week: currentWeek,
      day: item.dayEnum,
      consumedFat: item.consumedGramFatInTheDay,
      consumedProtein: item.consumedGramProteinInTheDay,
      consumedCarbohydrate: item.consumedGramCarbohydrateInTheDay,
      recommendedCalory: item.recommendedCaloriesInTheDay,
      percentCalory: item.percentUsedCaloriesInTheDay,
      consumedCalory: item.consumedCaloriesInTheDay
    };
    updateDietInfo(info);
    navigation("/dashboard/diet");

  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section onClick={setInfo} className={`w-[42px] h-[76px] rounded-full cursor-pointer flex flex-col justify-between py-3 overflow-hidden items-center relative text-center border border-[#B7E9F7] ${item?.currentDay === true ? "bg-[#4CC9EC] text-white" : mode === "firstDay" ? "bg-[##EEFAFE] text-[#191919] border-[#70D4F0]" : mode === "active" ? "bg-white text-[#191919]" : ""}`}>
      {mode === "deActive" ? (
        <section className="glass w-full h-full absolute top-0 left-0 flex justify-center items-center">
          <img src={svgFileUrl + "lock.svg"} alt="" />
        </section>
      ) : (
        ""
      )}
      {/*  <span className="text-xs font-semibold">{title}</span> */}
      <span className="text-xs font-semibold">روز</span>
      <span className="text-sm font-bold">{dayNumber}</span>
    </section>
  );
}
