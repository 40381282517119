import React from 'react';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import './style.css'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setState, state }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const style = {};
  const marks = {
    0: "s",
    25: "s",
    50: "s",
    75: "s",
    100: "s",

  };
  /* 0.3 0.4 0.5 */

  function setValue(value) {
    if (value === 0) {
      setState(1)
    }
    else if (value === 25) {
      setState(2)
    }
    else if (value === 50) {
      setState(3)
    }
    else if (value === 75) {
      setState(4)
    }
    else if (value === 100) {
      setState(5)
    }


  }
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────


  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className='basicActivitySelector' style={style}>
        <Slider min={0} /* value={state === 1 ? 0 : state === 2 ? 25 : state === 3 ? 50 : state === 4 ? 75 : state === 5 ? 100 : 0} */ marks={marks} step={null} onChange={setValue} defaultValue={state === 4 ? 75 : 0} />
      </section>
    </>
  );
}
